import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowNarrowLeft, HiOutlineCalendar, HiOutlineCash, HiOutlineCurrencyDollar, HiPencilAlt } from 'react-icons/hi';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLoanById } from 'api/loanFundManager/loanApi';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import CardIndexGroup from 'components/common/CardIndexGroup';
import AddLoanModal from 'components/modal/loan/addLoanModal';
import { AbilityContext } from 'contexts/can';
import moment from 'moment';
import PaymentsPage from 'pages/loanFundManager/payments/payments';
import { paymentAction } from 'redux/actions';
import { ABILITY_ACTION, FORMAT_DATE_SHOW_TABLE, PERMISSION, ROUTE_PATHS } from 'utils/constants';
import { LoanType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import { getNoteByEntityId } from '../../../../api/noteApi';
import AddNotesLoanModal from '../../../../components/modal/loan/addNotesLoanModal';

import LoanCollateral from './components/LoanCollateral';
import LoanNote from './components/loanNote';

import './renderLoanDetail.scss';

const RenderLoanDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation()
  const params = useParams();
  const ability = useContext(AbilityContext);
  const partnerId = params.partnerId;
  const loanId = params.id;
  const [openEditPartnerModal, setOpenEditPartnerModal] = useState(false);
  const [openAddLoanNotesModal, setOpenAddLoanNotesModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState<LoanType>();

  const { refetch: fetchLoanById, isFetching } = useQuery(['loan'], () => getLoanById(loanId as string), {
    onSuccess: ({ data }) => {
      setSelectedLoan(data);
    },
    onError: () => {
      setSelectedLoan(undefined);
    },
  });

  const { data: notesStore } = useQuery(
    ['getLoanNotes', selectedLoan?.notes?.length, selectedLoan?.id],
    () => getNoteByEntityId(selectedLoan?.id as string),
    {
      enabled: !!selectedLoan?.id,
    },
  );

  const cardIndexData = [
    {
      bgColor: 'bg-indigo-50',
      bgIconColor: 'bg-indigo-500',
      textIconColor: 'text-white',
      label: t('adminFee'),
      value: selectedLoan?.adminFee ? `${getNumberValueFromApi(selectedLoan?.adminFee as number).toFixed(2)} %` : '',
      icon: HiOutlineCurrencyDollar,
    },
    {
      bgColor: 'bg-red-50',
      bgIconColor: 'bg-red-500',
      textIconColor: 'text-white',
      label: t('interestRate'),
      value: selectedLoan?.interestRate ? `${getNumberValueFromApi(selectedLoan?.interestRate as number).toFixed(2)} %` : '',
      icon: HiOutlineCurrencyDollar,
    },
    {
      bgColor: 'bg-gray-100',
      bgIconColor: 'bg-gray-400',
      textIconColor: 'text-white',
      label: t('interestDenominator'),
      value: selectedLoan?.interestDenominator ? `${selectedLoan?.interestDenominator as number} ${t('days')}` : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-green-100',
      bgIconColor: 'bg-green-500',
      textIconColor: 'text-white',
      label: t('term'),
      value: selectedLoan?.term ? `${selectedLoan?.term} ${t('months')}` : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-yellow-50',
      bgIconColor: 'bg-yellow-400',
      textIconColor: 'text-white',
      label: t('amount'),
      value: convertNumberToCurrency(getNumberValueFromApi(selectedLoan?.amount as number)) as string,
      icon: HiOutlineCash,
    },
    {
      bgColor: 'bg-orange-100',
      bgIconColor: 'bg-orange-500',
      textIconColor: 'text-white',
      label: t('payInterest'),
      value: selectedLoan?.payInterest ? (selectedLoan?.payInterest as string) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-teal-100',
      bgIconColor: 'bg-teal-500',
      textIconColor: 'text-white',
      label: t('payPrincipal'),
      value: selectedLoan?.payPrincipal ? (selectedLoan?.payPrincipal as string) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-indigo-100',
      bgIconColor: 'bg-indigo-500',
      textIconColor: 'text-white',
      label: t('disbursementDate'),
      value: selectedLoan?.disbursementDate ? moment.utc(selectedLoan?.disbursementDate).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-purple-100',
      bgIconColor: 'bg-purple-500',
      textIconColor: 'text-white',
      label: t('firstPaymentDate'),
      value:
        selectedLoan?.firstPrincipalPayment && selectedLoan?.firstInterestPayment
          ? moment
            .utc(
              moment(selectedLoan?.firstPrincipalPayment).toDate() < moment(selectedLoan?.firstInterestPayment).toDate()
                ? selectedLoan?.firstPrincipalPayment
                : selectedLoan?.firstInterestPayment,
            )
            .format(FORMAT_DATE_SHOW_TABLE)
          : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-pink-100',
      bgIconColor: 'bg-pink-500',
      textIconColor: 'text-white',
      label: t('maturity'),
      value: selectedLoan?.maturity ? moment.utc(selectedLoan?.maturity as string).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-gray-100',
      bgIconColor: 'bg-gray-400',
      textIconColor: 'text-white',
      label: t('Write Off Date'),
      value: selectedLoan?.writeOffDate ? moment.utc(selectedLoan?.writeOffDate as string).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
  ];

  const editLoanHandler = () => {
    setOpenEditPartnerModal(!openEditPartnerModal);
    setIsEdit(!isEdit);
  };

  const addLoanNotesHandler = () => {
    setOpenAddLoanNotesModal(!openAddLoanNotesModal);
  };

  return (
    <div className="loan-detail-page flex flex-col justify-start items-center">
      <div className="header w-full flex justify-between items-center px-4 py-4">
        <HiArrowNarrowLeft
          className="header__icon leading-none text-gray-900 text-base font-medium cursor-pointer border border-gray-900 rounded-full p-1"
          size={36}
          onClick={() => {
            navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}`);
            dispatch(paymentAction.setPaymentsOfLoan(undefined));
          }}
        />
        <h3 className="header__title leading-none text-gray-900 text-base font-medium">{selectedLoan?.name && t('loanDetails', { loanName: selectedLoan?.name })}</h3>
        {ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.UPDATE) ? (
          <div className="flex space-x-4">
            <ButtonIconWithText className="header__button bg-blue-900" text={t('editLoan')} onClick={() => editLoanHandler()} Icon={HiPencilAlt} />
          </div>
        ) : (
          <div className="header__button" />
        )}
      </div>
      <div className="loan-detail h-full w-full px-4 pb-4 flex flex-col justify-center items-center">
        <div className="loan-detail__top w-full">
          <CardIndexGroup cardIndexData={cardIndexData.filter((item, index) => index < 5)} className="grid grid-cols-5 gap-4 mb-3" />
        </div>
        <div className="loan-detail__top w-full">
          <CardIndexGroup cardIndexData={cardIndexData.filter((item, index) => index > 4)} className="grid grid-cols-6 gap-4 mb-3" />
        </div>
        <div className="loan-detail__middle w-full">
          {selectedLoan?.collateral && <LoanCollateral selectedLoan={selectedLoan} isFetching={isFetching} />}
        </div>
        <div className="loan-detail__bottom h-full w-full mb-4">
          <PaymentsPage fetchLoanById={fetchLoanById} selectedLoan={selectedLoan} isFetchingLoan={isFetching} />
        </div>
        <div className="loan-detail__notes w-full">
          <LoanNote notes={notesStore?.data?.entities} isFetching={isFetching} addLoanNotes={addLoanNotesHandler}
            ability={ability} />
        </div>
      </div>
      <AddLoanModal
        openModal={openEditPartnerModal}
        setOpenModal={setOpenEditPartnerModal}
        refetchApi={fetchLoanById}
        selectedLoan={selectedLoan}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <AddNotesLoanModal
        openModal={openAddLoanNotesModal}
        notes={notesStore?.data?.entities}
        setOpenModal={setOpenAddLoanNotesModal}
        selectedLoan={selectedLoan}
      />
    </div>
  );
};

export default RenderLoanDetail;
