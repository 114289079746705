import { useTranslation } from 'react-i18next';
const searchLogoPath = require('../../assets/image/svg/search-normal.svg').default || '';

const NoResult = () => {
  const [t] = useTranslation();
  return (
    <div data-testid="no-result-content" className="flex flex-col w-full h-full items-center justify-center">
      <img src={searchLogoPath} alt="logo" />
      <div className="text-gray-900 text-3xl font-normal mt-3">{t('searchResult.title')}</div>
      <div className="text-gray-700 text-lg font-normal">{t('searchResult.subTitle')}</div>
    </div>
  );
};
export default NoResult;
