import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { exportSummaryReportsToCSV, getSummaryReports } from 'api/accountant/reportApi';
import PaginateTable from 'components/table/paginate';
import { Button } from 'flowbite-react';
import useLoading from 'hooks/useLoading';
import moment from 'moment';
import { DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME, FORMAT_DATE_API } from 'utils/constants';
import { FilterReportsValueType, FilterType, SummaryReportsResponseType } from 'utils/proptypes';
import { exportRowDataToCSV, messageErrors } from 'utils/utils';

import HeaderTableReports from './components/headerTableReports';
import RenderSummaryReportsTableData from './components/summaryReports/renderSummaryReportsTable';

import '../../../../styles/styles.scss';
import './summaryReportPdf.scss';

const SummaryReportPage = () => {
  const [currentPagePerforming, setCurrentPagePerforming] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [currentPageNonPerforming, setCurrentPageNonPerforming] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntitiesPerforming, setTotalEntitiesPerforming] = useState(0);
  const [totalEntitiesNonPerforming, setTotalEntitiesNonPerforming] = useState(0);
  const limit = 100;
  const [summaryReportsDataPerforming, setSummaryReportsDataPerforming] = useState<SummaryReportsResponseType>();
  const [summaryReportsDataNonPerforming, setSummaryReportsDataNonPerforming] = useState<SummaryReportsResponseType>();
  const [filterValue, setFilterValue] = useState<Partial<FilterReportsValueType>>({
    reportDate: moment().format(FORMAT_DATE_API),
    isNonPerforming: 0,
  });
  const refPerforming = useRef(null);
  const refReportPDF = useRef(null);
  const { t } = useTranslation();

  const filterArray: FilterType[] = [
    {
      placeholder: t('reportDates'),
      type: 'date',
      isSelectRangeDate: false,
      defaultValue: moment().format(FORMAT_DATE_API),
    },
  ];

  const { isFetching: isFetchingPerforming } = useQuery(
    ['performingSummaryReports', currentPagePerforming, filterValue],
    () => getSummaryReports({ page: currentPagePerforming, limit, ...filterValue, isNonPerforming: 0 }),
    {
      onSuccess: ({ data }) => {
        setTotalEntitiesPerforming(data.totalEntities);
        setSummaryReportsDataPerforming({ ...data });
      },
      onError: () => {
        setSummaryReportsDataPerforming(undefined);
        setTotalEntitiesPerforming(0);
      },
    },
  );

  const { isFetching: isFetchingNonPerforming } = useQuery(
    ['nonPerformingSummaryReports', currentPageNonPerforming, filterValue],
    () => getSummaryReports({ page: currentPageNonPerforming, limit, ...filterValue, isNonPerforming: 1 }),
    {
      onSuccess: ({ data }) => {
        setTotalEntitiesNonPerforming(data.totalEntities);
        setSummaryReportsDataNonPerforming({ ...data });
      },
      onError: () => {
        setSummaryReportsDataNonPerforming(undefined);
        setTotalEntitiesNonPerforming(0);
      },
    },
  );

  const handleExportSummaryReports = async (isNonPerforming: number) => {
    await exportSummaryReportsToCSV({ ...filterValue, isNonPerforming }).then(async res => {
      exportRowDataToCSV({
        stream: res.data,
        fileName: isNonPerforming ? EXPORT_FILE_NAME.MONTHLY_SUMMARY_REPORT.NON_PERFORMING : EXPORT_FILE_NAME.MONTHLY_SUMMARY_REPORT.PERFORMING,
      });
    });
  };

  const { refetch: refetchExportPerformingSummaryReports, isFetching: isFetchingDownloadPSR } = useQuery(
    'download-performing-summary-reports',
    () => handleExportSummaryReports(0),
    {
      onSuccess: () => {
        const message: string = t('reportPage.exportToCsvSuccess');
        toast.success(message);
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
      enabled: false,
    },
  );

  const { refetch: refetchExportNonPerformingSummaryReports, isFetching: isFetchingDownloadNPSR } = useQuery(
    'download-non-performing-summary-reports',
    () => handleExportSummaryReports(1),
    {
      onSuccess: () => {
        const message: string = t('reportPage.exportToCsvSuccess');
        toast.success(message);
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
      enabled: false,
    },
  );

  useLoading({
    isLoading: (isFetchingPerforming && isFetchingNonPerforming) || isFetchingDownloadNPSR || isFetchingDownloadPSR,
    ref: refPerforming,
  });

  const handleDownloadPDF = useReactToPrint({
    content: () => refReportPDF.current,
    documentTitle: `MONTHLY SUMMARY REPORT ${filterValue.reportDate}`,
    copyStyles: true,
  });

  return (
    <div className="summary-report">
      <LoadingBar color="#a1c93a" ref={refPerforming} shadow={true} containerStyle={{ height: '3px' }} />
      <HeaderTableReports<FilterReportsValueType>
        className="summary-report"
        hideFilter={false}
        setCurrentPage={setCurrentPagePerforming}
        setFilterValue={setFilterValue}
        filterArray={filterArray}
      />
      <div className="summary-report__table mt-4">
        <RenderSummaryReportsTableData
          reportDate={filterValue.reportDate as string}
          summaryReportsResponse={summaryReportsDataPerforming}
          isFetching={isFetchingPerforming}
        />
      </div>
      {totalEntitiesPerforming > 0 && (
        <PaginateTable
          className="summary-report__pagination"
          limit={limit}
          setCurrentPage={setCurrentPagePerforming}
          currentPage={currentPagePerforming}
          totalEntities={totalEntitiesPerforming}
          isFetching={isFetchingPerforming}
          isFetchingDownload={isFetchingDownloadPSR}
          exportHandler={refetchExportPerformingSummaryReports}
          items={summaryReportsDataPerforming}
        />
      )}
      <div className="summary-report__table mt-4">
        <RenderSummaryReportsTableData
          reportDate={filterValue.reportDate as string}
          summaryReportsResponse={summaryReportsDataNonPerforming}
          isFetching={isFetchingNonPerforming}
          isRenderNonPerformingRecords={true}
        />
      </div>
      {totalEntitiesNonPerforming > 0 && (
        <PaginateTable
          className="summary-report__pagination"
          limit={limit}
          setCurrentPage={setCurrentPageNonPerforming}
          currentPage={currentPageNonPerforming}
          totalEntities={totalEntitiesNonPerforming}
          isFetching={isFetchingNonPerforming}
          isFetchingDownload={isFetchingDownloadNPSR}
          exportHandler={refetchExportNonPerformingSummaryReports}
          items={summaryReportsDataNonPerforming}
        />
      )}
      {(totalEntitiesPerforming > 0 || totalEntitiesNonPerforming > 0) && (
        <Button className="download-reports-button text-white font-normal" onClick={() => handleDownloadPDF()}>
          {t('downloadPDF')}
        </Button>
      )}
      <div ref={refReportPDF} className="download-pdf-content" style={{ marginTop: '1rem' }}>
        <div className="summary-report-pdf summary-report__table">
          {totalEntitiesPerforming > 0 && (
            <RenderSummaryReportsTableData reportDate={filterValue.reportDate as string} summaryReportsResponse={summaryReportsDataPerforming} />
          )}
          {totalEntitiesNonPerforming > 0 && (
            <RenderSummaryReportsTableData
              reportDate={filterValue.reportDate as string}
              summaryReportsResponse={summaryReportsDataNonPerforming}
              isRenderNonPerformingRecords={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SummaryReportPage;
