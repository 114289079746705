import { FilterPaymentsScheduleValueType, QueryFilterType } from '../../utils/proptypes';
import base from '../baseApi';

export type AddSchedulePaymentType = {
  loanId: string;
  paymentDate: string;
  principalAmount: number;
};

export type UpdateSchedulePaymentType = {
  paymentDate: string;
  principalAmount: number;
  status: string;
};

const { urls, methods, execute } = base();

const getPaymentsScheduleByFilter = async (queryFilter: QueryFilterType) => {
  const { page, limit, searchQuery, f_startDate, f_endDate } = queryFilter;
  const method = methods.GET;
  const url = `${urls.schedulePayment.filter}?page=${page}&limit=${limit}&searchQuery=${searchQuery ? searchQuery : ''}${
    f_startDate ? `&f_startDate=${f_startDate}` : ''
  }${f_endDate ? `&f_endDate=${f_endDate}` : ''}`;
  return execute(method, url, { authorization: true });
};

const getPaymentsScheduleByLoanId = (loanId: string) => {
  const method = methods.GET;
  const url = `${urls.schedulePayment.schedulePayments}/loan/${loanId}`;
  return execute(method, url, { authorization: true });
};

const createSchedulePayment = (payload: AddSchedulePaymentType) => {
  const method = methods.POST;
  const url = urls.schedulePayment.schedulePayments;
  return execute(method, url, { authorization: true }, payload);
};

const updateSchedulePaymentById = (id: string, payload: UpdateSchedulePaymentType) => {
  const method = methods.PATCH;
  const url = `${urls.schedulePayment.schedulePayments}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const recalculateSchedulePaymentById = (loanId: string) => {
  const method = methods.PATCH;
  const url = `${urls.schedulePayment.recalculateSchedulePayments}/${loanId}`;
  return execute(method, url, { authorization: true });
};

const exportLoanPaymentsScheduleToCSV = async (payload: FilterPaymentsScheduleValueType & { searchQuery: string }) => {
  const method = methods.POST;
  const url = urls.schedulePayment.exportPaymentsToCSV;
  return execute(method, url, { authorization: true }, payload);
};

const removeSchedulePayment = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.schedulePayment.schedulePayments}/${id}`;

  return execute(method, url, { authorization: true });
};

export { createSchedulePayment, updateSchedulePaymentById, getPaymentsScheduleByLoanId, recalculateSchedulePaymentById, removeSchedulePayment,getPaymentsScheduleByFilter,exportLoanPaymentsScheduleToCSV };