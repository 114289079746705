import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { NoteType, QueryFilterType } from 'utils/proptypes';

import base from '../baseApi';

export type AddUpdateLoanType = {
  partnerId: string;
  name: string;
  amount: number;
  interestRate: number;
  term: number;
  startDate: string;
  maturity: string;
  interestPayment: string;
  principalPayment: string;
  collateral?: string;
  note?: NoteType[];
};

type GetLoansByPartnerIdType = QueryFilterType & {
  partnerId?: string;
};

const { urls, methods, execute } = base();

const createLoan = (payload: AddUpdateLoanType) => {
  const method = methods.POST;
  const url = urls.loan.loans;
  return execute(method, url, { authorization: true }, payload);
};

const getLoansByFilter = async (queryFilter: QueryFilterType) => {
  const { page = DEFAULT_VALUE_FILTER.PAGE, limit = DEFAULT_VALUE_FILTER.LIMIT, searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY } = queryFilter;
  const method = methods.GET;
  const url = `${urls.loan.loans}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  return execute(method, url, { authorization: true });
};

const getLoanById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.loan.loans}/${id}`;
  return execute(method, url, { authorization: true });
};

const getLoansByPartnerId = (queryFilterById: GetLoansByPartnerIdType) => {
  const {
    page = DEFAULT_VALUE_FILTER.PAGE,
    limit = DEFAULT_VALUE_FILTER.LIMIT,
    searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY,
    partnerId,
  } = queryFilterById;
  const method = methods.GET;
  const url = `${urls.loan.loans}/partner/${partnerId}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  return execute(method, url, { authorization: true });
};

const updateLoanById = (id: string, payload: AddUpdateLoanType) => {
  const method = methods.PATCH;
  const url = `${urls.loan.loans}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const removeLoan = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.loan.loans}/${id}`;

  return execute(method, url, { authorization: true });
};

const exportLoansToCSV = async (body: { partnerId: string }) => {
  const method = methods.POST;
  const url = urls.loan.exportLoansToCSV;
  return execute(method, url, { authorization: true }, body);
};

export { createLoan, getLoansByFilter, getLoanById, updateLoanById, removeLoan, getLoansByPartnerId, exportLoansToCSV };
