import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserCurrentUser } from 'api/userApi';
import { AbilityContext } from 'contexts/can';
import { ABILITY_ACTION, TOKEN_KEY, USER_ROLES } from 'utils/constants';
import { deleteCookie } from 'utils/cookie';
import { convertPermissionToAbilities } from 'utils/utils';

import { userAction } from '../redux/actions';
import { UserType } from '../utils/proptypes';

const useUser = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);

  const getUserRole = (data: UserType): string[] => {
    const { roles } = data;
    if (roles && roles.length > 0) {
      return roles?.map((item: any) => item.displayName);
    }
    return [USER_ROLES.STANDARD_USER];
  };

  const { refetch: fetchCurrentUser } = useQuery('getCurrentUser', getUserCurrentUser, {
    enabled: false,
    onSuccess: async ({ data }) => {
      const userRole = getUserRole(data);
      ability.update(
        userRole?.includes(USER_ROLES.SUPER_ADMIN)
          ? [
              {
                action: ABILITY_ACTION.manage,
                subject: 'all',
              },
            ]
          : convertPermissionToAbilities(data?.roles),
      );
      dispatch(userAction.setUserInfo({ ...data, userRole }));
    },
  });

  const handleLogout = () => {
    deleteCookie(TOKEN_KEY);
    logout({ returnTo: `${window.location.origin}` });
  };

  return {
    handleLogout,
    fetchCurrentUser,
  };
};

export default useUser;
