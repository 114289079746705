import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { QueryFilterType, QueryUserOrganizationFilter } from 'utils/proptypes';

import base from './baseApi';

export type addEditUserType = {
  emailAddress: string;
  emailVerified?: boolean;
  firstName: string;
  lastName: string;
  roleIds: string[];
};

export type changeStatusType = {
  isActive: boolean;
  updatedProfile: boolean;
};

const { urls, methods, execute } = base();
const getUserByFilter = async (queryFilter: QueryFilterType) => {
  const { page = DEFAULT_VALUE_FILTER.PAGE, limit = DEFAULT_VALUE_FILTER.LIMIT, searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY } = queryFilter;

  const method = methods.GET;

  const url = `${urls.user.filter}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;

  return execute(method, url, { authorization: true });
};

const getUserById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.user.filter}/${id}`;
  return execute(method, url, { authorization: true });
};

const getUserCurrentUser = () => {
  const method = methods.GET;
  const url = `${urls.user.getCurrentUser}`;
  return execute(method, url, { authorization: true });
};

const updateUserById = (id: any, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.users}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const createUser = (payload: addEditUserType) => {
  const method = methods.POST;
  const url = urls.user.users;
  return execute(method, url, { authorization: true }, payload);
};

const changeStatusUser = (id: string, payload: { isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/${id}`;
  return execute(method, url, { authorization: true }, payload, 0);
};

const changeEmailAddress = (id: string, emailAddress: string) => {
  const method = methods.GET;
  const url = `${urls.user.setRoles}/?id=${id}&emailAddress=${emailAddress}`;
  return execute(method, url, { authorization: true });
};

const getUsersOrganization = (queryFilter: QueryUserOrganizationFilter) => {
  const { page, limit, searchQuery, organizationId } = queryFilter;
  const method = methods.GET;
  const url = `${urls.user.userOrganization}/${organizationId}/?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;

  return execute(method, url, { authorization: true });
};

const updateActiveUserById = (id: string, payload: { isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const setRolesForUser = (payload: { userId: string; roleIds: string[] }) => {
  const method = methods.GET;
  const url = urls.user.setRoles;
  return execute(method, url, { authorization: true }, payload);
};

const updateUpdatedProfile = async (
  id: string,
  payload: {
    updatedProfile?: boolean;
  },
) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const updateUserImage = (payload: { id: string; file?: any }) => {
  const { id, file } = payload;
  const method = methods.POST;
  const url = `${urls.user.users}/${id}/uploadimage`;
  return execute(method, url, { authorization: true }, file);
};

const changePassword = async (payload: any) => {
  const method = methods.PATCH;
  const url = urls.user.changePassword;
  return execute(method, url, { authorization: false }, payload);
};

const getUserByVerifiedCode = async (code: any) => {
  const method = methods.GET;
  const url = urls.user.getUserByVerifiedCode;
  return execute(method, url + '/' + code, { authorization: false });
};

const exportUsersToCSV = async (payload: { searchQuery: string }) => {
  const method = methods.POST;
  const url = urls.user.exportUsersToCSV;
  return execute(method, url, { authorization: true }, payload);
};

export {
  createUser,
  getUserByFilter,
  getUserById,
  getUserCurrentUser,
  updateUserById,
  changeEmailAddress,
  setRolesForUser,
  updateActiveUserById,
  getUsersOrganization,
  changePassword,
  getUserByVerifiedCode,
  updateUpdatedProfile,
  updateUserImage,
  changeStatusUser,
  exportUsersToCSV,
};
