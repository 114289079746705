import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import { changeStatusType } from 'api/userApi';
import GroupButton from 'components/button/groupButton';
import { Modal } from 'flowbite-react';
import { ACTION_STATUS } from 'utils/constants';
import { messageErrors } from 'utils/utils';

import './confirmActiveModal.scss';

function HiOutlineExclamationCircle() {
  return null;
}

type ConfirmModalProps = {
  typeEvent: string;
  openModal: boolean;
  setOpenModal: Function;
  title: string;
  mutation: UseMutationResult<void, unknown, changeStatusType>;
  refetchApi: Function;
};

const ConfirmActiveModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation();
  const { typeEvent, openModal = false, setOpenModal, title, mutation, refetchApi } = props;
  const closeModalHandler = () => {
    setOpenModal(!openModal);
  };

  const typeEventText = t(typeEvent)

  const confirmHandler = () => {
    const successCallback = (message: string) => {
      setOpenModal(!openModal);
      refetchApi();
      toast.success(message);
    };
    mutation.mutate(
      {
        isActive: typeEvent !== ACTION_STATUS.DEACTIVE,
        updatedProfile: true,
      },
      {
        onSuccess: () => {
          const message: string = t('modalEventConfirm.confirmSuccess');
          successCallback(message);
        },
        onError: (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal id="confirm-active-modal" dismissible={true} show={openModal} size="md" popup={true} onClose={() => closeModalHandler()}>
      <Modal.Header>{''}</Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{t('confirmMessageModal', { typeEvent: typeEventText, title })}</h3>
          <GroupButton
            className="w-full gap-2 justify-center pb-2"
            buttons={[
              {
                type: 'button',
                text: t('modal.noCancel'),
                classType: 'white',
                action: () => closeModalHandler(),
              },
              {
                type: 'submit',
                text: t('modal.yesImSure'),
                classType: 'blue',
                action: () => confirmHandler(),
              },
            ]}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmActiveModal;
