import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updatePartnerById, UpdateRatingPartnerType } from 'api/loanFundManager/partnerApi';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_API, OUTLOOK_TYPE, ROUTE_PATHS } from 'utils/constants';
import { OptionType, PartnerType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, getOptions, messageErrors, selectDateHandler, selectItemHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import './editRatingPartnerModal.scss';

type editRatingPartnerModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  selectedPartner: PartnerType;
  refetchApi: Function;
  isComeback: boolean;
  fetchLoansByPartnerId: Function;
};

type AddPartnerFormTypes = {
  internalRating?: number;
  externalRating?: number;
  outlook: OptionType[];
  internalRatingDate?: Date;
  externalRatingDate?: Date;
  par30?: number;
  grossLoanPortfolio?: number;
  netLoanPortfolio?: number;
};

const EditRatingPartnerModal = (props: editRatingPartnerModalTypes) => {
  const { openModal = false, setOpenModal, selectedPartner, refetchApi, isComeback = true, fetchLoansByPartnerId } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const navigate = useNavigate();
  const outlookOptions = getOptions(OUTLOOK_TYPE);

  const ValidateSchema = Yup.object().shape({
    internalRating: Yup.number().min(0, t('partnerPage.internalRatingMin')).max(100, t('partnerPage.internalRatingMax')).nullable(),
    externalRating: Yup.number().min(0, t('partnerPage.externalRatingMin')).max(100, t('partnerPage.externalRatingMax')).nullable(),
    outlook: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
      }),
    ),
    internalRatingDate: Yup.date().nullable(),
    externalRatingDate: Yup.date().nullable(),
    par30: Yup.number().min(0, t('partnerPage.percentMin')).max(100, t('partnerPage.percentMax')).nullable(),
    grossLoanPortfolio: Yup.number().min(0, t('partnerPage.currencyMin')).max(1000000000, t('partnerPage.currencyMax')).nullable(),
    netLoanPortfolio: Yup.number().min(0, t('partnerPage.currencyMin')).max(1000000000, t('partnerPage.currencyMax')).nullable(),
  });

  const initialValues: AddPartnerFormTypes = useMemo(() => {
    return selectedPartner
      ? {
        internalRating: selectedPartner?.internalRating ? getNumberValueFromApi(selectedPartner.internalRating) : undefined,
        externalRating: selectedPartner?.externalRating ? getNumberValueFromApi(selectedPartner.externalRating) : undefined,
        outlook: selectedPartner?.outlook ? outlookOptions.filter(item => item.value === selectedPartner.outlook) : [],
        internalRatingDate: selectedPartner?.internalRatingDate ? moment.utc(selectedPartner?.internalRatingDate).toDate() : undefined,
        externalRatingDate: selectedPartner?.externalRatingDate ? moment.utc(selectedPartner?.externalRatingDate).toDate() : undefined,
        grossLoanPortfolio: selectedPartner?.grossLoanPortfolio ? getNumberValueFromApi(selectedPartner?.grossLoanPortfolio) : undefined,
        netLoanPortfolio: selectedPartner?.netLoanPortfolio ? getNumberValueFromApi(selectedPartner?.netLoanPortfolio) : undefined,
        par30: selectedPartner?.par30 ? getNumberValueFromApi(selectedPartner?.par30) : undefined,
        isResetForm,
      }
      : {
        internalRating: undefined,
        externalRating: undefined,
        outlook: [],
        internalRatingDate: undefined,
        externalRatingDate: undefined,
        grossLoanPortfolio: undefined,
        netLoanPortfolio: undefined,
        par30: undefined,
        isResetForm,
      };
  }, [selectedPartner, outlookOptions, isResetForm]);

  const handleUpdateRatingPartner = async (partnerPayload: UpdateRatingPartnerType) => {
    await updatePartnerById<UpdateRatingPartnerType>(selectedPartner?.id as string, partnerPayload);
  };

  const mutation = useMutation('update-rating-partner', {
    mutationFn: handleUpdateRatingPartner,
  });

  const handleSubmit = (
    data: AddPartnerFormTypes,
    action: {
      [key: string]: any;
    },
  ) => {
    const successCallback = (message: string) => {
      if (isComeback) navigate(ROUTE_PATHS.PARTNERS_PAGE, { replace: true });
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      refetchApi();
      if (fetchLoansByPartnerId) fetchLoansByPartnerId();
    };

    const tempData = {
      internalRating: data?.internalRating ? getNumberValueSendToApi(data?.internalRating) : 0,
      externalRating: data?.externalRating ? getNumberValueSendToApi(data?.externalRating) : 0,
      outlook: data?.outlook?.length ? data?.outlook[0]?.value : null,
      internalRatingDate: data?.internalRatingDate ? moment(data.internalRatingDate).format(FORMAT_DATE_API) : null,
      externalRatingDate: data?.externalRatingDate ? moment(data.externalRatingDate).format(FORMAT_DATE_API) : null,
      par30: data.par30 ? getNumberValueSendToApi(data.par30) : null,
      grossLoanPortfolio: data.grossLoanPortfolio ? getNumberValueSendToApi(data.grossLoanPortfolio) : 0,
      netLoanPortfolio: data.netLoanPortfolio ? getNumberValueSendToApi(data.netLoanPortfolio) : 0,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: () => {
            const message: string = t('partnerPage.editRatingSuccessMessage');
            successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<AddPartnerFormTypes>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
    if (isComeback) navigate(ROUTE_PATHS.PARTNERS_PAGE, { replace: true });
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="rating-partner-modal"
      show={openModal}
      size="4xl"
      onClose={() => {
        setOpenModal(!openModal);
        if (isComeback) navigate(ROUTE_PATHS.PARTNERS_PAGE, { replace: true });
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header className="pt-8 pb-2">{t('updateRating')}</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <CurrencyForm
                      id="internalRating"
                      name="internalRating"
                      label={t('internalRating')}
                      placeholder={t('internalRating')}
                      unit=""
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      decimalLimit={1}
                    />
                    <CurrencyForm
                      id="externalRating"
                      name="externalRating"
                      label={t('externalRating')}
                      placeholder={t('externalRating')}
                      unit="%"
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      decimalLimit={1}
                    />
                  </div>
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <CustomDatePickerForm
                      id="internalRatingDate"
                      name="internalRatingDate"
                      label={t('internalRatingDate')}
                      placeHolder={t('internalRatingDate')}
                      propsFormik={props}
                      selectItemsHandler={(date: Date) =>
                        selectDateHandler<AddPartnerFormTypes>({
                          date,
                          props,
                          fieldName: 'internalRatingDate',
                        })
                      }
                      position="top"
                      isDeleteSelectedDate={openModal}
                      isClearable={true}
                    />
                    <CustomDatePickerForm
                      id="externalRatingDate"
                      name="externalRatingDate"
                      label={t('externalRatingDate')}
                      placeHolder={t('externalRatingDate')}
                      propsFormik={props}
                      selectItemsHandler={(date: Date) =>
                        selectDateHandler<AddPartnerFormTypes>({
                          date,
                          props,
                          fieldName: 'externalRatingDate',
                        })
                      }
                      position="top"
                      isDeleteSelectedDate={openModal}
                      isClearable={true}
                    />
                  </div>
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <CurrencyForm
                      id="grossLoanPortfolio"
                      name="grossLoanPortfolio"
                      label={t('grossLoanPortfolio')}
                      placeholder={t('grossLoanPortfolio')}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      decimalLimit={2}
                    />
                    <CustomSelectForm
                      label={t('outlook')}
                      id="outlook"
                      name="outlook"
                      placeHolder={t('outlook')}
                      dataTestId="test-partner-outlook-error"
                      options={outlookOptions}
                      selectItemsHandler={(items: any, props: FormikProps<AddPartnerFormTypes>) =>
                        selectItemHandler<AddPartnerFormTypes>({ items, props, fieldName: 'outlook' })
                      }
                      propsFormik={props}
                      isMulti={false}
                      disableSearch={true}
                    />
                  </div>
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <CurrencyForm
                      id="netLoanPortfolio"
                      name="netLoanPortfolio"
                      label={t('netLoanPortfolio')}
                      placeholder={t('netLoanPortfolio')}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      decimalLimit={2}
                    />
                    <CurrencyForm
                      id="par30"
                      name="par30"
                      label={t('PAR30')}
                      placeholder={t('PAR30')}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      decimalLimit={2}
                      unit="%"
                    />
                  </div>
                </div>
                <GroupButton
                  className="w-full gap-2 justify-center pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EditRatingPartnerModal;
