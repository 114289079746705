import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tabs } from 'flowbite-react';
import { LoanType } from 'utils/proptypes';

import PaymentSchedule from './components/paymentSchedule/PaymentSchedule';

type PaymentsPageType = {
  fetchLoanById: Function;
  selectedLoan?: LoanType;
  isFetchingLoan: boolean;
};

const PaymentsPage = ({ selectedLoan, isFetchingLoan = false }: PaymentsPageType) => {
  const [t] = useTranslation()
  return (
    <div className="payment flex flex-col h-full bg-white relative">
      <div className="payment__header flex justify-between items-center h-full">
        <Tabs.Group
          aria-label="Tabs with underline"
          className={classNames('payment__header--tabs', { 'have-collateral': !!selectedLoan?.notes?.length })}
        >
          <Tabs.Item title={t('paymentSchedule')}>
            <div className="payment__header--paymentSchedule h-full">
              <PaymentSchedule selectedLoan={selectedLoan} isFetchingLoan={isFetchingLoan} />
            </div>
          </Tabs.Item>
        </Tabs.Group>
      </div>
    </div>
  );
};

export default PaymentsPage;
