import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AddInvestmentPaymentType,
  createInvestmentPayment,
  updateInvestmentPaymentById,
  UpdateInvestmentPaymentType,
} from 'api/supporter/investmentPayment';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_API, SUPPORTER_PREFERRED_PAYMENT_METHOD } from 'utils/constants';
import { InvestmentPaymentsType, InvestmentType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, messageErrors, selectDateHandler, setCurrencyValue, setTextValue } from 'utils/utils';
import * as Yup from 'yup';

import { useGeneratePayment } from '../../../hooks/useGeneratePayment';
import TextForm from '../../form/textForm/textForm';

import './addInvestmentPaymentModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddInvestmentPaymentProps = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit?: boolean;
  setIsEdit?: Function;
  selectedInvestmentPayment?: InvestmentPaymentsType;
  investmentPayments?: InvestmentPaymentsType[];
  selectedInvestment?: InvestmentType;
};

type AddInvestmentPaymentFormType = {
  amountReceived?: number;
  receivedDate?: Date;
  checkNumber?: string;
};

const AddInvestmentPaymentModal = (props: AddInvestmentPaymentProps) => {
  const { openModal = false, setOpenModal, investmentPayments, selectedInvestmentPayment, isEdit = false, setIsEdit, selectedInvestment } = props;
  const { t } = useTranslation();
  const { id: investmentId } = useParams();
  const queryClient = useQueryClient();
  const [isResetForm, setIsResetForm] = useState(false);
  const [newPaymentReceivedDate, setNewPaymentReceivedDate] = useState<Date | null>(null);

  const ValidateSchema = Yup.object().shape({
    amountReceived: Yup.number()
      .min(0, t('investmentPage.currencyMin'))
      .max(1000000000, t('investmentPage.currencyMax'))
      .required(t('investmentPage.requiredField')),
    receivedDate: Yup.date().required(t('investmentPage.requiredField')),
    checkNumber: selectedInvestment?.supporter?.preferredPaymentMethod?.includes(SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK)
      ? Yup.string()
          .test({
            name: 'checkNumber-test',
            skipAbsent: true,
            test(value, ctx) {
              const { createError } = ctx;
              return true;
            },
          })
          .required(t('investmentPage.requiredField'))
      : Yup.string().nullable(),
  });

  const { receivedPaymentQuantity, amountReceived, dueDate } = useGeneratePayment({
    investmentPayments: investmentPayments?.length ? investmentPayments : [],
    newPaymentReceivedDate: newPaymentReceivedDate as Date,
    interestOption: investmentPayments?.[0]?.investment?.interestOption as string,
  });

  const initialValues: AddInvestmentPaymentFormType = useMemo(() => {
    return isEdit && selectedInvestmentPayment
      ? {
          amountReceived: selectedInvestmentPayment?.amountReceived ? getNumberValueFromApi(selectedInvestmentPayment?.amountReceived) : undefined,
          receivedDate: selectedInvestmentPayment?.receivedDate ? moment(selectedInvestmentPayment?.receivedDate).toDate() : undefined,
          checkNumber: selectedInvestmentPayment?.checkNumber ? selectedInvestmentPayment?.checkNumber : '',
        }
      : {
          amountReceived: amountReceived,
          receivedDate: dueDate,
          checkNumber: '',
          isResetForm,
        };
  }, [isEdit, selectedInvestmentPayment, isResetForm, amountReceived, dueDate]);

  const handleAddAndUpdateInvestmentPayment = async (investmentPaymentPayload: any) => {
    if (isEdit) {
      await updateInvestmentPaymentById(selectedInvestmentPayment?.id as string, investmentPaymentPayload as UpdateInvestmentPaymentType);
    } else {
      await createInvestmentPayment(investmentPaymentPayload as AddInvestmentPaymentType);
    }
  };

  const mutation = useMutation('create-update-investment-payment', {
    mutationFn: handleAddAndUpdateInvestmentPayment,
  });

  const handleSubmit = async (data: AddInvestmentPaymentFormType, action: { [key: string]: any }) => {
    const successCallback = async (message: string) => {
      if (isEdit && typeof setIsEdit === 'function') {
        setIsEdit(!isEdit);
      }
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      setNewPaymentReceivedDate(null);
      await queryClient.invalidateQueries(['getInvestmentPaymentsSchedule']);
    };

    const tempData = {
      investmentId: investmentId as string,
      amountReceived: data.amountReceived ? getNumberValueSendToApi(data.amountReceived) : null,
      receivedDate: data.receivedDate ? moment(data.receivedDate).format(FORMAT_DATE_API) : null,
      checkNumber: data.checkNumber ? data.checkNumber : null,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: async () => {
            const message: string = isEdit
              ? t('investmentPage.editInvestmentPaymentSuccessMessage')
              : t('investmentPage.createInvestmentPaymentSuccessMessage');
            await successCallback(message);
          },
          onError: (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<AddInvestmentPaymentFormType>) => {
    if (isEdit && typeof setIsEdit === 'function') {
      setIsEdit(!isEdit);
    }
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
    setNewPaymentReceivedDate(null);
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-investment-payment-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        if (isEdit && typeof setIsEdit === 'function') {
          setIsEdit(!isEdit);
        }
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
        setNewPaymentReceivedDate(null);
      }}
      dismissible={true}
    >
      <Modal.Header>{!isEdit ? t('addPayment') : t('editPayment')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CustomDatePickerForm
                    id="receivedDate"
                    name="receivedDate"
                    label={t('paymentSent')}
                    placeHolder={t('paymentSent')}
                    propsFormik={props}
                    selectItemsHandler={async (date: Date) => {
                      await selectDateHandler<AddInvestmentPaymentFormType>({
                        date,
                        props,
                        fieldName: 'receivedDate',
                      });
                      setNewPaymentReceivedDate(date);
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    isDeleteSelectedDate={openModal}
                    position="top"
                  />
                  <CurrencyForm
                    id="amount"
                    name="amountReceived"
                    label={t('amount')}
                    placeholder={t('amount')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddInvestmentPaymentFormType>,
                    ) => {
                      await setCurrencyValue<AddInvestmentPaymentFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    isEdit={isEdit}
                    isRequired={true}
                    decimalLimit={2}
                  />
                  {selectedInvestment?.supporter?.preferredPaymentMethod?.includes(SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK) && (
                    <TextForm
                      id="checkNumber"
                      name="checkNumber"
                      label={t('checkNumber')}
                      placeholder={t('checkNumber')}
                      dataTestId="test-supporter-checkNumber-error"
                      propsFormik={props}
                      isEdit={true}
                      setCurrentValue={async (
                        text: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddInvestmentPaymentFormType>,
                      ) => {
                        await setTextValue<AddInvestmentPaymentFormType>({ text, props });
                      }}
                      isRequired={true}
                    />
                  )}
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddInvestmentPaymentModal;
