import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineTrash, HiPencilAlt, HiPlus } from 'react-icons/hi';
import { TiTickOutline } from 'react-icons/ti';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateNotesPayment, UpdateNotesPaymentType } from 'api/accountant/paymentRecordApi';
import { AddEditNoteType, getNoteByEntityId, removeNote, updateNoteById } from 'api/noteApi';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import NoNotes from 'components/common/noNote';
import TooltipComponent from 'components/common/Tooltip';
import TextAreaForm from 'components/form/textForm/textAreaForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import { FORMAT_DATE_SHOW_TABLE } from 'utils/constants';
import { PaymentType } from 'utils/proptypes';
import { messageErrors } from 'utils/utils';
import * as Yup from 'yup';

import './addNotesModal.scss';

type AddPaymentModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  selectedPayment?: PaymentType;
  refetchApi: Function;
  reLinkUrl?: string;
};

type AddNotesPaymentFormTypes = {
  note: string;
};

type AddNoteFormTypes = {
  oldNote: string;
};

const AddNotesPaymentModal = (props: AddPaymentModalTypes) => {
  const { openModal = false, setOpenModal, selectedPayment, reLinkUrl } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState<{ [key: string]: any }>({});
  const navigate = useNavigate();

  const ValidateNoteSchema = Yup.object().shape({
    note: Yup.string()
      .min(1, t('paymentPage.noteLengthMin'))
      .max(1025, t('paymentPage.noteLengthMax'))
      .nullable()
      .required(t('paymentPage.requiredField')),
  });

  const ValidateOldNoteSchema = Yup.object().shape({
    oldNote: Yup.string()
      .min(1, t('paymentPage.noteLengthMin'))
      .max(1025, t('paymentPage.noteLengthMax'))
      .nullable()
      .required(t('paymentPage.requiredField')),
  });

  const initialNoteValues: any = useMemo(() => {
    return { note: '', isResetForm };
  }, [isResetForm]);

  const initialOldNoteValues: any = useMemo(() => {
    return { oldNote: selectedNote?.content ? selectedNote?.content : '', isResetForm };
  }, [selectedNote, isResetForm]);

  const { data: notesStore, refetch: fetchNotesByPaymentId } = useQuery(
    ['notes', selectedPayment?.notes, selectedPayment?.id],
    () => getNoteByEntityId(selectedPayment?.id as string),
    {
      enabled: !!selectedPayment?.id,
    },
  );

  const handleUpdateNotesPayment = async (payload: UpdateNotesPaymentType) => {
    await updateNotesPayment(selectedPayment?.id as string, payload);
  };

  const updateNotesPaymentMutation = useMutation('update-notes-payment', {
    mutationFn: handleUpdateNotesPayment,
  });

  const handleNoteSubmit = (data: AddNotesPaymentFormTypes, action: { [key: string]: any }) => {
    const successCallback = async (message: string) => {
      toast.success(message);
      action.resetForm();
      await fetchNotesByPaymentId();
      if (reLinkUrl) {
        navigate(reLinkUrl, { replace: true });
      }
      setIsEditNote(false);
    };
    const tempData = {
      notes: data?.note && !selectedPayment?.notes?.filter(note => note?.content === data?.note).length ? [{ content: data?.note }] : undefined,
    };

    if (openModal) {
      updateNotesPaymentMutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: async () => {
            const message: string = t('paymentPage.addNoteSuccess');
            await successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const updateNoteMutation = useMutation('updateNote', {
    mutationFn: async (payload: AddEditNoteType & { id: string }) => {
      const realPayload = omit(payload, 'id');
      await updateNoteById(payload.id, realPayload);
    },
  });

  const handleOldNoteSubmit = (data: AddNoteFormTypes, action: { [key: string]: any }) => {
    const successCallback = async () => {
      setIsEditNote(!isEditNote);
      action.resetForm();
      await fetchNotesByPaymentId();
      if (reLinkUrl) {
        navigate(reLinkUrl, { replace: true });
      }
    };
    const tempPayload = {
      content: data?.oldNote,
      entityId: selectedNote?.entityId,
      entityType: selectedNote?.entityType,
      id: selectedNote?.id,
    };
    updateNoteMutation.mutate(
      {
        ...tempPayload,
      },
      {
        onSuccess: () => successCallback(),
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const deleteNoteMutation = useMutation('delete-note', {
    mutationFn: async (id: string) => {
      await removeNote(id);
    },
  });

  const handleUpdateNote = (note: { [key: string]: any }) => {
    setIsEditNote(!isEditNote);
    setSelectedNote(note);
    setIsResetForm(!isResetForm);
  };

  const handleDeleteNote = (note: { [key: string]: any }) => {
    deleteNoteMutation.mutate(note?.id, {
      onSuccess: async () => {
        await fetchNotesByPaymentId();
        setIsEditNote(false);
      },
      onError: async (error: any) => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const closeModalHandler = () => {
    setOpenModal(!openModal);
    if (reLinkUrl) {
      navigate(reLinkUrl, { replace: true });
    }
    setIsResetForm(!isResetForm);
    setIsEditNote(false);
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal id="add-notes-payment-modal" show={openModal} size="xl" onClose={() => closeModalHandler()} dismissible={true}>
      <Modal.Header>{t('addNote')}</Modal.Header>
      <Modal.Body>
        <div className="note-content w-full space-y-4 mb-4">
          {notesStore?.data?.entities?.filter((note: { [key: string]: any }) => note.content !== '')?.length ? (
            notesStore?.data?.entities
              ?.filter((note: { [key: string]: any }) => note.content !== '')
              ?.map((note: { [key: string]: any }, index: number) => (
                <div key={`content_${index}`}>
                  <div className="w-full space-y-4 px-4 py-4 bg-yellow-50 rounded">
                    <div className="flex justify-between">
                      <h4 className="note-content__date leading-none text-yellow-500 text-sm font-normal">
                        {moment.utc(note.created).format(FORMAT_DATE_SHOW_TABLE)}
                      </h4>
                      <div className="flex space-x-2">
                        <HiPencilAlt size={18} className="note-content__icon-edit" onClick={() => handleUpdateNote(note)} />
                        <HiOutlineTrash size={18} className="note-content__icon-delete" onClick={() => handleDeleteNote(note)} />
                      </div>
                    </div>
                    <div className="note-content__text leading-4 text-gray-900 text-sm font-normal">
                      {isEditNote && note?.id === selectedNote?.id ? (
                        <Formik
                          enableReinitialize
                          onSubmit={handleOldNoteSubmit}
                          initialValues={initialOldNoteValues}
                          validationSchema={ValidateOldNoteSchema}
                          validateOnChange={true}
                        >
                          {(props: FormikProps<any>) => {
                            return (
                              <Form className="flex flex-col gap-4">
                                <div className="flex justify-center items-center">
                                  <TextAreaForm
                                    id="oldNote"
                                    name="oldNote"
                                    placeholder={t('note')}
                                    propsFormik={props}
                                    rowTotal={1}
                                    isOtherBgLabel={true}
                                  />
                                  <ButtonIconWithText Icon={TiTickOutline} color="bg-green-old-note" className="h-full ml-4 rounded" type="submit" />
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      ) : (
                        <TooltipComponent
                          anchorSelect={`note-content-${index}`}
                          content={note?.content ? note?.content : ''}
                          className="text-gray-900 note-content__text--truncate dark:text-white"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="note-no-result h-full bg-yellow-50 py-8 mb-4 rounded">
              <NoNotes />
            </div>
          )}
        </div>
        <Formik
          enableReinitialize
          onSubmit={handleNoteSubmit}
          initialValues={initialNoteValues}
          validationSchema={ValidateNoteSchema}
          validateOnChange={true}
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="flex justify-center items-center">
                  <TextAreaForm id="note" name="note" placeholder={t('note')} propsFormik={props} isRequired={true} />
                  <ButtonIconWithText Icon={HiPlus} color="bg-green-note" type="submit" className="h-full ml-4 rounded" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddNotesPaymentModal;
