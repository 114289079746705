import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  users: [],
};

const setUserInfo = (state: any, action: any) => {
  state.userInfo = action.payload;
};

const setUsers = (state: any, action: any) => {
  state.users = action.payload;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo,
    setUsers,
  },
});
export default userSlice;
