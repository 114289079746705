import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { useAuth0 } from '@auth0/auth0-react';
import ScrollToTopProvider from 'components/scrollToTop';
import useLoading from 'hooks/useLoading';
import useUser from 'hooks/useUser';
import { BasicLayout, ChangePassword, LoginPage, NotFoundPage, UpdateProfile } from 'pages';
import { userSelector } from 'redux/selectors';
import GuardedRoute from 'router/guardedRoute';
import routes from 'router/routes';
import { ROUTE_PATHS, USER_ROLES } from 'utils/constants';
import { RouteType } from 'utils/proptypes';

import MainLayout from '../components/layouts/mainLayout';

const Router = () => {
  const { isLoading, error, isAuthenticated } = useAuth0();
  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);
  const { userRole } = userInfo;
  const ref = useRef(null);

  useLoading({ isLoading, ref });

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  if (error) {
    return <div>{error.message}</div>;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center" style={{ height: '100vh' }}>
        <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      </div>
    );
  }

  const getFinalRoute = (route: RouteType, key: number) => {
    if (route.children.length > 0) {
      route.children.forEach((child, childKey) => {
        return <Route path={child.path} element={<child.component />} key={`child-route-key-${childKey}`} />;
      });
    }

    let Layout: any;
    if (route.layout === 'MainLayout') {
      Layout = MainLayout;
    } else Layout = BasicLayout;

    return (
      <Route
        path={route.path}
        element={
          <Layout isAdmin={route.isAdmin}>
            <Helmet>
              <title>{route.title ? route.title : 'WCCN'}</title>
              <meta name="description" content={'Description'} />
            </Helmet>
            <route.component />
          </Layout>
        }
        key={`route-key-${key}`}
      />
    );
  };

  const getRedirectRoute = (roles: string) => {
    switch (true) {
      case roles.includes(USER_ROLES.ACCOUNTANT):
        return ROUTE_PATHS.PAYMENT_PAGE;

      case roles.includes(USER_ROLES.SUPPORTER_MANAGER):
        return ROUTE_PATHS.SUPPORTER_MANAGER_PAGE;

      default:
        return ROUTE_PATHS.PARTNERS_PAGE;
    }
  };

  return (
    <BrowserRouter>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <Routes>
        <Route path={ROUTE_PATHS.LOGIN_PAGE} element={<LoginPage />} key={`route-key-login`} />
        <Route path={ROUTE_PATHS.CHANGE_PASSWORD} element={<ChangePassword />} key={`route-key-change-password`} />
        {!isAuthenticated && <Route path="*" element={<Navigate replace to={ROUTE_PATHS.LOGIN_PAGE} />} key={`route-key-navigate-login`} />}
        {isAuthenticated && userInfo.updatedProfile === null && (
          <>
            <Route path={ROUTE_PATHS.PROFILE_PAGE} element={<UpdateProfile isNavigatedFromDashboard={true} />} key={`route-key-update-profile`} />
            <Route
              path={ROUTE_PATHS.DASHBOARD_PAGE}
              element={<Navigate replace to={ROUTE_PATHS.PROFILE_PAGE} />}
              key={`route-key-navigate-update-profile`}
            />
          </>
        )}
        {isAuthenticated && userInfo.updatedProfile && (
          <Route
            path={ROUTE_PATHS.DASHBOARD_PAGE}
            element={<Navigate replace to={getRedirectRoute(userRole)} />}
            key={`route-key-navigate-partner`}
          />
        )}
        {isAuthenticated &&
          routes.map((route, key) => {
            if (route.isGuarded) {
              return (
                <Route path={route.path} element={<GuardedRoute path={route.path} userRole={userRole} />} key={`guarded-route-key-${key}`}>
                  {getFinalRoute(route, key)}
                </Route>
              );
            }
            return getFinalRoute(route, key);
          })}
        <Route path="*" element={<NotFoundPage />} key={`route-key-not-found`} />
      </Routes>
      <ScrollToTopProvider />
    </BrowserRouter>
  );
};

export default Router;
