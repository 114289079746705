import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getAdditionalSettings, updateAdditionalSettings } from 'api/addtionalSettingsApi';
import GroupButton from 'components/button/groupButton';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Form, Formik, FormikProps } from 'formik';
import { messageErrors, setCurrencyValue } from 'utils/utils';

import './style.scss'

export type AdditionalSettingsFormType = {
    historicReserve: number;
    historicalRate: number;
};

const AdditionalSettings = () => {
    const { data } = useQuery(['getAdditionalSettings'], () => getAdditionalSettings())
    const [t] = useTranslation()
    const queryClient = useQueryClient()

    const handleUpdateSettings = async (values: any) => {
        const payload = { ...values, historicReserve: Number(values.historicReserve) }
        await updateAdditionalSettings(payload)
    }

    const matationUpdateAdditionalSettings = useMutation('update-user', {
        mutationFn: handleUpdateSettings,
        onSuccess: async () => {
            const message: string = t('updateAdditionalSettingSuccefully');
            toast.success(message);
            queryClient.invalidateQueries('getAdditionalSettings')
        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSubmit = (values: any) => {
        matationUpdateAdditionalSettings.mutate(values);
    };

    return (
        <div className='w-full p-8'>
            <div className='bg-white m-8 w-1/2 border p-8 additional-settings'>
                <Formik enableReinitialize onSubmit={handleSubmit} initialValues={data?.data || {}}
                    validateOnChange={true}>
                    {(props: FormikProps<any>) => {
                        return (
                            <Form className="grid gap-4  w-full flex-col">
                                <div className="space-y-4">
                                    <div className='mb-8'>
                                        <CurrencyForm
                                            id="historicReserve"
                                            name="historicReserve"
                                            label={t('historicReserve')}
                                            placeholder={t('historicReserve')}
                                            setCurrentValue={async (currency: { name?: string; value?: string }, props: FormikProps<AdditionalSettingsFormType>) => {
                                                await setCurrencyValue<AdditionalSettingsFormType>({ currency, props });
                                            }}
                                            propsFormik={props}
                                            decimalLimit={2}
                                            unit="$"
                                        />
                                    </div>
                                    <div className='mb-8'>
                                        <CurrencyForm
                                            id="historicalRate"
                                            name="historicalRate"
                                            label={t('historicalRate')}
                                            placeholder={t('historicalRate')}
                                            setCurrentValue={async (currency: { name?: string; value?: string }, props: FormikProps<AdditionalSettingsFormType>) => {
                                                await setCurrencyValue<AdditionalSettingsFormType>({ currency, props });
                                            }}
                                            propsFormik={props}
                                            decimalLimit={2}
                                            unit="%"
                                        />
                                    </div>
                                    <GroupButton
                                        className="items-center justify-center mt-6"
                                        buttons={[
                                            {
                                                type: 'button',
                                                text: t('modal.cancel'),
                                                classType: 'white',
                                                action: () => props.resetForm(),
                                            },
                                            {
                                                type: 'submit',
                                                text: t('modal.save'),
                                                classType: 'blue',
                                                isLoading: matationUpdateAdditionalSettings.isLoading,
                                            },
                                        ]}
                                    />

                                </div>
                            </Form>
                        )
                    }}

                </Formik>
            </div >
        </div>
    )
}
export default AdditionalSettings