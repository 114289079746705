import { useMemo } from 'react';
import moment from 'moment/moment';

import { FORMAT_DATE_API, FORMAT_DATE_SHOW_TABLE } from '../utils/constants';
import { InvestmentPaymentsType, LoanType, PaymentType } from '../utils/proptypes';
import { getNumberValueFromApi } from '../utils/utils';

type UsePayOffParams = {
  newPayOffDate: Date;
  schedulePayments: PaymentType[];
  // interestOption: string;
  selectedLoan?: LoanType;
};

type UsePayOffResponse = {
  receivedPayments: PaymentType[];
  receivedPaymentQuantity: number;
  // initPaymentsSchedule: PaymentType[];
  amountReceived?: number;
  payPrincipal?: number;
  payInterest?: number;
  dueDate?: Date;
  totalPrincipal: number;
};
export const usePayOff = ({
  newPayOffDate,
  schedulePayments,
  selectedLoan,
}: // interestOption,
UsePayOffParams): UsePayOffResponse => {
  const receivedPayments: PaymentType[] = useMemo(() => {
    return schedulePayments?.filter((payment: PaymentType) => payment.name && payment.paymentReceivedAmount) as PaymentType[];
  }, [schedulePayments]);

  const receivedPaymentQuantity: number = useMemo(() => {
    return receivedPayments.length;
  }, [receivedPayments]);

  const initPaymentsSchedule = useMemo(() => {
    return schedulePayments?.filter((payment: PaymentType) => !payment.name);
  }, [schedulePayments]);

  const payPrincipal: number = useMemo(() => {
    return getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.principalAmount as number);
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const interestPayPerDate: number = useMemo(() => {
    return getNumberValueFromApi(
      (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.interestAmount as number) / (selectedLoan?.interestDenominator as number),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const initPaymentsScheduleWithoutElement0 = initPaymentsSchedule?.filter((payment: PaymentType) => payment.interestAmount);
  const dueDate = initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.paymentDate
    ? moment(moment.utc(initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.paymentDate).format(FORMAT_DATE_SHOW_TABLE)).toDate()
    : moment(
        moment
          .utc(initPaymentsScheduleWithoutElement0?.[initPaymentsScheduleWithoutElement0?.length - 1]?.paymentDate)
          .format(FORMAT_DATE_SHOW_TABLE),
      ).toDate();

  const lastPayment = useMemo(() => {
    const payment = newPayOffDate
      ? schedulePayments.filter(schedule => moment(schedule.paymentDate) <= moment(newPayOffDate))
      : schedulePayments.filter(schedule => moment(schedule.paymentDate) <= moment(dueDate));
    const lastItem = payment[payment.length - 1];
    return {
      lastPaymentDate: lastItem?.paymentDate,
      previousBalance: lastItem?.balance ? lastItem?.balance : payment[payment.length - 2]?.balance,
    };
    // eslint-disable-next-line
  }, [initPaymentsSchedule, selectedLoan, newPayOffDate, receivedPaymentQuantity]);
  const newDaysSinceLastTransaction: number = newPayOffDate
    ? moment(newPayOffDate).diff(moment(moment.utc(lastPayment?.lastPaymentDate).format(FORMAT_DATE_API)), 'days')
    : moment(dueDate).diff(moment(moment.utc(lastPayment?.lastPaymentDate).format(FORMAT_DATE_API)), 'days');

  // eslint-disable-next-line
  const payInterest: number = useMemo(() => {
    if (initPaymentsSchedule && selectedLoan && selectedLoan.interestRate && selectedLoan.interestDenominator) {
      const previousBalance = lastPayment.previousBalance || 0;
      return getNumberValueFromApi(
        Math.round(Number((previousBalance * newDaysSinceLastTransaction * selectedLoan.interestRate) / 10000 / selectedLoan.interestDenominator)),
      );
    }
    return 0;
    // eslint-disable-next-line
  }, [initPaymentsSchedule, receivedPaymentQuantity, interestPayPerDate, newDaysSinceLastTransaction, selectedLoan]);

  const amountReceived = useMemo(() => {
    return getNumberValueFromApi(initPaymentsSchedule?.[initPaymentsSchedule?.length - 1]?.paymentReceivedAmount);
    // eslint-disable-next-line
  }, [payInterest, payPrincipal, initPaymentsSchedule, receivedPaymentQuantity]);

  const totalPrincipal: number = lastPayment?.previousBalance || 0;
  return {
    receivedPayments,
    receivedPaymentQuantity,
    amountReceived,
    payInterest,
    payPrincipal,
    dueDate,
    totalPrincipal,
  };
};
