// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import roleSlice from './slice//roleSlice';
import loanSlice from './slice/loanSlice';
import paymentSlice from './slice/paymentSlice';
import userSlice from './slice/userSlice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  role: roleSlice.reducer,
  loan: loanSlice.reducer,
  payment: paymentSlice.reducer,
});

export default rootReducer;
