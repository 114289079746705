// eslint-disable-next-line
import { PropsBasicLayoutType } from 'utils/proptypes';
import './basicLayout.scss';

const BasicLayout = (props: PropsBasicLayoutType) => {
  const { children } = props;
  return (
    <div className="basic-layout">
      <div className=" flex justify-center h-screen mb-auto">{children}</div>
    </div>
  );
};
export default BasicLayout;
