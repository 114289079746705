import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { HiOutlineCalendar } from 'react-icons/hi';
import classNames from 'classnames';
import { Label } from 'flowbite-react';
import moment from 'moment';
import { FORMAT_DATE_API, FORMAT_DATE_API_REGEX, FORMAT_DATE_SHOW_TABLE } from 'utils/constants';

import 'react-datepicker/dist/react-datepicker.css';
import './dateInput.scss';

type DateInputType = {
  isDisabled?: boolean;
  isSelectRange?: boolean;
  isFilter?: boolean;
  defaultValue?: string;
  selectItemsHandler: Function;
  placeHolder?: string;
  isClearable?: boolean;
  showMonthYearPicker?: boolean;
  dateFormat: string;
  filterDate?: any;
  defaultStartDate?: string;
  defaultEndDate?: string;
  isFromSelectDateRangeModal?: boolean;
  onOpenSelectDateRangeModal?: Function;
  minDate?: Date;
  maxDate?: Date;
};
const DateInput = ({
  defaultValue,
  defaultStartDate,
  defaultEndDate,
  selectItemsHandler,
  isDisabled,
  isSelectRange = false,
  placeHolder,
  isFilter = false,
  isClearable,
  dateFormat,
  showMonthYearPicker = false,
  filterDate,
  isFromSelectDateRangeModal = false,
  onOpenSelectDateRangeModal,
  minDate,
  maxDate,
}: DateInputType) => {
  const realDefaultValue =
    defaultValue && FORMAT_DATE_API_REGEX.test(moment(defaultValue).format(FORMAT_DATE_API))
      ? moment(moment.utc(defaultValue).format(FORMAT_DATE_SHOW_TABLE)).toDate()
      : null;

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    if (defaultStartDate) setStartDate(moment(defaultStartDate).toDate());
    if (defaultEndDate) setEndDate(moment(defaultEndDate).toDate());
  }, [defaultStartDate, defaultEndDate]);

  const changeDateHandler = (dates: any, event: any) => {
    if (isSelectRange) {
      const [start, end] = dates;
      if (event.keyCode === 13 || event.type === 'click') {
        setStartDate(start);
        setEndDate(end);
        if ((start && end) || (!start && !end)) selectItemsHandler(start, setStartDate, end);
      }
    } else {
      if (event.keyCode === 13 || event.type === 'click') selectItemsHandler(dates, setStartDate);
    }
  };

  const CustomInput = React.forwardRef((props: any, ref) => {
    const { className, value, onChange, disabled, onClick, isFromSelectDateRangeModal = false, onOpenSelectDateRangeModal, placeHolder } = props;
    return (
      <div className={`flex justify-between items-center rounded relative ${className ? className : ''}`}>
        <input
          placeholder={placeHolder}
          ref={ref as any}
          type="text"
          className={classNames('text-sm text-gray-900 border border-gray-200 px-1.5 py-2 rounded', {
            'cursor-pointer': isFromSelectDateRangeModal,
            haveValue: value ? value : '',
            'pr-20': isSelectRange,
          })}
          onClick={onClick && onClick}
          value={value ? value : ''}
          onChange={onChange && onChange}
          disabled={disabled && !isFromSelectDateRangeModal ? disabled : false}
        />
        <div
          className={classNames('react-datepicker__schedule-icon absolute right-2 p cursor-pointer', {
            haveValue: !!value && isClearable,
          })}
          onClick={onOpenSelectDateRangeModal ? onOpenSelectDateRangeModal : onClick && onClick}
        >
          <span className="input-group-text">
            <HiOutlineCalendar size={18} />
          </span>
        </div>
        {isFilter && (
          <div
            onClick={props.onClick}
            className={classNames('react-datepicker__label absolute block cursor-pointer rounded-sm leading-none', {
              disabled: props.disabled,
              haveValue: props.value,
            })}
          >
            <Label value={placeHolder} />
          </div>
        )}
      </div>
    );
  });
  return (
    <div className="date-form w-full">
      <DatePicker
        showYearDropdown
        yearDropdownItemNumber={50}
        scrollableYearDropdown
        dateFormat={dateFormat}
        selected={startDate ? startDate : realDefaultValue}
        startDate={startDate}
        endDate={endDate}
        onChange={(dates, event) => changeDateHandler(dates, event)}
        wrapperClassName={classNames('date-picker-input', {
          haveValue: realDefaultValue,
        })}
        minDate={minDate && minDate}
        maxDate={maxDate && maxDate}
        fixedHeight
        showMonthYearPicker={showMonthYearPicker}
        popperClassName="some-custom-class"
        popperPlacement="bottom"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true,
            },
          },
        ]}
        customInput={
          <CustomInput
            placeHolder={placeHolder}
            isFromSelectDateRangeModal={isFromSelectDateRangeModal}
            onOpenSelectDateRangeModal={typeof onOpenSelectDateRangeModal === 'function' && onOpenSelectDateRangeModal}
          />
        }
        selectsRange={isSelectRange}
        isClearable={isClearable}
        disabled={isDisabled}
        filterDate={typeof filterDate === 'function' && filterDate}
        clearButtonClassName={classNames('', { 'clear-button-disabled': isDisabled })}
      />
    </div>
  );
};

export default DateInput;
