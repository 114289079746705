import { HiX } from 'react-icons/hi';
import classNames from 'classnames';
import remove from 'lodash/remove';

type CustomValueRendererType = {
  selected: any[];
  placeHolder: string;
  isMulti: boolean;
  isRequired: boolean;
  isEdit: boolean;
};
export const CustomValueRenderer = ({ selected, placeHolder, isMulti, isRequired, isEdit = false }: CustomValueRendererType) => {
  !isMulti && selected.length > 1 && selected.splice(0, selected.length - 1);
  return selected.length ? (
    <div className="flex justify-between items-center">
      <div className="dropdown-heading-value__content space-y-1">
        {selected
          ?.filter((item: any) => item.label)
          ?.map(({ label }: any, index: any) => {
            return (
              <div
                key={`multi-value-${index}`}
                className={classNames('flex items-center dropdown-heading-value__content--item mr-1', { 'bg-gray-200': isMulti })}
              >
                <span>{label}</span>
                {isMulti && (
                  <button
                    className="ml-1"
                    onClick={e => {
                      e.preventDefault();
                      remove(selected, { label });
                    }}
                  >
                    <HiX size={12} />
                  </button>
                )}
              </div>
            );
          })}
      </div>
      {isMulti && (
        <button
          className="p-1 ml-2 dropdown-heading-value__hiX"
          onClick={e => {
            e.preventDefault();
            selected.splice(0, selected.length);
          }}
        >
          <HiX size={18} />
        </button>
      )}
    </div>
  ) : (
    <div className="dropdown-heading-value__no-content flex" style={{ width: 'fit-content' }}>
      <p className="text-gray-500">{placeHolder}</p> {isRequired && !isEdit && <span className="text-red-500 text-sm">&nbsp;*</span>}
    </div>
  );
};
