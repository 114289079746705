import { OptionType } from 'utils/proptypes';

interface CustomItemRendererInputType {
  checked: boolean;
  option: OptionType;
  disabled?: boolean;
  onClick: any;
  isMulti: boolean;
}

const CustomItemRendererInput = ({ checked, option, onClick, disabled, isMulti }: CustomItemRendererInputType) => (
  <div className={`item-renderer ${disabled ? 'disabled' : ''}`} onClick={!isMulti ? onClick : null} tabIndex={-1}>
    {isMulti && <input type="checkbox" onChange={onClick} checked={checked} tabIndex={-1} disabled={disabled} />}
    <span>{option.label}</span>
  </div>
);

export default CustomItemRendererInput;
