import { useEffect } from 'react';

const useLoading = ({ isLoading, ref }: { isLoading: boolean; ref: any }) => {
  useEffect(() => {
    if (ref.current)
      if (isLoading) {
        ref.current.continuousStart();
      } else {
        ref.current.complete();
      }
  }, [isLoading, ref]);
};

export default useLoading;
