import base from '../baseApi';

const { urls, methods, execute } = base();

const getCountries = async () => {
  const method = methods.GET;
  const url = urls.country.country;
  return execute(method, url, { authorization: true });
};

export { getCountries };
