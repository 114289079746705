import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { exportToCsv } from 'api/common/contactApi';
import { exportPartnersToCSV, getPartnersByFilter } from 'api/loanFundManager/partnerApi';
import AddPartnerModal from 'components/modal/partner/addPartnerModal';
import HeaderTable from 'components/table/headerTable';
import PaginateTable from 'components/table/paginate';
import useLoading from 'hooks/useLoading';
import { DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME, ROUTE_PATHS } from 'utils/constants';
import { PartnerType } from 'utils/proptypes';
import { exportRowDataToCSV, messageErrors } from 'utils/utils';

import { RenderPartnersTableData } from './components';

import '../../../styles/styles.scss';
import './partners.scss';

const PartnersPage = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntities, setTotalEntities] = useState(0);
  const limit = DEFAULT_VALUE_FILTER.LIMIT;
  const [partners, setPartners] = useState<PartnerType[]>([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerType>();
  const [isEdit, setIsEdit] = useState(false);
  const ref = useRef(null);

  const { refetch: fetchPartners, isFetching } = useQuery(
    ['partners', currentPage, searchValue],
    () => getPartnersByFilter({ page: currentPage, limit, searchQuery: searchValue }),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.totalEntities);
        setPartners(data.entities);
      },
      onError: () => {
        setPartners([]);
        setTotalEntities(0);
      },
    },
  );

  useLoading({ isLoading: isFetching, ref });

  const addPartnerHandler = () => {
    setOpenAddModal(!openAddModal);
  };

  const editPartnerHandler = (item: PartnerType) => {
    setOpenAddModal(!openAddModal);
    setIsEdit(!isEdit);
    navigate(`${ROUTE_PATHS.PARTNERS_PAGE}?id=${item?.id}`);
    setSelectedPartner(item);
  };

  const openPartnerDetail = (item: PartnerType) => {
    navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${item?.id}`);
  };

  const removePartnerHandler = (item: PartnerType) => {
    setOpenRemoveModal(!openRemoveModal);
    setSelectedPartner(item);
    navigate(`${ROUTE_PATHS.PARTNERS_PAGE}?id=${item.id}`, { replace: true });
  };

  const handleExportPartners = async () => {
    await exportPartnersToCSV({ searchQuery: searchValue }).then(async res => {
      exportRowDataToCSV({ stream: res.data, fileName: EXPORT_FILE_NAME.PARTNER });
    });
  };

  const { refetch: refetchExportPartners, isFetching: isFetchingDownloadPartners } = useQuery('download-partners', handleExportPartners, {
    onSuccess: () => {
      const message: string = t('partnerPage.exportToCsvSuccess');
      toast.success(message);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
    enabled: false,
  });

  const handleExportContacts = async () => {
    await exportToCsv({ entityType: 'Partner' }).then(async res => {
      exportRowDataToCSV({ stream: res.data, fileName: EXPORT_FILE_NAME.PARTNER_CONTACT });
    });
  };

  const { refetch: refetchExportContacts } = useQuery('export-contacts', handleExportContacts, {
    onSuccess: () => {
      const message: string = t('contact.exportToCsvSuccess');
      toast.success(message);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
    enabled: false,
  });

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="w-full partners-page">
        <HeaderTable
          setCurrentPage={setCurrentPage}
          placeholderSearch={t('partnerPage.searchPartners')}
          nameButton={t('addPartner')}
          setSearchValue={setSearchValue}
          addHandler={addPartnerHandler}
          exportHandler={refetchExportContacts}
          hideSearch={false}
          hideExportButton={false}
          className="partners"
          abilityObjectTitle="PARTNER"
        />
        <RenderPartnersTableData
          partners={partners}
          editPartnerHandler={editPartnerHandler}
          openPartnerDetail={openPartnerDetail}
          removePartner={removePartnerHandler}
          isFetching={isFetching}
        />
        {totalEntities > 0 && (
          <PaginateTable
            className="partners-page__pagination"
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isFetching={isFetching}
            isFetchingDownload={isFetchingDownloadPartners}
            exportHandler={refetchExportPartners}
            items={partners}
          />
        )}
      </div>
      <AddPartnerModal
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        refetchApi={fetchPartners}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        selectedPartner={selectedPartner}
      />
    </>
  );
};
export default PartnersPage;
