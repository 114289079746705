import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCountries } from 'api/loanFundManager/countryApi';
import { addPartnerContacts, AddPartnerContactsType, updatePartnerContact } from 'api/loanFundManager/partnerApi';
import GroupButton from 'components/button/groupButton';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import TextForm from 'components/form/textForm/textForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { EMAIL_REGEX, PREFIX_TITLES } from 'utils/constants';
import { ContactType, OptionType } from 'utils/proptypes';
import { getOptions, messageErrors, selectItemHandler, setTextValue } from 'utils/utils';
import * as Yup from 'yup';

import CheckboxForm from '../../form/checkBoxForm/customCheckboxForm';

import './addPartnerContactsModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddPartnerContactsModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  selectedContact?: ContactType;
  isEditContact: boolean;
};

type AddPartnerContactsFormTypes = {
  prefixTitles: OptionType[];
  name: string;
  position: string;
  street: string;
  city: string;
  country: OptionType[];
  phone: string;
  email: string;
  isHeadquarter: string;
};

const AddPartnerContactsModal = (props: AddPartnerContactsModalTypes) => {
  const { openModal = false, setOpenModal, selectedContact, isEditContact } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id: selectedPartnerId } = useParams();
  const [isResetForm, setIsResetForm] = useState(false);

  const ValidateSchema = Yup.object().shape({
    prefixTitles: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
      }),
    ),
    name: Yup.string()
      .min(1, t('partnerPage.nameLengthMin'))
      .max(100, t('partnerPage.nameLengthMax'))
      .nullable()
      .required(t('partnerPage.requiredField')),
    position: Yup.string().min(0, t('partnerPage.nameLengthMin')).max(100, t('partnerPage.nameLengthMax')),
    street: Yup.string().min(0, t('partnerPage.nameLengthMin')).max(100, t('partnerPage.nameLengthMax')),
    city: Yup.string().min(0, t('partnerPage.nameLengthMin')).max(100, t('partnerPage.nameLengthMax')).required(t('partnerPage.requiredField')),
    country: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
        }),
      )
      .min(1, t('partnerPage.requiredField')),
    phone: Yup.string().min(1, t('partnerPage.nameLengthMin')).max(100, t('partnerPage.nameLengthMax')).nullable(),
    email: Yup.string()
      .email(t('partnerPage.emailValid'))
      .matches(EMAIL_REGEX, t('partnerPage.emailValid'))
      .min(1, t('partnerPage.nameLengthMin'))
      .max(100, t('partnerPage.nameLengthMax'))
      .nullable(),
    isHeadquarter: Yup.string().nullable(),
  });

  const { data: countries } = useQuery(['countries'], () => getCountries());

  const countryOptions = useMemo(() => {
    return countries?.data?.entities?.map((country: any) => ({ label: country.name, value: country.id }));
  }, [countries]);

  const prefixTitleOptions = getOptions(PREFIX_TITLES);

  const initialValues: Partial<AddPartnerContactsFormTypes> = useMemo(() => {
    if (selectedContact) {
      return {
        prefixTitles: selectedContact?.address[0]?.country
          ? prefixTitleOptions?.filter((item: OptionType) => item?.label === selectedContact.prefixTitles)
          : [],
        name: selectedContact?.name ?? '',
        position: selectedContact?.position ?? '',
        street: selectedContact?.address[0]?.street ?? '',
        city: selectedContact?.address[0]?.city ?? '',
        country: selectedContact?.address[0]?.country
          ? countryOptions?.filter((item: OptionType) => item?.label === selectedContact?.address[0]?.country?.name)
          : [],
        phone: selectedContact?.phones[0]?.phoneNumber ?? '',
        email: selectedContact?.emails[0]?.emailAddress ?? '',
        isHeadquarter: selectedContact?.isHeadquarter ? 'Headquarter' : '',
        isResetForm,
      };
    } else {
      return {
        prefixTitles: [],
        name: '',
        position: '',
        street: '',
        city: '',
        country: [],
        phone: '',
        email: '',
        isResetForm,
        isHeadquarter: '',
      };
    }
    // eslint-disable-next-line
  }, [isResetForm, selectedContact, isEditContact]);
  const handleAddPartnerContacts = async (partnerContactsPayload: AddPartnerContactsType) => {
    await addPartnerContacts(selectedPartnerId as string, partnerContactsPayload);
  };

  const mutationCreateContact = useMutation('create-update-partner-contacts', {
    mutationFn: handleAddPartnerContacts,
  });

  const handleUpdatePartnerContacts = async (partnerContactsPayload: AddPartnerContactsType) => {
    await updatePartnerContact(selectedPartnerId as string, selectedContact?.id as string, partnerContactsPayload);
  };

  const mutationUpdateContact = useMutation('create-update-partner-contacts', {
    mutationFn: handleUpdatePartnerContacts,
  });

  const handleSubmit = (data: Partial<AddPartnerContactsFormTypes>, action: { [key: string]: any }) => {
    const successCallback = async (message: string) => {
      setOpenModal(false);
      toast.success(message);
      action.resetForm();
      setIsResetForm(true);
      await queryClient.invalidateQueries(['getPartner']);
    };

    const defaultAddress = {
      street: data.street ? data.street : '',
      city: data.city ? data.city : undefined,
      countryId: data?.country?.length ? data?.country[0]?.value : undefined,
    };

    const defaultEmail = data.email
      ? {
        emailAddress: data.email,
      }
      : '';

    const defaultPhone = data.phone
      ? {
        phoneNumber: data.phone,
      }
      : '';

    const tempData = {
      contacts: [
        {
          prefixTitles: data.prefixTitles?.length ? data?.prefixTitles[0]?.value : '',
          name: data.name ? data.name : undefined,
          position: data.position ? data.position : '',
          address: !isEmpty(defaultAddress) ? [{ ...defaultAddress }] : undefined,
          emails: !isEmpty(defaultEmail) ? [{ ...defaultEmail }] : undefined,
          phones: !isEmpty(defaultPhone) ? [{ ...defaultPhone }] : undefined,
          isHeadquarter: !!data.isHeadquarter,
        },
      ],
    };

    if (openModal) {
      if (isEditContact) {
        mutationUpdateContact.mutate(
          {
            ...(tempData.contacts[0] as any),
          },
          {
            onSuccess: async () => {
              const message = t('partnerPage.updatePartnerContactsSuccessMessage');
              await successCallback(message);
            },
            onError: async (error: any) => {
              const message: string = messageErrors(error, t);
              toast.error(message);
            },
          },
        );
      } else {
        mutationCreateContact.mutate(
          {
            ...(tempData as any),
          },
          {
            onSuccess: async () => {
              const message = t('partnerPage.createPartnerContactsSuccessMessage');
              await successCallback(message);
            },
            onError: async (error: any) => {
              const message: string = messageErrors(error, t);
              toast.error(message);
            },
          },
        );
      }
    }
  };

  const closeModalHandler = (props: FormikProps<AddPartnerContactsFormTypes>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-partner-contacts-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('addContact')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CustomSelectForm
                    label={t('prefixTitles')}
                    id="prefixTitles"
                    name="prefixTitles"
                    placeHolder={t('prefixTitles')}
                    dataTestId="test-supporter-prefix-error"
                    options={prefixTitleOptions || []}
                    selectItemsHandler={(items: any, props: FormikProps<AddPartnerContactsFormTypes>) =>
                      selectItemHandler<AddPartnerContactsFormTypes>({ items, props, fieldName: 'prefixTitles' })
                    }
                    propsFormik={props}
                    isMulti={false}
                    disableSearch={true}
                  />
                  <TextForm
                    id="name"
                    name="name"
                    label={t('name')}
                    placeholder={t('name')}
                    dataTestId="test-partner-name-error"
                    propsFormik={props}
                    isRequired={true}
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddPartnerContactsFormTypes>,
                    ) => {
                      await setTextValue<AddPartnerContactsFormTypes>({ text, props });
                    }}
                  />
                  <TextForm
                    id="position"
                    name="position"
                    label="Position"
                    placeholder="Position"
                    propsFormik={props}
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddPartnerContactsFormTypes>,
                    ) => {
                      await setTextValue<AddPartnerContactsFormTypes>({ text, props });
                    }}
                  />
                  <TextForm
                    id="street"
                    name="street"
                    label={t('street')}
                    placeholder={t('street')}
                    propsFormik={props}
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddPartnerContactsFormTypes>,
                    ) => {
                      await setTextValue<AddPartnerContactsFormTypes>({ text, props });
                    }}
                  />
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <TextForm
                      id="city"
                      name="city"
                      label={t('city')}
                      placeholder={t('city')}
                      dataTestId="test-partner-city-error"
                      propsFormik={props}
                      isRequired={true}
                      setCurrentValue={async (
                        text: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerContactsFormTypes>,
                      ) => {
                        await setTextValue<AddPartnerContactsFormTypes>({ text, props });
                      }}
                    />
                    <CustomSelectForm
                      label={t('country')}
                      id="country"
                      name="country"
                      placeHolder={t('country')}
                      dataTestId="test-partner-country-error"
                      options={countryOptions}
                      selectItemsHandler={(items: any, props: FormikProps<AddPartnerContactsFormTypes>) =>
                        selectItemHandler<AddPartnerContactsFormTypes>({ items, props, fieldName: 'country' })
                      }
                      propsFormik={props}
                      isRequired={true}
                      isMulti={false}
                    />
                  </div>
                  <TextForm
                    id="phone"
                    name="phone"
                    label={t('phone')}
                    placeholder={t('phone')}
                    propsFormik={props}
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddPartnerContactsFormTypes>,
                    ) => {
                      await setTextValue<AddPartnerContactsFormTypes>({ text, props });
                    }}
                  />
                  <TextForm
                    id="email"
                    name="email"
                    label={t('email')}
                    placeholder={t('email')}
                    propsFormik={props}
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddPartnerContactsFormTypes>,
                    ) => {
                      await setTextValue<AddPartnerContactsFormTypes>({ text, props });
                    }}
                  />
                  <CheckboxForm
                    name="isHeadquarter"
                    label={t('headquarter')}
                    propsFormik={props}
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddPartnerContactsFormTypes>,
                    ) => {
                      await setTextValue<AddPartnerContactsFormTypes>({ text, props });
                    }}
                  />
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default AddPartnerContactsModal;
