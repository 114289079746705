import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tabs } from 'flowbite-react';
import { InvestmentType } from 'utils/proptypes';

import InvestmentPaymentSchedule from './components/paymentSchedule/investmentPaymentSchedule';

type PaymentsPageType = {
  selectedInvestment?: InvestmentType;
  isFetchingInvestment: boolean;
};

const InvestmentPaymentsPage = ({ selectedInvestment, isFetchingInvestment = false }: PaymentsPageType) => {
  const [t] = useTranslation()
  return (
    <div className="investment-payment flex flex-col h-full bg-white relative">
      <div className="investment-payment__header flex justify-between items-center h-full">
        <Tabs.Group
          aria-label="Tabs with underline"
          className={classNames('investment-payment__header--tabs', { 'have-collateral': !!selectedInvestment?.notes?.[0]?.content })}
        >
          <Tabs.Item title={t('paymentSchedule')}>
            <div className="investment-payment__header--paymentSchedule h-full">
              <InvestmentPaymentSchedule isFetchingInvestment={isFetchingInvestment} selectedInvestment={selectedInvestment} />
            </div>
          </Tabs.Item>
        </Tabs.Group>
      </div>
    </div>
  );
};

export default InvestmentPaymentsPage;
