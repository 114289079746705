import createAuth0Client from '@auth0/auth0-spa-js';
import * as jwtDecode from 'jwt-decode';
import { getCookie, setCookie } from 'utils/cookie';

import { TOKEN_KEY } from '../utils/constants';

export const getTokenSilently = async () => {
  const auth0 = await createAuth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    redirect_uri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  });
  return auth0.getTokenSilently();
};

export const setToken = (token: string) => {
  setCookie(TOKEN_KEY, token);
};

export const getToken = async () => {
  let token = getCookie(TOKEN_KEY);
  const decoded = token ? jwtDecode.default(token) : null;
  if (!decoded) {
    token = await getTokenSilently();
    setToken(token);
  }

  return token;
};
