import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createInvestmentPayment, PayOffType } from 'api/supporter/investmentPayment';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import { sumBy } from 'lodash';
import moment from 'moment';
import { FORMAT_DATE_API, INTEREST_OPTION_TYPE } from 'utils/constants';
import { InvestmentPaymentsType, InvestmentType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, selectDateHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import { AddInvestmentType } from '../../../api/investmentApi';
import { usePayOff } from '../../../hooks/usePayOffPayment';

import './payOffModal.scss';
import '../../../styles/common/multiSelect.scss';

type PayOffProps = {
  openModal: boolean;
  setOpenModal: Function;
  investmentPayments?: InvestmentPaymentsType[];
  selectedInvestment?: InvestmentType;
};

type PayOffFormType = {
  paymentDate?: Date;
  principalToPay: number;
  interestToPay?: number;
  totalToPay: number;
};

const PayOffModal = (props: PayOffProps) => {
  const { openModal = false, setOpenModal, selectedInvestment, investmentPayments } = props;
  const { t } = useTranslation();
  const { id: investmentId } = useParams();
  const queryClient = useQueryClient();
  const [isResetForm, setIsResetForm] = useState(false);
  const [newPaymentReceivedDate, setNewPaymentReceivedDate] = useState<Date | null>(null);

  const ValidateSchema = Yup.object().shape({
    interestToPay: Yup.number().min(0, t('investmentPage.currencyMin')).max(1000000000, t('investmentPage.currencyMax')),
    paymentDate: Yup.date().required(t('investmentPage.requiredField')),
  });

  const { receivedPayments, payInterest, dueDate } = usePayOff({
    investmentPayments: investmentPayments?.length ? investmentPayments : [],
    newPaymentReceivedDate: newPaymentReceivedDate as Date,
    interestOption: investmentPayments?.[0]?.investment?.interestOption as string,
    selectedInvestment: selectedInvestment as InvestmentType,
  });
  const totalPrincipal = Number(selectedInvestment?.amount) - sumBy(receivedPayments, 'principalReceived');

  const initialValues: PayOffFormType = {
    paymentDate: dueDate ?? (newPaymentReceivedDate as Date),
    principalToPay: getNumberValueFromApi(Number(totalPrincipal)),
    interestToPay: Number(payInterest),
    totalToPay: Number(payInterest) + getNumberValueFromApi(totalPrincipal),
  };

  const handleAddAndUpdateInvestmentPayment = async (investmentPaymentPayload: any) => {
    await createInvestmentPayment(investmentPaymentPayload as PayOffType);
  };

  const mutation = useMutation('pay-off-investment-payment', {
    mutationFn: handleAddAndUpdateInvestmentPayment,
  });

  const handleSubmit = async (data: PayOffFormType, action: { [key: string]: any }) => {
    const successCallback = async (message: string) => {
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      setNewPaymentReceivedDate(null);
      await queryClient.invalidateQueries(['getInvestmentPaymentsSchedule']);
    };

    if (openModal) {
      mutation.mutate(
        {
          investmentId: investmentId as string,
          payOffDate: data.paymentDate ? moment(data.paymentDate).format(FORMAT_DATE_API) : null,
          receivedDate: data.paymentDate ? moment(data.paymentDate).format(FORMAT_DATE_API) : null,
          principalToPay: data.principalToPay ? getNumberValueSendToApi(data.principalToPay) : null,
          interestToPay: data.interestToPay ? getNumberValueSendToApi(data.interestToPay) : null,
          amountReceived: data.totalToPay ? getNumberValueSendToApi(data.totalToPay) : null,
        },
        {
          onSuccess: async () => {
            const message: string = t('investmentPage.createInvestmentPaymentSuccessMessage');
            await successCallback(message);
          },
          onError: (error: any) => {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<PayOffFormType>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
    setNewPaymentReceivedDate(null);
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-investment-payment-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
        setNewPaymentReceivedDate(null);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('Pay Off Investment')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CustomDatePickerForm
                    id="paymentDate"
                    name="paymentDate"
                    label={t('Pay Off Date')}
                    placeHolder={t('Pay Off Date')}
                    isRequired={true}
                    propsFormik={props}
                    selectItemsHandler={async (date: Date) => {
                      if (date) {
                        await selectDateHandler<AddInvestmentType>({
                          date,
                          props,
                          fieldName: 'paymentDate',
                        });
                        setNewPaymentReceivedDate(date);
                      }
                    }}
                    isDeleteSelectedDate={openModal}
                    position="top"
                    minDate={moment(selectedInvestment?.startDate).toDate()}
                  />
                  <CurrencyForm
                    id="principalToPay"
                    name="principalToPay"
                    label={t('principalToPay')}
                    placeholder={t('principalToPay')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<PayOffFormType>,
                    ) => {
                      await setCurrencyValue<PayOffFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    decimalLimit={2}
                    isDisabled={true}
                  />
                  <CurrencyForm
                    id="interestToPay"
                    name="interestToPay"
                    label={t('interestToPay')}
                    placeholder={t('interestToPay')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<PayOffFormType>,
                    ) => {
                      await setCurrencyValue<PayOffFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    decimalLimit={2}
                  />
                  <CurrencyForm
                    id="totalToPay"
                    name="totalToPay"
                    label={t('totalToPay')}
                    placeholder={t('totalToPay')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<PayOffFormType>,
                    ) => {
                      await setCurrencyValue<PayOffFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    decimalLimit={2}
                    isDisabled={true}
                  />
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PayOffModal;
