import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getHealth } from 'api/healthApi';
import { Card } from 'flowbite-react';
import { formatDate } from 'utils/utils';
const healthDefaultValue = {
  Branch: '',
  BuildNumber: 0,
  Commit: '',
  Environment: '',
  Name: '',
  Timestamp: Date(),
  Version: '',
};

const VersionPage = () => {
  const [t] = useTranslation();

  const [health, setHealth] = useState(healthDefaultValue);
  useQuery('getHeath', getHealth, {
    onSuccess: data => {
      setHealth(data?.data);
    },
    onError: () => {
      setHealth(healthDefaultValue);
    },
  });

  return (
    <>
      <div className="flex items-center justify-center h-screen">
        <div className="w-100 self-center ">
          <Card>
            <div className="p-3">
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {`${t('versionPage.project')}: ${health.Name}`} <br />
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400 ">
                {`${t('versionPage.versionNumber')}:  ${health.Version}`} <br />
                {`${t('versionPage.currentTimestamp')}: ${formatDate(health.Timestamp)}`} <br />
                {`${t('versionPage.environment')}: ${health.Environment}`} <br />
                {`${t('versionPage.buildNumber')}: ${health.BuildNumber}`} <br />
                {`${t('versionPage.branch')}: ${health.Branch}`} <br />
                {`${t('versionPage.commit')}: ${health.Commit}`} <br />
              </p>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
export default VersionPage;
