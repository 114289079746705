import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import classNames from 'classnames';
import { Label } from 'flowbite-react';
import { OptionType } from 'utils/proptypes';

import CustomItemRendererInput from './components/customItemRendereInput';
import CustomValueRendererInput from './components/customValueRendererInput';

import './customSelectInput.scss';

type MultiSelectFormType = {
  options: OptionType[];
  defaultValue?: OptionType[];
  selectItemsHandler: Function;
  isMulti?: boolean;
  isDisabled?: boolean;
  dataTestId?: string;
  placeHolder?: string;
  isFilter?: boolean;
  disableSearch?: boolean;
};
const CustomSelectInput = ({
  options,
  defaultValue,
  selectItemsHandler,
  isMulti = false,
  isDisabled = false,
  placeHolder,
  isFilter,
  disableSearch = false,
}: MultiSelectFormType) => {
  const [currentValues, setCurrentValues] = useState<OptionType[]>([]);
  const [t] = useTranslation()
  return (
    <div
      className={classNames('custom-multi-select-input w-full', {
        disabled: isDisabled,
        relative: isFilter,
      })}
    >
      <MultiSelect
        overrideStrings={{
          placeHolder: placeHolder ? placeHolder : t('select'),
          selectSomeItems: placeHolder ? placeHolder : t('select'),
          selectAll: isMulti ? t('selectAll') : '',
          search: t('search'),
          selectAllFiltered: isMulti ? t('selectAllFiltered') : '',
          noOptions: t('noOptions'),
        }}
        options={options || []}
        value={defaultValue ? defaultValue : currentValues.length ? currentValues : []}
        onChange={(items: OptionType[]) => {
          setCurrentValues(items.length ? items : []);
          selectItemsHandler(items.length > 1 ? items[1].value : items.length ? items[0].value : defaultValue?.[0].value);
        }}
        labelledBy=""
        className={classNames('multi-select text-sm text-gray-900', {
          disabled: isDisabled,
          'filter-group': isFilter,
        })}
        hasSelectAll={isMulti}
        valueRenderer={selected => CustomValueRendererInput({ selected, isMulti })}
        ItemRenderer={({ checked, option, onClick, disabled }: any) =>
          CustomItemRendererInput({
            checked,
            option,
            onClick,
            disabled,
            isMulti,
          })
        }
        disabled={isDisabled}
        closeOnChangedValue={!isMulti}
        disableSearch={disableSearch}
      />
      {isFilter && (
        <div
          className={classNames('custom-multi-select-input__label absolute block rounded-sm leading-none', {
            disabled: isDisabled,
            haveValue: defaultValue?.length || currentValues?.length,
          })}
        >
          <Label value={placeHolder} />
        </div>
      )}
    </div>
  );
};

export default CustomSelectInput;
