import { configureStore } from '@reduxjs/toolkit';

import reducers from './reducers';

export default function configureAppStore(preloadedState: any = {}) {
  return configureStore({
    reducer: reducers,
    preloadedState,
  });
}
