import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { getRoles } from 'api/rolesApi';
import { changeStatusType, changeStatusUser, exportUsersToCSV, getUserByFilter } from 'api/userApi';
import ConfirmActiveModal from 'components/modal/common/confirmActiveModal';
import AddUsersManagementModal from 'components/modal/usersManagement/addUserManagementModal';
import HeaderTable from 'components/table/headerTable';
import PaginateTable from 'components/table/paginate';
import useLoading from 'hooks/useLoading';
import { ACTION_STATUS, DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME, ROUTE_PATHS, status } from 'utils/constants';
import { UserType } from 'utils/proptypes';
import { exportRowDataToCSV, messageErrors } from 'utils/utils';

import { RenderUsersTableData } from './components';

import '../../../styles/styles.scss';
import './usersManagement.scss';

const UsersManagementPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntities, setTotalEntities] = useState(0);
  const limit = DEFAULT_VALUE_FILTER.LIMIT;
  const [users, setUsers] = useState<UserType[]>([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUserStatus, setSelectedUserStatus] = useState('');
  const [selectedUser, setSelectedUser] = useState<UserType>();
  const ref = useRef(null);

  const { data: rolesData } = useQuery(
    ['roles'],
    () => {
      const params: any = {
        page: currentPage,
        limit,
      };
      return getRoles(params);
    },
    {
      cacheTime: 60 * 1000,
    },
  );

  const { refetch: fetchUsers, isFetching } = useQuery(
    ['uses', currentPage, searchValue],
    () => getUserByFilter({ page: currentPage, limit, searchQuery: searchValue }),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.totalEntities);
        setUsers(data.entities);
      },
      onError: () => {
        setUsers([]);
        setTotalEntities(0);
      },
    },
  );

  useLoading({ isLoading: isFetching, ref });

  const mutation = useMutation('change-status-user', {
    mutationFn: async (userPayload: changeStatusType) => {
      await changeStatusUser(selectedUser?.id as string, userPayload);
    },
  });

  const addUserHandler = () => {
    setOpenAddModal(!openAddModal);
  };

  const editUserHandler = (item: UserType) => {
    setOpenAddModal(!openAddModal);
    setIsEdit(!isEdit);
    navigate(`${ROUTE_PATHS.USERS_PAGE}?id=${item?.id}`, { replace: true });
    setSelectedUser(item);
  };

  const changeUserStatus = (item: UserType) => {
    setOpenStatusModal(!openStatusModal);
    setSelectedUserStatus(item.isActive ? status.ACTIVE : status.INACTIVE);
    setSelectedUser(item);
    navigate(`${ROUTE_PATHS.USERS_PAGE}?id=${item.id}`, { replace: true });
  };

  const handleExportUsers = async () => {
    await exportUsersToCSV({ searchQuery: searchValue }).then(async res => {
      exportRowDataToCSV({ stream: res.data, fileName: EXPORT_FILE_NAME.USER });
    });
  };

  const { refetch: refetchExportUsers, isFetching: isFetchingDownloadUsers } = useQuery('download-users', handleExportUsers, {
    onSuccess: () => {
      const message: string = t('userManagementPage.exportToCsvSuccess');
      toast.success(message);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
    enabled: false,
  });

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="w-full users-management-page">
        <HeaderTable
          setCurrentPage={setCurrentPage}
          placeholderSearch={t('userManagementPage.searchUsers')}
          nameButton={t('userManagementPage.addUser')}
          setSearchValue={setSearchValue}
          addHandler={addUserHandler}
          exportHandler={refetchExportUsers}
          hideSearch={false}
          hideExportButton={true}
          className="users"
          dataTestId="userHeaderSearchInput"
        />
        <RenderUsersTableData users={users} editUserHandler={editUserHandler} changeUserStatus={changeUserStatus} isFetching={isFetching} />
        {totalEntities > 0 && (
          <PaginateTable
            className="users-management-page__pagination"
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isFetching={isFetching}
            isFetchingDownload={isFetchingDownloadUsers}
            exportHandler={refetchExportUsers}
            items={users}
          />
        )}
      </div>
      <AddUsersManagementModal
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        refetchApi={fetchUsers}
        rolesData={rolesData?.data.entities}
        selectedUser={selectedUser}
      />
      <ConfirmActiveModal
        openModal={openStatusModal}
        setOpenModal={setOpenStatusModal}
        typeEvent={selectedUserStatus === status.ACTIVE ? ACTION_STATUS.DEACTIVE : ACTION_STATUS.REACTIVE}
        title={t('userManagementPage.user')}
        mutation={mutation}
        refetchApi={fetchUsers}
      />
    </>
  );
};
export default UsersManagementPage;
