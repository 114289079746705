import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowNarrowLeft, HiOutlineCalendar, HiOutlineCash, HiOutlineCurrencyDollar, HiOutlineDownload, HiPencilAlt } from 'react-icons/hi';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import CardIndexGroup from 'components/common/CardIndexGroup';
import { AbilityContext } from 'contexts/can';
import moment from 'moment';
import { paymentAction } from 'redux/actions';
import { ABILITY_ACTION, FORMAT_DATE_SHOW_TABLE, PERMISSION, ROUTE_PATHS } from 'utils/constants';
import { InvestmentType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi, openStaticFile } from 'utils/utils';

import { downloadInterestInvestmentPDF, getInvestmentById } from '../../../../api/investmentApi';
import { downloadInvestmentWithoutInterest, DownloadInvestmentWithoutInterestType } from '../../../../api/supporter/investmentPayment';
import InvestmentModal from '../../../../components/modal/investments/investmentModal';

import InvestmentNotes from './components/investmentCollateral';
import InvestmentPaymentsPage from './investmentPayments/investmentPayments';

import './renderInvestmentDetail.scss';

const RenderInvestmentDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const params = useParams();
  const ability = useContext(AbilityContext);
  const supporterId = params.supporterId;
  const investmentId = params.id;
  const [openEditInvestmentModal, setOpenEditPartnerModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedInvestment, setSelectedInvesment] = useState<InvestmentType>();

  const { refetch: fetchInvestmentById, isFetching } = useQuery(['getInvestmentById'], () => getInvestmentById(investmentId as string), {
    onSuccess: ({ data }) => {
      setSelectedInvesment(data);
    },
    onError: () => {
      setSelectedInvesment(undefined);
    },
  });

  const cardIndexData = [
    {
      bgColor: 'bg-yellow-50',
      bgIconColor: 'bg-yellow-400',
      textIconColor: 'text-white',
      label: t('amount'),
      value: convertNumberToCurrency(getNumberValueFromApi(selectedInvestment?.amount as number)) as string,
      icon: HiOutlineCash,
    },
    {
      bgColor: 'bg-red-50',
      bgIconColor: 'bg-red-500',
      textIconColor: 'text-white',
      label: t('interestRate'),
      value: selectedInvestment?.interestRate ? `${getNumberValueFromApi(selectedInvestment?.interestRate as number).toFixed(2)} %` : '',
      icon: HiOutlineCurrencyDollar,
    },
    {
      bgColor: 'bg-green-100',
      bgIconColor: 'bg-green-500',
      textIconColor: 'text-white',
      label: t('period'),
      value: selectedInvestment?.term ? `${getNumberValueFromApi(selectedInvestment?.term)} ${t('year(s)')}` : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-indigo-100',
      bgIconColor: 'bg-indigo-500',
      textIconColor: 'text-white',
      label: t('investmentDate'),
      value: selectedInvestment?.startDate ? moment.utc(selectedInvestment?.startDate).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-pink-100',
      bgIconColor: 'bg-pink-500',
      textIconColor: 'text-white',
      label: t('maturityDate'),
      value: selectedInvestment?.maturity ? moment.utc(selectedInvestment?.maturity as string).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-orange-100',
      bgIconColor: 'bg-orange-500',
      textIconColor: 'text-white',
      label: t('payInterest'),
      value: selectedInvestment?.payInterest ? (selectedInvestment?.payInterest as string) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-teal-100',
      bgIconColor: 'bg-teal-500',
      textIconColor: 'text-white',
      label: t('payPrincipal'),
      value: selectedInvestment?.payPrincipal ? (selectedInvestment?.payPrincipal as string) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-gray-100',
      bgIconColor: 'bg-gray-400',
      textIconColor: 'text-white',
      label: t('creationDate'),
      value: selectedInvestment?.payPrincipal ? moment.utc(selectedInvestment.created).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
  ];

  const editPartnerHandler = () => {
    setOpenEditPartnerModal(!openEditInvestmentModal);
    setIsEdit(!isEdit);
  };

  const { mutate: downloadPDFWithoutInterestMutation, isLoading: isDownloadingPDFWithoutInterest } = useMutation(downloadInvestmentWithoutInterest, {
    onSuccess: res => {
      openStaticFile(res?.data?.download_url);
    },
  });

  const supporter = useMemo(() => selectedInvestment?.supporter, [selectedInvestment?.supporter]);

  const handleDownloadPDFInvestmentWithoutInterest = async () => {
    const downloadPdfPayload: DownloadInvestmentWithoutInterestType = {
      CurrentDate: moment.utc().format(FORMAT_DATE_SHOW_TABLE),
      FirstName: supporter?.firstName ? supporter?.firstName : '',
      LastName: supporter?.lastName ? supporter?.lastName : '',
      Salutation: supporter?.prefixTitles ? `${supporter?.prefixTitles}.` : '',
      Organization: supporter?.organizationName ? supporter?.organizationName : '',
      Address: supporter?.contacts?.[0]?.address?.[0]?.street ? supporter?.contacts?.[0]?.address?.[0]?.street : '',
      City: supporter?.contacts?.[0]?.address?.[0]?.city ? supporter?.contacts?.[0]?.address?.[0]?.city : '',
      State: supporter?.contacts?.[0]?.address?.[0]?.state ? supporter?.contacts?.[0]?.address?.[0]?.state : '',
      Zip: supporter?.contacts?.[0]?.address?.[0]?.zip ? supporter?.contacts?.[0]?.address?.[0]?.zip : '',
      InvestmentNumber: selectedInvestment?.name ? selectedInvestment?.name : '',
    };

    downloadPDFWithoutInterestMutation(downloadPdfPayload);
  };

  const { mutate: downloadInterestInvestmentPaymentPDFMutate, isLoading: isDownloadInterestInvestmentPaymentPDF } = useMutation(
    downloadInterestInvestmentPDF,
    {
      onSuccess: res => {
        openStaticFile(res?.data?.download_url);
      },
    },
  );

  const handleDownloadInterestInvestmentPaymentPDF = async () => {
    if (selectedInvestment?.id) downloadInterestInvestmentPaymentPDFMutate(selectedInvestment?.id);
  };

  return (
    <div className="investment-detail-page flex flex-col justify-start items-center">
      <div className="header w-full flex justify-between items-center px-4 py-4">
        <HiArrowNarrowLeft
          className="header__icon leading-none text-gray-900 text-base font-medium cursor-pointer border border-gray-900 rounded-full p-1"
          size={36}
          onClick={() => {
            navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${supporterId}`);
            dispatch(paymentAction.setPaymentsOfLoan(undefined));
          }}
        />
        <h3 className="header__title leading-none text-gray-900 text-base font-medium">{t('investmentDetails')}</h3>
        {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT.VIEW) && (
          <div className="flex gap-4">
            {selectedInvestment && selectedInvestment?.interestRate === 0 ? (
              <ButtonIconWithText
                className={classNames('header__button bg-blue-900', { 'animate-pulse': isDownloadingPDFWithoutInterest })}
                text={t('downloadPDF')}
                onClick={() => handleDownloadPDFInvestmentWithoutInterest()}
                Icon={HiOutlineDownload}
                disabled={isDownloadingPDFWithoutInterest}
              />
            ) : (
              <ButtonIconWithText
                className={classNames('header__button bg-blue-900', { 'animate-pulse': isDownloadInterestInvestmentPaymentPDF })}
                text={t('downloadPDF')}
                onClick={() => handleDownloadInterestInvestmentPaymentPDF()}
                Icon={HiOutlineDownload}
                disabled={isDownloadInterestInvestmentPaymentPDF}
              />
            )}
            {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT.UPDATE) && (
              <ButtonIconWithText
                className="header__button bg-blue-900"
                text={t('editInvestment')}
                onClick={() => editPartnerHandler()}
                Icon={HiPencilAlt}
              />
            )}
          </div>
        )}
      </div>
      <div className="investment-payment-detail h-full w-full px-4 pb-4 flex flex-col justify-center items-center">
        <div className="investment-payment-detail__top w-full">
          <CardIndexGroup cardIndexData={cardIndexData} className="grid grid-cols-4 gap-4 mb-3" />
        </div>
        {(selectedInvestment?.notes?.length as number) > 0 && (
          <div className="investment-payment-detail__middle w-full">
            <InvestmentNotes selectedInvestment={selectedInvestment} isFetching={isFetching} />
          </div>
        )}
        <div className="investment-payment-detail__bottom h-full w-full">
          <InvestmentPaymentsPage selectedInvestment={selectedInvestment} isFetchingInvestment={isFetching} />
        </div>
      </div>
      <InvestmentModal
        openModal={openEditInvestmentModal}
        setOpenModal={setOpenEditPartnerModal}
        isEdit={true}
        refetchApi={fetchInvestmentById}
        selectedInvestment={selectedInvestment}
      />
    </div>
  );
};

export default RenderInvestmentDetail;
