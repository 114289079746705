import base from './baseApi';

export type AddEditNoteType = {
  content: string;
  entityId: string;
  entityType: string;
};

const { urls, methods, execute } = base();

const addNote = async (payload: AddEditNoteType) => {
  const method = methods.POST;
  const url = urls.notes.notes;
  return execute(method, url, { authorization: true }, payload);
};

const getNoteByEntityId = async (entityId: string) => {
  const method = methods.GET;
  const url = `${urls.notes.entityId}/${entityId}`;
  return execute(method, url, { authorization: true });
};

const updateNoteById = (id: string, payload: AddEditNoteType) => {
  const method = methods.PATCH;
  const url = `${urls.notes.notes}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const removeNote = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.notes.notes}/${id}`;

  return execute(method, url, { authorization: true });
};

export { addNote, getNoteByEntityId, updateNoteById, removeNote };
