import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PERMISSION_PROFILES } from 'utils/permission';
import { GuardedRouteTypes } from 'utils/proptypes';

const GuardedRoute = ({ path, userRole }: GuardedRouteTypes) => {
  if (userRole) {
    const userPermissionsProfile = PERMISSION_PROFILES.filter(profile => userRole.includes(profile.type));

    const userIsAuthorized = userPermissionsProfile?.filter(userPermission => userPermission?.permittedRoutes.find(route => route === path));

    return userIsAuthorized ? <Outlet /> : <Navigate to={userPermissionsProfile?.[0].defaultRedirectUrl || ''} />;
  }

  return <Navigate replace to={path} />;
};

GuardedRoute.propTypes = {
  path: PropTypes.string.isRequired,
};

export default GuardedRoute;
