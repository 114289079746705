import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createPayment } from 'api/loanFundManager/paymentApi';
import { createSchedulePayment } from 'api/loanFundManager/schedulePaymentApi';
import {
  createInvestmentPayment,
  PayOffType,
} from 'api/supporter/investmentPayment';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { sumBy } from 'lodash';
import moment from 'moment';
import { FORMAT_DATE_API } from 'utils/constants';
import { InvestmentType, LoanType, PaymentType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, selectDateHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import { AddInvestmentType } from '../../../api/investmentApi';
import { usePayOff } from '../../../hooks/usePayOffLoan';

import './payOffModal.scss';
import '../../../styles/common/multiSelect.scss';

type PayOffProps = {
  openModal: boolean;
  setOpenModal: Function;
  schedulePayments?: PaymentType[];
  selectedLoan?: LoanType;
};

type PayOffFormType = {
  paymentDate?: Date;
  principalToPay: number;
  interestToPay?: number;
  totalToPay: number;
  payOffDate?: Date;
};

const PayOffModal = (props: PayOffProps) => {
  const { openModal = false, setOpenModal, selectedLoan, schedulePayments } = props;
  const { t } = useTranslation();
  const { id: investmentId } = useParams();
  const queryClient = useQueryClient();
  const [isResetForm, setIsResetForm] = useState(false);
  const [newPayOffDate, setNewPayOffDate] = useState<Date | null>(null);

  const ValidateSchema = Yup.object().shape({
    interestToPay: Yup.number()
      .min(0, t('investmentPage.currencyMin'))
      .max(1000000000, t('investmentPage.currencyMax')),
    paymentDate: Yup.date().required(t('investmentPage.requiredField')),
  });

  const { receivedPayments, payInterest, dueDate, totalPrincipal } = usePayOff({
    schedulePayments: schedulePayments?.length ? schedulePayments : [],
    newPayOffDate: newPayOffDate as Date,
    selectedLoan: selectedLoan
  });

  const formik = useFormikContext();

  const initialValues: PayOffFormType = {
    paymentDate: dueDate ?? newPayOffDate as Date,
    principalToPay: getNumberValueFromApi(Number(totalPrincipal)),
    interestToPay: Number(payInterest),
    totalToPay: Number(payInterest) + getNumberValueFromApi(totalPrincipal),
  };

  const handleAddAndUpdateSchedulePayment = async (paymentPayload: any) => {
    await createSchedulePayment(paymentPayload);
  };

  const mutation = useMutation('pay-off-loan-schedule-payment', {
    mutationFn: handleAddAndUpdateSchedulePayment,
  });

  const handleSubmit = async (data: PayOffFormType, action: { [key: string]: any }) => {
    const successCallback = async (message: string) => {
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      setNewPayOffDate(null);
      await queryClient.invalidateQueries(['paymentsSchedule']);
    };

    if (openModal) {
      mutation.mutate(
        {
          loanId: selectedLoan?.id as string,
          payOffDate: newPayOffDate ? moment(newPayOffDate).format(FORMAT_DATE_API) : moment(data?.paymentDate).format(FORMAT_DATE_API),
          principalAmount: data.principalToPay ? getNumberValueSendToApi(data.principalToPay) : undefined,
          paymentDate: newPayOffDate ? moment(newPayOffDate).format(FORMAT_DATE_API) : moment(data?.paymentDate).format(FORMAT_DATE_API),
          isResetForm,
        },
        {
          onSuccess: async () => {
            const message: string = t('Pay off Loan successfully');
            await successCallback(message);
          },
          onError: (error: any) => {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<PayOffFormType>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
    setNewPayOffDate(null);
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="pay-off-payment-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
        setNewPayOffDate(null);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('Pay Off')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CustomDatePickerForm
                    id="paymentDate"
                    name="paymentDate"
                    label={t('Pay Off Date')}
                    placeHolder={t('Pay Off Date')}
                    isRequired={true}
                    propsFormik={props}
                    selectItemsHandler={async (date: Date) => {
                      await selectDateHandler<AddInvestmentType>({
                        date,
                        props,
                        fieldName: 'paymentDate',
                      });
                      setNewPayOffDate(date);
                    }}
                    isDeleteSelectedDate={openModal}
                    position="top"
                  />
                  <CurrencyForm
                    id="principalToPay"
                    name="principalToPay"
                    label={t('Principal to Pay')}
                    placeholder={t('Principal to Pay')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<PayOffFormType>,
                    ) => {
                      await setCurrencyValue<PayOffFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    decimalLimit={2}
                    isDisabled={true}
                  />
                  <CurrencyForm
                    id="interestToPay"
                    name="interestToPay"
                    label={t('Interest to Pay')}
                    placeholder={t('Interest to Pay')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<PayOffFormType>,
                    ) => {
                      await setCurrencyValue<PayOffFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    decimalLimit={2}
                    isDisabled={true}
                  />
                  <CurrencyForm
                    id="totalToPay"
                    name="totalToPay"
                    label={t('Total to Pay')}
                    placeholder={t('Total to Pay')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<PayOffFormType>,
                    ) => {
                      await setCurrencyValue<PayOffFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    decimalLimit={2}
                    isDisabled={true}
                  />
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PayOffModal;
