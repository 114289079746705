import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiX } from 'react-icons/hi';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import { AbilityContext } from 'contexts/can';
import moment from 'moment';
import { ABILITY_ACTION, FORMAT_DATE_SHOW_TABLE, PERMISSION } from 'utils/constants';
import { InvestmentType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import CustomSpinner from '../../../../components/common/customSpinner';

type RenderInvestmentsTableDataProps = {
  investments: Partial<InvestmentType>[];
  openInvestmentDetail: Function;
  isFetching: boolean;
  setSelectedInvestment: Function;
  setIsOpenRemoveModal: Function;
  isOpenRemoveModal: boolean;
  isAdmin: boolean;
};

const RenderInvestmentsTableDataProps = (props: RenderInvestmentsTableDataProps) => {
  const ability = useContext(AbilityContext);
  const { investments = [], openInvestmentDetail, isFetching, setIsOpenRemoveModal, isOpenRemoveModal, setSelectedInvestment, isAdmin } = props;
  const [t] = useTranslation()
  return investments?.length > 0 ? (
    <table className={classNames('w-full h-auto text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': !investments.length })}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 rounded">
        <tr className="capitalize">
          <th scope="col" className="py-3 px-3 max-width-80">
            {t('id')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80">
            {t('amount')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile-tablet">
            {t('period')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile-tablet">
            {t('rate')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile">
            {t('start')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile">
            {t('maturity')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile">
            {t('outstanding')}
          </th>
          {isAdmin && <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile" />}
        </tr>
      </thead>
      <tbody className="text-xs">
        {investments?.map((investment: Partial<InvestmentType>, key: number) => {
          return (
            <tr
              key={`${key}-list-investments`}
              data-testid={`investments-table-row-${key}`}
              className={`investment-${key === investments?.length - 1 ? 'last' : key
                } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
            >
              <th
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW_DETAIL)) openInvestmentDetail(investment);
                }}
                scope="row"
                className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white"
              >
                <TooltipComponent
                  anchorSelect={`investment-content-${key}`}
                  content={investment?.name}
                  className="font-normal partners-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW_DETAIL)) openInvestmentDetail(investment);
                }}
                scope="row"
                className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white"
              >
                <TooltipComponent
                  anchorSelect={`investment-amount-${key}`}
                  content={convertNumberToCurrency(getNumberValueFromApi(investment?.amount as number)) as string}
                  className="font-normal partners-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW_DETAIL)) openInvestmentDetail(investment);
                }}
                scope="row"
                className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white hidden-mobile-tablet"
              >
                <TooltipComponent
                  anchorSelect={`investment-term-${key}`}
                  content={
                    investment?.term
                      ? investment?.term > 100
                        ? `${getNumberValueFromApi(investment?.term)} ${t('years')}`
                        : `${getNumberValueFromApi(investment?.term)}  ${t('year')}`
                      : `${getNumberValueFromApi(investment?.term as number)}  ${t('year')}`
                  }
                  className="font-normal partners-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW_DETAIL)) openInvestmentDetail(investment);
                }}
                scope="row"
                className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white hidden-mobile-tablet"
              >
                <TooltipComponent
                  anchorSelect={`investment-interest-rate-${key}`}
                  content={`${getNumberValueFromApi(investment?.interestRate as number).toFixed(2)}%`}
                  className="font-normal partners-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW_DETAIL)) openInvestmentDetail(investment);
                }}
                scope="row"
                className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white hidden-mobile"
              >
                <TooltipComponent
                  anchorSelect={`investment-disbursement-date-${key}`}
                  content={investment?.startDate ? moment.utc(investment?.startDate).format(FORMAT_DATE_SHOW_TABLE) : ''}
                  className="font-normal partners-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW_DETAIL)) openInvestmentDetail(investment);
                }}
                scope="row"
                className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white hidden-mobile"
              >
                <TooltipComponent
                  anchorSelect={`investment-maturity-date-${key}`}
                  content={
                    investment?.maturity ? moment(moment.utc(investment?.maturity).format(FORMAT_DATE_SHOW_TABLE)).format(FORMAT_DATE_SHOW_TABLE) : ''
                  }
                  className="font-normal partners-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.VIEW_DETAIL)) openInvestmentDetail(investment);
                }}
                scope="row"
                className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white hidden-mobile"
              >
                <TooltipComponent
                  anchorSelect={`investment-outstanding-${key}`}
                  content={
                    investment?.totalOutstanding
                      ? convertNumberToCurrency(
                        getNumberValueFromApi(investment?.totalOutstanding) < 1
                          ? Math.round(getNumberValueFromApi(investment?.totalOutstanding))
                          : getNumberValueFromApi(investment?.totalOutstanding),
                      )
                      : ''
                  }
                  className="font-normal partners-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th scope="row" className="py-3 px-3 font-normal text-gray-900 investments--truncate max-width-80 dark:text-white hidden-mobile">
                {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT.DELETE) && isAdmin && (
                  <button
                    onClick={() => {
                      setIsOpenRemoveModal(!isOpenRemoveModal);
                      setSelectedInvestment(investment);
                    }}
                    className="delete-loan-button cursor-pointer px-1 py-1 text-white bg-red-700 rounded-lg float-right"
                  >
                    <HiX className="h-4 w-4" />
                  </button>
                )}
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div className="h-full investment-no-result text-center bg-white py-4">{isFetching ? <CustomSpinner /> : <NoResult />}</div>
  );
};

export default RenderInvestmentsTableDataProps;
