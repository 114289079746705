import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import moment from 'moment';
import { FORMAT_DATE_SHOW_TABLE } from 'utils/constants';
import { SummaryReportsResponseType, SummaryReportType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import CustomSpinner from '../../../../../../components/common/customSpinner';

type RenderSummaryReportsTableDataType = {
  summaryReportsResponse?: Partial<SummaryReportsResponseType>;
  isFetching?: boolean;
  reportDate: string;
  isRenderNonPerformingRecords?: boolean;
};

const RenderSummaryReportsTableData = (props: RenderSummaryReportsTableDataType) => {
  const { summaryReportsResponse, isFetching, reportDate, isRenderNonPerformingRecords = false } = props;
  const [t] = useTranslation()
  return (
    <table
      className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', {
        'h-full': !summaryReportsResponse?.entities?.length,
      })}
    >
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-loan"
              content={t('loan')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-country"
              content={t('country')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-partner"
              content={t('partner')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-date"
              content="Date"
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-accrued-unpaid-interest"
              content={t('accruedUnpaidInterest')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-outstanding-principal"
              content={t('totalOutstandingPrincipal')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-short-term-principal"
              content={t('shortTermPrincipal')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-long-term-principal"
              content={t('longTermPrincipal')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-interest-rate"
              content={t('interestRate')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="summary-report-percent-total-interest"
              content={t('weightedInterestRate')}
              className="summary-report--truncate max-w-full dark:text-white"
            />
          </th>
          {!isRenderNonPerformingRecords ? (
            <th scope="col" className="py-3 px-6">
              <TooltipComponent
                isShowTooltip={false}
                anchorSelect="summary-report-percent-performing-interest"
                content={t('weightedPerformingInterestRate')}
                className="summary-report--truncate max-w-full dark:text-white"
              />
            </th>
          ) : (
            ''
          )}
        </tr>
      </thead>
      <tbody>
        {summaryReportsResponse?.entities?.length ? (
          summaryReportsResponse?.entities?.map((summaryReport: SummaryReportType, key: number) => {
            return (
              <tr
                key={`${key}-list-summary-report`}
                className={`summary-report-${key === (summaryReportsResponse?.entities?.length as number) - 1 ? 'last' : key
                  } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
              >
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-loan-${key}`}
                    content={summaryReport?.loanName?.length ? summaryReport?.loanName : ''}
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-country-${key}`}
                    content={summaryReport?.country ? summaryReport?.country : ''}
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-partner-${key}`}
                    content={summaryReport?.partnerNickName ? summaryReport?.partnerNickName : ''}
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-date-${key}`}
                    content={reportDate ? moment.utc(reportDate).format(FORMAT_DATE_SHOW_TABLE) : ''}
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-accrued-unpaid-interest-${key}`}
                    content={
                      !isRenderNonPerformingRecords && summaryReport?.accruedUnpaidInterest
                        ? convertNumberToCurrency(getNumberValueFromApi(summaryReport?.accruedUnpaidInterest))
                        : ''
                    }
                    className={classNames('font-normal summary-report--truncate max-w-full dark:text-white', {
                      'text-gray-900': !isRenderNonPerformingRecords,
                      'text-yellow-500': isRenderNonPerformingRecords,
                    })}
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white max-width-140">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-outstanding-principal-${key}`}
                    content={
                      summaryReport?.outstandingPrincipal ? convertNumberToCurrency(getNumberValueFromApi(summaryReport?.outstandingPrincipal)) : ''
                    }
                    className={classNames('font-normal summary-report--truncate max-w-full dark:text-white', {
                      'text-gray-900': !isRenderNonPerformingRecords,
                      'text-yellow-500': isRenderNonPerformingRecords,
                    })}
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-short-term-principal-${key}`}
                    content={
                      summaryReport?.shortTermPrincipal ? convertNumberToCurrency(getNumberValueFromApi(summaryReport?.shortTermPrincipal)) : ''
                    }
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-long-term-principal-${key}`}
                    content={summaryReport?.longTermPrincipal ? convertNumberToCurrency(getNumberValueFromApi(summaryReport?.longTermPrincipal)) : ''}
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-term-interest-rate-${key}`}
                    content={summaryReport?.interestRate ? `${getNumberValueFromApi(summaryReport?.interestRate).toFixed(2)}%` : ''}
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                  <TooltipComponent
                    isShowTooltip={false}
                    anchorSelect={`summary-report-percent-total-interest-${key}`}
                    content={
                      summaryReport?.percentOfTotalInterest ? `${getNumberValueFromApi(summaryReport?.percentOfTotalInterest).toFixed(2)}%` : ''
                    }
                    className="font-normal summary-report--truncate max-w-full dark:text-white"
                  />
                </th>
                {!isRenderNonPerformingRecords ? (
                  <th scope="row" className="py-4 px-6 font-normal text-gray-900 dark:text-white">
                    <TooltipComponent
                      isShowTooltip={false}
                      anchorSelect={`summary-report-percent-total-interest-${key}`}
                      content={
                        summaryReport?.percentOfPerformingInterest
                          ? `${getNumberValueFromApi(summaryReport?.percentOfPerformingInterest).toFixed(2)}%`
                          : ''
                      }
                      className="font-normal summary-report--truncate max-w-full dark:text-white"
                    />
                  </th>
                ) : (
                  ''
                )}
              </tr>
            );
          })
        ) : (
          <tr className="h-full summary-report-no-result text-center">
            <th colSpan={11} className="p-8">
              {isFetching ? <CustomSpinner /> : <NoResult />}
            </th>
          </tr>
        )}
      </tbody>
      {summaryReportsResponse?.entities?.length ? (
        <tfoot>
          <tr className="bg-blue-50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="col" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
              {''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
              {''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
              {''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
              {''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
              {!isRenderNonPerformingRecords && summaryReportsResponse?.totalAccruedUnpaidInterest
                ? convertNumberToCurrency(getNumberValueFromApi(summaryReportsResponse?.totalAccruedUnpaidInterest))
                : ''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140 ">
              {isRenderNonPerformingRecords
                ? summaryReportsResponse?.totalNonPerforming
                  ? convertNumberToCurrency(getNumberValueFromApi(summaryReportsResponse?.totalNonPerforming))
                  : ''
                : summaryReportsResponse?.totalOutstandingPrincipal
                  ? convertNumberToCurrency(getNumberValueFromApi(summaryReportsResponse?.totalOutstandingPrincipal))
                  : ''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
              {summaryReportsResponse?.totalOutstandingPrincipalShortTerm
                ? convertNumberToCurrency(getNumberValueFromApi(summaryReportsResponse?.totalOutstandingPrincipalShortTerm))
                : ''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
              {summaryReportsResponse?.totalOutstandingPrincipalLongTerm
                ? convertNumberToCurrency(getNumberValueFromApi(summaryReportsResponse?.totalOutstandingPrincipalLongTerm))
                : ''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
              {''}
            </th>
            <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
              {summaryReportsResponse?.totalPercentOfTotalInterest
                ? `${getNumberValueFromApi(summaryReportsResponse?.totalPercentOfTotalInterest).toFixed(2)}%`
                : ''}
            </th>
            {!isRenderNonPerformingRecords ? (
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
                {summaryReportsResponse?.totalPercentOfPerformingInterest
                  ? `${getNumberValueFromApi(summaryReportsResponse?.totalPercentOfPerformingInterest).toFixed(2)}%`
                  : ''}
              </th>
            ) : (
              ''
            )}
          </tr>
          {!isRenderNonPerformingRecords && (
            <tr className="bg-blue-200 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="col" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
                <TooltipComponent
                  isShowTooltip={false}
                  anchorSelect="summary-report-weighted-average-interest-incom"
                  content={t('Weighted Average Interest Income')}
                  className="summary-report--truncate max-w-full dark:text-white"
                />
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140">
                {!isRenderNonPerformingRecords && summaryReportsResponse?.weightedAverageInterestIncome
                  ? convertNumberToCurrency(getNumberValueFromApi(summaryReportsResponse?.weightedAverageInterestIncome))
                  : ''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white max-width-140 ">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
                {''}
              </th>
              <th scope="row" className="py-4 px-6 text-gray-900 dark:text-white">
                {''}
              </th>
            </tr>
          )}
        </tfoot>
      ) : (
        ''
      )}
    </table>
  );
};

export default RenderSummaryReportsTableData;
