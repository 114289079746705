import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiX } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import ConfirmModal from 'components/modal/common/confirmModal';
import { AbilityContext } from 'contexts/can';
import moment from 'moment';
import { userSelector } from 'redux/selectors';
import { ABILITY_ACTION, FORMAT_DATE_SHOW_TABLE, PERMISSION, USER_ROLES } from 'utils/constants';
import { LoanType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi, getOutstanding } from 'utils/utils';

import CustomSpinner from '../../../../components/common/customSpinner';

type renderLoansTableDataType = {
  loans: any[];
  openLoanDetail: Function;
  isFetching: boolean;
  handleDeleteLoan: Function;
};

const RenderLoansTableData = (props: renderLoansTableDataType) => {
  const ability = useContext(AbilityContext);
  const [t] = useTranslation()
  const { loans = [], openLoanDetail, isFetching = false, handleDeleteLoan } = props;
  const [selectedLoanId, setSelectedLoanId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { userInfo } = useSelector(userSelector);
  const { userRole } = userInfo;
  const isAdmin = userRole.includes(USER_ROLES.SUPER_ADMIN);

  const handleOpenConfirmModal = (loanId: string) => {
    setSelectedLoanId(loanId);
    setOpenModal(true);
  };

  return (
    <table className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': !loans.length })}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 rounded">
        <tr>
          <th scope="col" className="py-3 px-3 max-width-80">
            {t('loan')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80">
            {t('amount')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-40 hidden-mobile-tablet">
            {t('term')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-40 hidden-mobile-tablet">
            {t('rate')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile">
            {t('disbursement')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile">
            {t('maturity')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile">
            {t('outstanding')}
          </th>
          {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.UPDATE) && isAdmin && <th scope="col" className="py-3 px-3" />}
        </tr>
      </thead>
      <tbody className="text-xs">
        {loans?.length > 0 ? (
          loans?.map((loan: LoanType, key: number) => {
            return (
              <tr
                key={`${key}-list-loan`}
                data-testid={`loans-table-row-${key}`}
                className={`loan-${key === loans?.length - 1 ? 'last' : key
                  } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
              >
                <th
                  scope="row"
                  className="py-3 px-3 font-normal text-gray-900 loans--truncate max-width-120 dark:text-white"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.VIEW_LOAN_DETAIL)) openLoanDetail(loan);
                  }}
                >
                  <TooltipComponent
                    anchorSelect={`loan-content-${key}`}
                    content={loan?.name}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-3 font-normal text-gray-900 loans--truncate max-width-80 dark:text-white"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.VIEW_LOAN_DETAIL)) openLoanDetail(loan);
                  }}
                >
                  <TooltipComponent
                    anchorSelect={`loan-amount-${key}`}
                    content={convertNumberToCurrency(getNumberValueFromApi(loan?.amount)) as string}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-3 font-normal text-gray-900 loans--truncate max-width-40 dark:text-white hidden-mobile-tablet"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.VIEW_LOAN_DETAIL)) openLoanDetail(loan);
                  }}
                >
                  <TooltipComponent
                    anchorSelect={`loan-term-${key}`}
                    content={loan?.term ? (loan?.term > 1 ? `${loan?.term} ${t('Months')}` : `${loan?.term} Month`) : ``}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-3 font-normal text-gray-900 loans--truncate max-width-40 dark:text-white hidden-mobile-tablet"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.VIEW_LOAN_DETAIL)) openLoanDetail(loan);
                  }}
                >
                  <TooltipComponent
                    anchorSelect={`loan-interest-rate-${key}`}
                    content={loan?.interestRate ? `${getNumberValueFromApi(loan?.interestRate).toFixed(2)}%` : ''}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-3 font-normal text-gray-900 loans--truncate max-width-80 dark:text-white hidden-mobile"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.VIEW_LOAN_DETAIL)) openLoanDetail(loan);
                  }}
                >
                  <TooltipComponent
                    anchorSelect={`loan-disbursement-date-${key}`}
                    content={loan?.disbursementDate ? moment.utc(loan?.disbursementDate).format(FORMAT_DATE_SHOW_TABLE) : ''}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-3 font-normal text-gray-900 loans--truncate max-width-80 dark:text-white hidden-mobile"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.VIEW_LOAN_DETAIL)) openLoanDetail(loan);
                  }}
                >
                  <TooltipComponent
                    anchorSelect={`loan-maturity-date-${key}`}
                    content={loan?.maturity ? moment.utc(loan?.maturity).format(FORMAT_DATE_SHOW_TABLE) : ''}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-3 font-normal text-gray-900 loans--truncate max-width-80 dark:text-white hidden-mobile"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.VIEW_LOAN_DETAIL)) openLoanDetail(loan);
                  }}
                >
                  <TooltipComponent
                    anchorSelect={`loan-total-outstanding-${key}`}
                    content={loan?.outstanding ? getOutstanding(loan?.outstanding) : ''}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>

                {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.UPDATE) && isAdmin && (
                  <th scope="row" className="py-3 pr-3 font-normal text-gray-900 loans--truncate">
                    <button
                      onClick={() => handleOpenConfirmModal(loan.id)}
                      className="delete-loan-button cursor-pointer px-1 py-1 text-white bg-red-700 rounded-lg float-right"
                    >
                      <HiX className="h-4 w-4" />
                    </button>
                  </th>
                )}
              </tr>
            );
          })
        ) : (
          <tr className="h-full loan-no-result text-center bg-white">
            <th colSpan={8}>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
          </tr>
        )}
      </tbody>
      <ConfirmModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        typeEvent={'delete'}
        title={t('loan')}
        confirmFunction={() => handleDeleteLoan(selectedLoanId)}
      />
    </table>
  );
};

export default RenderLoansTableData;
