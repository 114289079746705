/* eslint-disable camelcase */
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import languagePackageEng from './en.json';

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: languagePackageEng,
    },
  },
  debug: true,
  fallbackLng: 'en',
});

export default i18next;
