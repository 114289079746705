import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentsOfLoan: { entities: [], totalEntities: 0, rowPerPages: 0, currentPage: null },
  paymentsScheduleOfLoan: {
    entities: [],
    totalEntities: 0,
    rowPerPages: 0,
    currentPage: null,
    totalDisplay: { totalDaysSinceLastTransaction: 0, totalInterestAmount: 0, totalOfTotalToPay: 0, totalPrincipalAmount: 0 },
  },
};

const setPaymentsOfLoan = (state: any, action: any) => {
  state.paymentsOfLoan.entities = action.payload?.entities;
  state.paymentsOfLoan.totalEntities = action.payload?.totalEntities;
  state.paymentsOfLoan.rowPerPages = action.payload?.rowPerPages;
  state.paymentsOfLoan.currentPage = action.payload?.currentPage;
};

const setPaymentsScheduleOfLoan = (state: any, action: any) => {
  state.paymentsScheduleOfLoan.entities = action.payload?.entities;
  state.paymentsScheduleOfLoan.totalEntities = action.payload?.totalEntities;
  state.paymentsScheduleOfLoan.rowPerPages = action.payload?.rowPerPages;
  state.paymentsScheduleOfLoan.currentPage = action.payload?.currentPage;
  state.paymentsScheduleOfLoan.totalDisplay.totalDaysSinceLastTransaction = action.payload?.totalDaysSinceLastTransaction;
  state.paymentsScheduleOfLoan.totalDisplay.totalInterestAmount = action.payload?.totalInterestAmount;
  state.paymentsScheduleOfLoan.totalDisplay.totalOfTotalToPay = action.payload?.totalOfTotalToPay;
  state.paymentsScheduleOfLoan.totalDisplay.totalPrincipalAmount = action.payload?.totalPrincipalAmount;
};

export const paymentSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    setPaymentsOfLoan,
    setPaymentsScheduleOfLoan,
  },
});

export default paymentSlice;
