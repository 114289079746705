import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiCash,
  HiChartPie,
  HiCog,
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineCog,
  HiOutlineLogout,
  HiOutlinePresentationChartLine,
  HiOutlineUserGroup,
  HiOutlineUsers,
  HiPresentationChartLine,
  HiUserGroup,
  HiUsers
} from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { AbilityContext } from 'contexts/can';
import { Sidebar } from 'flowbite-react';
import useUser from 'hooks/useUser';
import { ABILITY_ACTION, PERMISSION, ROUTE_PATHS, USER_ROLES } from 'utils/constants';

import { userSelector } from '../../redux/selectors';

import './sidebar.scss';

type sidebarProps = {
  isDashboard?: boolean;
  isAdmin?: boolean;
};

const SidebarComponent = (props?: sidebarProps) => {
  const location = useLocation();
  const [t] = useTranslation();
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);
  const { handleLogout } = useUser();
  const { userRole } = userInfo;
  const url = location.pathname;

  const checkActivePage = (page: string): any => {
    return url.split('/')[1] === page.split('/')[1] ? 'font-semibold' : undefined;
  };

  const isDashboard = props?.isDashboard || false;
  const isAdmin = props?.isAdmin || false;

  const getMenu = () => {
    return !isDashboard ? (
      <Sidebar.ItemGroup className="sidebar__container--menu">
        {userRole.includes(USER_ROLES.SUPER_ADMIN) && isAdmin && (
          <div className={classNames('menu-item', { 'bg-gray-100': checkActivePage(ROUTE_PATHS.USERS_PAGE) })}>
            <Sidebar.Item
              onClick={() => {
                navigate(ROUTE_PATHS.USERS_PAGE);
              }}
              icon={checkActivePage(ROUTE_PATHS.USERS_PAGE) ? HiUserGroup : HiOutlineUserGroup}
              active={checkActivePage(ROUTE_PATHS.USERS_PAGE)}
            >
              <p className={`ml-4 ${checkActivePage(ROUTE_PATHS.USERS_PAGE)}`}>{t('sidebar.userManagement')}</p>
            </Sidebar.Item>
          </div>
        )}
        {userRole.includes(USER_ROLES.SUPER_ADMIN) && isAdmin && (
          <div className={classNames('menu-item', { 'bg-gray-100': checkActivePage(ROUTE_PATHS.ADDITIONAL_SETTINGS) })}>
            <Sidebar.Item
              onClick={() => {
                navigate(ROUTE_PATHS.ADDITIONAL_SETTINGS);
              }}
              icon={checkActivePage(ROUTE_PATHS.ADDITIONAL_SETTINGS) ? HiCog : HiOutlineCog}
              active={checkActivePage(ROUTE_PATHS.ADDITIONAL_SETTINGS)}
            >
              <p className={`ml-4 ${checkActivePage(ROUTE_PATHS.ADDITIONAL_SETTINGS)}`}>{t('sidebar.additionalSettings')}</p>
            </Sidebar.Item>
          </div>
        )}
        {ability.can(ABILITY_ACTION.manage, PERMISSION.PARTNER.VIEW) && !isAdmin && (
          <div className={classNames('menu-item', { 'bg-gray-100': checkActivePage(ROUTE_PATHS.PARTNERS_PAGE) })}>
            <Sidebar.Item
              onClick={() => navigate(ROUTE_PATHS.PARTNERS_PAGE)}
              icon={checkActivePage(ROUTE_PATHS.PARTNERS_PAGE) ? HiUsers : HiOutlineUsers}
              active={checkActivePage(ROUTE_PATHS.PARTNERS_PAGE)}
            >
              <p className={`ml-4 ${checkActivePage(ROUTE_PATHS.PARTNERS_PAGE)}`}>{t('sidebar.partners')}</p>
            </Sidebar.Item>
          </div>
        )}
        {(userRole.includes(USER_ROLES.SUPER_ADMIN) || userRole.includes(USER_ROLES.ACCOUNTANT)) && !isAdmin && (
          <div className={classNames('menu-item', { 'bg-gray-100': checkActivePage(ROUTE_PATHS.PAYMENT_PAGE) })}>
            <Sidebar.Item
              onClick={() => navigate(ROUTE_PATHS.PAYMENT_PAGE)}
              icon={checkActivePage(ROUTE_PATHS.PAYMENT_PAGE) ? HiCash : HiOutlineCash}
              active={checkActivePage(ROUTE_PATHS.PAYMENT_PAGE)}
            >
              <p className={`ml-4 ${checkActivePage(ROUTE_PATHS.PAYMENT_PAGE)}`}>{t('sidebar.payments')}</p>
            </Sidebar.Item>
          </div>
        )}
        {(userRole.includes(USER_ROLES.SUPER_ADMIN) || userRole.includes(USER_ROLES.SUPPORTER_MANAGER) || userRole.includes(USER_ROLES.ACCOUNTANT)) &&
          !isAdmin && (
            <div className={classNames('menu-item', { 'bg-gray-100': checkActivePage(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE) })}>
              <Sidebar.Item
                onClick={() => navigate(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE)}
                icon={checkActivePage(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE) ? HiPresentationChartLine : HiOutlinePresentationChartLine}
                active={checkActivePage(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE)}
              >
                <p className={`ml-4 ${checkActivePage(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE)}`}>{t('sidebar.supporters')}</p>
              </Sidebar.Item>
            </div>
          )}
        {(userRole.includes(USER_ROLES.SUPER_ADMIN) || userRole.includes(USER_ROLES.LOAN_FUND_MANAGER) || userRole.includes(USER_ROLES.ACCOUNTANT)) &&
          !isAdmin && (
            <div className={classNames('menu-item', { 'bg-gray-100': checkActivePage(ROUTE_PATHS.REPORTS_PAGE) })}>
              <Sidebar.Item
                onClick={() => navigate(ROUTE_PATHS.REPORTS_PAGE)}
                icon={checkActivePage(ROUTE_PATHS.REPORTS_PAGE) ? HiChartPie : HiOutlineChartPie}
                active={checkActivePage(ROUTE_PATHS.REPORTS_PAGE)}
              >
                <p className={`ml-4 ${checkActivePage(ROUTE_PATHS.REPORTS_PAGE)}`}>{t('sidebar.reports')}</p>
              </Sidebar.Item>
            </div>
          )}
        <span
          onClick={() => handleLogout()}
          className="sidebar-logout flex flex-row sm:hidden cursor-pointer text-base font-normal sign-out p-2 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <HiOutlineLogout className="h-6 w-6 mr-3 text-red-500" />
          <span className="font-medium text-red-500">{t('logout')}</span>
        </span>
      </Sidebar.ItemGroup>
    ) : (
      ''
    );
  };

  return (
    <div
      className="sidebar flex flex-col bg-white overflow-y-hidden border border-gray-200 shadow-md dark:border-gray-700 dark:bg-gray-800 text-left sticky"
      style={{ height: 'calc(100vh - 64px)', top: '64px' }}
    >
      <Sidebar aria-label="Sidebar with logo branding" className="sidebar__container">
        {getMenu()}
      </Sidebar>
      {!isDashboard && (
        <div className="flex justify-between items-center px-0 lg:px-4 sidebar__footer">
          <div className="cursor-pointer py-1.5 px-2.5 text-gray-900 hover:bg-gray-100 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group flex h-min w-fit items-center justify-center focus:z-10 rounded-md">
            <HiOutlineCog className="h-6 w-6" />
          </div>
          <div
            onClick={() => handleLogout()}
            className="flex lg:hidden cursor-pointer py-1.5 px-2.5 text-gray-900 hover:bg-gray-100 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group h-min w-fit items-center justify-center focus:z-10 rounded-md"
          >
            <HiOutlineLogout className="h-6 w-6" />
          </div>
          <button
            onClick={() => handleLogout()}
            className="hidden-mobile-tablet text-white hover:bg-white hover:text-red-600 px-4 h-9 text-sm font-medium cursor-pointer focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-lg"
          >
            {t('logout')}
          </button>
        </div>
      )}
    </div>
  );
};
export default SidebarComponent;
