import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddEditPartnerType, createPartner, updatePartnerById } from 'api/loanFundManager/partnerApi';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import TextAreaForm from 'components/form/textForm/textAreaForm';
import TextForm from 'components/form/textForm/textForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { BUSINESS_SECTOR, FORMAT_DATE_API, FUND_ALLOCATION, ROUTE_PATHS } from 'utils/constants';
import { OptionType, PartnerType } from 'utils/proptypes';
import {
  getNumberValueFromApi,
  getNumberValueSendToApi,
  getOptions,
  getOptions1,
  messageErrors,
  selectDateHandler,
  selectItemHandler,
  setCurrencyValue,
  setTextValue,
} from 'utils/utils';
import * as Yup from 'yup';

import './addPartnerModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddPartnerModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit: boolean;
  setIsEdit: Function;
  selectedPartner?: PartnerType;
  refetchApi: Function;
  isComeback?: boolean;
  fetchLoansByPartnerId?: Function;
};

type AddPartnerFormTypes = {
  name: string;
  nickName?: string;
  businessSector?: OptionType[];
  boardApprovedCreditLimit?: number;
  note?: string;
  creditLimit?: number;
  llr?: number;
  creditLimitExpirationDate?: Date;
  fundAllocation?: OptionType[];
};

const AddPartnerModal = (props: AddPartnerModalTypes) => {
  const { openModal = false, setOpenModal, selectedPartner, refetchApi, isEdit = false, setIsEdit, isComeback = true, fetchLoansByPartnerId } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const navigate = useNavigate();

  const ValidateSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, t('partnerPage.nameLengthMin'))
      .max(1000, t('partnerPage.nameLengthMax'))
      .nullable()
      .required(t('partnerPage.requiredField')),
    nickName: Yup.string().min(1, t('partnerPage.nickNameLengthMin')).max(50, t('partnerPage.nickNameLengthMax')).nullable(),
    boardApprovedCreditLimit: Yup.number().min(0, t('partnerPage.currencyMin')).max(1000000000, t('partnerPage.currencyMax')).nullable(),
    llr: Yup.number().min(0, t('partnerPage.percentMin')).max(100, t('partnerPage.percentMax')).nullable(),
    creditLimit: Yup.number().min(0, t('partnerPage.currencyMin')).max(1000000000, t('partnerPage.currencyMax')).nullable(),
    note: Yup.string().min(1, t('partnerPage.noteLengthMin')).max(1025, t('partnerPage.noteLengthMax')).nullable(),
    llc: Yup.number().min(0, t('partnerPage.currencyMin')).max(1000000000, t('partnerPage.currencyMax')).nullable(),
  });

  const fundAllocationOptions = getOptions(FUND_ALLOCATION);
  const businessSectorOptions = getOptions1(BUSINESS_SECTOR);

  const initialValues: AddPartnerFormTypes = useMemo(() => {
    return isEdit && selectedPartner
      ? {
        name: selectedPartner?.name ? selectedPartner.name : '',
        nickName: selectedPartner?.nickName ? selectedPartner.nickName : '',
        businessSector: selectedPartner?.businessSector
          ? businessSectorOptions.filter((item: OptionType) => item.value === selectedPartner.businessSector)
          : [],
        boardApprovedCreditLimit: selectedPartner?.boardApprovedCreditLimit
          ? getNumberValueFromApi(selectedPartner?.boardApprovedCreditLimit)
          : undefined,
        llr: selectedPartner?.llr ? getNumberValueFromApi(selectedPartner?.llr) : undefined,
        creditLimit: selectedPartner?.creditLimit ? getNumberValueFromApi(selectedPartner?.creditLimit) : undefined,
        creditLimitExpirationDate: selectedPartner?.creditLimitExpirationDate
          ? moment(selectedPartner?.creditLimitExpirationDate).toDate()
          : undefined,
        note: selectedPartner?.notes ? selectedPartner?.notes?.filter(note => note.content !== '')[0]?.content : '',
        llc: selectedPartner?.llc ? getNumberValueFromApi(selectedPartner?.llc) : undefined,
        fundAllocation: selectedPartner?.fundAllocation
          ? fundAllocationOptions.filter((item: OptionType) => item.value === selectedPartner.fundAllocation)
          : [],
      }
      : {
        name: '',
        nickName: '',
        note: '',
        businessSector: [],
        boardApprovedCreditLimit: undefined,
        llr: undefined,
        creditLimit: undefined,
        creditLimitExpirationDate: undefined,
        llc: undefined,
        fundAllocation: [],
        isResetForm,
      };
  }, [isEdit, selectedPartner, isResetForm, fundAllocationOptions, businessSectorOptions]);

  const handleAddAndUpdatePartner = async (partnerPayload: AddEditPartnerType) => {
    if (isEdit) {
      await updatePartnerById<AddEditPartnerType>(selectedPartner?.id as string, partnerPayload);
    } else {
      await createPartner(partnerPayload);
    }
  };

  const mutation = useMutation('create-update-partner', {
    mutationFn: handleAddAndUpdatePartner,
  });

  const handleSubmit = (data: AddPartnerFormTypes, action: { [key: string]: any }) => {
    const successCallback = (message: string) => {
      if (isComeback) navigate(ROUTE_PATHS.PARTNERS_PAGE, { replace: true });
      setOpenModal(!openModal);
      setIsEdit(false);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      refetchApi();
      if (isEdit && fetchLoansByPartnerId) fetchLoansByPartnerId();
    };

    const tempData = {
      name: data.name,
      nickName: data.nickName,
      notes: data.note && !selectedPartner?.notes?.filter(note => note.content === data.note).length ? [{ content: data.note }] : [],
      businessSector: data.businessSector?.length ? data?.businessSector[0]?.value : null,
      boardApprovedCreditLimit: data.boardApprovedCreditLimit ? getNumberValueSendToApi(data?.boardApprovedCreditLimit) : null,
      llr: data.llr ? getNumberValueSendToApi(data.llr) : null,
      creditLimit: data.creditLimit ? getNumberValueSendToApi(data.creditLimit) : null,
      creditLimitExpirationDate: data.creditLimitExpirationDate ? moment(data.creditLimitExpirationDate).format(FORMAT_DATE_API) : null,
      fundAllocation: data.fundAllocation?.length ? data?.fundAllocation[0]?.value : null,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: () => {
            const message: string = isEdit ? t('partnerPage.editSuccessMessage') : t('partnerPage.createSuccessMessage');
            successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<AddPartnerFormTypes>) => {
    setOpenModal(!openModal);
    setIsEdit(false);
    props.resetForm();
    props.setErrors({});
    if (isComeback) navigate(ROUTE_PATHS.PARTNERS_PAGE, { replace: true });
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-partner-modal"
      show={openModal}
      size="4xl"
      onClose={() => {
        setOpenModal(!openModal);
        setIsEdit(false);
        if (isComeback) navigate(ROUTE_PATHS.PARTNERS_PAGE, { replace: true });
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{!isEdit ? t('addPartner') : t('editPartner')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <TextForm
                      id="nickName"
                      name="nickName"
                      label={t('nickName')}
                      placeholder={t('nickName')}
                      propsFormik={props}
                      isEdit={isEdit}
                      setCurrentValue={async (
                        text: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setTextValue<AddPartnerFormTypes>({ text, props });
                      }}
                    />
                    <TextForm
                      id="name"
                      name="name"
                      label={t('name')}
                      placeholder={t('name')}
                      dataTestId="test-partner-name-error"
                      propsFormik={props}
                      isRequired={true}
                      isEdit={isEdit}
                      setCurrentValue={async (
                        text: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setTextValue<AddPartnerFormTypes>({ text, props });
                      }}
                    />
                  </div>
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <CustomSelectForm
                      label={t('businessSector')}
                      id="businessSector"
                      name="businessSector"
                      placeHolder={t('businessSector')}
                      options={businessSectorOptions}
                      selectItemsHandler={(items: any, props: FormikProps<AddPartnerFormTypes>) =>
                        selectItemHandler<AddPartnerFormTypes>({ items, props, fieldName: 'businessSector' })
                      }
                      propsFormik={props}
                      isEdit={isEdit}
                      isMulti={false}
                      disableSearch={true}
                    />
                    <CurrencyForm
                      id="creditLimit"
                      name="creditLimit"
                      label={t('creditLimit')}
                      placeholder={t('creditLimit')}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      isEdit={isEdit}
                      decimalLimit={2}
                    />
                    <CurrencyForm
                      id="llr"
                      name="llr"
                      label={t('loanLevelReserve')}
                      placeholder={t('loanLevelReserve')}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      isEdit={isEdit}
                      decimalLimit={2}
                      unit="%"
                    />
                  </div>
                  <div className="form-flex-col flex w-full justify-between items-start space-x-3">
                    <CurrencyForm
                      id="boardApprovedCreditLimit"
                      name="boardApprovedCreditLimit"
                      label={t('boardApprovedCreditLimit')}
                      placeholder={t('boardApprovedCreditLimit')}
                      setCurrentValue={async (
                        currency: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<AddPartnerFormTypes>,
                      ) => {
                        await setCurrencyValue<AddPartnerFormTypes>({ currency, props });
                      }}
                      propsFormik={props}
                      isEdit={isEdit}
                      decimalLimit={2}
                    />
                    <CustomDatePickerForm
                      id="creditLimitExpirationDate"
                      name="creditLimitExpirationDate"
                      label={t('creditLimitExpiration')}
                      placeHolder={t('creditLimitExpiration')}
                      propsFormik={props}
                      selectItemsHandler={(date: Date) =>
                        selectDateHandler<AddPartnerFormTypes>({ date, props, fieldName: 'creditLimitExpirationDate' })
                      }
                      isEdit={isEdit}
                      isDeleteSelectedDate={openModal}
                      position="top"
                    />
                    <CustomSelectForm
                      label={t('fundAllocation')}
                      id="fundAllocation"
                      name="fundAllocation"
                      placeHolder={t('fundAllocation')}
                      options={fundAllocationOptions}
                      selectItemsHandler={(items: any, props: FormikProps<AddPartnerFormTypes>) =>
                        selectItemHandler<AddPartnerFormTypes>({ items, props, fieldName: 'fundAllocation' })
                      }
                      propsFormik={props}
                      isEdit={isEdit}
                      isMulti={false}
                      disableSearch={true}
                    />
                  </div>
                  <TextAreaForm id="note" name="note" placeholder={t('note')} propsFormik={props} dataTestId="add-partner-modal-note" />
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddPartnerModal;
