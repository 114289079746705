import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddEditDonationType, createDonation, updateDonationById } from 'api/supporter/donation';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import TextAreaForm from 'components/form/textForm/textAreaForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { DONATION_CATEGORY, FORMAT_DATE_API, ROUTE_PATHS } from 'utils/constants';
import { DonationType, OptionType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, getOptions1, messageErrors, selectDateHandler, selectItemHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import './addDonationModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddDonationModalProps = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit: boolean;
  setIsEdit: Function;
  selectedDonation?: DonationType;
  refetchApi: Function;
  supporterId: string;
};

type AddDonationModalType = {
  amount?: number;
  date?: Date;
  category?: OptionType[];
  note?: string;
};

const AddDonationModal = (props: AddDonationModalProps) => {
  const { openModal = false, setOpenModal, selectedDonation, refetchApi, isEdit = false, setIsEdit, supporterId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isResetForm, setIsResetForm] = useState(false);

  const ValidateSchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, t('donationPage.currencyMin'))
      .max(1000000000, t('donationPage.currencyMax'))
      .nullable()
      .required(t('donationPage.requiredField')),
    date: Yup.date().nullable(),
    category: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required(t('loanPage.rateTypeIsString')),
      }),
    ),
    note: Yup.string().min(1, t('donationPage.noteLengthMin')).max(1025, t('donationPage.noteLengthMax')).nullable(),
  });

  const donationCategoryOptions = getOptions1(DONATION_CATEGORY);

  const initialValues: AddDonationModalType = useMemo(() => {
    return isEdit && selectedDonation
      ? {
        amount: selectedDonation?.amount ? getNumberValueFromApi(selectedDonation?.amount) : undefined,
        date: selectedDonation?.date ? moment(selectedDonation?.date).toDate() : undefined,
        category: selectedDonation?.category ? donationCategoryOptions.filter((item: OptionType) => item.value === selectedDonation.category) : [],
        note: selectedDonation?.notes ? selectedDonation?.notes?.filter(note => note.content !== '')[0]?.content : '',
      }
      : {
        amount: undefined,
        date: undefined,
        category: [],
        note: '',
        isResetForm,
      };
  }, [isEdit, selectedDonation, isResetForm, donationCategoryOptions]);

  const handleAddAndUpdateDonation = async (donationPayload: AddEditDonationType) => {
    if (isEdit) {
      await updateDonationById(selectedDonation?.id as string, donationPayload);
    } else {
      await createDonation(donationPayload);
    }
  };

  const mutation = useMutation('create-update-donation', {
    mutationFn: handleAddAndUpdateDonation,
  });

  const handleSubmit = async (data: AddDonationModalType, action: { [key: string]: any }) => {
    const successCallback = (message: string) => {
      if (isEdit) {
        navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${supporterId}`, { replace: true });
        setIsEdit(!isEdit);
      }
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      refetchApi();
    };

    const tempData = {
      supporterId,
      amount: data.amount ? getNumberValueSendToApi(data.amount) : null,
      date: data.date ? moment(data.date).format(FORMAT_DATE_API) : null,
      category: data.category?.length ? data?.category[0]?.value : null,
      notes: data.note && !selectedDonation?.notes?.filter(note => note.content === data.note).length ? [{ content: data.note }] : [],
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: () => {
            const message: string = isEdit ? t('donationPage.editSuccessMessage') : t('donationPage.createSuccessMessage');
            successCallback(message);
          },
          onError: (error: any) => {
            const message: string = messageErrors(error, t)
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<AddDonationModalType>) => {
    if (isEdit) {
      navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${supporterId}`, { replace: true });
      setIsEdit(!isEdit);
    }
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-donation-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        if (isEdit) {
          navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${supporterId}`, { replace: true });
          setIsEdit(!isEdit);
        }
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{!isEdit ? t('recordDonation') : t('editDonation')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CurrencyForm
                    id="amount"
                    name="amount"
                    label={t('amount')}
                    placeholder={t('amount')}
                    setCurrentValue={async (currency: { name?: string; value?: string }, props: FormikProps<AddDonationModalType>) => {
                      await setCurrencyValue<AddDonationModalType>({ currency, props });
                    }}
                    propsFormik={props}
                    isEdit={isEdit}
                    decimalLimit={2}
                    isRequired={true}
                  />
                  <CustomDatePickerForm
                    id="date"
                    name="date"
                    label={t('date')}
                    placeHolder={t('date')}
                    propsFormik={props}
                    selectItemsHandler={(date: Date) => selectDateHandler<AddDonationModalType>({ date, props, fieldName: 'date' })}
                    isEdit={isEdit}
                    isDeleteSelectedDate={openModal}
                    position="top"
                  />
                  <CustomSelectForm
                    label={t('category')}
                    id="category"
                    name="category"
                    placeHolder={t('category')}
                    options={donationCategoryOptions}
                    selectItemsHandler={(items: any, props: FormikProps<AddDonationModalType>) =>
                      selectItemHandler<AddDonationModalType>({ items, props, fieldName: 'category' })
                    }
                    propsFormik={props}
                    isEdit={isEdit}
                    isMulti={false}
                  />
                  <TextAreaForm id="note" name="note" placeholder={t('note')} propsFormik={props} />
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddDonationModal;
