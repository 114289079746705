// eslint-disable-next-line
export default {
  baseUrl: `${process.env.REACT_APP_BASE_API_URL}/v1`,
  user: {
    users: 'users',
    getCurrentUser: 'users/CurrentUser',
    filter: 'users/filter',
    userControllerChangeEmailAddress: 'users/ChangeEmailAddress',
    setRoles: 'users/SetRoles',
    userOrganization: 'users/Organization',
    changePassword: 'users/change-password',
    getUserByVerifiedCode: 'users/VerifiedCode',
    exportUsersToCSV: 'users/exportToCSV',
  },

  role: {
    Roles: 'Roles',
  },

  userRole: {
    userRoles: 'UserRoles',
  },

  organization: {
    organizations: 'organizations',
    filter: 'Organizations/Filter',
  },

  userOrganization: {
    userOrganizations: 'UserOrganizations',
  },

  health: 'Health',

  position: 'Positions',

  notes: {
    notes: 'notes',
    entityId: 'notes/entityId',
  },

  partner: {
    partners: 'partners',
    filter: 'partners/Filter',
    exportPartnersToCSV: 'partners/exportToCSV',
  },

  loan: {
    loans: 'loans',
    exportLoansToCSV: 'loans/exportToCSV',
  },

  payment: {
    payments: 'payments',
    recordPayment: 'payments/record-partial',
    paymentsByLoanId: 'payments/loan',
    paymentsScheduleByLoanId: 'payments/schedule/loan',
    status: 'payments/status',
    notes: 'payments/notes',
    exportPaymentsToCSV: 'payments/exportToCSV',
  },

  schedulePayment: {
    schedulePayments: 'schedulePayments',
    filter: 'schedulePayments/filter',
    recalculateSchedulePayments: 'schedulePayments/loan',
    exportPaymentsToCSV: 'schedulePayments/exportToCSV',
  },

  report: {
    reports: 'reports',
    portfolio: 'reports/portfolio',
    summary: 'reports/monthly-summary',
    reference: 'reports/reference',
    exportSummaryReportsToCSV: 'reports/monthly-summary/exportToCSV',
    exportPortfolioReportsToCSV: 'reports/portfolio/exportToCSV',
  },

  supporter: {
    supporters: 'supporters',
    downloadSupporterContact: 'supporters/downloadSupporterContact',
    exportSupportersToCSV: 'supporters/exportToCSV',
  },

  country: {
    country: 'country',
  },

  investments: {
    investments: 'investments',
    investmentSupporter: 'investments/supporter',
    downloadInvestmentInfo: 'investments/downloadInvestmentInfo',
  },

  donation: {
    donations: 'donations',
    donationSupporter: 'donations/supporter',
    downloadDonationInfo: 'downloadDonationInfo',
    donationDownloadAllDonationInfo: 'donations/downloadAllDonationInfo',
  },

  investmentPayment: {
    investmentPayments: 'investmentPayments',
    paymentsByInvestmentId: 'investmentPayments/investments',
    export: 'investmentPayments/export',
    downloadPdf: 'create-pdf',
  },

  contact: { contacts: 'contacts', export: 'contacts/export' },
  additionalSettings: {
    additionalSettings: '/additionalSettings'
  }
};
