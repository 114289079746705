import { useRef } from 'react';
import CustomIcon from 'components/common/CustomIcon';
import CurrencyInput from 'components/input/currencyInput/CurrencyInput';
import { TYPE_INPUT } from 'utils/constants';
import { convertNumberToCurrency } from 'utils/utils';
export type CardIndexType = {
  inputType?: string;
  changeValueHandler?: Function;
  fieldNameInput?: string;
  bgColor: string;
  bgIconColor: string;
  textIconColor: string;
  label: string;
  value: string | number | null;
  subValue?: string | number | null;
  icon: any;
};

const CardIndex = (props: CardIndexType) => {
  const { inputType, changeValueHandler, fieldNameInput, bgColor, bgIconColor, textIconColor, label, value, subValue, icon } = props;
  const inputRef = useRef();

  return (
    <div className={`card-index__container h-full w-full flex justify-start items-center py-3 px-3 space-x-2 rounded ${bgColor}`}>
      <div className="card-index__container--icon cursor-pointer">
        <CustomIcon Icon={icon} bgColor={bgIconColor} textColor={textIconColor} />
      </div>
      <div className="card-index__container--content flex flex-col justify-center space-y-1 items-start">
        <p className="content-label text-xs font-normal text-gray-900">{label}</p>
        {inputType === TYPE_INPUT.CURRENCY ? (
          <CurrencyInput
            defaultValue={value as number}
            changeValueHandler={(value: number) => {
              if (changeValueHandler) changeValueHandler({ fieldNameInput, value });
            }}
            prefix="$"
            decimalLimit={2}
            inputRef={inputRef}
            width="w-32"
            height="h-6"
          />
        ) : (
          <p
            className="content-value text-sm font-normal text-gray-900">{typeof value === 'number' ? convertNumberToCurrency(value) : value}</p>
        )}
        <p className="content-label text-xs font-normal text-gray-500">{subValue}</p>
      </div>
    </div>
  );
};

export default CardIndex;
