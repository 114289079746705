import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { Auth0Provider } from '@auth0/auth0-react';
import i18next from '@core/locales';
import ability from 'configs/casl/ability';
import { AbilityContext } from 'contexts/can';

import Routes from './router/';
import { DELAY_SHOW_TOAST } from './utils/constants';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  if (typeof window !== 'undefined') {
    injectStyle();
  }

  const queryClient = new QueryClient();
  return (
    <div className="App">
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
      >
        <QueryClientProvider client={queryClient}>
          <AbilityContext.Provider value={ability}>
            <I18nextProvider i18n={i18next}>
              <Routes />
              <ToastContainer
                position="top-right"
                autoClose={DELAY_SHOW_TOAST}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </I18nextProvider>
          </AbilityContext.Provider>
        </QueryClientProvider>
      </Auth0Provider>
    </div>
  );
}

export default App;
