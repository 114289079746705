import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { ContactType, NoteType, QueryFilterType, SupporterFilterValueType } from 'utils/proptypes';

import base from '../baseApi';

const { urls, methods, execute } = base();

export type addEditSupporterTypes = {
  prefixTitles?: string;
  firstName: string;
  lastName: string;
  trustName?: string;
  SSN?: string;
  dateOfBirth?: Date;
  email?: string;
  homePhone?: string;
  businessPhone?: string;
  organizationName?: string;
  type?: string;
  preferredCommunicationMethod?: string;
  preferredPaymentMethod?: string;
  contacts?: ContactType[];
  notes?: NoteType[];
  beneficiaries?: string;
  wCCNIsBeneficiary?: boolean;
};

const createSupporter = (payload: addEditSupporterTypes) => {
  const method = methods.POST;
  const url = urls.supporter.supporters;
  return execute(method, url, { authorization: true }, payload);
};

const getSupportersByFilter = async (queryFilter: QueryFilterType) => {
  const {
    page = DEFAULT_VALUE_FILTER.PAGE,
    limit = DEFAULT_VALUE_FILTER.LIMIT,
    searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY,
    fType,
    fPreferredCommunicationMethod,
  } = queryFilter;
  const method = methods.GET;
  const url = `${urls.supporter.supporters}?page=${page}&limit=${limit}&searchQuery=${searchQuery}${fType ? '&fType=' + fType : ''}${
    fPreferredCommunicationMethod ? '&fPreferredCommunicationMethod=' + fPreferredCommunicationMethod : ''
  }`;
  return execute(method, url, { authorization: true });
};

const getSupporterById = (id: string, fDate: string) => {
  const method = methods.GET;
  const url = `${urls.supporter.supporters}/${id}?fDate=${fDate}`;
  return execute(method, url, { authorization: true });
};

const updateSupporterById = <T = addEditSupporterTypes>(id: string, payload: T) => {
  const method = methods.PUT;
  const url = `${urls.supporter.supporters}/${id}`;
  return execute(method, url, { authorization: true }, payload as object);
};

const removeSupporter = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.supporter.supporters}/${id}`;

  return execute(method, url, { authorization: true });
};

const downloadSupporterContact = (filter: SupporterFilterValueType) => {
  const method = methods.POST;
  const url = `${urls.supporter.downloadSupporterContact}?fPreferredCommunicationMethod=${filter.fPreferredCommunicationMethod ?? ''}`;
  return execute(method, url, { authorization: true });
};

const exportSupportersToCSV = async (payload: SupporterFilterValueType & { searchQuery: string }) => {
  const method = methods.POST;
  const url = urls.supporter.exportSupportersToCSV;
  return execute(method, url, { authorization: true }, payload);
};

export {
  createSupporter,
  getSupportersByFilter,
  getSupporterById,
  updateSupporterById,
  removeSupporter,
  downloadSupporterContact,
  exportSupportersToCSV,
};
