import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { getPaymentsByFilter } from 'api/accountant/paymentRecordApi';
import { exportPaymentsToCSV, updatePaymentById, UpdatePaymentRecordType } from 'api/loanFundManager/paymentApi';
import AddNotesPaymentModal from 'components/modal/payment/addNotesModal';
import RecordPaymentModal from 'components/modal/payment/recordPaymentModal';
import HeaderTable from 'components/payments/headerTable';
import PaginateTable from 'components/table/paginate';
import useLoading from 'hooks/useLoading';
import omit from 'lodash/omit';
import moment from 'moment';
import { DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME, FORMAT_DATE_API, PAYMENT_STATUS_OPTIONS, ROUTE_PATHS } from 'utils/constants';
import { FilterPaymentsValueType, OptionType, PartnerType, PaymentType } from 'utils/proptypes';
import { exportRowDataToCSV, getNumberValueSendToApi, messageErrors } from 'utils/utils';
import { read, utils } from 'xlsx';

import { getPartnersByFilter } from '../../../../api/loanFundManager/partnerApi';

import RenderPaymentsTable from './components/RenderPaymentsTable';

type AddPaymentFormType = {
  selectedPayment: PaymentType;
  paymentReceivedAmount: number | string;
  paymentReceived: string;
  status: OptionType[];
};

const LoanPayments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
  const [filterValue, setFilterValue] = useState<FilterPaymentsValueType>({
    f_startDate: undefined,
    f_endDate: undefined,
    startReceivedDate: undefined,
    endReceivedDate: undefined,
    f_status: undefined,
  });
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntities, setTotalEntities] = useState(0);
  const limit = DEFAULT_VALUE_FILTER.LIMIT;
  const [payments, setPayments] = useState<PaymentType[]>([]);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openRecordModal, setOpenRecordModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<PaymentType>();
  const [__html, setHtml] = useState('');
  const tbl = useRef(null);
  const ref = useRef(null);
  const [currentPartnerPage, setCurrentPartnerPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [searchPartnerQuery, setSearchPartnerQuery] = useState(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
  const [partnerOptions, setPartnerOptions] = useState<OptionType[]>([]);

  const { refetch: fetchPayments, isFetching } = useQuery(
    ['payments', currentPage, searchValue, filterValue],
    () => getPaymentsByFilter({ page: currentPage, limit, searchQuery: searchValue, ...filterValue }),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.totalEntities);
        setPayments(data.entities);
        const f = new ArrayBuffer(data.entities);
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const dataPayment = utils.sheet_to_html(ws);
        setHtml(dataPayment);
      },
      onError: () => {
        setPayments([]);
        setTotalEntities(0);
      },
    },
  );

  const { isFetching: isFetchingPartner } = useQuery(
    ['partners', currentPartnerPage, searchPartnerQuery],
    () =>
      getPartnersByFilter({
        page: currentPartnerPage,
        limit: 100,
        searchQuery: searchPartnerQuery,
      }),
    {
      onSuccess: ({ data }) => {
        const partnerOptions: OptionType[] = data.entities?.map((partner: PartnerType) => ({
          label: partner.name,
          value: partner.id,
        }));
        setPartnerOptions(partnerOptions);
      },
      onError: () => {
        setPartnerOptions([]);
      },
    },
  );

  useLoading({ isLoading: isFetching, ref });

  const updatePayment = async (paymentPayload: UpdatePaymentRecordType & { id: string }) => {
    await updatePaymentById(paymentPayload?.id as string, omit(paymentPayload, 'id') as any);
  };

  const mutationUpdate = useMutation('update-payment', {
    mutationFn: updatePayment,
  });

  const handleUpdatePayment = (data: AddPaymentFormType) => {
    const successCallback = async (message: string) => {
      toast.success(message);
      await fetchPayments();
    };
    const tempData = {
      id: data.selectedPayment.id,
      paymentReceivedAmount: data.paymentReceivedAmount
        ? getNumberValueSendToApi(data.paymentReceivedAmount as number)
        : data.paymentReceivedAmount === ''
        ? null
        : undefined,
      paymentReceived: data.paymentReceived ? moment(data.paymentReceived).format(FORMAT_DATE_API) : data.paymentReceived === null ? null : undefined,
      status: data.status
        ? data.status
        : data.paymentReceived === null ||
          data.paymentReceivedAmount === '' ||
          !PAYMENT_STATUS_OPTIONS.includes({ label: data.selectedPayment.status, value: data.selectedPayment.status })
        ? undefined
        : data.selectedPayment.status,
    };
    mutationUpdate.mutate(
      {
        ...(tempData as any),
      },
      {
        onSuccess: async () => {
          const message: string = t('paymentPage.editSuccessMessage');
          await successCallback(message);
        },
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const recordPaymentHandler = () => {
    setOpenRecordModal(!openRecordModal);
  };

  const updateNotesPaymentHandler = (item: PaymentType) => {
    navigate(`${ROUTE_PATHS.PAYMENT_PAGE}?id=${item?.id}`, { replace: true });
    setOpenNotesModal(!openNotesModal);
    setSelectedPayment(item);
  };

  const handleExportPayments = async () => {
    await exportPaymentsToCSV({ ...filterValue, searchQuery: searchValue }).then(async res => {
      exportRowDataToCSV({ stream: res.data, fileName: EXPORT_FILE_NAME.PAYMENT });
    });
  };

  const { refetch: refetchExportPayments, isFetching: isFetchingDownloadPayments } = useQuery('download-payments', handleExportPayments, {
    onSuccess: () => {
      const message: string = t('paymentPage.exportToCsvSuccess');
      toast.success(message);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
    enabled: false,
  });

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="w-full loan-payments">
        <HeaderTable
          setCurrentPage={setCurrentPage}
          placeholderSearch={t('paymentPage.searchPayments')}
          nameButton={t('paymentPage.recordAPayment')}
          setSearchValue={setSearchValue}
          setFilterValue={setFilterValue}
          exportHandler={refetchExportPayments}
          hideSearch={false}
          hideExportButton={true}
          hideFilter={false}
          className="loan-payments"
          abilityObjectTitle="PAYMENT"
          addHandler={recordPaymentHandler}
        />
        <div className="loan-payments__table h-full bg-white">
          <RenderPaymentsTable
            dangerouslySetInnerHTML={{ __html }}
            tbl={tbl}
            payments={payments}
            editPaymentHandler={handleUpdatePayment}
            updateNotesPaymentHandler={updateNotesPaymentHandler}
            isFetching={isFetching}
          />
        </div>
        {totalEntities > 0 && (
          <PaginateTable
            className="loan-payments__pagination"
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isFetching={isFetching}
            isFetchingDownload={isFetchingDownloadPayments}
            exportHandler={refetchExportPayments}
            items={payments}
          />
        )}
      </div>
      <AddNotesPaymentModal
        selectedPayment={selectedPayment}
        openModal={openNotesModal}
        setOpenModal={setOpenNotesModal}
        refetchApi={fetchPayments}
        reLinkUrl={ROUTE_PATHS.PAYMENT_PAGE}
      />
      <RecordPaymentModal
        openModal={openRecordModal}
        setOpenModal={setOpenRecordModal}
        refetchApi={fetchPayments}
        isFetchingPartner={isFetchingPartner}
        currentPartnerPage={currentPartnerPage}
        setCurrentPartnerPage={setCurrentPartnerPage}
        searchPartnerQuery={searchPartnerQuery}
        setSearchPartnerQuery={setSearchPartnerQuery}
        partnerOptions={partnerOptions}
        setPartnerOptions={setPartnerOptions}
      />
    </>
  );
};
export default LoanPayments;
