import { MutableRefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import moment from 'moment';
import { ABILITY_ACTION, FORMAT_DATE_API, FORMAT_DATE_API_REGEX, FORMAT_DATE_SHOW_TABLE, PERMISSION } from 'utils/constants';
import { PaymentType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import CustomSpinner from '../../../../../components/common/customSpinner';
import { AbilityContext } from '../../../../../contexts/can';

type RenderPaymentsRecordTableDataType = {
  tbl: MutableRefObject<null>;
  payments: PaymentType[];
  isFetching: boolean;
  setSelectedSchedulePayment: Function;
  openAddLoanPaymentModal: boolean;
  setOpenAddLoanPaymentModal: Function;
};

const RenderPaymentsScheduleTable = (props: RenderPaymentsRecordTableDataType) => {
  const { tbl, payments, isFetching, setSelectedSchedulePayment, openAddLoanPaymentModal = false, setOpenAddLoanPaymentModal } = props;
  const ability = useContext(AbilityContext);
  const [t] = useTranslation()
  const addPaymentHandler = (item: any) => {
    setSelectedSchedulePayment(item);
    setOpenAddLoanPaymentModal(!openAddLoanPaymentModal);
  };

  return (
    <table className={classNames('w-full text-sm text-left text-gray-500', { 'h-full': !payments.length })} ref={tbl}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100">
        <tr>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-loan-name-title" content={t('loan')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-partner-name-title" content={t('partner')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-principal-due-title" content={t('principalDue')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-interest-due-title" content={t('interestDue')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-payment-date-title" content={t('accrualDate')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-3 pr-6 w-10">
            <p className="sr-only">{t('addPayment')} </p>
          </th>
        </tr>
      </thead>
      <tbody>
        {payments?.length ? (
          payments?.map((item: PaymentType, key: number) => {
            return (
              <tr
                key={`${key}-list-payment`}
                className={`payment-${key === payments?.length - 1 ? 'last' : key} relative bg-white border-b hover:bg-gray-50`}
              >
                <th scope="row" className="py-1 px-4 font-normal text-gray-900 ">
                  <TooltipComponent
                    anchorSelect={`payment-name-${key}`}
                    content={item?.loan?.name ? item?.loan?.name : ''}
                    className="loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`partner-name-${key}`}
                    content={item?.loan?.partner?.name ? item?.loan?.partner?.name : ''}
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`payment-principal-amount-${key}`}
                    content={item?.principalAmount ? convertNumberToCurrency(getNumberValueFromApi(item?.principalAmount)) : ''}
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`payment-interest-amount-${key}`}
                    content={item?.interestAmount ? convertNumberToCurrency(getNumberValueFromApi(item?.interestAmount)) : ''}
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`payment-due-date-amount-${key}`}
                    content={
                      item?.paymentDate && FORMAT_DATE_API_REGEX.test(moment(item?.paymentDate).format(FORMAT_DATE_API))
                        ? moment.utc(item?.paymentDate).format(FORMAT_DATE_SHOW_TABLE)
                        : ''
                    }
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4">
                  {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE) && (
                    <button
                      onClick={() => addPaymentHandler(item)}
                      className="add-loan-payment-button cursor-pointer px-1 py-1 text-white bg-blue-700 rounded-lg"
                    >
                      <HiPlus className="h-4 w-4" />
                    </button>
                  )}
                </th>
              </tr>
            );
          })
        ) : (
          <tr className="h-full payment-no-result text-center">
            <th colSpan={10}>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RenderPaymentsScheduleTable;
