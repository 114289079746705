import { Tooltip as ReactTooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

type TooltipType = { content: any; className?: string; anchorSelect: string; isShowTooltip?: boolean };

const TooltipComponent = (props: TooltipType) => {
  const { content, className, anchorSelect, isShowTooltip = true } = props;
  return (
    <>
      <span data-tooltip-id={anchorSelect} className={className} data-testid="flowbite-tooltip-target">
        {content}
      </span>
      {isShowTooltip && (
        <ReactTooltip
          content={content as string}
          place="top"
          variant="dark"
          id={anchorSelect}
          className={`z-50 ${className}`}
          classNameArrow="tooltip-arrow-icon"
        />
      )}
    </>
  );
};

export default TooltipComponent;
