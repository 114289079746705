import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { QueryFilterType } from 'utils/proptypes';

import base from '../baseApi';

const { urls, methods, execute } = base();

export type UpdateReferencePayloadType = {
  capitalForCommunitiesCommunityNeedsLLC: number;
  totalLoanPortfolio: number;
  totalAssets: number;
};

const getPortfolioReports = async (queryFilter: Partial<QueryFilterType>) => {
  const {
    page = DEFAULT_VALUE_FILTER.PAGE,
    limit = DEFAULT_VALUE_FILTER.LIMIT,
    searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY,
    reportDate,
    isNonPerforming,
  } = queryFilter;
  const method = methods.GET;
  const url = reportDate
    ? `${urls.report.portfolio}?page=${page}&limit=${limit}&searchQuery=${searchQuery}&isNonPerforming=${
        isNonPerforming ? isNonPerforming : 0
      }&reportDate=${reportDate}`
    : `${urls.report.portfolio}?page=${page}&limit=${limit}&searchQuery=${searchQuery}&isNonPerforming=${isNonPerforming ? isNonPerforming : 0}`;
  return execute(method, url, { authorization: true });
};

const getSummaryReports = async (queryFilter: Partial<QueryFilterType>) => {
  const {
    page = DEFAULT_VALUE_FILTER.PAGE,
    limit = DEFAULT_VALUE_FILTER.LIMIT,
    searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY,
    reportDate,
    isNonPerforming,
  } = queryFilter;
  const method = methods.GET;
  const url = reportDate
    ? `${urls.report.summary}?page=${page}&limit=${limit}&searchQuery=${searchQuery}&reportDate=${reportDate}&isNonPerforming=${isNonPerforming}`
    : `${urls.report.summary}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  return execute(method, url, { authorization: true });
};

const updateReference = async (payload: Partial<UpdateReferencePayloadType>) => {
  const method = methods.PATCH;
  const url = urls.report.reference;

  return execute(method, url, { authorization: true }, payload);
};

const exportSummaryReportsToCSV = async (payload: Partial<QueryFilterType>) => {
  const method = methods.POST;
  const url = urls.report.exportSummaryReportsToCSV;
  return execute(method, url, { authorization: true }, payload);
};

export { getPortfolioReports, getSummaryReports, updateReference, exportSummaryReportsToCSV };
