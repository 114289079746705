import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiBookmark } from 'react-icons/ci';
import { HiArrowNarrowLeft, HiOutlineCalendar, HiPencilAlt } from 'react-icons/hi';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { getSupporterById } from 'api/supporter/supporter';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import CardIndexGroup from 'components/common/CardIndexGroup';
import AddSupporterModal from 'components/modal/supporter/addSupporterModal';
import { AbilityContext } from 'contexts/can';
import useLoading from 'hooks/useLoading';
import moment from 'moment';
import DonationPage from 'pages/supporterManager/donations/donation';
import InvestmentPage from 'pages/supporterManager/investments/investment';
import { userSelector } from 'redux/selectors';
import { ABILITY_ACTION, FORMAT_DATE_API, PERMISSION, ROUTE_PATHS, USER_ROLES } from 'utils/constants';
import { SupporterType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import AddNotesSupporterModal from '../../../../components/modal/supporter/addNotesSupporterModal';

import SupporterInfo from './components/SupporterInfo';
import SupporterNote from './components/SupporterNote';

import './renderSupporterDetail.scss';

const RenderSupporterDetail = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [t] = useTranslation()
  const params = useParams();
  const ability = useContext(AbilityContext);
  const supporterId = params.id;
  const [openEditSupporterModal, setOpenEditSupporterModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSupporter, setSelectedSupporter] = useState<SupporterType>();
  const [openAddSupporterNotesModal, setOpenAddSupporterNotesModal] = useState(false);
  const [fDate, setFDate] = useState(moment().format(FORMAT_DATE_API));
  const { userInfo } = useSelector(userSelector);
  const { userRole } = userInfo;
  const isAdmin = userRole.includes(USER_ROLES.SUPER_ADMIN)

  const { refetch: fetchSupporterById, isFetching } = useQuery(['supporter', fDate], () => getSupporterById(supporterId as string, fDate), {
    onSuccess: ({ data }) => {
      setSelectedSupporter(data);
    },
    onError: () => {
      setSelectedSupporter(undefined);
    },
  });

  useLoading({ isLoading: isFetching, ref });

  const editSupporterHandler = () => {
    setOpenEditSupporterModal(!openEditSupporterModal);
    setIsEdit(!isEdit);
  };

  const addSupporterNotes = () => {
    setOpenAddSupporterNotesModal(!openAddSupporterNotesModal);
  };

  const cardIndexData = [
    {
      bgColor: 'bg-purple-100',
      bgIconColor: 'bg-purple-500',
      textIconColor: 'text-white',
      label: t('totalInvestments'),
      value: selectedSupporter?.supporterTotalAmount?.totalInvestment
        ? convertNumberToCurrency(getNumberValueFromApi(selectedSupporter?.supporterTotalAmount?.totalInvestment))
        : '',
      icon: CiBookmark,
    },
    {
      bgColor: 'bg-red-100',
      bgIconColor: 'bg-red-500',
      textIconColor: 'text-white',
      label: t('totalDonations'),
      value: selectedSupporter?.supporterTotalAmount?.totalDonation
        ? convertNumberToCurrency(getNumberValueFromApi(selectedSupporter?.supporterTotalAmount?.totalDonation))
        : '',
      icon: HiOutlineCalendar,
    },
  ];

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="supporter-detail-page flex flex-col justify-start items-center mx-4 my-4 space-y-4">
        <div className="supporter-detail-page__header w-full flex justify-between items-center">
          <HiArrowNarrowLeft
            className="header__icon leading-none text-gray-900 text-base font-medium cursor-pointer border border-gray-900 rounded-full p-1"
            size={36}
            onClick={() => {
              navigate(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE);
            }}
          />
          <h3 className="header__title leading-none text-gray-900 text-base font-medium capitalize">{t('supporterDetails')}</h3>
          {ability.can(ABILITY_ACTION.manage, PERMISSION.SUPPORTER.UPDATE) ? (
            <div className="flex">
              <ButtonIconWithText
                className="header__button bg-blue-900 capitalize"
                text={t('supporterPage.editSupporter')}
                onClick={() => editSupporterHandler()}
                Icon={HiPencilAlt}
              />
            </div>
          ) : (
            <div className="header__button" />
          )}
        </div>
        <div className="supporter-detail-page__container h-full w-full flex justify-center items-center">
          <div className="container-left rounded-sm h-full flex flex-col justify-center">
            <SupporterInfo selectedSupporter={selectedSupporter as SupporterType} refetchSupporterApi={fetchSupporterById} />
            <SupporterNote selectedSupporter={selectedSupporter as SupporterType} addSupporterNotes={addSupporterNotes} ability={ability} />
          </div>
          <div className="container-right flex-1 h-full flex flex-col justify-center items-center gap-3">
            <CardIndexGroup cardIndexData={cardIndexData} className="w-full grid grid-cols-2 gap-4" />
            <div className="container-right__two-table w-full">
              <InvestmentPage
                ability={ability}
                fetchSupporterById={fetchSupporterById}
                setFDate={setFDate}
                isAdmin={isAdmin}
              />
              <DonationPage ability={ability} fetchSupporterById={fetchSupporterById} isAdmin={isAdmin} />
            </div>
          </div>
        </div>
        <AddSupporterModal
          openModal={openEditSupporterModal}
          setOpenModal={setOpenEditSupporterModal}
          refetchApi={fetchSupporterById}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          selectedSupporter={selectedSupporter}
          isComeback={false}
        />
        <AddNotesSupporterModal
          openModal={openAddSupporterNotesModal}
          notes={selectedSupporter?.notes ?? []}
          setOpenModal={setOpenAddSupporterNotesModal}
          selectedSupporter={selectedSupporter}
        />
      </div>
    </>
  );
};

export default RenderSupporterDetail;
