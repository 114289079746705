import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineTrash, HiPencilAlt, HiPlus } from 'react-icons/hi';
import { TiTickOutline } from 'react-icons/ti';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AddEditNoteType, addNote, removeNote, updateNoteById } from 'api/noteApi';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import NoNotes from 'components/common/noNote';
import TooltipComponent from 'components/common/Tooltip';
import TextAreaForm from 'components/form/textForm/textAreaForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import { FORMAT_DATE_SHOW_TABLE, NOTE_ENTITY_TYPES } from 'utils/constants';
import { NoteType, SupporterType } from 'utils/proptypes';
import { messageErrors } from 'utils/utils';
import * as Yup from 'yup';

import './addNotesSupporterModal.scss';

type AddNotesSupporterModalTypes = {
  notes: NoteType[];
  openModal: boolean;
  setOpenModal: Function;
  selectedSupporter?: SupporterType;
};

type AddNotesSupporterFormTypes = {
  note: string;
};

type AddNoteFormTypes = {
  oldNote: string;
};

const AddNotesSupporterModal = (props: AddNotesSupporterModalTypes) => {
  const queryClient = useQueryClient();
  const { openModal = false, setOpenModal, notes, selectedSupporter } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState<{ [key: string]: any }>({});

  const ValidateNoteSchema = Yup.object().shape({
    note: Yup.string()
      .min(1, t('supporterPage.noteLengthMin'))
      .max(1025, t('supporterPage.noteLengthMax'))
      .nullable()
      .required(t('supporterPage.requiredField')),
  });

  const ValidateOldNoteSchema = Yup.object().shape({
    oldNote: Yup.string()
      .min(1, t('supporterPage.noteLengthMin'))
      .max(1025, t('supporterPage.noteLengthMax'))
      .nullable()
      .required(t('supporterPage.requiredField')),
  });

  const initialNoteValues: any = useMemo(() => {
    return { note: '', isResetForm };
  }, [isResetForm]);

  const initialOldNoteValues: any = useMemo(() => {
    return { oldNote: selectedNote?.content ? selectedNote?.content : '', isResetForm };
  }, [selectedNote, isResetForm]);

  const handleAddNote = async (payload: AddEditNoteType) => {
    await addNote(payload);
  };

  const addNoteMutation = useMutation('addSupporterNote', {
    mutationFn: handleAddNote,
  });

  const handleNoteSubmit = (data: AddNotesSupporterFormTypes, action: { [key: string]: any }) => {
    const successCallback = async (message: string) => {
      toast.success(message);
      action.resetForm();
      await queryClient.invalidateQueries('supporter');
      setIsEditNote(false);
    };
    const tempData: AddEditNoteType = {
      entityId: selectedSupporter?.id as string,
      content: data.note,
      entityType: NOTE_ENTITY_TYPES.SUPPORTER,
    };

    if (openModal) {
      addNoteMutation.mutate(
        {
          ...tempData,
        },
        {
          onSuccess: async () => {
            const message: string = t('supporterPage.addNoteSuccess');
            await successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const updateNoteMutation = useMutation('add-supporter-note', {
    mutationFn: async (payload: AddEditNoteType & { id: string }) => {
      const realPayload = omit(payload, 'id');
      await updateNoteById(payload.id, realPayload);
    },
  });

  const handleOldNoteSubmit = (data: AddNoteFormTypes, action: { [key: string]: any }) => {
    const successCallback = async () => {
      setIsEditNote(!isEditNote);
      action.resetForm();
      await queryClient.invalidateQueries('supporter');
    };
    const tempPayload = {
      content: data?.oldNote,
      entityId: selectedNote?.entityId,
      entityType: selectedNote?.entityType,
      id: selectedNote?.id,
    };
    updateNoteMutation.mutate(
      {
        ...tempPayload,
      },
      {
        onSuccess: () => successCallback(),
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const deleteNoteMutation = useMutation('delete-note', {
    mutationFn: async (id: string) => {
      await removeNote(id);
    },
  });

  const handleUpdateNote = (note: { [key: string]: any }) => {
    setIsEditNote(!isEditNote);
    setSelectedNote(note);
    setIsResetForm(!isResetForm);
  };

  const handleDeleteNote = (note: { [key: string]: any }) => {
    deleteNoteMutation.mutate(note?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('supporter');
        setIsEditNote(false);
      },
      onError: async (error: any) => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const closeModalHandler = async () => {
    setOpenModal(!openModal);
    setIsResetForm(!isResetForm);
    setIsEditNote(false);
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal id="add-notes-supporter-modal" show={openModal} size="xl" onClose={() => closeModalHandler()} dismissible={true}>
      <Modal.Header>{t('addNote')}</Modal.Header>
      <Modal.Body>
        <div className="note-content w-full space-y-4 mb-4">
          {notes?.filter((note: { [key: string]: any }) => note.content !== '')?.length ? (
            notes
              ?.filter((note: { [key: string]: any }) => note.content !== '')
              ?.map((note: { [key: string]: any }, index: number) => (
                <div key={`content_${index}`}>
                  <div className="w-full space-y-4 px-4 py-4 bg-yellow-50 rounded">
                    <div className="flex justify-between">
                      <h4 className="note-content__date leading-none text-yellow-500 text-sm font-normal">
                        {moment.utc(note.created).format(FORMAT_DATE_SHOW_TABLE)}
                      </h4>
                      <div className="flex space-x-2">
                        <HiPencilAlt size={18} className="note-content__icon-edit" onClick={() => handleUpdateNote(note)} />
                        <HiOutlineTrash size={18} className="note-content__icon-delete" onClick={() => handleDeleteNote(note)} />
                      </div>
                    </div>
                    <div className="note-content__text leading-4 text-gray-900 text-sm font-normal">
                      {isEditNote && note?.id === selectedNote?.id ? (
                        <Formik
                          enableReinitialize
                          onSubmit={handleOldNoteSubmit}
                          initialValues={initialOldNoteValues}
                          validationSchema={ValidateOldNoteSchema}
                          validateOnChange={true}
                        >
                          {(props: FormikProps<any>) => {
                            return (
                              <Form className="flex flex-col gap-4">
                                <div className="flex justify-center items-center">
                                  <TextAreaForm
                                    id="oldNote"
                                    name="oldNote"
                                    placeholder={t('note')}
                                    propsFormik={props}
                                    rowTotal={1}
                                    isOtherBgLabel={true}
                                  />
                                  <ButtonIconWithText Icon={TiTickOutline} color="bg-green-old-note" className="h-full ml-4 rounded" type="submit" />
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      ) : (
                        <TooltipComponent
                          anchorSelect={`note-content-${index}`}
                          content={note?.content ? note?.content : ''}
                          className="text-gray-900 note-content__text--truncate dark:text-white"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="note-no-result h-full bg-yellow-50 py-8 mb-4 rounded">
              <NoNotes />
            </div>
          )}
        </div>
        <Formik
          enableReinitialize
          onSubmit={handleNoteSubmit}
          initialValues={initialNoteValues}
          validationSchema={ValidateNoteSchema}
          validateOnChange={true}
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="flex justify-center items-center">
                  <TextAreaForm id="note" name="note" placeholder={t('note')} propsFormik={props} isRequired={true} />
                  <ButtonIconWithText Icon={HiPlus} color="bg-green-note" type="submit" className="h-full ml-4 rounded" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddNotesSupporterModal;
