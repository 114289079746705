import { HiOutlineCash } from 'react-icons/hi';

type customIconType = { bgColor: string; textColor: string; Icon: any };

const CustomIcon = (props: customIconType) => {
  const { bgColor, textColor, Icon = HiOutlineCash } = props;
  return (
    <div
      className={`px-2 py-2 ${bgColor}`}
      style={{
        height: 'auto',
        borderRadius: '8px',
      }}
    >
      <Icon className={`h-6 w-6 ${textColor}`} />
    </div>
  );
};
export default CustomIcon;
