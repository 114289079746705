import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import TextForm from 'components/form/textForm/textForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { AddInvestmentType, createInvestment, updateInvestmentById } from '../../../api/investmentApi';
import { E_PAYMENT_TYPE, FORMAT_DATE_API, INTEREST_OPTIONS } from '../../../utils/constants';
import { InvestmentType, OptionType } from '../../../utils/proptypes';
import {
  getEPaymentTypeOptions,
  getNumberValueFromApi,
  getNumberValueSendToApi,
  messageErrors,
  selectDateHandler,
  selectItemHandler,
  setCurrencyValue,
  setTextValue,
} from '../../../utils/utils';
import GroupButton from '../../button/groupButton';
import CustomSelectForm from '../../form/selectForm/customSelectForm';
import CurrencyForm from '../../form/textForm/currencyForm';
import TextAreaForm from '../../form/textForm/textAreaForm';

import './investmentModal.scss';

type InvestmentModalProps = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit?: boolean;
  refetchApi: Function;
  fetchSupporterById?: Function;
  selectedInvestment?: InvestmentType;
};

type InvestmentFormTypes = {
  name: string;
  amount: number;
  adminFee: number;
  interestOption: OptionType[];
  interestRate: number;
  term: number;
  payInterest: OptionType[];
  payPrincipal: OptionType[];
  note?: string;
  startDate?: string;
};
const InvestmentModal = (props: InvestmentModalProps) => {
  const { openModal, setOpenModal, isEdit, refetchApi, fetchSupporterById, selectedInvestment } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const params = useParams();
  const [isResetForm, setIsResetForm] = useState(false);
  const supporterId = isEdit ? params?.supporterId : params?.id;

  const initialValues: any = useMemo(() => {
    return isEdit && selectedInvestment
      ? {
        name: selectedInvestment?.name,
        amount: selectedInvestment?.amount ? getNumberValueFromApi(selectedInvestment?.amount) : '',
        term: selectedInvestment?.term ? getNumberValueFromApi(selectedInvestment?.term) : '',
        interestOption: selectedInvestment?.interestOption
          ? INTEREST_OPTIONS.filter(item => item.value === selectedInvestment?.interestOption)
          : [],
        interestRate: selectedInvestment?.interestRate
          ? getNumberValueFromApi(selectedInvestment?.interestRate)
          : selectedInvestment?.interestRate === 0
            ? '0'
            : '',
        payInterest: selectedInvestment?.payInterest ? getEPaymentTypeOptions().filter(item => item.value === selectedInvestment?.payInterest) : [],
        payPrincipal: selectedInvestment?.payPrincipal
          ? getEPaymentTypeOptions().filter(item => item.value === selectedInvestment?.payPrincipal)
          : [],
        note: selectedInvestment?.notes?.length > 0 ? selectedInvestment?.notes?.[0]?.content : '',
        startDate: selectedInvestment?.startDate ? moment(selectedInvestment?.startDate).toDate() : '',
        isResetForm,
      }
      : {
        name: '',
        amount: null,
        term: null,
        interestOption: INTEREST_OPTIONS.filter(item => item.value === ''),
        interestRate: null,
        payInterest: getEPaymentTypeOptions().filter(item => item.value === E_PAYMENT_TYPE.ANNUALLY),
        payPrincipal: getEPaymentTypeOptions().filter(item => item.value === E_PAYMENT_TYPE.AT_MATURITY),
        note: '',
        startDate: '',
        isResetForm,
      };
  }, [selectedInvestment, isResetForm, isEdit]);

  const ValidateSchema = Yup.object().shape({
    amount: Yup.number()
      .required(t('investmentModal.requiredField'))
      .min(1, t('investmentModal.amountMin'))
      .max(1000000000, t('investmentModal.amountMax'))
      .typeError(t('investmentModal.amountTypeIsNumber')),
    term: Yup.number().required(t('investmentModal.requiredField')).min(0.01, t('investmentModal.termLengthMin')),
    interestRate: Yup.number()
      .required(t('investmentModal.requiredField'))
      .min(0, t('investmentModal.rateLengthMin'))
      .max(20, t('investmentModal.rateLengthMax')),
    interestOption: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('investmentModal.requiredField')),
        }),
      )
      .min(1, t('investmentModal.requiredField')),

    payInterest: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('investmentModal.requiredField')),
        }),
      )
      .min(1, t('investmentModal.requiredField')),
    payPrincipal: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('investmentModal.requiredField')),
        }),
      )
      .min(1, t('investmentModal.requiredField')),
    note: Yup.string().min(1, t('investmentModal.noteLengthMin')).max(255, t('investmentModal.noteLengthMax')).nullable(),
    startDate: Yup.date().nullable().required(t('investmentModal.requiredField')),
  });

  const closeModalHandler = (props: FormikProps<InvestmentFormTypes>) => {
    setOpenModal(false);
    props.resetForm();
    props.setErrors({});
  };

  const handleAddInvestment = async (payload: AddInvestmentType) => {
    await (isEdit
      ? updateInvestmentById({
        investmentId: selectedInvestment?.id as string,
        payload,
      })
      : createInvestment(payload));
  };

  const mutation = useMutation('create-edit-investment', {
    mutationFn: handleAddInvestment,
  });

  const handleSubmit = (data: InvestmentFormTypes, action: { [key: string]: any }) => {
    const params: any = {
      supporterId,
      name: data.name,
      amount: getNumberValueSendToApi(data.amount),
      interestRate: getNumberValueSendToApi(data.interestRate),
      term: getNumberValueSendToApi(data.term),
      payInterest: data.payInterest[0].value,
      payPrincipal: data.payPrincipal[0].value,
      interestOption: data.interestOption[0].value,
      notes: data.note ? [{ content: data.note }] : undefined,
      startDate: moment(data.startDate).format(FORMAT_DATE_API),
    };

    mutation.mutate(params, {
      onSuccess: () => {
        const message: string = t(isEdit ? 'investmentModal.editSuccessMessage' : 'investmentModal.createSuccessMessage');
        setOpenModal(false);
        toast.success(message);
        action.resetForm();
        refetchApi();
        if (typeof fetchSupporterById === 'function') fetchSupporterById();
        if (isEdit) queryClient.invalidateQueries('getInvestmentPaymentsSchedule').then(() => true);
      },
      onError: async (error: any) => {
        let message: string = messageErrors(error, t);
        if (message === 'undefined') {
          message = t('investmentModal.createFailMessage');
        }
        toast.error(message);
      },
    });
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <Modal
      id="investment-modal"
      show={openModal}
      size="2xl"
      onClose={() => {
        setIsResetForm(!isResetForm);
        setOpenModal(false);
      }}
      dismissible={true}
    >
      <Modal.Header>{!isEdit ? t('addInvestment') : t('editInvestment')}</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="form-flex-col flex justify-between items-start space-x-2">
                <TextForm
                        id="name"
                        name="name"
                        label={t('name')}
                        placeholder={t('name')}
                        //dataTestId="test-investment-name"
                        propsFormik={props}
                        isEdit={isEdit}
                        isDisabled={isEdit}
                        isRequired={false}
                        setCurrentValue={async (
                          text: {
                            name?: string;
                            value?: string;
                          },
                          props: FormikProps<InvestmentFormTypes>,
                        ) => {
                          await setTextValue<InvestmentFormTypes>({ text, props });
                        }}
                      />
                </div>
                <div className="form-flex-col flex justify-between items-start space-x-2">
                  <CurrencyForm
                    id="amount"
                    name="amount"
                    label={t('amount')}
                    placeholder={t('amount')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<InvestmentFormTypes>,
                    ) => {
                      await setCurrencyValue<InvestmentFormTypes>({ currency, props });
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CustomSelectForm
                    id="interestOption"
                    name="interestOption"
                    label={t('interestOption')}
                    placeHolder={t('interestOption')}
                    options={INTEREST_OPTIONS}
                    selectItemsHandler={async (items: any, props: FormikProps<InvestmentFormTypes>) => {
                      await selectItemHandler<InvestmentFormTypes>({ items, props, fieldName: 'interestOption' });
                      if (items?.[0]?.value === 'Roll-Over')
                        await props.setFieldValue(
                          'payPrincipal',
                          getEPaymentTypeOptions().filter(item => item.value === 'At Maturity'),
                        );
                    }}
                    propsFormik={props}
                    isRequired={true}
                    isEdit={isEdit}
                    isMulti={false}
                    disableSearch={true}
                  />
                </div>
                <div className="form-flex-col flex justify-between items-start space-x-2">
                  <CurrencyForm
                    id="term"
                    name="term"
                    label={t('term')}
                    placeholder={t('term')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<InvestmentFormTypes>,
                    ) => {
                      await setCurrencyValue<InvestmentFormTypes>({ currency, props });
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    unit={t('year(s)')}
                    styleUnit="suffix"
                    decimalLimit={2}
                  />
                  <CustomDatePickerForm
                    id="startDate"
                    name="startDate"
                    label={t('investmentDate')}
                    placeHolder={t('date')}
                    isRequired={true}
                    propsFormik={props}
                    selectItemsHandler={(date: Date) => {
                      selectDateHandler<AddInvestmentType>({
                        date,
                        props,
                        fieldName: 'startDate',
                      });
                    }}
                    isEdit={isEdit}
                    isShowInitial={true}
                    isDeleteSelectedDate={openModal}
                    position="top"
                  />
                </div>
                <div className="form-flex-col flex justify-between items-start space-x-2">
                  <CurrencyForm
                    id="interestRate"
                    name="interestRate"
                    label={t('interestRate')}
                    placeholder={t('interestRate')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<InvestmentFormTypes>,
                    ) => {
                      await setCurrencyValue<InvestmentFormTypes>({ currency, props, isZero: true });
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    unit="%"
                    styleUnit="suffix"
                    decimalLimit={2}
                  />
                </div>
                <div className="form-flex-col flex flex-column justify-between items-start space-x-2">
                  <CustomSelectForm
                    id="payInterest"
                    name="payInterest"
                    label={t('payInterest')}
                    placeHolder={t('payInterest')}
                    options={getEPaymentTypeOptions().filter(item => item.value !== E_PAYMENT_TYPE.MONTHLY)}
                    selectItemsHandler={async (items: any, props: FormikProps<InvestmentFormTypes>) => {
                      await selectItemHandler<InvestmentFormTypes>({ items, props, fieldName: 'payInterest' });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    isEdit={isEdit}
                    isMulti={false}
                    disableSearch={true}
                  />
                  <CustomSelectForm
                    id="payPrincipal"
                    name="payPrincipal"
                    label={t('payPrincipal')}
                    placeHolder={t('payPrincipal')}
                    options={getEPaymentTypeOptions().filter(item => item.value !== E_PAYMENT_TYPE.MONTHLY)}
                    selectItemsHandler={async (items: any, props: FormikProps<InvestmentFormTypes>) => {
                      await selectItemHandler<InvestmentFormTypes>({ items, props, fieldName: 'payPrincipal' });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    isEdit={isEdit}
                    isMulti={false}
                    disableSearch={true}
                    isDisabled={props.getFieldProps('interestOption')?.value?.[0]?.value === 'Roll-Over'}
                  />
                </div>
                <TextAreaForm id="note" name="note" placeholder={t('note')} propsFormik={props} />
                <GroupButton
                  className="w-full gap-2 justify-center pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default InvestmentModal;
