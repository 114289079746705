import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { GetInvestmentBySupporterFilter, NoteType } from 'utils/proptypes';

import base from './baseApi';

export type AddInvestmentType = {
  supporterId: string;
  amount: number;
  term: number;
  startDate: Date;
  interestRate: number;
  interestOption: string;
  payInterest: string;
  payPrincipal: string;
  notes: NoteType[];
};

const { urls, methods, execute } = base();

export const createInvestment = (payload: AddInvestmentType) => {
  const method = methods.POST;
  const url = urls.investments.investments;
  return execute(method, url, { authorization: true }, payload);
};

export const getInvestmentBySupporter = (supporterId: string, queryFilter: GetInvestmentBySupporterFilter) => {
  const { page = DEFAULT_VALUE_FILTER.PAGE, limit = DEFAULT_VALUE_FILTER.LIMIT, f_date } = queryFilter;
  const method = methods.GET;

  const url = `${urls.investments.investmentSupporter}/${supporterId}/?page=${page}&limit=${limit}${f_date ? `&f_date=${f_date}` : ''}`;
  return execute(method, url, { authorization: true });
};

export const getInvestmentById = (investmentId: string) => {
  const method = methods.GET;

  const url = `${urls.investments.investments}/${investmentId}`;
  return execute(method, url, { authorization: true });
};

export const updateInvestmentById = ({ investmentId, payload }: { investmentId: string; payload: Partial<AddInvestmentType> }) => {
  const method = methods.PATCH;

  const url = `${urls.investments.investments}/${investmentId}`;
  return execute(method, url, { authorization: true }, payload);
};

export const downloadInvestmentInfo = (queryFilter: GetInvestmentBySupporterFilter) => {
  const { f_date, supporterId = '' } = queryFilter;
  const method = methods.POST;
  const url = `${urls.investments.downloadInvestmentInfo}?supporterId=${supporterId}${f_date ? `&f_date=${f_date}` : ''}`;
  return execute(method, url, { authorization: true });
};

export const deleteInvestmentById = (investmentId: string) => {
  const method = methods.DELETE;

  const url = `${urls.investments.investments}/${investmentId}`;
  return execute(method, url, { authorization: true });
};

export const downloadInterestInvestmentPDF = async (investmentId: string) => {
  const method = methods.GET;
  const url = `${urls.investments.investments}/${investmentId}/downloadInterestInvestmentPDF`;
  return execute(method, url, { authorization: true });
};

export const downloadFinalInvestmentPDF = async (investmentId: string) => {
  const method = methods.GET;
  const url = `${urls.investments.investments}/${investmentId}/dowloadFinalInvestmentPDF`;
  return execute(method, url, { authorization: true });
};
