import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { exportLoansToCSV, getLoansByPartnerId, removeLoan } from 'api/loanFundManager/loanApi';
import AddLoanModal from 'components/modal/loan/addLoanModal';
import PaginateTable from 'components/table/paginate';
import useLoading from 'hooks/useLoading';
import { loanAction } from 'redux/actions';
import { loanSelector } from 'redux/selectors';
import { ABILITY_ACTION, DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME, PERMISSION, ROUTE_PATHS } from 'utils/constants';
import { ContactType, LoanType, PartnerType } from 'utils/proptypes';
import { exportRowDataToCSV, messageErrors } from 'utils/utils';

import RenderLoansTableData from './components/RenderLoansTableData';

const LoansPage = ({
  fetchPartnerById,
  ability,
  isFetchingPartner = false,
  selectedPartner,
}: {
  fetchPartnerById: Function;
  ability?: any;
  isFetchingPartner?: boolean;
  selectedPartner?: PartnerType;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const partnerId = params.id;
  const selectedPartnerContacts = selectedPartner?.contacts as ContactType[];

  const {
    loansOfPartner: { entities: loans, totalEntities },
  } = useSelector(loanSelector);

  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const limit = 10;
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const ref = useRef(null);

  const { refetch: fetchLoansByPartnerId, isFetching } = useQuery(
    ['loans', currentPage],
    () => getLoansByPartnerId({ page: currentPage, limit, partnerId }),
    {
      onSuccess: ({ data }) => {
        dispatch(
          loanAction.setLoansOfPartner({
            ...data,
            entities: data.entities,
            totalEntities: data.totalEntities,
            rowPerPages: data.entities.length,
            currentPage,
          }),
        );
      },
      onError: () => {
        dispatch(loanAction.setLoansOfPartner(undefined));
      },
    },
  );

  useLoading({ isLoading: isFetching && isFetchingPartner, ref });

  const openLoanDetail = (item: LoanType) => {
    navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}${ROUTE_PATHS.LOAN_PAGE}/${item.id}`);
  };

  const addLoanHandler = () => {
    if (selectedPartnerContacts?.some(contact => contact.isHeadquarter)) setOpenAddModal(!openAddModal);

    else {
      const message = t('pleaseAddContact')
      toast.warning(message);
    }
  };

  const handleExportLoans = async () => {
    await exportLoansToCSV({ partnerId: partnerId as string }).then(async res => {
      exportRowDataToCSV({ stream: res.data, fileName: EXPORT_FILE_NAME.LOAN });
    });
  };

  const { refetch: refetchExportLoans, isFetching: isFetchingDownloadLoans } = useQuery('download-loans', handleExportLoans, {
    onSuccess: () => {
      const message: string = t('loanPage.exportToCsvSuccess');
      toast.success(message);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
    enabled: false,
  });

  const handleDeleteLoan = (loanId: string) => {
    removeLoan(loanId).then(
      res => {
        if (res.status === 200) {
          fetchLoansByPartnerId();
          fetchPartnerById();
          const message = t('deletedLoadSuccessfully')
          toast.success(message);
        }
      },
      e => {
        const message = t('deletedLoadFail')
        toast.warning(message)
      }

    );
  };

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="loan flex flex-col h-full">
        <div className="loan__header flex justify-between items-center pb-2">
          <h3 className="text-gray-500 uppercase">{t('loans')}</h3>
          {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.CREATE) && (
            <button onClick={() => addLoanHandler()} className="cursor-pointer px-1 py-1 text-white bg-blue-700 rounded-lg" data-testid="partner-info-add-loan">
              <HiPlus className="h-4 w-4" />
            </button>
          )}
        </div>
        <RenderLoansTableData loans={loans} openLoanDetail={openLoanDetail} isFetching={isFetching} handleDeleteLoan={handleDeleteLoan} />
        {totalEntities > 0 && (
          <PaginateTable
            className="loan__pagination"
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isFetching={isFetching}
            isFetchingDownload={isFetchingDownloadLoans}
            exportHandler={refetchExportLoans}
            items={loans}
          />
        )}
      </div>
      <AddLoanModal
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        refetchApi={fetchPartnerById}
        fetchLoans={fetchLoansByPartnerId}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </>
  );
};

export default LoansPage;
