import { Ability } from '@casl/ability';

const initialAbility = [
  {
    action: 'read',
    subject: 'VIEW_PARTNER',
  },
];

const currentAbility = new Ability(initialAbility);

export default currentAbility;
