import { QueryFilterType } from 'utils/proptypes';

import base from './baseApi';

const { urls, methods, execute } = base();

const getRoles = (queryFilter: QueryFilterType) => {
  const { page, limit } = queryFilter;

  const method = methods.GET;
  const url = `${urls.role.Roles}?page=${page}&limit=${limit}&withDeleted=false`;
  return execute(method, url, { authorization: true });
};

export { getRoles };
