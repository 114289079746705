import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowDown, HiPlus } from 'react-icons/hi';
import { MdClear } from 'react-icons/md';
import classNames from 'classnames';
import DateInput from 'components/input/dateInput/DateInput';
import CustomSelectInput from 'components/input/selectInput/CustomSelectInput';
import ExportAllDonationByDateFilter from 'components/modal/supporter/exportAllDonationByDateFilter/exportAllDonationAndInvestmentByDateFilter';
import { AbilityContext } from 'contexts/can';
import { Button, Label } from 'flowbite-react';
import moment from 'moment';
import { ABILITY_ACTION, FORMAT_DATE_API, FORMAT_DATE_PICKER, PAYMENT_STATUS_OPTIONS, PERMISSION } from 'utils/constants';
import { FilterPaymentsValueType, HeaderTableType, PermissionType } from 'utils/proptypes';

const HeaderTable = (props: HeaderTableType) => {
  const {
    placeholderSearch,
    nameButton,
    addHandler,
    setSearchValue,
    hideSearch = true,
    hideExportButton = false,
    hideAddButton = false,
    setCurrentPage,
    className,
    dataTestId,
    hideFilter = true,
    setFilterValue,
    abilityObjectTitle,
    exportHandler,
    isSupporterHeader = false,
    isPaymentSchedule = false,
  } = props;

  const ability = useContext(AbilityContext);
  const [value, setValue] = useState('');
  const [openExportModal, setOpenExportModal] = useState(false);
  const [isExportInvestment, setExportInvestment] = useState(false);
  const [t] = useTranslation()
  const openExportInvestmentModal = () => {
    setOpenExportModal(true);
    setExportInvestment(true);
  };

  const openExportDonationModal = () => {
    setOpenExportModal(true);
    setExportInvestment(false);
  };

  const handleSearch = (e: any, fetchData: Function) => {
    const { key } = e;
    if (key === 'Enter') {
      if (setCurrentPage) {
        setCurrentPage(0);
      }
      fetchData(value);
    }
  };

  return (
    <div className={`flex flex-row items-center justify-between flex-wrap ${className}__header py-3.5 px-4`}>
      <div className={`sm:w-full text-gray-600 flex flex-row flex-1 items-center ${className}__header--left`}>
        {!hideSearch && (
          <div className={classNames('relative header-search-group flex-1', { 'mr-3': hideFilter })}>
            <MdClear
              className={classNames('header-search-icon absolute top-3 right-2 cursor-pointer', { haveValue: value })}
              size={16.5}
              onClick={() => {
                if (setCurrentPage && setSearchValue) {
                  setCurrentPage(0);
                  setSearchValue('');
                  setValue('');
                }
              }}
            />
            <input
              data-testid={dataTestId}
              className="search-input w-full border-1 border-gray-200 bg-white pl-2.5 pr-8 rounded text-sm focus:outline-none"
              type="text"
              name="search"
              value={value}
              onChange={e => setValue(e?.target?.value)}
              placeholder={t('search')}
              onKeyPress={e => handleSearch(e, setSearchValue as Function)}
            />
            <div
              className={classNames('header-search-label absolute flex items-center cursor-pointer rounded-sm leading-none', {
                haveValue: value,
              })}
            >
              <Label value={placeholderSearch} />
            </div>
          </div>
        )}
        {!hideFilter && (
          <>
            <p className="bulkhead ml-3 text-xs text-gray-200">|</p>
            <p className="header-filter-title mx-3 text-md text-gray-900 font-semibold uppercase">{t('filter')}</p>
            <div className="header-filter-group flex justify-start items-center gap-3 mr-3 flex-1">
              <DateInput
                dateFormat={FORMAT_DATE_PICKER}
                isSelectRange={true}
                isFilter={true}
                selectItemsHandler={(startDate: Date, setStartDate: Function, endDate: Date) => {
                  if (setFilterValue) {
                    setFilterValue((state: FilterPaymentsValueType) => ({
                      ...state,
                      f_startDate: startDate ? moment(startDate).format(FORMAT_DATE_API) : undefined,
                      f_endDate: startDate ? moment(endDate).format(FORMAT_DATE_API) : undefined,
                    }));
                    if (typeof setCurrentPage === 'function') setCurrentPage(0);
                  }
                }}
                placeHolder={t('accrualDate')}
                isClearable={true}
              />
              {!isPaymentSchedule && (
                <CustomSelectInput
                  options={PAYMENT_STATUS_OPTIONS}
                  selectItemsHandler={(paymentStatus: string) => {
                    if (setFilterValue) {
                      setFilterValue((state: FilterPaymentsValueType) => ({
                        ...state,
                        f_status: paymentStatus ? paymentStatus : undefined,
                      }));
                      if (typeof setCurrentPage === 'function') setCurrentPage(0);
                    }
                  }}
                  placeHolder={t('status')}
                  isFilter={true}
                  disableSearch={true}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className={`add-action flex justify-end flex-row items-center ${className}__header--right space-x-3`}>
        {isSupporterHeader &&
          (abilityObjectTitle ? ability.can(ABILITY_ACTION.manage, PERMISSION[abilityObjectTitle as keyof PermissionType].UPDATE) : true) && (
            <>
              <Button size="xs" onClick={() => openExportDonationModal()}>
                <HiArrowDown className="mr-1 h-4 w-4" />
                <span className={classNames('', { 'sm:hidden xl:block': !isSupporterHeader })}>{t('exportDonations')}</span>
              </Button>
              <Button size="xs" onClick={() => openExportInvestmentModal()}>
                <HiArrowDown className="mr-1 h-4 w-4" />
                <span className={classNames('', { 'sm:hidden xl:block': !isSupporterHeader })}>{t('exportInvestments')}</span>
              </Button>
              <ExportAllDonationByDateFilter openModal={openExportModal} setOpenModal={setOpenExportModal} isExportInvestment={isExportInvestment} />
            </>
          )}
        {!hideExportButton &&
          (abilityObjectTitle ? ability.can(ABILITY_ACTION.manage, PERMISSION[abilityObjectTitle as keyof PermissionType].UPDATE) : true) && (
            <Button
              color="light"
              size="xs"
              onClick={() => {
                if (typeof exportHandler === 'function') exportHandler();
              }}
            >
              <HiArrowDown className="mr-1 h-4 w-4" />
              <span className={classNames('', { 'sm:hidden xl:block': !hideFilter })}>{t('downloadContacts')}</span>
            </Button>
          )}
        {!hideAddButton &&
          (abilityObjectTitle ? ability.can(ABILITY_ACTION.manage, PERMISSION[abilityObjectTitle as keyof PermissionType].CREATE) : true) && (
            <Button
              size="xs"
              onClick={() => {
                if (typeof addHandler === 'function') addHandler();
              }}
            >
              <HiPlus className="h-4 w-4" />
              <span className={classNames('', { 'sm:hidden xl:block': !hideFilter })}>{nameButton}</span>
            </Button>
          )}
      </div>
    </div>
  );
};
export default HeaderTable;
