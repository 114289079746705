import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { downloadInvestmentInfo } from 'api/investmentApi';
import { downloadAllDonationInfo } from 'api/supporter/donation';
import GroupButton from 'components/button/groupButton';
import DateInput from 'components/input/dateInput/DateInput';
import { Modal } from 'flowbite-react';
import moment from 'moment';
import { EXPORT_FILE_NAME, FORMAT_DATE_API, FORMAT_DATE_PICKER } from 'utils/constants';
import { GetDonationBySupporterFilter, GetInvestmentBySupporterFilter } from 'utils/proptypes';
import { exportRowDataToCSV, messageErrors } from 'utils/utils';

import './exportAllDonationAndInvestmentByDateFilter.scss';

type exportAllDonationByDateFilterModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  isExportInvestment: boolean;
};

const ExportAllDonationAndInvestmentByDateFilter = (props: exportAllDonationByDateFilterModalTypes) => {
  const { openModal = false, setOpenModal, isExportInvestment } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [donationfilterValue, setDonationFilterValue] = useState<GetDonationBySupporterFilter>({
    f_startDate: '',
    f_endDate: new Date().toDateString(),
  });
  const [investmentFilterValue, setInvestmentFilterValue] = useState<GetInvestmentBySupporterFilter>({ f_date: '' });

  const handleCancelModal = () => {
    setDonationFilterValue({
      f_startDate: '',
      f_endDate: new Date().toDateString(),
    });
    setInvestmentFilterValue({ f_date: '' });
    setOpenModal(false);
  };

  const handleExportModal = () => {
    if (isExportInvestment) {
      downloadInvestmentInfo(investmentFilterValue).then(
        res => {
          if (res.status === 200) {
            exportRowDataToCSV({
              stream: res.data,
              fileName: `${investmentFilterValue.f_date ? `${investmentFilterValue.f_date}_` : ''}${EXPORT_FILE_NAME.ALL_INVESTMENT}`,
            });
            handleCancelModal();
          }
        },
        error => {
          const message: string = messageErrors(error, t) || t('exportFail')
          toast.error(message);
        },
      );
    } else {
      let filtervalue = donationfilterValue;
      if (!donationfilterValue.f_endDate) {
        filtervalue = {
          f_startDate: donationfilterValue.f_startDate,
          f_endDate: new Date().toDateString(),
        };
        setDonationFilterValue(filtervalue);
      }
      downloadAllDonationInfo(filtervalue).then(
        res => {
          if (res.status === 200) {
            const { f_startDate, f_endDate } = filtervalue;
            exportRowDataToCSV({
              stream: res.data,
              fileName: `${f_startDate ? `${moment(f_startDate).format(FORMAT_DATE_API)}` : ''}${f_endDate ? `-${moment(f_endDate).format(FORMAT_DATE_API)}_` : ''
                }${EXPORT_FILE_NAME.ALL_DONATION}`,
            });
            handleCancelModal();
          }
        },
        error => {
          const message: string = messageErrors(error, t) || t('exportFail')
          toast.error(message);
        },
      );
    }
  };
  return (
    <Modal
      id="export-all-donation-by-filter-date-filter"
      show={openModal}
      size="md"
      onClose={() => {
        handleCancelModal();
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{isExportInvestment ? t('exportInvestments') : t('exportDonations')}</Modal.Header>
      <Modal.Body style={{ paddingLeft: '2.0rem', paddingBottom: '2.0rem', paddingRight: '2.0rem' }}>
        {isExportInvestment && (
          <div>
            <DateInput
              isFilter={true}
              selectItemsHandler={(startDate: Date) => {
                if (setInvestmentFilterValue) {
                  setInvestmentFilterValue((state: GetDonationBySupporterFilter) => ({
                    ...state,
                    f_date: startDate ? moment(startDate).format(FORMAT_DATE_API) : '',
                  }));
                }
              }}
              placeHolder={t('date')}
              isClearable={true}
              defaultValue={investmentFilterValue.f_date ? investmentFilterValue.f_date : undefined}
              dateFormat={FORMAT_DATE_PICKER}
            />
          </div>
        )}

        {!isExportInvestment && (
          <>
            <div>
              <DateInput
                isFilter={true}
                selectItemsHandler={(startDate: Date) => {
                  if (setDonationFilterValue) {
                    setDonationFilterValue((state: GetDonationBySupporterFilter) => ({
                      ...state,
                      f_startDate: startDate ? moment(startDate).format(FORMAT_DATE_API) : '',
                    }));
                  }
                }}
                placeHolder={t('startDate')}
                isClearable={true}
                defaultValue={donationfilterValue.f_startDate ? donationfilterValue.f_startDate : undefined}
                dateFormat={FORMAT_DATE_PICKER}
                maxDate={moment(donationfilterValue.f_endDate).toDate()}
              />
            </div>
            <div className="mt-5">
              <DateInput
                isFilter={true}
                selectItemsHandler={(startDate: Date) => {
                  if (setDonationFilterValue) {
                    setDonationFilterValue((state: GetDonationBySupporterFilter) => ({
                      ...state,
                      f_endDate: startDate ? moment(startDate).format(FORMAT_DATE_API) : '',
                    }));
                  }
                }}
                minDate={moment(donationfilterValue.f_startDate).toDate()}
                placeHolder={t('endDate')}
                isClearable={true}
                defaultValue={donationfilterValue.f_endDate ? donationfilterValue.f_endDate : undefined}
                dateFormat={FORMAT_DATE_PICKER}
              />
            </div>
          </>
        )}

        <GroupButton
          className="w-full gap-4 justify-center mt-7"
          buttons={[
            {
              type: 'button',
              text: t('modal.cancel'),
              classType: 'white',
              action: () => handleCancelModal(),
            },
            {
              type: 'submit',
              text: t('modal.export'),
              classType: 'blue',
              action: () => handleExportModal(),
            },
          ]}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ExportAllDonationAndInvestmentByDateFilter;
