import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCash, HiOutlineCurrencyDollar, HiPencilAlt, HiPlus, HiX } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { deleteContact } from 'api/common/contactApi';
import classNames from 'classnames';
import CustomIcon from 'components/common/CustomIcon';
import { ContactType, PartnerType } from 'utils/proptypes';
import { getTotalBorrowed, getTotalOutstanding } from 'utils/utils';

import { ABILITY_ACTION, PERMISSION } from '../../../../../utils/constants';

type partnerInfoType = {
  selectedPartner: PartnerType;
  ability: any;
  setOpenAddPartnerContactsModal: Function;
  setSelectedContact: Function;
  setIsEditContact: Function;
};

const PartnerInfo = (props: partnerInfoType) => {
  const { selectedPartner, ability, setOpenAddPartnerContactsModal, setSelectedContact, setIsEditContact } = props;
  const queryClient = useQueryClient();
  const [hoverContact, setHoverContact] = useState<ContactType>();
  const [t] = useTranslation()
  const handleClickEditContact = (contact: ContactType) => {
    setIsEditContact(true);
    setSelectedContact(contact);
    setOpenAddPartnerContactsModal(true);
  };
  const handleClickAddContact = () => {
    setIsEditContact(false);
    setSelectedContact(undefined);
    setOpenAddPartnerContactsModal(true);
  };
  const handleDeleteContact = async (contactId: string) => {
    deleteContact(contactId).then(
      async res => {
        const message = t('deleteContactSuccessfully')
        toast.success(message);
        await queryClient.invalidateQueries(['getPartner']);
      },
      error => {
        const message = t('deleteContactFail')
        toast.success(message);
      },
    );
  };

  return (
    <div className="partner-info__container h-full flex flex-col justify-start items-center py-8">
      <div className="w-full flex flex-col justify-center items-center space-y-1 px-4">
        <h3 className="w-full leading-none text-gray-900 font-semibold text-xl text-center">{selectedPartner?.name}</h3>
        <p className="w-full leading-none text-gray-900 font-normal text-sm text-center">{selectedPartner?.nickName ?? ''}</p>
      </div>
      <div className="w-full flex justify-center items-start py-8 space-x-8">
        <div className="flex flex-col justify-center items-center space-y-3">
          <CustomIcon Icon={HiOutlineCurrencyDollar} bgColor="bg-purple-200" textColor="text-purple-600" />
          <p className="leading-none text-gray-900 text-xs font-normal">{t('totalBorrowed')}</p>
          <h3 className="leading-none text-gray-900 text-sm font-semibold">
            {selectedPartner?.totalBorrowed ? getTotalBorrowed(selectedPartner?.totalBorrowed) : ''}
          </h3>
        </div>
        <div className="flex flex-col justify-center items-center space-y-3">
          <CustomIcon Icon={HiOutlineCash} bgColor="bg-green-200" textColor="text-green-600" />
          <p className="leading-none text-gray-900 text-xs font-normal">{t('totalOutStanding')}</p>
          <h3 className="leading-none text-gray-900 text-sm font-semibold">
            {selectedPartner?.totalOutstanding ? getTotalOutstanding(selectedPartner?.totalOutstanding) : ''}
          </h3>
        </div>
      </div>
      <div className="w-full border-b border-gray-200" />
      <div className="w-full flex justify-between items-center px-4 pt-6 pb-4">
        <h3 className="w-full text-gray-500 text-base font-normal text-start uppercase" >{t('contacts')}</h3>
        {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.PARTNER.UPDATE) && (
          <button
            onClick={() => handleClickAddContact()}
            className="cursor-pointer px-1 py-1 text-white bg-blue-700 rounded-lg"
            style={{ height: 'auto' }}
            data-testid="partner-info-add-contact"
          >
            <HiPlus className="h-4 w-4" />
          </button>
        )}
      </div>
      <div className="contact h-full flex flex-col justify-start items-center space-y-4 w-full">
        {selectedPartner?.contacts?.map((contact, index) => (
          <div
            onMouseEnter={() => setHoverContact(contact)}
            key={`address-${index}`}
            className={classNames(
              'flex flex-col gap-3 w-full p-4 relative',
              { 'bg-gray-50': index % 2 === 0 },
              { showhover: hoverContact === contact },
            )}
          >
            <div className="button">
              <span>
                <button
                  onClick={() => handleClickEditContact(contact)}
                  className="edit-button cursor-pointer px-1 py-1 text-white bg-blue-700 rounded-lg"
                >
                  <HiPencilAlt className="h-4 w-4" />
                </button>
              </span>
              <span>
                <button
                  onClick={() => handleDeleteContact(contact.id)}
                  className="delete-button cursor-pointer px-1 py-1 text-white bg-red-700 rounded-lg"
                >
                  <HiX className="h-4 w-4" />
                </button>
              </span>
            </div>

            {contact?.isHeadquarter && (
              <span
                className="bg-blue-100 text-gray-900 text-xs font-medium me-2 px-1 py-0.5 rounded absolute -top-2"
                style={{ width: 'fit-content' }}
                data-testid="partner-contact-headquarter"
              >
                {t('headquarter')}
              </span>
            )}
            <div className="flex justify-between pt-1">
              <h3 className="text-gray-900 font-semibold text-sm leading-none">{t('name')} </h3>
              <p className="text-gray-900 font-normal text-sm leading-none text-right">{`${contact?.prefixTitles ?? ''} ${contact?.name ?? ''}`}</p>
            </div>
            {contact?.position && <div className="flex justify-between pt-1">
              <h3 className="text-gray-900 font-semibold text-sm leading-none">{t('position')}</h3>
              <p
                className="text-gray-900 font-normal text-sm leading-none text-right">{contact?.position ?? ''}`</p>
            </div>}
            <div className="flex justify-between">
              <h3 className="text-gray-900 font-semibold text-sm leading-none">{t('email')}</h3>
              <p className="text-gray-900 font-normal text-sm leading-none text-right">
                {contact?.emails?.length > 0 ? contact?.emails?.[0]?.emailAddress : ''}
              </p>
            </div>
            <div className="flex justify-between">
              <h3 className="text-gray-900 font-semibold text-sm leading-none">{t('phone')}</h3>
              <p className="text-gray-900 font-normal text-sm leading-none text-right">
                {contact?.phones?.length > 0 ? contact?.phones?.[0]?.phoneNumber : ''}
              </p>
            </div>
            <p className="text-gray-900 font-normal text-sm leading-none text-right">{`${contact?.address?.[0]?.street ? contact?.address?.[0]?.street + ',' : ''
              } ${contact?.address?.[0]?.city ? contact?.address?.[0]?.city + ',' : ''} ${contact?.address?.[0]?.country?.name ? contact?.address?.[0]?.country?.name : ''
              }`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnerInfo;
