import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changePassword } from 'api/userApi';
import { Modal } from 'flowbite-react';
import { Form, Formik } from 'formik';
import _ from 'lodash';

import { userSelector } from '../../redux/selectors';
import { messageErrors, validatiePassword } from '../../utils/utils';
import GroupButton from '../button/groupButton';
import FormComponent from '../form/form';

import CustomModalHeader from './customModalHeader';

type changePasswordModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  nameHeader?: string;
};

const ChangePasswordModal = (props: changePasswordModalTypes) => {
  const [t] = useTranslation();
  const { openModal, setOpenModal, nameHeader } = props;
  const { userInfo } = useSelector(userSelector);
  const initData = { currentPassword: '', newPassword: '', newPasswordConfirm: '' };
  const mutation = useMutation('changePassword', { mutationFn: changePassword });

  const handleSubmit = (value: any, props: any) => {
    mutation.mutate(
      { ...value, userEmail: userInfo.emailAddress },
      {
        onSuccess: () => {
          setOpenModal(!openModal);
          const message: string = t('changePassword.messageSuccess');
          toast.success(message);
          props.resetForm();
        },
        onError: (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const cancelHandler = (props: any) => {
    setOpenModal(!openModal);
    props.resetForm();
  };

  const onValidate = (values: any) => {
    const errors: any = {};
    if (!values.currentPassword || _.isEmpty(values.currentPassword)) errors.currentPassword = t('requiredField');
    if (_.isEmpty(values.newPassword) || !validatiePassword(values.newPassword)) errors.newPassword = t('changePassword.newPassInvalid');
    if (!values.newPasswordConfirm || _.isEmpty(values.newPasswordConfirm)) errors.newPasswordConfirm = t('requiredField');
    if (values.newPassword !== values.newPasswordConfirm) errors.newPasswordConfirm = t('changePassword.confirmPassInvalid');
    return errors;
  };

  return (
    <Modal show={openModal} size="md" popup={true}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initData}
        validate={onValidate}
        validateOnChange={true}
        initialTouched={{
          field: true,
        }}
        validateOnMount
        validateOnBlur={false}
      >
        {props => {
          return (
            <Form>
              <CustomModalHeader title={nameHeader} toggle={() => cancelHandler(props)} />
              <Modal.Body>
                <div className="flex flex-col px-4 gap-4">
                  <FormComponent
                    label={`${t('changePassword.currentPassword')} *`}
                    id="currentPassword"
                    type="password"
                    placeholder="********"
                    name="currentPassword"
                  />
                  <FormComponent
                    label={`${t('changePassword.newPassword')} *`}
                    id="newPassword"
                    type="password"
                    placeholder="********"
                    name="newPassword"
                    isValidatePassword={true}
                  />
                  <FormComponent
                    label={`${t('changePassword.newPasswordConfirm')} *`}
                    id="newPasswordConfirm"
                    type="password"
                    placeholder="********"
                    name="newPasswordConfirm"
                  />
                  <GroupButton
                    className="items-center justify-center pt-1 pb-2"
                    buttons={[
                      {
                        type: 'button',
                        text: t('modal.cancel'),
                        classType: 'white',
                        action: () => cancelHandler(props),
                      },
                      {
                        type: 'submit',
                        text: t('modal.save'),
                        classType: 'blue',
                        isLoading: mutation.isLoading,
                      },
                    ]}
                  />
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default ChangePasswordModal;
