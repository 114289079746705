import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AddInvestmentPaymentType, createInvestmentPayment } from 'api/supporter/investmentPayment';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_API, SUPPORTER_PREFERRED_PAYMENT_METHOD } from 'utils/constants';
import { InvestmentPaymentsType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, messageErrors, selectDateHandler, setCurrencyValue, setTextValue } from 'utils/utils';
import * as Yup from 'yup';

import TextForm from '../../form/textForm/textForm';

import './recordPaymentMethodModal.scss';
import '../../../styles/common/multiSelect.scss';

type RecordInvestmentPaymentProps = {
  openModal: boolean;
  setOpenModal: Function;
  selectedInvestmentPayment: InvestmentPaymentsType & { preferredPaymentMethod: string };
  refetchInvestmentPayments: Function;
};

type recordInvestmentPaymentFormType = {
  amountReceived?: number;
  receivedDate?: Date;
  checkNumber?: string;
};

const RecordPaymentMethodModal = (props: RecordInvestmentPaymentProps) => {
  const { openModal = false, setOpenModal, selectedInvestmentPayment, refetchInvestmentPayments } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);

  const ValidateSchema = Yup.object().shape({
    amountReceived: Yup.number()
      .min(0, t('investmentPage.currencyMin'))
      .max(1000000000, t('investmentPage.currencyMax'))
      .required(t('investmentPage.requiredField')),
    receivedDate: Yup.date().required(t('investmentPage.requiredField')),
    checkNumber: selectedInvestmentPayment?.preferredPaymentMethod?.includes(SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK)
      ? Yup.string()
        .test({
          name: 'checkNumber-test',
          skipAbsent: true,
          test(value, ctx) {
            const { createError } = ctx;
            return true;
          },
        })
        .required(t('investmentPage.requiredField'))
      : Yup.string().nullable(),
  });

  const initialValues: recordInvestmentPaymentFormType = useMemo(
    () => ({
      amountReceived: selectedInvestmentPayment?.amountReceived ? getNumberValueFromApi(selectedInvestmentPayment?.amountReceived) : undefined,
      receivedDate: selectedInvestmentPayment?.dueDate ? moment(selectedInvestmentPayment?.dueDate).toDate() : undefined,
      checkNumber: selectedInvestmentPayment?.checkNumber ? selectedInvestmentPayment?.checkNumber : '',
      isResetForm,
    }),
    [selectedInvestmentPayment, isResetForm],
  );

  const handleRecordInvestmentPayment = async (investmentPaymentPayload: any) => {
    await createInvestmentPayment(investmentPaymentPayload as AddInvestmentPaymentType);
  };

  const mutation = useMutation('record-investment-payment', {
    mutationFn: handleRecordInvestmentPayment,
  });

  const handleSubmit = async (data: recordInvestmentPaymentFormType, action: { [key: string]: any }) => {
    const successCallback = async (message: string) => {
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      setIsResetForm(!isResetForm);
      await refetchInvestmentPayments();
    };

    const tempData = {
      investmentId: selectedInvestmentPayment?.investmentId,
      amountReceived: data.amountReceived ? getNumberValueSendToApi(data.amountReceived) : null,
      receivedDate: data.receivedDate ? moment(data.receivedDate).format(FORMAT_DATE_API) : null,
      checkNumber: data.checkNumber ? data.checkNumber : null,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: async () => {
            const message = t('investmentPage.createInvestmentPaymentSuccessMessage');
            await successCallback(message);
          },
          onError: (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<recordInvestmentPaymentFormType>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  console.log('selectedInvestmentPayment', selectedInvestmentPayment);

  return (
    <Modal
      id="record-investment-payment-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('editPayment')}</Modal.Header>
      <Modal.Body style={{ padding: '1.0rem' }}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          setIsResetForm={setIsResetForm}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="flex flex-row gap-4 justify-between">
                    <div>
                      Note #: {selectedInvestmentPayment?.name}
                    </div>
                    <div>
                      Interest Type: {selectedInvestmentPayment?.paymentType}
                    </div>
                  </div>
                  <CurrencyForm
                    id="amount"
                    name="amountReceived"
                    label={t('amount')}
                    placeholder={t('amount')}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<recordInvestmentPaymentFormType>,
                    ) => {
                      await setCurrencyValue<recordInvestmentPaymentFormType>({ currency, props });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    decimalLimit={2}
                  />
                  <CustomDatePickerForm
                    id="receivedDate"
                    name="receivedDate"
                    label={t('paymentSent')}
                    placeHolder={t('paymentSent')}
                    propsFormik={props}
                    selectItemsHandler={async (date: Date) => {
                      await selectDateHandler<recordInvestmentPaymentFormType>({
                        date,
                        props,
                        fieldName: 'receivedDate',
                      });
                    }}
                    isRequired={true}
                    isDeleteSelectedDate={openModal}
                    position="top"
                  />
                  {selectedInvestmentPayment?.preferredPaymentMethod?.includes(SUPPORTER_PREFERRED_PAYMENT_METHOD.CHECK) && (
                    <TextForm
                      id="checkNumber"
                      name="checkNumber"
                      label={t('checkNumber')}
                      placeholder={t('checkNumber')}
                      dataTestId="test-supporter-checkNumber-error"
                      propsFormik={props}
                      isEdit={true}
                      setCurrentValue={async (
                        text: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<recordInvestmentPaymentFormType>,
                      ) => {
                        await setTextValue<recordInvestmentPaymentFormType>({ text, props });
                      }}
                      isRequired={true}
                    />
                  )}
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default RecordPaymentMethodModal;
