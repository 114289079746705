import CardIndex, { CardIndexType } from './CardIndex';

type CardIndexGroupType = { cardIndexData: CardIndexType[]; className: string; height?: string; width?: string };

const CardIndexGroup = (props: CardIndexGroupType) => {
  const { cardIndexData, className } = props;
  return (
    <div className={`w-auto card-index-group ${className}`}>
      {cardIndexData.map((cardIndex: CardIndexType, index) => (
        <CardIndex
          inputType={cardIndex.inputType}
          changeValueHandler={cardIndex.changeValueHandler}
          fieldNameInput={cardIndex.fieldNameInput}
          key={`cardIndex-${index}`}
          bgColor={cardIndex.bgColor}
          bgIconColor={cardIndex.bgIconColor}
          textIconColor={cardIndex.textIconColor}
          label={cardIndex.label}
          value={cardIndex.value}
          subValue={cardIndex?.subValue}
          icon={cardIndex.icon}
        />
      ))}
    </div>
  );
};

export default CardIndexGroup;
