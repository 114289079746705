import loanSlice from './slice/loanSlice';
import paymentSlice from './slice/paymentSlice';
import roleSlice from './slice/roleSlice';
import userSlice from './slice/userSlice';

const userAction = userSlice.actions;
const roleAction = roleSlice.actions;
const loanAction = loanSlice.actions;
const paymentAction = paymentSlice.actions;

export { userAction, roleAction, loanAction, paymentAction };
