import { getToken } from 'auth0';
import axios from 'axios';
import { HTTP_METHODS, ROUTE_PATHS } from 'utils/constants';
import { HeaderType, PaginationDataType } from 'utils/proptypes';

import urls from './urls';

const BaseApi = () => {
  const generateAuthToken = (token: any) => {
    return `Bearer ${token}`;
  };

  const handlePagination = (data: PaginationDataType, DEFAULT_PAGE_SIZE: number) => {
    if (!data || (data.page == null && data.limit == null)) return data;

    const limit = data.limit || DEFAULT_PAGE_SIZE;

    delete data.limit;
    return {
      ...data,
      limit,
    };
  };

  const execute = async (method: string, endpoint: string, headers: HeaderType = {}, body: object = {}, defaultLimit: number = 0) => {
    const token = await getToken();
    localStorage.setItem('accessToken', token as string);
    const requestHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(headers.authorization && {
        authorization: generateAuthToken(token),
      }),
      ...(headers.xApiKey && {
        'X-API-KEY': headers.xApiKey,
      }),
    };
    axios.defaults.baseURL = !headers.xApiKey ? urls.baseUrl : '';
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const { response } = error;
        if (response && response.status === 403) {
          window.location.href = ROUTE_PATHS.NOT_AUTHORIZED;
        }
        return Promise.reject(error);
      },
    );

    if (method === HTTP_METHODS.GET) {
      body = handlePagination(body, defaultLimit);
    }

    const requestConfig = {
      url: endpoint,
      method,
      ...(method === HTTP_METHODS.GET && { params: body }),
      data: body,
      headers: requestHeaders,
    };

    return axios(requestConfig);
  };

  return {
    methods: HTTP_METHODS,
    urls,
    execute,
  };
};

export default BaseApi;
