import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import { schemeCategory10 } from 'd3-scale-chromatic';
import moment from 'moment';
import { Cell, Legend, Pie, PieChart, Sector } from 'recharts';
import { FORMAT_DATE_SHOW_TABLE } from 'utils/constants';
import { PieChartType } from 'utils/proptypes';
import { scaleOrdinal } from 'victory-vendor/d3-scale';

import 'react-tooltip/dist/react-tooltip.css';

type PieChartCustomType = {
  data: PieChartType[];
  title: string;
  reportDate: string;
};

const PieChartCustom = (props: PieChartCustomType) => {
  const { data, title, reportDate } = props;
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [t] = useTranslation()
  const colors = scaleOrdinal(schemeCategory10).range();

  const onClick = useCallback(
    (data: any, index: number) => {
      setActiveIndex(activeIndex !== index && data?.value !== 100 ? index : null);
    },
    [activeIndex],
  );

  const onMouseLeave = useCallback(() => {
    setActiveIndex(null);
  }, []);

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul className="grid grid-cols-2">
        {payload.map((entry: any, index: number) => {
          return (
            <div key={`item-${index}`} className="flex items-center" onClick={() => onClick(entry.value, index)} onMouseLeave={onMouseLeave}>
              <div style={{ backgroundColor: colors[(index + 1) % 10] as string }} className="w-6 h-2 mr-2 rounded cursor-pointer" />
              <li className="text-gray-900 text-sm font-normal cursor-pointer" data-tooltip-id={`pie-chart-legend-item-${entry.value}`}>
                {entry.value ? entry.value : 'N/A'}
                <span className="text-gray-400 text-sm ml-1 font-normal">{`${entry.payload.value?.toFixed(2)}%`}</span>
              </li>
              <ReactTooltip
                id={`pie-chart-legend-item-${entry.value}`}
                place="top"
                variant="dark"
                content={entry.value ? `${entry.value} ${entry.payload.value?.toFixed(2)}%` : `N/A ${entry.payload.value?.toFixed(2)}%`}
                className="max-w-full"
                style={{ width: 'fit-content' }}
              />
            </div>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="pie-chart-wrapper max-w-auto rounded-sm">
      <h5 className="text-md font-bold tracking-tight text-gray-900 text-center">
        {title} <br />
        <span className="font-normal text-gray-400">{`${t('asOf')} ${moment.utc(reportDate).format(FORMAT_DATE_SHOW_TABLE)}`}</span>
      </h5>
      {data.filter(item => item.value)?.length ? (
        <PieChart width={520} height={300} className="h-full w-full flex justify-center items-center">
          <Legend align="center" verticalAlign="middle" layout="centric" content={renderLegend} />
          <Pie
            data={data.filter(item => item.value > 0)}
            dataKey="value"
            label={props => {
              const { cx, cy, midAngle, outerRadius, fill, value, centerText, index, payload } = props;
              const RADIAN = Math.PI / 180;
              const sin = Math.sin(-RADIAN * midAngle);
              const cos = Math.cos(-RADIAN * midAngle);
              const sx = cx + (outerRadius + 1) * cos;
              const sy = cy + (outerRadius + 1) * sin;
              const mx = cx + (outerRadius + 15) * cos;
              const my = cy + (outerRadius + 15) * sin;
              const ex = mx + (cos >= 0 ? 1 : -1) * 15;
              const ey = my;
              const textAnchor = cos >= 0 ? 'start' : 'end';
              return (
                <g
                  className={classNames('recharts-layer-custom', {
                    hidden: activeIndex !== index,
                  })}
                >
                  <text x={cx} y={cy} textAnchor="middle" fill={fill}>
                    {centerText?.title}
                  </text>
                  <text x={cx} y={cy} dy={20} textAnchor="middle" fill={fill}>
                    {centerText?.value}
                  </text>
                  <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                  <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                  <text
                    style={{
                      fontWeight: 'bold',
                    }}
                    x={ex + (cos >= 0 ? 1 : -1) * 12 - 0}
                    y={ey - 10}
                    textAnchor={textAnchor}
                    fill={fill}
                    lengthAdjust="spacingAndGlyphs"
                    textLength={payload.name?.length > 15 ? '7em' : undefined}
                  >
                    {payload.name ? payload.name : 'N/A'}
                  </text>
                  <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey + 15} textAnchor={textAnchor} fill="#a6acb7" className="font-semibold text-sm">
                    {`${payload.value}%`}
                  </text>
                </g>
              );
            }}
            startAngle={360}
            endAngle={0}
            outerRadius={100}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            activeIndex={activeIndex as number}
            activeShape={(props: any) => {
              const RADIAN = Math.PI / 180;
              const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, midAngle } = props;
              const sin = Math.sin(-RADIAN * midAngle);
              const cos = Math.cos(-RADIAN * midAngle);
              const sx = cx + (outerRadius - 90) * cos;
              const sy = cy + (outerRadius - 90) * sin;
              return (
                <Sector
                  cx={sx}
                  cy={sy}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill={colors[((activeIndex as number) + 1) % 10] as string}
                />
              );
            }}
          >
            {data.map((entry, index) => {
              return <Cell key={`slice-${index}`} fill={colors[(index + 1) % 10] as string} stroke="none" className="cursor-pointer" />;
            })}
          </Pie>
        </PieChart>
      ) : (
        <PieChart width={520} height={300} className="h-full w-full flex justify-center items-center">
          <Pie data={[{ name: 'N/A', value: 100 }]} dataKey="value">
            <Cell key={`slice-no-data`} fill={colors[0] as string} stroke="none" className="cursor-pointer" />
          </Pie>
        </PieChart>
      )}
    </div>
  );
};
export default PieChartCustom;
