import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddUpdateLoanType, updateLoanById } from 'api/loanFundManager/loanApi';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_API } from 'utils/constants';
import { LoanType } from 'utils/proptypes';
import {
  selectDateHandler,
} from 'utils/utils';
import * as Yup from 'yup';

import './writeOffModal.scss';

type WriteOffModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  selectedLoan?: LoanType;
};

type WriteOffFormTypes = {
  writeOffDate: string;
};

const WriteOffModal = (props: WriteOffModalTypes) => {
  const { openModal = false, setOpenModal, selectedLoan } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const params = useParams();
  const queryClient = useQueryClient();
  const partnerId = params.partnerId;
  const ValidateSchema = Yup.object().shape({
    writeOffDate: Yup.date().nullable(),
  });

  const initialValues: any = useMemo(() => {
    return selectedLoan
      ? {
        writeOffDate: selectedLoan?.writeOffDate ? moment(selectedLoan?.writeOffDate).toDate() : '',
      }
      : {
        writeOffDate: '',
      };
  }, [selectedLoan]);

  const handleUpdateLoan = async (loanPayload: AddUpdateLoanType) => {
    await updateLoanById(selectedLoan?.id as string, loanPayload);
  };

  const mutation = useMutation('set-write-off-loan', {
    mutationFn: handleUpdateLoan,
  });

  const handleSubmit = (
    data: WriteOffFormTypes,
    action: {
      [key: string]: any;
    },
  ) => {
    const successCallback = async (message: string) => {
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      await queryClient.invalidateQueries(['loan']);
      await queryClient.invalidateQueries(['paymentsSchedule']);
    };

    if (openModal) {
      mutation.mutate(
        {
          partnerId,
          writeOffDate: data.writeOffDate ? moment(data.writeOffDate).format(FORMAT_DATE_API) : null,
        } as any,
        {
          onSuccess: async () => {
            const message: string = t('loanPage.writeOffSuccessMessage');
            await successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<WriteOffFormTypes>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      setIsResetForm(!isResetForm);
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setIsResetForm]);

  return (
    <Modal
      id="write-off-modal"
      show={openModal}
      size="md"
      onClose={() => {
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('Write Off')}</Modal.Header>
      <Modal.Body>
        <Formik enableReinitialize onSubmit={handleSubmit} initialValues={initialValues}
          validationSchema={ValidateSchema} validateOnChange={true}>
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="form-flex-col flex justify-between items-start space-x-2">
                    <CustomDatePickerForm
                      id="writeOffDate"
                      name="writeOffDate"
                      label={t('Write Off Date')}
                      placeHolder={t('Write Off Date')}
                      defaultValue={props.getFieldProps('writeOffDate').value}
                      propsFormik={props}
                      selectItemsHandler={(date: Date) =>
                        selectDateHandler<WriteOffFormTypes>({
                          date,
                          props,
                          fieldName: 'writeOffDate',
                        })
                      }
                      isRequired={false}
                      isDeleteSelectedDate={openModal}
                      minDate={moment(selectedLoan?.disbursementDate).toDate()}
                      maxDate={moment(selectedLoan?.maturity).toDate()}
                    />
                  </div>
                </div>
                <GroupButton
                  className="w-full gap-2 justify-center pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default WriteOffModal;
