import useUser from 'hooks/useUser';

const LogoutPage = () => {
  const { handleLogout } = useUser();

  const handleLogoutUser = () => {
    handleLogout();
  };

  handleLogoutUser();
  return null;
};

export default LogoutPage;
