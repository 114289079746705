import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    setTimeout(loginWithRedirect, 0);
  }, [loginWithRedirect]);

  return null;
};

export default LoginPage;
