import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadDonationInfoBySupporterId, getDonationBySupporter, removeDonation } from 'api/supporter/donation';
import DateInput from 'components/input/dateInput/DateInput';
import RemoveDonationModal from 'components/modal/common/removeModal';
import AddDonationModal from 'components/modal/donation/addDonationModal';
import PaginateTable from 'components/table/paginate';
import moment from 'moment';
import { ABILITY_ACTION, EXPORT_FILE_NAME, FORMAT_DATE_API, FORMAT_DATE_PICKER, PERMISSION, ROUTE_PATHS } from 'utils/constants';
import { DonationType, FilterPaymentsValueType, GetDonationBySupporterFilter } from 'utils/proptypes';
import { exportRowDataToCSV } from 'utils/utils';

import RenderDonationsTableData from './components/RenderDonationsTableData';

type DonationPageProps = {
  ability?: any;
  fetchSupporterById?: Function;
  isAdmin: boolean;
};

const DonationPage = ({ ability, fetchSupporterById, isAdmin }: DonationPageProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const supporterId = params.id ?? '';
  const [t] = useTranslation()
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDonation, setSelectedDonation] = useState<DonationType>();
  const [filterDonationBySupproter, setFilterDonationBySupproter] = useState<GetDonationBySupporterFilter>({
    f_startDate: '',
    f_endDate: '',
    supporterId,
  });

  const openAddModalHandler = ({ isEditClick = false, currentDonation }: { isEditClick?: boolean; currentDonation?: DonationType }) => {
    setOpenAddModal(!openAddModal);
    if (isEditClick) {
      navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${supporterId}?type=donation&donationId=${selectedDonation?.id}`, { replace: true });
      setIsEdit(isEditClick);
      setSelectedDonation(currentDonation);
    }
  };

  const {
    data: donationsData,
    refetch: fetchSupporterDonation,
    isFetching: isFetchingDonations,
  } = useQuery(['supporterDonation', filterDonationBySupproter], () => getDonationBySupporter(supporterId, filterDonationBySupproter));

  const fetchWhenUpdateDonation = () => {
    fetchSupporterDonation();
    fetchSupporterById && fetchSupporterById(supporterId);
  };

  const donations: Partial<DonationType>[] = donationsData?.data?.entities ? donationsData?.data?.entities : [];

  const exportSupproterDonations = async () => {
    if (supporterId) {
      downloadDonationInfoBySupporterId(supporterId, filterDonationBySupproter).then(res => {
        if (res.status === 200) {
          const { f_startDate, f_endDate } = filterDonationBySupproter;
          exportRowDataToCSV({
            stream: res.data,
            fileName: `${f_startDate ? `${moment(f_startDate).format(FORMAT_DATE_API)}` : ''}${f_endDate ? `-${moment(f_endDate).format(FORMAT_DATE_API)}_` : ''
              }${EXPORT_FILE_NAME.SUPPORTER_DONATION}`,
          });
        }
      });
    }
  };

  const handleDeleteDonation = async (donationId: string) => {
    await removeDonation(donationId);
  };

  const mutation = useMutation('delete-investment-donation', {
    mutationFn: handleDeleteDonation,
  });

  return (
    <div className="donation w-full flex-1 flex flex-col mt-3">
      <div className="donation__header flex justify-between items-center">
        <h3 className="text-gray-500 uppercase">{t('donations')}</h3>
        <div className="flex justify-between pt-3 pb-3">
          <span className="investment__dateInput mr-4">
            <DateInput
              dateFormat={FORMAT_DATE_PICKER}
              isSelectRange={true}
              isFilter={true}
              selectItemsHandler={(startDate: Date, setStartDate: Function, endDate: Date) => {
                if (setFilterDonationBySupproter) {
                  setStartDate(startDate);
                  setFilterDonationBySupproter((state: FilterPaymentsValueType) => ({
                    ...state,
                    f_startDate: startDate ? moment(startDate).format(FORMAT_DATE_API) : '',
                    f_endDate: startDate ? moment(endDate).format(FORMAT_DATE_API) : '',
                  }));
                }
              }}
              placeHolder={t('dateRange')}
              isClearable={true}
            />
          </span>
          <span>
            {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.DONATION.CREATE) && (
              <button onClick={() => openAddModalHandler({})} className="cursor-pointer p-1 text-white bg-blue-700 rounded-lg">
                <HiPlus className="h-4 w-4" />
              </button>
            )}
          </span>
        </div>
      </div>
      <div className="donation__body h-full overflow-y-auto whitespace-nowrap">
        <RenderDonationsTableData
          donations={donations}
          openAddModalHandler={openAddModalHandler}
          setIsOpenRemoveModal={setIsOpenRemoveModal}
          isOpenRemoveModal={isOpenRemoveModal}
          setSelectedDonation={setSelectedDonation}
          isFetching={isFetchingDonations}
          isAdmin={isAdmin}
        />
        {donations.length > 0 && (
          <PaginateTable
            className="investment__pagination"
            totalEntities={donations.length ?? 0}
            exportHandler={exportSupproterDonations}
            hidePaginate={true}
          />
        )}
      </div>
      <AddDonationModal
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        refetchApi={fetchWhenUpdateDonation}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        selectedDonation={selectedDonation}
        supporterId={supporterId as string}
      />
      <RemoveDonationModal
        title={t('donation')}
        openModal={isOpenRemoveModal}
        setOpenModal={setIsOpenRemoveModal}
        refetchApi={fetchWhenUpdateDonation}
        successDeleteMessage="donationPage.deleteSuccessMessage"
        mutation={mutation}
        selectItemId={selectedDonation?.id as string}
      />
    </div>
  );
};

export default DonationPage;
