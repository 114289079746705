import classNames from 'classnames';
import { Label, Textarea } from 'flowbite-react';
import { ErrorMessage, Field, FormikProps } from 'formik';

type TextAreaFromType = {
  id: string;
  placeholder: string;
  name: string;
  propsFormik: FormikProps<any>;
  isDisabled?: boolean;
  isRequired?: boolean;
  rowTotal?: number;
  isOtherBgLabel?: boolean;
  dataTestId?: string;
};
const TextAreaForm = ({
  id,
  placeholder,
  name,
  propsFormik,
  isDisabled = false,
  isRequired = false,
  rowTotal = 4,
  isOtherBgLabel = false,
  dataTestId,
}: TextAreaFromType) => {
  const borderError = 'border-red-500 focus:border-red-500 dark:border-red-600 dark:focus:border-red-500 focus:ring-red-500 dark:focus:ring-red-500';
  const borderSuccess = 'border-gray-200 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500';
  return (
    <div className="text-form relative w-full">
      <Field
        id={id}
        name={name}
        className={`${
          propsFormik.errors[name] && propsFormik.touched[name] ? borderError : borderSuccess
        } border block w-full disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 p-2.5 text-sm rounded`}
      >
        {({ field }: any) => (
          <Textarea
            {...field}
            rows={rowTotal}
            className={`${
              propsFormik.errors[name] && propsFormik.touched[name] ? borderError : borderSuccess
            } border rounded block w-full disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 py-4 text-sm`}
            disabled={isDisabled}
            data-testid={dataTestId}
          />
        )}
      </Field>
      <div
        className={classNames('text-form__label absolute block bg-white rounded', {
          otherBackGroundColor: isOtherBgLabel,
          disabled: isDisabled,
          haveValue: propsFormik?.values[name],
        })}
      >
        <Label value={placeholder} />
        {isRequired && !isDisabled && <span className="text-red-500 text-sm"> *</span>}
      </div>
      {propsFormik.errors[name] && propsFormik.touched[name] && <ErrorMessage className="text-red-500 text-xs mt-1" name={name} component="p" />}
    </div>
  );
};

export default TextAreaForm;
