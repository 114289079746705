import { useEffect, useRef, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import { GroupBase, MenuListProps, MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import { Label } from 'flowbite-react';
import { ErrorMessage, FormikProps } from 'formik';
import { debounce } from 'lodash';
import { OptionType } from 'utils/proptypes';

import { DEFAULT_VALUE_FILTER } from '../../../utils/constants';

import CustomMenuList from './components/customMenuList';

type CustomAsyncSelectFormType = {
  label: string;
  id: string;
  name: string;
  placeHolder: string;
  selectItemsHandler: Function;
  propsFormik: FormikProps<any>;
  isMulti: boolean;
  isDisabled?: boolean;
  isEdit?: boolean;
  isRequired?: boolean;
  dataTestId?: string;
  isSelectAll?: boolean;
  fetchApi: Function;
  isResetAll: boolean;
  setSearchQuery: Function;
  setCurrentPage: Function;
  setPartnerOptions: Function;
  searchQuery: string;
  currentPage: number;
  isFetching: boolean;
  partnerOptions: OptionType[];
};

const CustomAsyncSelectForm = ({
  name,
  placeHolder,
  selectItemsHandler,
  propsFormik,
  isMulti = false,
  isDisabled = false,
  isRequired = false,
  dataTestId,
  isResetAll = false,
  setSearchQuery,
  setCurrentPage,
  searchQuery = '',
  currentPage = 0,
  isFetching = false,
  setPartnerOptions,
  partnerOptions = [],
}: CustomAsyncSelectFormType) => {
  const ref: any = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleSearchOption = async (inputValue: string) => {
    if (inputValue) {
      setSearchQuery(inputValue);
    } else {
      setSearchQuery(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
    }
  };

  const promiseOptions = debounce(async ({ inputValue }) => {
    await new Promise(resolve => {
      resolve(handleSearchOption(inputValue));
    });
  }, 1000);

  useEffect(() => {
    if (isResetAll)
      return () => {
        setSearchQuery(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
        setCurrentPage(DEFAULT_VALUE_FILTER.PAGE);
        setIsFocused(false);
      };
  }, [isResetAll, setCurrentPage, setPartnerOptions, setSearchQuery]);

  const onClickOutsideHandler = async (e: any) => {
    const { target } = e;
    if (ref?.current && !ref?.current.contains(target)) {
      setIsFocused(false);
      setSearchQuery(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutsideHandler, false);
    return () => {
      document.removeEventListener('click', onClickOutsideHandler, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames('custom-async-select relative w-full', {
        disabled: isDisabled,
        haveValue: propsFormik?.values[name].length,
      })}
      ref={ref}
    >
      <AsyncSelect
        className={classNames('async-select', {
          error: propsFormik.errors[name] && propsFormik.touched[name],
          disabled: isDisabled,
          'single-select': !isMulti,
        })}
        cacheOptions
        isSearchable={false}
        isMulti={isMulti}
        isDisabled={isDisabled}
        menuIsOpen={isFocused}
        onMenuOpen={() => setIsFocused(!isFocused)}
        value={propsFormik?.values[name] as any[]}
        components={{
          MenuList: (props: MenuListProps<any, boolean, GroupBase<any>>) => (
            <CustomMenuList
              {...props}
              partnerOptions={partnerOptions}
              setPartnerOptions={setPartnerOptions}
              isFocused={isFocused}
              setIsFocused={setIsFocused}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isFetching={isFetching}
            />
          ),
        }}
        onInputChange={val => {
          setSearchQuery(val);
        }}
        onChange={(items: MultiValue<OptionType>) => {
          if (Array.isArray(items)) selectItemsHandler(items, propsFormik);
        }}
        loadOptions={(inputValue, callback) => {
          promiseOptions({
            inputValue,
            callback,
          });
        }}
      />
      <div
        className={classNames('custom-async-select__label absolute block bg-white', {
          disabled: isDisabled,
          haveValue: propsFormik?.values[name].length,
        })}
      >
        <Label value={placeHolder} />
        {isRequired && !isDisabled && <span className="text-red-500 text-xs"> *</span>}
      </div>
      <RiArrowDownSLine
        className="open-menu-icon absolute right-3 top-4"
        onClick={() => {
          setIsFocused(!isFocused);
          setSearchQuery(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
        }}
      />
      {propsFormik.errors[name] && propsFormik.touched[name] && (
        <ErrorMessage data-testid={dataTestId} className="text-red-500 text-xs mt-1" name={name} component="p" />
      )}
    </div>
  );
};

export default CustomAsyncSelectForm;
