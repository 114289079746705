import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_API, ROUTE_PATHS } from 'utils/constants';
import { LoanType, OptionType, PaymentType } from 'utils/proptypes';
import { getNumberValueSendToApi, messageErrors, selectDateHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import {
  AddSchedulePaymentType,
  createSchedulePayment,
} from '../../../api/loanFundManager/schedulePaymentApi';

import './addScheduleModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddScheduleModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit?: boolean;
  setIsEdit?: Function;
  selectedPayment?: PaymentType;
  selectedLoan?: LoanType;
  refetchApi: Function;
  fetchLoanById?: Function;
  reLinkUrl?: string;
  paymentsSchedule?: PaymentType[];
};

export type addPaymentFormType = {
  loanId: string;
  name: string;
  principalAmount: number;
  interestAmount: number;
  paymentReceivedAmount: number;
  paymentDate: string;
  paymentReceived: string;
  status: OptionType[];
};

const AddScheduleModal = (props: AddScheduleModalTypes) => {
  const {
    openModal = false,
    setOpenModal,
    refetchApi,
    fetchLoanById,
    selectedLoan,
    isEdit = false,
    setIsEdit,
    reLinkUrl,
    paymentsSchedule,
  } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const loanId = params.id;
  const partnerId = params.partnerId;

  const ValidateSchema = Yup.object().shape({
    principalAmount: Yup.number()
      .min(-1000000000, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    paymentDate: Yup.date().required(t('paymentPage.requiredField')),
  });

  const receivedPayments: PaymentType[] = useMemo(() => {
    return paymentsSchedule?.filter((payment: PaymentType) => payment.name && (payment.principalAmount || payment.interestAmount)) as PaymentType[];
  }, [paymentsSchedule]);

  const receivedPaymentQuantity: number = useMemo(() => {
    return receivedPayments.length;
  }, [receivedPayments]);

  const initialValues: any = {
    principalAmount: undefined,
    paymentDate: '',
    isResetForm,
  }

  const handleAddSchedule = async (paymentPayload: AddSchedulePaymentType) => {
    await createSchedulePayment(paymentPayload);
  };

  const mutation = useMutation('create-schedule', {
    mutationFn: handleAddSchedule,
  });

  const handleSubmit = (
    data: addPaymentFormType,
    action: {
      [key: string]: any;
    },
  ) => {
    const successCallback = (message: string) => {
      setOpenModal(!openModal);
      if (setIsEdit) setIsEdit(false);
      toast.success(message);
      action.resetForm();
      refetchApi();
      if (typeof fetchLoanById === 'function') {
        fetchLoanById();
        reLinkUrl
          ? navigate(reLinkUrl, { replace: true })
          : navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}${ROUTE_PATHS.LOAN_PAGE}/${loanId}`, { replace: true });
      }
    };
    const tempData = {
      loanId: loanId as string,
      principalAmount: getNumberValueSendToApi(data.principalAmount),
      paymentDate: moment(data.paymentDate).format(FORMAT_DATE_API),
    };

    if (openModal) {
      mutation.mutate(
        {
          ...tempData,
        },
        {
          onSuccess: () => {
            const message: string = t(isEdit ? 'paymentPage.editSuccessMessage' : 'paymentPage.createSuccessMessage');
            successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<addPaymentFormType>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
    if (reLinkUrl) {
      navigate(reLinkUrl, { replace: true });
    } else {
      navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}${ROUTE_PATHS.LOAN_PAGE}/${loanId}`, { replace: true });
    }
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-schedule-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        setOpenModal(!openModal);
        if (reLinkUrl) {
          navigate(reLinkUrl, { replace: true });
        } else {
          navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}${ROUTE_PATHS.LOAN_PAGE}/${loanId}`, { replace: true });
        }
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{!isEdit ? t('addLine') : t('editLine')}</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CustomDatePickerForm
                    id="paymentDate"
                    name="paymentDate"
                    label={t('paymentDate')}
                    placeHolder={t('paymentDate')}
                    propsFormik={props}
                    selectItemsHandler={(date: Date) =>
                      selectDateHandler<addPaymentFormType>({
                        date,
                        props,
                        fieldName: 'paymentDate',
                      })
                    }
                    isRequired={true}
                    isEdit={isEdit}
                    isDeleteSelectedDate={openModal}
                    filterDate={(date: Date) => {
                      return (
                        date > moment(moment.utc(selectedLoan?.disbursementDate).format(FORMAT_DATE_API))?.toDate() &&
                        date < moment(moment.utc(selectedLoan?.maturity).format(FORMAT_DATE_API))?.toDate()
                      );
                    }}
                  />
                  <CurrencyForm
                    id="principalAmount"
                    name="principalAmount"
                    label={t('principalToPay')}
                    placeholder={t('principalToPay')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<addPaymentFormType>,
                    ) => {
                      await setCurrencyValue<addPaymentFormType>({ currency, props, isZero: true });
                    }}
                    isEdit={isEdit}
                    isRequired={false}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />

                </div>
                <GroupButton
                  className="w-full gap-2 justify-center pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => {
                        closeModalHandler(props);
                      },
                    },
                    {
                      type:
                        receivedPaymentQuantity + 1 >= (paymentsSchedule?.filter((payment: PaymentType) => !payment.name)?.length as number)
                          ? 'button'
                          : 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      action: () => {
                        if (receivedPaymentQuantity + 1 >= (paymentsSchedule?.filter((payment: PaymentType) => !payment.name)?.length as number)) {
                          const message = t('loanHasBeenPaidOff')
                          toast.warn(message);
                          closeModalHandler(props);
                        }
                      },
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddScheduleModal;
