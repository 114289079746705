import { useTranslation } from 'react-i18next';
const noteLogoPath = require('../../assets/image/svg/note.svg').default || '';

const NoNotes = () => {
  const [t] = useTranslation();
  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <img src={noteLogoPath} alt="logo" />
      <div className="text-gray-900 text-2xl font-normal mt-3">{t('noNotes.title')}</div>
      <div className="text-gray-700 text-lg font-normal">{t('noNotes.subTitle')}</div>
    </div>
  );
};
export default NoNotes;
