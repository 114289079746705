import { useState } from 'react';
import classNames from 'classnames';
import { Label, Textarea } from 'flowbite-react';

import './textAreaInput.scss';

type TextAreaInputType = {
  id: string;
  defaultValue?: string;
  className: string;
  label: string;
  isDisabled?: boolean;
  rowTotal: number;
  isOtherBgLabel?: boolean;
  changeValueHandler: Function;
};

const TextAreaInput = ({
  id,
  defaultValue,
  className,
  label,
  isDisabled = false,
  rowTotal = 3,
  isOtherBgLabel = false,
  changeValueHandler,
}: TextAreaInputType) => {
  const [currentValue, setCurrentValue] = useState<string | null>(null);
  return (
    <div className="text-input relative w-full">
      <Textarea
        id={id}
        rows={rowTotal}
        className={className}
        value={currentValue !== null ? currentValue : defaultValue}
        disabled={isDisabled}
        onChange={e => setCurrentValue(e.target.value)}
        onBlur={e => {
          const { type } = e;
          if (type === 'blur') {
            changeValueHandler({ beneficiaries: currentValue?.trim() });
          }
        }}
      />
      <div
        className={classNames('text-input__label absolute block bg-white rounded leading-none', {
          otherBackGroundColor: isOtherBgLabel,
          disabled: isDisabled,
          haveValue: defaultValue || currentValue,
        })}
      >
        <Label value={label} />
      </div>
    </div>
  );
};

export default TextAreaInput;
