import { useTranslation } from 'react-i18next';
import { Tabs } from 'flowbite-react';
import { t } from 'i18next';

import LoanPayments from './components/loanPayments';
import LoanPaymentsSchedule from './components/loanPaymentSchedules';

import '../../../styles/styles.scss';
import './loanPaymentsPage.scss';

const LoanPaymentPage = () => {
  const [t] = useTranslation()
  return (
    <div className="payments-page flex flex-col">
      <div className="payments-page__body mt-4 flex justify-between items-center">
        <Tabs.Group aria-label="Tabs with underline" className="payments-page__body--tabs gap-0">
          <Tabs.Item title={t('payments')} >
            <LoanPayments />
          </Tabs.Item>
          <Tabs.Item title={t('paymentsSchedule')}>
            <div>
              <LoanPaymentsSchedule />
            </div>
          </Tabs.Item>
        </Tabs.Group>
      </div>
    </div >
  );
};

export default LoanPaymentPage;
