import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteInvestmentById, downloadInvestmentInfo, getInvestmentBySupporter } from 'api/investmentApi';
import DateInput from 'components/input/dateInput/DateInput';
import RemoveModal from 'components/modal/common/removeModal';
import PaginateTable from 'components/table/paginate';
import moment from 'moment';
import { ABILITY_ACTION, EXPORT_FILE_NAME, FORMAT_DATE_API, FORMAT_DATE_PICKER, PERMISSION, ROUTE_PATHS } from 'utils/constants';
import { GetInvestmentBySupporterFilter, InvestmentType } from 'utils/proptypes';
import { exportRowDataToCSV } from 'utils/utils';

import InvestmentModal from '../../../components/modal/investments/investmentModal';

import RenderInvestmentsTableDataProps from './components/RenderInvestmentsTableData';

import './investment.scss';

type InvestmentPageProps = {
  ability?: any;
  fetchSupporterById: Function;
  setFDate: Function;
  isAdmin: boolean;
};

const InvestmentPage = ({ ability, fetchSupporterById, setFDate, isAdmin = false }: InvestmentPageProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const supporterId = params.id ?? '';
  const [t] = useTranslation()
  const [openAddInvestmentModal, setOpenAddInvestmentModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState<InvestmentType>();

  const [filterInvestmentBySupporterValue, setFilterInvestmentBySupporterValue] = useState<GetInvestmentBySupporterFilter>({
    f_date: '',
    supporterId,
  });

  const openInvestmentDetail = (item: InvestmentType) => {
    navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${supporterId}${ROUTE_PATHS.INVESTMENT_PAGE}/${item.id}`);
  };

  const handleAddInvestment = () => {
    setOpenAddInvestmentModal(true);
  };

  const {
    data: investmentsData,
    refetch: fetchSupporterInvestment,
    isFetching: isFetchingInvestment,
  } = useQuery(
    ['supporterInvestment', filterInvestmentBySupporterValue],
    () => getInvestmentBySupporter(supporterId, filterInvestmentBySupporterValue),
    {},
  );

  const investments: Partial<InvestmentType>[] = investmentsData?.data?.entities ? investmentsData?.data?.entities : [];

  const exportSupporterInvestments = async () => {
    if (supporterId) {
      downloadInvestmentInfo(filterInvestmentBySupporterValue).then(res => {
        if (res.status === 200)
          exportRowDataToCSV({
            stream: res.data,
            fileName: `${filterInvestmentBySupporterValue.f_date ? `${filterInvestmentBySupporterValue.f_date}_` : ''}${EXPORT_FILE_NAME.SUPPORTER_INVESTMENT
              }`,
          });
      });
    }
  };

  const fetchWhenUpdateInvestment = () => {
    fetchSupporterInvestment();
    fetchSupporterById && fetchSupporterById(supporterId);
  };

  const handleDeleteInvesment = async (investmentId: string) => {
    await deleteInvestmentById(investmentId);
  };

  const mutation = useMutation('delete-investment-donation', {
    mutationFn: handleDeleteInvesment,
  });

  return (
    <div className="investment w-full flex-1 flex flex-col">
      <div className="investment__header flex justify-between items-center">
        <h3 className="text-gray-500 uppercase">{t('investments')}</h3>
        <div className="flex justify-between pt-3 pb-3">
          <span className="investment__dateInput mr-4">
            <DateInput
              dateFormat={FORMAT_DATE_PICKER}
              isFilter={true}
              selectItemsHandler={(date: Date, setStartDate: Function) => {
                if (setFilterInvestmentBySupporterValue) {
                  setStartDate(date);
                  setFDate(date ? moment(date).format(FORMAT_DATE_API) : '');
                  setFilterInvestmentBySupporterValue((state: GetInvestmentBySupporterFilter) => ({
                    ...state,
                    f_date: date ? moment(date).format(FORMAT_DATE_API) : '',
                  }));
                }
              }}
              isClearable={true}
              placeHolder={t('date')}
            />
          </span>
          <span>
            {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT.CREATE) && (
              <button onClick={handleAddInvestment} className="investment__createButton cursor-pointer px-1 py-1 text-white bg-blue-700 rounded-lg">
                <HiPlus className="h-4 w-4" />
              </button>
            )}
          </span>
        </div>
      </div>
      <div className="investment__body h-full overflow-y-auto whitespace-nowrap">
        <RenderInvestmentsTableDataProps
          investments={investments}
          openInvestmentDetail={openInvestmentDetail}
          isFetching={isFetchingInvestment}
          setSelectedInvestment={setSelectedInvestment}
          isOpenRemoveModal={isOpenRemoveModal}
          setIsOpenRemoveModal={setIsOpenRemoveModal}
          isAdmin={isAdmin}
        />
        {investments.length > 0 && (
          <PaginateTable
            className="investment__pagination"
            totalEntities={investments.length ?? 0}
            exportHandler={exportSupporterInvestments}
            hidePaginate={true}
          />
        )}
      </div>
      <InvestmentModal
        openModal={openAddInvestmentModal}
        setOpenModal={setOpenAddInvestmentModal}
        refetchApi={fetchSupporterInvestment}
        fetchSupporterById={fetchSupporterById}
      />
      <RemoveModal
        title={t('investment')}
        openModal={isOpenRemoveModal}
        setOpenModal={setIsOpenRemoveModal}
        refetchApi={fetchWhenUpdateInvestment}
        successDeleteMessage="investmentPage.deleteSuccessMessage"
        mutation={mutation}
        selectItemId={selectedInvestment?.id as string}
      />
    </div>
  );
};

export default InvestmentPage;
