import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AddPaymentType, createPayment } from 'api/loanFundManager/paymentApi';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_API, PAYMENT_STATUS_OPTIONS } from 'utils/constants';
import { OptionType, PaymentType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, messageErrors, selectDateHandler, selectItemHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import './addPaymentModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddPaymentModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  selectedSchedulePayment: PaymentType;
};

export type addPaymentFormType = {
  loanId: string;
  name: string;
  principalAmount: number;
  interestAmount: number;
  paymentReceivedAmount: number;
  paymentDate: string;
  paymentReceived: string;
  status: OptionType[];
};

const AddPaymentNotGenerateModal = (props: AddPaymentModalTypes) => {
  const { openModal = false, setOpenModal, selectedSchedulePayment } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isResetForm, setIsResetForm] = useState(false);

  const ValidateSchema = Yup.object().shape({
    principalAmount: Yup.number()
      .min(0, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    interestAmount: Yup.number()
      .min(0, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    paymentReceivedAmount: Yup.number()
      .min(0, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .required(t('paymentPage.requiredField'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    paymentDate: Yup.date().required(t('paymentPage.requiredField')),
    paymentReceived: Yup.date().required(t('paymentPage.requiredField')),
    status: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('paymentPage.requiredField')),
        }),
      )
      .min(1, t('paymentPage.requiredField')),
  });

  const initialValues: any = useMemo(
    () => ({
      principalAmount: selectedSchedulePayment?.principalAmount ? getNumberValueFromApi(selectedSchedulePayment?.principalAmount) : undefined,
      interestAmount: selectedSchedulePayment?.interestAmount ? getNumberValueFromApi(selectedSchedulePayment?.interestAmount) : undefined,
      paymentReceivedAmount:
        getNumberValueFromApi(selectedSchedulePayment?.principalAmount) + getNumberValueFromApi(selectedSchedulePayment?.interestAmount),
      paymentDate: selectedSchedulePayment?.paymentDate ? moment(selectedSchedulePayment?.paymentDate).toDate() : '',
      paymentReceived: selectedSchedulePayment?.paymentDate ? moment(selectedSchedulePayment?.paymentDate).toDate() : '',
      status: [],
      isResetForm,
    }),
    [selectedSchedulePayment, isResetForm],
  );

  const handleAddPayment = async (paymentPayload: AddPaymentType) => await createPayment(paymentPayload);

  const mutation = useMutation('create-payment', {
    mutationFn: handleAddPayment,
  });

  const handleSubmit = (
    data: addPaymentFormType,
    action: {
      [key: string]: any;
    },
  ) => {
    const successCallback = (message: string) => {
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
    };

    const tempData = {
      loanId: selectedSchedulePayment?.loan?.id as string,
      principalAmount: getNumberValueSendToApi(data.principalAmount),
      interestAmount: getNumberValueSendToApi(data.interestAmount),
      paymentReceivedAmount: getNumberValueSendToApi(data.paymentReceivedAmount),
      paymentDate: moment(data.paymentDate).format(FORMAT_DATE_API),
      paymentReceived: moment(data.paymentReceived).format(FORMAT_DATE_API),
      status: data.status[0].value as string,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...tempData,
        },
        {
          onSuccess: async () => {
            const message: string = t('paymentPage.createSuccessMessage');
            successCallback(message);
            await queryClient.invalidateQueries(['payments']);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<addPaymentFormType>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-payment-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('addPayment')}</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CurrencyForm
                    id="principalAmount"
                    name="principalAmount"
                    label={t('principalOwed')}
                    placeholder={t('principalOwed')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<addPaymentFormType>,
                    ) => {
                      await setCurrencyValue<addPaymentFormType>({ currency, props });
                    }}
                    isRequired={true}
                    isDisabled={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CurrencyForm
                    id="interestAmount"
                    name="interestAmount"
                    label={t('interestOwed')}
                    placeholder={t('interestOwed')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<addPaymentFormType>,
                    ) => {
                      await setCurrencyValue<addPaymentFormType>({ currency, props });
                    }}
                    isRequired={true}
                    isDisabled={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CurrencyForm
                    id="paymentReceivedAmount"
                    name="paymentReceivedAmount"
                    label={t('amountReceived')}
                    placeholder={t('amountReceived')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<addPaymentFormType>,
                    ) => {
                      await setCurrencyValue<addPaymentFormType>({ currency, props });
                    }}
                    isRequired={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CustomDatePickerForm
                    id="paymentDate"
                    name="paymentDate"
                    label={t('dueDate')}
                    placeHolder={t('dueDate')}
                    propsFormik={props}
                    selectItemsHandler={(date: Date) =>
                      selectDateHandler<addPaymentFormType>({
                        date,
                        props,
                        fieldName: 'paymentDate',
                      })
                    }
                    isDisabled={true}
                    isDeleteSelectedDate={openModal}
                  />
                  <CustomDatePickerForm
                    id="paymentReceived"
                    name="paymentReceived"
                    label={t('paymentReceived')}
                    placeHolder={t('paymentReceived')}
                    propsFormik={props}
                    selectItemsHandler={async (date: Date) => {
                      await selectDateHandler<addPaymentFormType>({
                        date,
                        props,
                        fieldName: 'paymentReceived',
                      });
                    }}
                    isRequired={true}
                    isDeleteSelectedDate={openModal}
                    dataTestId="paymentReceived"
                  />
                  <CustomSelectForm
                    label={t('status')}
                    id="status"
                    name="status"
                    placeHolder={t('status')}
                    options={PAYMENT_STATUS_OPTIONS}
                    selectItemsHandler={async (items: any, props: FormikProps<addPaymentFormType>) => {
                      await selectItemHandler<addPaymentFormType>({ items, props, fieldName: 'status' });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    isMulti={false}
                    disableSearch={true}
                  />
                </div>
                <GroupButton
                  className="w-full gap-2 justify-center pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => {
                        closeModalHandler(props);
                      },
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddPaymentNotGenerateModal;
