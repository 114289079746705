import { useTranslation } from 'react-i18next';
import { HiPencilAlt, HiRefresh, HiX } from 'react-icons/hi';
import { VscCircleLargeFilled } from 'react-icons/vsc';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import { status } from 'utils/constants';
import { UserType } from 'utils/proptypes';
import { concatName, concatRoleDisplayName } from 'utils/utils';

import CustomSpinner from '../../../../components/common/customSpinner';

type renderUsersTableDataType = {
  users: UserType[];
  editUserHandler: Function;
  changeUserStatus: Function;
  isFetching: boolean;
};

const RenderUsersTableData = (props: renderUsersTableDataType) => {
  const [t] = useTranslation();
  const { users, editUserHandler, changeUserStatus, isFetching } = props;
  return (
    <table className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': !users.length })}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="py-3 px-6 max-width-140">
            {t('fullName')}
          </th>
          <th scope="col" className="py-3 px-6 max-width-140 hidden-mobile-tablet">
            {t('email')}
          </th>
          <th scope="col" className="py-3 px-6 max-width-140 hidden-mobile">
            {t('userManagementPage.role')}
          </th>
          <th scope="col" className="py-3 px-6 hidden-mobile">
            {t('status')}
          </th>
          <th scope="col" className="py-3 w-10">
            <p className="sr-only">
              {t('userManagementPage.actions')}
            </p>
          </th>
          <th scope="col" className="py-3 pr-6 w-10">
            <p className="sr-only">{t('userManagementPage.changeStatus')}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {users?.length ? (
          users?.map((item: any, key: number) => {
            return (
              <tr
                key={`${key}-list-user`}
                className={`user-${key === users?.length - 1 ? 'last' : key
                  } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
              >
                <th scope="row" className="py-3 px-6 font-normal text-gray-900 users-management-page--truncate max-width-140 dark:text-white">
                  <TooltipComponent
                    anchorSelect={`user-name-${key}`}
                    content={concatName({
                      firstName: item?.firstName,
                      lastName: item?.lastName,
                    })}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-6 font-normal text-gray-900 users-management-page--truncate max-width-140 dark:text-white hidden-mobile-tablet"
                >
                  <TooltipComponent
                    anchorSelect={`user-email-address-${key}`}
                    content={item?.emailAddress}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-3 px-6 font-normal text-gray-900 users-management-page--truncate max-width-140 dark:text-white hidden-mobile"
                >
                  <TooltipComponent
                    anchorSelect={`user-role-${key}`}
                    content={concatRoleDisplayName(item?.roles, t as any)}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-3 px-6 font-normal text-gray-900 users-management-page--truncate dark:text-white hidden-mobile">
                  <div className="flex items-center">
                    <VscCircleLargeFilled color={item.isActive ? '#A1C93A' : '#e02424'} size={12} />
                    &nbsp;
                    {item.isActive ? t(status.ACTIVE) : t(status.INACTIVE)}
                  </div>
                </th>
                <th scope="row" className="py-3 m-h-full">
                  <button
                    onClick={() => editUserHandler(item)}
                    className="cursor-pointer p-0.5 px-3 text-white bg-blue-700  border-gray-300 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 group flex h-min w-fit items-center justify-center text-center font-medium focus:z-10 rounded-lg"
                  >
                    <HiPencilAlt className="h-4 w-4 lg-mr-1" />
                    <span className="hidden-mobile-tablet">{t('userManagementPage.edit')}</span>
                  </button>
                </th>
                <th scope="row" className="py-3 px-6">
                  {item.isActive ? (
                    <button
                      data-testid="userDeactivate"
                      onClick={() => changeUserStatus(item)}
                      className="cursor-pointer p-0.5 px-3 text-white bg-red-700 border-gray-300 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 group flex h-min w-fit items-center justify-center text-center font-medium focus:z-10 rounded-lg"
                    >
                      <HiX className="h-4 w-4 lg-mr-1" />
                      <span className="hidden-mobile-tablet">{t('userManagementPage.deactivate')}</span>
                    </button>
                  ) : (
                    <button
                      data-testid="userReactivate"
                      onClick={() => changeUserStatus(item)}
                      className="cursor-pointer p-0.5 px-3 text-gray-400 bg-white border border-gray-400 hover:bg-gray-200 hover:text-white focus:ring-4 focus:ring-blue-300 group flex h-min w-fit items-center justify-center text-center font-medium focus:z-10 rounded-lg"
                    >
                      <HiRefresh className="h-4 w-4 lg-mr-1" />
                      <span className="hidden-mobile-tablet">{t('userManagementPage.reactivate')}</span>
                    </button>
                  )}
                </th>
              </tr>
            );
          })
        ) : (
          <tr className="h-full user-no-result text-center">
            <th colSpan={6}>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RenderUsersTableData;
