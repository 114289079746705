import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { HiOutlineCalendar } from 'react-icons/hi';
import classNames from 'classnames';
import { Label } from 'flowbite-react';
import { FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_PICKER } from 'utils/constants';

import 'react-datepicker/dist/react-datepicker.css';

type customDatePickerFormType = {
  label: string;
  id: string;
  name: string;
  placeHolder: string;
  selectItemsHandler: Function;
  propsFormik: FormikProps<any>;
  isDisabled?: boolean;
  isEdit?: boolean;
  isRequired?: boolean;
  defaultValue?: Date;
  termValue?: number;
  position?: string;
  isDeleteSelectedDate: boolean;
  isShowInitial?: boolean;
  setIsShowInitial?: Function;
  isClearable?: boolean;
  filterDate?: any;
  dataTestId?: string;
  yearDropdownItemNumber?: number;
  minDate?: Date;
  maxDate?: Date;
};

const CustomDatePickerForm = ({
  label,
  name,
  placeHolder,
  selectItemsHandler,
  propsFormik,
  isDisabled = false,
  isEdit = false,
  isRequired = false,
  defaultValue,
  termValue,
  position,
  isDeleteSelectedDate = false,
  isShowInitial,
  setIsShowInitial,
  isClearable = true,
  filterDate,
  dataTestId,
  yearDropdownItemNumber = 25,
  minDate,
  maxDate,
}: customDatePickerFormType) => {
  const borderError = 'border-red-500 focus:border-red-500 dark:border-red-600 dark:focus:border-red-500 focus:ring-red-500 dark:focus:ring-red-500';
  const borderSuccess = 'border-gray-200 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500';
  const [selectedDate, setSelectedDate] = useState<Date>();

  const realDefaultValue = useMemo(() => {
    return (termValue || termValue === 0) && defaultValue
      ? moment(defaultValue)
          .add(15 - defaultValue.getDate() > 0 ? termValue : termValue + 1, 'M')
          .add(
            15 -
              moment(defaultValue)
                .add(15 - defaultValue.getDate() > 0 ? termValue : termValue + 1, 'M')
                .toDate()
                .getDate(),
            'd',
          )
          .toDate()
      : defaultValue;
  }, [termValue, defaultValue]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedDate(realDefaultValue);
      selectItemsHandler(realDefaultValue);
    } else if (isShowInitial) {
      setSelectedDate(realDefaultValue);
      selectItemsHandler(realDefaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realDefaultValue]);

  useEffect(() => {
    setSelectedDate(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedDate, isDeleteSelectedDate]);

  const CustomInput = React.forwardRef((props: any, ref) => {
    return (
      <div className={`input-group flex justify-between items-center rounded relative`}>
        <input
          ref={ref as any}
          type="text"
          className={classNames(
            `form-control w-full text-sm text-gray-900 border border-gray-200 py-4 rounded ${
              propsFormik.errors[name] && propsFormik.touched[name] ? borderError : borderSuccess
            }}`,
            {
              haveValue: props.value,
            },
          )}
          onClick={props.onClick}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <div onClick={props.onClick} className="input-group__append absolute right-3 cursor-pointer">
          <span className={classNames('input-group-text', { hidden: isClearable && propsFormik.values[name] })}>
            <HiOutlineCalendar size={18} />
          </span>
        </div>
        <div
          className={classNames('input-group__label absolute block bg-white', {
            disabled: props.disabled,
            haveValue: props.value,
          })}
        >
          <Label value={label} />
          {isRequired && <span className="text-red-500 text-sm"> *</span>}
        </div>
      </div>
    );
  });

  return (
    <div className="date-form w-full" data-testid={dataTestId}>
      <DatePicker
        showYearDropdown
        yearDropdownItemNumber={yearDropdownItemNumber}
        scrollableYearDropdown
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText={!isEdit ? placeHolder : ''}
        selected={selectedDate ? selectedDate : propsFormik.values[name]}
        onChange={(date: any, event: any) => {
          if (event.keyCode === 13 || event.type === 'click') {
            selectItemsHandler(date);
            setSelectedDate(date);
            if (setIsShowInitial) setIsShowInitial(true);
          }
        }}
        wrapperClassName={classNames('date-picker-input', {
          error: propsFormik.errors[name] && propsFormik.touched[name],
          haveValue: selectedDate,
        })}
        clearButtonClassName={classNames('', { 'clear-button-disabled': isDisabled })}
        fixedHeight
        popperClassName="some-custom-class"
        popperPlacement={position === 'top' ? 'top' : 'bottom'}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true,
            },
          },
        ]}
        disabled={isDisabled}
        isClearable={isClearable && propsFormik.values[name]}
        customInput={<CustomInput className={classNames({ error: propsFormik.errors[name] && propsFormik.touched[name] })} />}
        onBlur={propsFormik.handleBlur}
        filterDate={typeof filterDate === 'function' && filterDate}
        minDate={minDate}
        maxDate={maxDate}
      />
      {propsFormik.errors[name] && propsFormik.touched[name] && (
        <p data-testid={'date-picker-error'} className="text-red-500 text-xs mt-1">
          {propsFormik.errors?.[name] as string}
        </p>
      )}
    </div>
  );
};

export default CustomDatePickerForm;
