import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { components, GroupBase, MenuListProps } from 'react-select';
import classNames from 'classnames';
import { SEARCH_PARTNER_VALUE_KEY } from 'utils/constants';
import { OptionType } from 'utils/proptypes';

type CustomMenuListType = {
  partnerOptions: OptionType[];
  setPartnerOptions: Function;
  searchQuery: string;
  setSearchQuery: Function;
  currentPage: number;
  setCurrentPage: Function;
  isFocused: Boolean;
  setIsFocused: Function;
  isFetching: boolean;
};

const CustomMenuList = (props: CustomMenuListType & MenuListProps<any, boolean, GroupBase<any>>) => {
  const {
    selectProps: { onChange, onInputChange },
    partnerOptions,
    setPartnerOptions,
    setSearchQuery,
    setIsFocused,
    isFetching,
    currentPage,
    setCurrentPage,
  } = props;

  const [t] = useTranslation()
  const [searchValue, setSearchValue] = useState(localStorage.getItem(SEARCH_PARTNER_VALUE_KEY) as string);
  const searchPartnerHandler = ({ inputValue = '', onInputChange }: { inputValue: string; onInputChange: Function }) => {
    setCurrentPage(0);
    localStorage.setItem(SEARCH_PARTNER_VALUE_KEY, inputValue);
    onInputChange(inputValue, {
      action: 'input-change',
    });
  };

  const onSelectHandler = (e: any, item: OptionType) => {
    e.preventDefault();
    setSearchQuery('');
    setIsFocused(false);
    let selectedValue: OptionType[] = [];
    selectedValue.push(item);
    onChange(selectedValue, { action: 'select-option', option: [] });
  };

  return (
    <div className="async-select-panel">
      <div className="panel-search">
        <input
          className="panel-search-input"
          type="text"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          value={searchValue}
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              searchPartnerHandler({ inputValue: searchValue, onInputChange });
            }
          }}
          onMouseDown={(e: any) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onTouchEnd={(e: any) => {
            e.stopPropagation();
            e.target.focus();
          }}
        />
        <div className={classNames({ 'loading-indicator': isFetching })} aria-hidden="true">
          <span className="loading-dot-first" />
          <span className="loading-dot-second" />
          <span className="loading-dot-third" />
        </div>
        <button
          type="button"
          className="panel-search-clear-button"
          onClick={e => {
            e.stopPropagation();
            setSearchValue('');
            searchPartnerHandler({ inputValue: '', onInputChange });
          }}
        >
          <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" className="search-clear-icon">
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
      </div>
      <hr className="m-0" />
      <components.MenuList {...props}>
        <div id="scrollableDiv" className="menu-list">
          <InfiniteScroll
            dataLength={5000}
            next={() => {
              setCurrentPage(currentPage + 1);
              setPartnerOptions(partnerOptions);
            }}
            hasMore={true}
            loader={
              <div className={classNames({ 'loading-indicator': isFetching })} aria-hidden="true">
                <span className="loading-dot-first" />
                <span className="loading-dot-second" />
                <span className="loading-dot-third" />
              </div>
            }
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.9}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>{t('haveSeen')}</b>
              </p>
            }
          >
            {partnerOptions.length ? (
              partnerOptions.map((item: OptionType, index: number) => {
                return (
                  <div key={index} className="menu-list-item" onClick={e => onSelectHandler(e, item)}>
                    <span> {item.label}</span>
                  </div>
                );
              })
            ) : (
              <div className="menu-list-item">
                <p className="text-center w-full text-gray-900 text-sm">{t('noOptions')!}</p>
              </div>
            )}
          </InfiniteScroll>
        </div>
      </components.MenuList>
    </div>
  );
};

export default CustomMenuList;
