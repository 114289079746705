import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import HeaderTable from 'components/table/headerTable';
import PaginateTable from 'components/table/paginate';
import useLoading from 'hooks/useLoading';
import { DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME } from 'utils/constants';
import { FilterPaymentsScheduleValueType, PaymentType } from 'utils/proptypes';
import { exportRowDataToCSV, messageErrors } from 'utils/utils';

import { exportLoanPaymentsScheduleToCSV, getPaymentsScheduleByFilter } from '../../../../api/loanFundManager/schedulePaymentApi';
import AddPaymentNotGenerateModal from '../../../../components/modal/payment/addPaymentNotGenerateModal';

import RenderPaymentsScheduleTable from './components/RenderPaymentsScheduleTable';

const LoanPaymentsSchedule = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(DEFAULT_VALUE_FILTER.SEARCH_QUERY);
  const [filterValue, setFilterValue] = useState<FilterPaymentsScheduleValueType>({
    f_startDate: undefined,
    f_endDate: undefined,
  });
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntities, setTotalEntities] = useState(0);
  const [openAddLoanPaymentModal, setOpenAddLoanPaymentModal] = useState(false);
  const limit = DEFAULT_VALUE_FILTER.LIMIT;
  const [paymentsSchedule, setPaymentsSchedule] = useState<PaymentType[]>([]);
  const [selectedSchedulePayment, setSelectedSchedulePayment] = useState<PaymentType>();
  const tbl = useRef(null);
  const ref = useRef(null);

  const { isFetching } = useQuery(
    ['paymentsSchedule', currentPage, searchValue, filterValue],
    () => getPaymentsScheduleByFilter({ page: currentPage, limit, searchQuery: searchValue, ...filterValue }),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.totalEntities);
        setPaymentsSchedule(data.entities);
      },
      onError: () => {
        setPaymentsSchedule([]);
        setTotalEntities(0);
      },
    },
  );

  useLoading({ isLoading: isFetching, ref });

  const handleExportLoanPaymentsSchedule = async () => {
    await exportLoanPaymentsScheduleToCSV({ ...filterValue, searchQuery: searchValue }).then(async res => {
      exportRowDataToCSV({ stream: res.data, fileName: EXPORT_FILE_NAME.PAYMENT_SCHEDULE });
    });
  };

  const { refetch: refetchExportPayments, isFetching: isFetchingDownloadPayments } = useQuery(
    'download-paymentsSchedule',
    handleExportLoanPaymentsSchedule,
    {
      onSuccess: () => {
        const message: string = t('paymentPage.exportToCsvSuccess');
        toast.success(message);
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
      enabled: false,
    },
  );

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="w-full loan-paymentsSchedule">
        <HeaderTable
          setCurrentPage={setCurrentPage}
          placeholderSearch={t('paymentPage.searchPaymentsSchedule')}
          nameButton={t('addPayment')}
          setSearchValue={setSearchValue}
          setFilterValue={setFilterValue}
          exportHandler={refetchExportPayments}
          hideSearch={false}
          hideExportButton={true}
          hideFilter={false}
          hideAddButton={true}
          className="loan-paymentsSchedule"
          abilityObjectTitle="PAYMENT"
          isPaymentSchedule={true}
        />
        <div className="loan-payments__table h-full bg-white">
          <RenderPaymentsScheduleTable
            tbl={tbl}
            payments={paymentsSchedule}
            isFetching={isFetching}
            setSelectedSchedulePayment={setSelectedSchedulePayment}
            openAddLoanPaymentModal={openAddLoanPaymentModal}
            setOpenAddLoanPaymentModal={setOpenAddLoanPaymentModal}
          />
        </div>
        {totalEntities > 0 && (
          <PaginateTable
            className="loan-payments__pagination"
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isFetching={isFetching}
            isFetchingDownload={isFetchingDownloadPayments}
            exportHandler={refetchExportPayments}
            items={paymentsSchedule}
          />
        )}
        <AddPaymentNotGenerateModal
          openModal={openAddLoanPaymentModal}
          setOpenModal={setOpenAddLoanPaymentModal}
          selectedSchedulePayment={selectedSchedulePayment as PaymentType}
        />
      </div>
    </>
  );
};
export default LoanPaymentsSchedule;
