import { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { downloadFinalInvestmentPDF } from 'api/investmentApi';
import { omit } from 'lodash';
import moment from 'moment';
import { ABILITY_ACTION, EXPORT_FILE_NAME, FORMAT_DATE_API, PERMISSION } from 'utils/constants';
import { exportToFile, getNumberValueSendToApi, messageErrors, openStaticFile } from 'utils/utils';
import { read, utils } from 'xlsx';

import {
  deleteInvestmentPaymentById,
  dowloadPaidInvestmentPaymentPDF,
  getInvestmentPaymentsByInvestmentId,
  updateInvestmentPaymentById,
} from '../../../../../../../api/supporter/investmentPayment';
import AddInvestmentPaymentModal from '../../../../../../../components/modal/investments/addInvestmentPaymentModal';
import PayOffModal from '../../../../../../../components/modal/investments/payOffModal';
import PaginateTable from '../../../../../../../components/table/paginate';
import { AbilityContext } from '../../../../../../../contexts/can';
import useLoading from '../../../../../../../hooks/useLoading';
import { InvestmentPaymentsType, InvestmentType } from '../../../../../../../utils/proptypes';

import RenderInvestmentPaymentsTableSchedule from './components/renderInvestmentPaymentsTableSchedule';

type PaymentScheduleType = {
  isFetchingInvestment: boolean;
  selectedInvestment?: InvestmentType;
};

const InvestmentPaymentSchedule = ({ isFetchingInvestment = false, selectedInvestment }: PaymentScheduleType) => {
  const [t] = useTranslation();
  const params = useParams();
  const ability = useContext(AbilityContext);
  const queryClient = useQueryClient();
  const investmentId = params.id;

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openPayOffModal, setOpenPayOffModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isPayOff, setIsPayOff] = useState(false);
  const [selectedInvestmentPayment, setSelectedInvestmentPayment] = useState<InvestmentPaymentsType>();

  const [__html, setHtml] = useState('');

  const tbl = useRef(null);
  const ref = useRef(null);

  const checkFinalInvestment = () => {
    const paymentsSchedule = paymentsScheduleStore?.data?.entities;
    if (selectedInvestment && paymentsSchedule) {
      let amountReceived = 0;
      let checkAmountReceived = 0;
      paymentsSchedule.forEach((payment: any) => {
        if (payment.name && payment.status === 'Paid') amountReceived += payment.amountReceived;
      });

      paymentsSchedule.forEach((payment: any) => {
        if (!payment.name && payment.status === 'Unpaid') checkAmountReceived += payment.amountReceived;
      });

      return checkAmountReceived === amountReceived;
    } else return false;
  };

  const { data: paymentsScheduleStore, isFetching: isFetchingPaymentsSchedule } = useQuery(
    ['getInvestmentPaymentsSchedule', investmentId],
    () => getInvestmentPaymentsByInvestmentId(investmentId as string),
    {
      onSuccess: ({ data }) => {
        const payOff = data?.entities?.find((entity: InvestmentPaymentsType) => entity.payOffDate);
        setIsPayOff(!!payOff);
        const f = new ArrayBuffer(data.entities);
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const dataPayment = utils.sheet_to_html(ws);
        setHtml(dataPayment);
      },
    },
  );

  const payOffHandler = () => {
    setOpenPayOffModal(!openPayOffModal);
  };

  const addInvestmentPaymentHandler = () => {
    setOpenAddModal(!openAddModal);
  };

  const updateInvestmentSchedulePayment = async (investmentSchedulePaymentPayload: { id: string; dueDate: string }) => {
    await updateInvestmentPaymentById(investmentSchedulePaymentPayload?.id as string, omit(investmentSchedulePaymentPayload, 'id'));
  };

  const mutationUpdate = useMutation('update-investment-schedule-payment', {
    mutationFn: updateInvestmentSchedulePayment,
  });

  const deleteInvestmentPayment = async (id: string) => {
    await deleteInvestmentPaymentById(id);
  };

  const mutationDeleteInvestmentPayment = useMutation('delete-investment-payment', {
    mutationFn: deleteInvestmentPayment,
  });

  const successCallback = async (message: string) => {
    toast.success(message);
    await queryClient.invalidateQueries(['getInvestmentPaymentsSchedule']);
  };
  const handleUpdateInvestmentPayment = ({ id, dueDate, amountReceived }: { id: string; dueDate?: Date; amountReceived?: number }) => {
    const tempData = {
      id,
      dueDate: dueDate ? moment(dueDate).format(FORMAT_DATE_API) : undefined,
      amountReceived: amountReceived ? getNumberValueSendToApi(amountReceived) : undefined,
    };
    mutationUpdate.mutate(
      {
        ...(tempData as any),
      },
      {
        onSuccess: async () => {
          const message: string = t('paymentPage.editSuccessMessage');
          await successCallback(message);
        },
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const handleDeleteInvestmentPayment = (id: string) => {
    mutationDeleteInvestmentPayment.mutate(id, {
      onSuccess: async () => {
        const message: string = t('paymentPage.deleteSuccessMessage');
        await successCallback(message);
      },
      onError: async (error: any) => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const exportPaymentHandler = useCallback(() => {
    exportToFile(tbl, EXPORT_FILE_NAME.PAYMENT);
  }, [tbl]);

  useLoading({ isLoading: isFetchingPaymentsSchedule || isFetchingInvestment, ref });

  const handleDownloadCashOutInvestmentPaymentPDF = async (investmentPaymentById: string) => {
    const response = await dowloadPaidInvestmentPaymentPDF(investmentPaymentById);
    if (response && response?.data?.download_url !== '') {
      const url: string = response?.data?.download_url;
      openStaticFile(url);
    }
  };

  const handleDownloadFinalInvestmentPDF = async (investmentId: string) => {
    const response = await downloadFinalInvestmentPDF(investmentId);
    if (response && response?.data?.download_url !== '') {
      const url: string = response?.data?.download_url;
      openStaticFile(url);
    }
  };

  const editInvestmentPaymentHandler = (payment: InvestmentPaymentsType) => {
    setOpenAddModal(!openAddModal);
    setIsEdit(true);
    setSelectedInvestmentPayment(payment);
  };

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <RenderInvestmentPaymentsTableSchedule
        investment={selectedInvestment as InvestmentType}
        paymentsSchedule={paymentsScheduleStore?.data?.entities}
        isFetching={isFetchingPaymentsSchedule}
        tbl={tbl}
        editPaymentHandler={handleUpdateInvestmentPayment}
        deletePaymentHandler={handleDeleteInvestmentPayment}
        handleDownloadCashOutInvestmentPaymentPDF={handleDownloadCashOutInvestmentPaymentPDF}
        handleDownloadFinalInvestmentPDF={handleDownloadFinalInvestmentPDF}
        editInvestmentPaymentHandler={editInvestmentPaymentHandler}
      />
      <PaginateTable
        className="investment-payment__pagination"
        totalEntities={paymentsScheduleStore?.data?.totalEntities}
        hidePaginate={true}
        exportHandler={exportPaymentHandler}
      />
      {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.CREATE) && !isPayOff && !checkFinalInvestment() && (
        <button
          onClick={() => payOffHandler()}
          className="pay-off-button cursor-pointer px-2 py-1 mr-2 text-sm text-white bg-blue-900 rounded-lg absolute right-6"
        >
          {t('payOff')}
        </button>
      )}
      {ability.can(ABILITY_ACTION.manage, PERMISSION.INVESTMENT_PAYMENT.CREATE) && !isPayOff && !checkFinalInvestment() && (
        <button
          onClick={() => addInvestmentPaymentHandler()}
          className="add-icon cursor-pointer px-1 py-1 mr-2 text-white bg-blue-700 rounded-lg absolute right-0"
        >
          <HiPlus className="h-4 w-4" />
        </button>
      )}
      {openPayOffModal && (
        <PayOffModal
          openModal={openPayOffModal}
          setOpenModal={setOpenPayOffModal}
          investmentPayments={paymentsScheduleStore?.data?.entities}
          selectedInvestment={selectedInvestment}
        />
      )}
      <AddInvestmentPaymentModal
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        investmentPayments={paymentsScheduleStore?.data?.entities}
        selectedInvestment={selectedInvestment}
        selectedInvestmentPayment={selectedInvestmentPayment}
        isEdit={isEdit}
      />
    </>
  );
};

export default InvestmentPaymentSchedule;
