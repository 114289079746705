import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClear } from 'react-icons/md';
import classNames from 'classnames';
import DateInput from 'components/input/dateInput/DateInput';
import CustomSelectInput from 'components/input/selectInput/CustomSelectInput';
import { Label } from 'flowbite-react';
import moment from 'moment';
import { FORMAT_DATE_API, FORMAT_DATE_PICKER } from 'utils/constants';
import { FilterType } from 'utils/proptypes';

type HeaderTableSupporterProps = {
  className: string;
  hideFilter: boolean;
  hideSearch: boolean;
  filterArray: FilterType[];
  setCurrentPage: Function;
  setFilterValue: Function;
  setSearchValue: Function;
  placeholderSearch: string;
};

const HeaderTableSupporterType = <FilterNameType,>(props: HeaderTableSupporterProps) => {
  const {
    filterArray = [],
    className,
    hideSearch = true,
    hideFilter = true,
    setFilterValue,
    setSearchValue,
    setCurrentPage,
    placeholderSearch,
  } = props;
  const [value, setValue] = useState('');
  const [t] = useTranslation()
  const handleSearch = (e: any, fetchData: Function) => {
    const { key } = e;
    if (key === 'Enter') {
      if (setCurrentPage) {
        setCurrentPage(0);
      }
      fetchData(value);
    }
  };

  return (
    <div className="bg-gray-100 border-b border-gray-200">
      <h4 className="text-gray-900 font-semibold whitespace-nowrap mr-3 pt-3 px-3 uppercase">{t('supporters')}</h4>
      <div className={`${className}__header flex justify-between items-center p-3`}>
        {!hideSearch && (
          <div className={`relative ${className}__header--search w-1/3`}>
            <MdClear
              className={classNames('header-search-icon absolute top-3 right-2 cursor-pointer', { haveValue: value })}
              size={16.5}
              onClick={() => {
                if (setCurrentPage && setSearchValue) {
                  setCurrentPage(0);
                  setSearchValue('');
                  setValue('');
                }
              }}
            />
            <input
              id="search"
              className={classNames('w-full border-1 border-gray-200 bg-white pl-2.5 pr-8 rounded text-sm focus:outline-none filter-input')}
              type="text"
              name="search"
              value={value}
              onChange={e => setValue(e?.target?.value)}
              onKeyPress={e => handleSearch(e, setSearchValue as Function)}
              placeholder={placeholderSearch}
            />
            <div
              className={classNames('header-search-label absolute block bg-white cursor-pointer rounded-sm leading-none', {
                haveValue: value,
              })}
            >
              <Label value={placeholderSearch} />
            </div>
          </div>
        )}
        {!hideFilter && (
          <div className={`text-gray-600 items-center ${className}__header--filter sm:flex justify-start items-center w-2/3`}>
            {filterArray.length &&
              filterArray.map((item: FilterType, index: number) => {
                return (
                  <div key={`filter-group-${index}`} className={`header-filter-group header-filter-group-${index} w-full`}>
                    {item.type === 'date' && (
                      <DateInput
                        isSelectRange={item.isSelectRangeDate}
                        isFilter={true}
                        selectItemsHandler={(startDate: Date, setStartDate: Function, endDate: Date) => {
                          if (setFilterValue)
                            if (item.isSelectRangeDate) {
                              setFilterValue((state: FilterNameType) => ({
                                ...state,
                                f_startDate: startDate ? moment(startDate).format(FORMAT_DATE_API) : undefined,
                                f_endDate: startDate ? moment(endDate).format(FORMAT_DATE_API) : undefined,
                              }));
                            } else {
                              setFilterValue((state: FilterNameType) => ({
                                ...state,
                                [item.name as string]: startDate ? moment(startDate).format(FORMAT_DATE_API) : item.defaultValue,
                              }));
                            }
                        }}
                        defaultValue={item.defaultValue}
                        placeHolder={item.placeholder}
                        isClearable={true}
                        dateFormat={FORMAT_DATE_PICKER}
                      />
                    )}
                    {item.type === 'select' && (
                      <CustomSelectInput
                        options={item.options || []}
                        selectItemsHandler={(selectValue: string | number) => {
                          if (setFilterValue)
                            setFilterValue((state: FilterNameType) => ({
                              ...state,
                              [item.name as string]: selectValue ? selectValue : undefined,
                            }));
                          setCurrentPage(0);
                        }}
                        placeHolder={item.placeholder}
                        isFilter={true}
                        disableSearch={true}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
export default HeaderTableSupporterType;
