import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import { Badge } from 'flowbite-react';
import moment from 'moment';
import { FORMAT_DATE_SHOW_TABLE, PAYMENT_STATUS } from 'utils/constants';
import { PaymentType } from 'utils/proptypes';
import { convertNumberToCurrency, getColorStatus, getNumberValueFromApi } from 'utils/utils';

import CustomSpinner from '../../../../../../components/common/customSpinner';

type RenderPaymentsTableScheduleDataPdfType = {
  paymentsSchedule: PaymentType[];
  isFetching: boolean;
  totalDisplay?: {
    totalDaysSinceLastTransaction: number;
    totalInterestAmount: number;
    totalOfTotalToPay: number;
    totalPrincipalAmount: number;
  };
};

const RenderPaymentsTableScheduleDataPdf = (props: RenderPaymentsTableScheduleDataPdfType) => {

  const { paymentsSchedule = [], isFetching = false } = props;
  const [t] = useTranslation()
  return (
    <table
      className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': !paymentsSchedule.length })}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 rounded">
        <tr>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate">
            {t('paymentDate')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile-tablet">
            {t('principalToPay')}

          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile">
            {t('daysSinceLastTrans')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile-tablet">
            {t('interestToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile-tablet">
            {t('totalToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile">
            {t('balance')}
          </th>
        </tr>
      </thead>
      <tbody className="text-xs">
        {paymentsSchedule?.length > 0 ? (
          paymentsSchedule?.map((payment: PaymentType, key: number) => {
            if (payment?.paymentReceived) {
              return null;
            }
            return (
              payment.status !== PAYMENT_STATUS.UN_PAID && (
                <tr
                  key={`${key}-list-payment`}
                  className={classNames(
                    `payment-${key === paymentsSchedule?.length - 1 ? 'last' : key
                    } border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`,
                    { 'bg-gray-100': !!payment.name },
                  )}
                >
                  {payment.paymentReceived || !payment.daysSinceLastTransaction ? (
                    <td className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108">
                      {
                        payment.paymentReceived
                          ? `${t('received')}: ${moment.utc(payment?.paymentReceived).format(FORMAT_DATE_SHOW_TABLE)}`
                          : moment.utc(payment.paymentDate).format(FORMAT_DATE_SHOW_TABLE)
                      }
                    </td>
                  ) : (
                    <td className="py-1 px-4 font-normal text-gray-900">
                      {payment?.paymentDate ? moment.utc(payment?.paymentDate).format(FORMAT_DATE_SHOW_TABLE) : undefined}
                    </td>
                  )}
                  {payment.name || !payment.daysSinceLastTransaction || !payment.principalAmount ? (
                    <td

                      className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile-tablet"
                    >
                      {payment.principalAmount && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.principalAmount)) : ''}
                    </td>
                  ) : (
                    <td className="py-1 px-4 font-normal text-gray-900 hidden-mobile-tablet">
                      {payment.principalAmount ? getNumberValueFromApi(payment.principalAmount) : undefined}
                    </td>
                  )}
                  <td
                    className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile">
                    {payment.daysSinceLastTransaction && !payment.status
                      ? `${payment.daysSinceLastTransaction} ${t('days')}`
                      : payment.status && (
                        <Badge className={`${getColorStatus(payment.status)} h-6 text-white font-normal p-2`}
                          style={{ width: 'fit-content' }}>
                          {payment.status}
                        </Badge>
                      )}
                  </td>
                  <td

                    className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile-tablet"
                  >
                    {payment?.interestAmount && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.interestAmount)) : ''}
                  </td>
                  <td className="py-1 px-4 font-normal text-gray-900 hidden-mobile-tablet">
                    {payment.name ? (
                      payment.paymentReceivedAmount ? getNumberValueFromApi(payment.paymentReceivedAmount) : undefined
                    ) : payment.totalToPay || payment.paymentReceivedAmount ? (
                      convertNumberToCurrency(
                        getNumberValueFromApi(
                          payment.paymentReceivedAmount ? (payment.paymentReceivedAmount as number) : (payment.totalToPay as number),
                        ),
                      )
                    ) : (
                      ''
                    )}
                  </td>
                  <td
                    className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile">
                    {payment.balance && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.balance)) : ''}
                  </td>
                </tr>
              )
            );
          })
        ) : (
          <tr className="h-full payment-no-result text-center bg-white">
            <td colSpan={8}>{isFetching ? <CustomSpinner /> : <NoResult />}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default RenderPaymentsTableScheduleDataPdf;
