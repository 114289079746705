import { QueryFilterType } from 'utils/proptypes';

import base from '../baseApi';

export type AddInvestmentPaymentType = {
  investmentId: string;
  amountReceived: number;
  receivedDate: string;
  checkNumber?: string;
};

export type PayOffType = {
  investmentId: string;
  amountReceived: number;
  receivedDate: string;
  checkNumber?: string;
};

export type UpdateInvestmentPaymentType = {
  dueDate: string;
};

export type ExportUpcomingInvestmentPaymentType = {
  fStartDate: Date;
  fEndDate: Date;
};

export type DownloadInvestmentPaymentType = {
  CurrentDate: string;
  FirstName: string;
  LastName: string;
  Salutation: string;
  Organization: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  RolloverAmount: string;
  InterestRate: string;
  OutstandingPrincipal: string;
};

export type DownloadInvestmentPaymentWithDonationType = {
  CurrentDate: string;
  FirstName: string;
  LastName: string;
  Salutation: string;
  Organization: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  DonationAmount: string;
  LoanAmount: string;
  InterestRate: string;
};

export type DownloadInvestmentWithoutInterestType = {
  CurrentDate: string;
  FirstName: string;
  LastName: string;
  Salutation: string;
  Organization: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  InvestmentNumber: string;
};

const { urls, methods, execute } = base();

const createInvestmentPayment = async (payload: AddInvestmentPaymentType) => {
  const method = methods.POST;
  const url = urls.investmentPayment.investmentPayments;
  return execute(method, url, { authorization: true }, payload);
};

const getInvestmentPayments = async (queryFilter: Partial<QueryFilterType>) => {
  const method = methods.GET;
  const url = `${urls.investmentPayment.investmentPayments}?fPaymentDate=${queryFilter?.fPaymentDate}`;
  return execute(method, url, { authorization: true });
};

const getInvestmentPaymentsByInvestmentId = async (investmentId: string) => {
  const method = methods.GET;
  const url = `${urls.investmentPayment.paymentsByInvestmentId}/${investmentId}`;
  return execute(method, url, { authorization: true });
};

const updateInvestmentPaymentById = async (id: string, payload: UpdateInvestmentPaymentType) => {
  const method = methods.PATCH;
  const url = `${urls.investmentPayment.investmentPayments}/${id}/updatePaidPayment`;
  return execute(method, url, { authorization: true }, payload);
};

const deleteInvestmentPaymentById = async (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.investmentPayment.investmentPayments}/${id}`;
  return execute(method, url, { authorization: true });
};

const exportUpcomingInvestmentPayments = async (payload: ExportUpcomingInvestmentPaymentType) => {
  const method = methods.POST;
  const url = urls.investmentPayment.export;
  return execute(method, url, { authorization: true }, { ...payload });
};

const dowloadPaidInvestmentPaymentPDF = async (investmentPaymentId: string) => {
  const method = methods.GET;
  const url = `${urls.investmentPayment.investmentPayments}/${investmentPaymentId}/dowloadPaidCashOutInvestmentPaymentPDF`;
  return execute(method, url, { authorization: true });
};
const downloadInvestmentPayments = async (payload: DownloadInvestmentPaymentType) => {
  const method = methods.POST;
  const url = `${process.env.REACT_APP_BASE_API_TEMPLATE_IO_URL}/${urls.investmentPayment.downloadPdf}?template_id=${process.env.REACT_APP_TEMPLATE_INVESTMENT_PAYMENT_ID}`;
  return execute(method, url, { xApiKey: process.env.REACT_APP_REACT_TEMPLATE_X_API_KEY }, { ...payload });
};

const downloadInvestmentPaymentsWithDonation = async (payload: DownloadInvestmentPaymentWithDonationType) => {
  const method = methods.POST;
  const url = `${process.env.REACT_APP_BASE_API_TEMPLATE_IO_URL}/${urls.investmentPayment.downloadPdf}?template_id=${process.env.REACT_APP_TEMPLATE_INVESTMENT_PAYMENT_WITH_DONATION_ID}`;
  return execute(method, url, { xApiKey: process.env.REACT_APP_REACT_TEMPLATE_X_API_KEY }, { ...payload });
};

const downloadInvestmentWithoutInterest = async (payload: DownloadInvestmentWithoutInterestType) => {
  const method = methods.POST;
  const url = `${process.env.REACT_APP_BASE_API_TEMPLATE_IO_URL}/${urls.investmentPayment.downloadPdf}?template_id=${process.env.REACT_APP_TEMPLATE_INVESTMENT_PAYMENT_WITHOUT_INTEREST_ID}`;
  return execute(method, url, { xApiKey: process.env.REACT_APP_REACT_TEMPLATE_X_API_KEY }, { ...payload });
};

export {
  createInvestmentPayment,
  getInvestmentPayments,
  updateInvestmentPaymentById,
  deleteInvestmentPaymentById,
  exportUpcomingInvestmentPayments,
  getInvestmentPaymentsByInvestmentId,
  dowloadPaidInvestmentPaymentPDF,
  downloadInvestmentPayments,
  downloadInvestmentPaymentsWithDonation,
  downloadInvestmentWithoutInterest,
};
