import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUserById, updateUserImage } from 'api/userApi';
import GroupButton from 'components/button/groupButton';
import FileUpload from 'components/common/fileUpload';
import FormComponent from 'components/form/form';
import { Form, Formik } from 'formik';
import useUser from 'hooks/useUser';
import omit from 'lodash/omit';
import { userSelector } from 'redux/selectors';
import { IMAGE_TYPE_REGEX, ROUTE_PATHS, USER_ROLES } from 'utils/constants';
import validator from 'validator';

import ChangePasswordModal from '../components/modal/ChangePasswordModal';
import { messageErrors } from '../utils/utils';

import '../styles/styles.scss';
const avatarDefault = require('../assets/image/png/avatar-default.png');

type updateProfileProps = {
  isNavigatedFromDashboard?: boolean;
};
const UpdateProfile = (props: updateProfileProps) => {
  const { isNavigatedFromDashboard = false } = props;
  const [t] = useTranslation();
  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);
  const navigate = useNavigate();
  const [file, setFile] = useState<any>();
  const [imgUrl, setImgUrl] = useState(userInfo?.image?.url || '');
  const [imgSizeError, setImgSizeError] = useState(false);
  const [imgTypeError, setImgTypeError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleRemoveImage = () => {
    setFile('');
    setImgUrl('');
  };

  const changePasswordHandler = () => {
    setOpenModal(true);
  };

  const handleUploadImage = async (e: any) => {
    const currentFile = e.target.files[0];

    if (currentFile) {
      const currentFileName = currentFile.name
        ?.split('.')
        .map((item: string) => item.toLowerCase())
        .join('.');
      if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
        setImgTypeError(true);
        setImgSizeError(true);
        return;
      } else {
        if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
          setImgTypeError(true);
          setImgSizeError(false);
          return;
        }
        if (currentFile.size > 8000000) {
          setImgSizeError(true);
          setImgTypeError(false);
          return;
        }
      }
      setImgUrl(URL.createObjectURL(currentFile));
      currentFile.isUploaded = true;
      setFile(currentFile);
      setImgSizeError(false);
      setImgTypeError(false);
    }
  };

  const handleUpdateUser = async (values: any) => {
    const response = await fetch(avatarDefault);
    const avatarDefaultBlob = await response.blob();
    const fileDefault = new File([avatarDefaultBlob], 'default', {
      type: avatarDefaultBlob.type || 'image/jpeg',
    });

    const currentFileName = file?.name
      ?.split('.')
      .map((item: string) => item.toLowerCase())
      .join('.');
    const formData = new FormData();
    formData.append('file', file ? file : fileDefault, currentFileName ? currentFileName : 'default.png');
    const { data } = await updateUserImage({ id: userInfo.id, file: formData });
    setImgUrl(data.image.url);

    await updateUserById(userInfo.id, omit({ ...values, updatedProfile: true }, ['organizations', 'roles']));
  };

  const handleClickSkip = async () => {
    isNavigatedFromDashboard && navigate(ROUTE_PATHS.PARTNERS_PAGE);
    const roles: string[] = userInfo?.roles?.map((role: { [key: string]: any }) => role.displayName);
    switch (true) {
      case roles.includes(USER_ROLES.LOAN_FUND_MANAGER):
        navigate(ROUTE_PATHS.PARTNERS_PAGE);
        break;
      case roles.includes(USER_ROLES.ACCOUNTANT):
        navigate(ROUTE_PATHS.PAYMENT_PAGE);
        break;
      case roles.includes(USER_ROLES.SUPPORTER_MANAGER):
        navigate(ROUTE_PATHS.SUPPORTER_MANAGER_PAGE);
        break;
      default:
        navigate(ROUTE_PATHS.PARTNERS_PAGE);
    }
  };

  const mutationUpdateUser = useMutation('update-user', {
    mutationFn: handleUpdateUser,
    onSuccess: async () => {
      await fetchCurrentUser();
      const message: string = t('user.updateSuccess');
      toast.success(message);
      isNavigatedFromDashboard && navigate('/');
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleSubmit = (values: any) => {
    mutationUpdateUser.mutate(values);
  };

  const onValidate = (values: any) => {
    const errors: any = {};
    if (!values.firstName) errors.firstName = t('requiredField');
    if (!values.lastName) errors.lastName = t('requiredField');
    if (!values.emailAddress) errors.emailAddress = t('requiredField');
    if (!validator.isEmail(values.emailAddress)) errors.emailAddress = t('invalidEmail');

    return errors;
  };

  const renderUserForm = (isNavigatedFromDashboard: boolean) => {
    return (
      <div className="flex flex-col items-center shadow-sm bg-white p-6 rounded-lg justify-center">
        <FileUpload handleUploadImage={handleUploadImage} imgUrl={imgUrl} handleRemoveImage={handleRemoveImage} />
        {imgSizeError && (
          <div className="text-red-600 text-xs font-normal text-center mb-4 veri-modal height-16">{t('userProfilePage.imageSizeError')}</div>
        )}
        {imgTypeError && (
          <div className="text-red-600 text-xs text-center font-normal mb-4 veri-modal height-16">{t('userProfilePage.imageTypeError')}</div>
        )}
        <Formik enableReinitialize onSubmit={handleSubmit} initialValues={userInfo || {}} validate={onValidate} validateOnChange={true}>
          <Form className="grid gap-4 lg:grid-cols-2 grid-rows-2 w-full sm:grid-cols-1">
            <FormComponent label={t('userProfilePage.firstName')} id="firstName" type="text" placeholder="First name" name="firstName" />
            <FormComponent label={t('userProfilePage.lastName')} id="lastName" type="text" placeholder="Last name" name="lastName" />
            <FormComponent
              label={t('userProfilePage.email')}
              id="emailAddress"
              type="email"
              placeholder="jeseleos@milemarker.io"
              name="emailAddress"
              disabled={true}
            />
            {isNavigatedFromDashboard && (
              <div className="flex items-end justify-end">
                <GroupButton
                  className="items-center justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('userProfilePage.skip'),
                      classType: 'white',
                      action: handleClickSkip,
                    },
                    {
                      type: 'submit',
                      text: t('userProfilePage.saveAndContinue'),
                      classType: 'blue',
                      isLoading: mutationUpdateUser.isLoading,
                    },
                  ]}
                />
              </div>
            )}
            {!isNavigatedFromDashboard && (
              <div className="flex items-end justify-end">
                <GroupButton
                  className="items-center justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('userProfilePage.changePassword'),
                      classType: 'white',
                      action: changePasswordHandler,
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      isLoading: mutationUpdateUser.isLoading,
                    },
                  ]}
                />
              </div>
            )}
          </Form>
        </Formik>
      </div>
    );
  };

  return (
    <>
      {isNavigatedFromDashboard && (
        <div className="flex flex-col items-center pt-24 mx-auto h-full bg-gray-50">
          <h1 className="text-lg ">
            <strong>{t('userProfilePage.almostDone')}</strong>
          </h1>
          <p className="pb-10 pt-4 px-2">{t('userProfilePage.addYourInfo')}</p>
          <div className="bg-white shadow-sm rounded-lg m-auto">{renderUserForm(true)}</div>
        </div>
      )}
      {!isNavigatedFromDashboard && (
        <div className="p-8 flex flex-col justify-start items-start">
          {renderUserForm(false)}
          <ChangePasswordModal nameHeader="Change Password" openModal={openModal} setOpenModal={setOpenModal} />
        </div>
      )}
    </>
  );
};
export default UpdateProfile;
