import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { exportUpcomingInvestmentPayments, ExportUpcomingInvestmentPaymentType } from 'api/supporter/investmentPayment';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { EXPORT_FILE_NAME, FORMAT_DATE_API } from 'utils/constants';
import { FilterDownloadUpcomingPaymentsValueType } from 'utils/proptypes';
import { exportRowDataToCSV, messageErrors, selectDateHandler } from 'utils/utils';
import * as Yup from 'yup';

import './selectDateRangeModal.scss';

type SelectDateRangeModalProps = {
  openModal: boolean;
  setOpenModal: Function;
  advanceFilter: FilterDownloadUpcomingPaymentsValueType;
};

type SelectDateRangeFormType = {
  fStartDate: Date;
  fEndDate: Date;
};

const SelectDateRangeModal = (props: SelectDateRangeModalProps) => {
  const { t } = useTranslation();
  const { openModal = false, setOpenModal, advanceFilter } = props;
  const [isResetForm, setIsResetForm] = useState(false);

  const ValidateSchema = Yup.object().shape({
    fStartDate: Yup.date()
      .nullable()
      .required(t('supporterPage.requiredField'))
      .test({
        name: 'fStartDate-test',
        skipAbsent: true,
        test(value, ctx) {
          const {
            createError,
            parent: { fEndDate },
          } = ctx;
          if (value > fEndDate) return createError({ message: t('supporterPage.fStartDateMin') });
          return true;
        },
      }),
    fEndDate: Yup.date()
      .nullable()
      .required(t('supporterPage.requiredField'))
      .test({
        name: 'fEndDate-test',
        skipAbsent: true,
        test(value, ctx) {
          const {
            createError,
            parent: { fStartDate },
          } = ctx;
          if (value < fStartDate) return createError({ message: t('supporterPage.fEndDateMax') });
          return true;
        },
      }),
  });

  const initialValues: SelectDateRangeFormType = useMemo(() => {
    return {
      fStartDate: moment(advanceFilter.fStartDate).toDate(),
      fEndDate: moment(advanceFilter.fEndDate).toDate(),
      isResetForm,
    };
  }, [isResetForm, advanceFilter]);

  const handleExportUpcomingPayments = async (exportPayload: ExportUpcomingInvestmentPaymentType) => {
    await exportUpcomingInvestmentPayments(exportPayload).then(async res => {
      exportRowDataToCSV({
        stream: res.data,
        fileName: `${exportPayload.fStartDate}⇢${exportPayload.fEndDate}${EXPORT_FILE_NAME.UPCOMING_PAYMENTS_REPORT}`,
      });
    });
  };

  const mutation = useMutation('export-upcoming-payments', {
    mutationFn: handleExportUpcomingPayments,
  });

  const handleSubmit = (data: SelectDateRangeFormType, action: { [key: string]: any }) => {
    const successCallback = () => {
      setOpenModal(!openModal);
      action.resetForm();
    };

    const tempData: FilterDownloadUpcomingPaymentsValueType = {
      fStartDate: moment(data.fStartDate).format(FORMAT_DATE_API),
      fEndDate: moment(data.fEndDate).format(FORMAT_DATE_API),
    };

    mutation.mutate(
      {
        ...(tempData as any),
      },
      {
        onSuccess: () => {
          successCallback();
        },
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const closeModalHandler = (props: FormikProps<SelectDateRangeFormType>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      setIsResetForm(!isResetForm);
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setIsResetForm]);

  return (
    <Modal
      id="select-date-range-modal"
      dismissible={true}
      show={openModal}
      size="md"
      popup={true}
      onClose={() => {
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
      }}
    >
      <Modal.Header>{t('selectDateRange')}</Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <Formik enableReinitialize onSubmit={handleSubmit} initialValues={initialValues} validationSchema={ValidateSchema} validateOnChange={true}>
            {(props: FormikProps<SelectDateRangeFormType>) => {
              return (
                <Form className="flex flex-col gap-4">
                  <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                    <CustomDatePickerForm
                      id="fStartDate"
                      name="fStartDate"
                      label={t('startDate')}
                      placeHolder={t('startDate')}
                      propsFormik={props}
                      selectItemsHandler={async (date: Date) => {
                        await selectDateHandler<SelectDateRangeFormType>({ date, props, fieldName: 'fStartDate' });
                      }}
                      isDeleteSelectedDate={openModal}
                      maxDate={props.values.fEndDate}
                    />
                    <CustomDatePickerForm
                      id="fEndDate"
                      name="fEndDate"
                      label={t('endDate')}
                      placeHolder={t('endDate')}
                      propsFormik={props}
                      selectItemsHandler={async (date: Date) => {
                        await selectDateHandler<SelectDateRangeFormType>({ date, props, fieldName: 'fEndDate' });
                      }}
                      isDeleteSelectedDate={openModal}
                      minDate={props.values.fStartDate}
                    />
                    <GroupButton
                      className="w-full gap-2 justify-center pb-2"
                      buttons={[
                        {
                          type: 'button',
                          text: t('modal.cancel'),
                          classType: 'white',
                          action: () => closeModalHandler(props),
                        },
                        {
                          type: 'submit',
                          text: t('modal.save'),
                          classType: 'blue',
                        },
                      ]}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectDateRangeModal;
