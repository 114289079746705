import { MutableRefObject } from 'react';
import { MultiValue } from 'react-select';
import { Slide, toast, TypeOptions } from 'react-toastify';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

import { ABILITY_ACTION, E_PAYMENT_TYPE, FORMAT_DATE_SHOW_TABLE, PAYMENT_STATUS } from './constants';
import { OptionType, RoleType } from './proptypes';

const countryCodes = require('country-codes-list');

const formatDate = (date: string) => {
  const datejs = dayjs(date);
  const $d = datejs.toDate();
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${$D > 9 ? $D : '0' + $D}/${$M + 1 > 9 ? $M + 1 : '0' + ($M + 1)}/${$y} ${parseInt(splitTime[0]) > 12 ? parseInt(splitTime[0]) - 12 : splitTime[0]
    }:${splitTime[1]}${parseInt(splitTime[0]) > 12 ? 'pm' : 'am'}`;
};

const checkLowerUpper = (value: string) => {
  return /(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
};

const checkNumberDymbol = (value: string) => {
  return /(?=.*\d)(?=.*\W+).*$/.test(value);
};

const checkLeast8Char = (value: string) => {
  return value.length >= 8;
};

const checkNoSpaces = (value: string) => {
  return /^\S+$/.test(value);
};

const validatiePassword = (value: string) => {
  return checkLowerUpper(value) && checkNumberDymbol(value) && checkLeast8Char(value) && checkNoSpaces(value);
};

const showToast = (key: TypeOptions, message: any) => {
  if (key === 'success' || key === 'error' || key === 'warning') {
    return toast[key](message, { transition: Slide, autoClose: 1500, icon: false });
  }
};

const messageErrors = (error: any, translation: any) => {
  if ([500, 404].includes(error?.response?.status)) {
    return translation('generalErrors');
  } else {
    const message = error?.response?.data?.errors[0]?.detail;
    return (isArray(message) ? translation(message[0]) : translation(message) || translation('generalErrors'));
  }
};

const concatName = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
  if (firstName && lastName) {
    return firstName.concat(' ', lastName);
  }
  return '';
};

const concatRoleDisplayName = (
  role: {
    [key: string]: any;
  }[],
  t: any,
) => {
  const concatHandler = (initialRoleName: string, currentRoleName: string) => {
    if (initialRoleName) return initialRoleName.concat(', ', currentRoleName);
    return initialRoleName.concat(currentRoleName);
  };

  return role?.reduce(
    (
      initialValue,
      currentValue: {
        [key: string]: any;
      },
    ) => concatHandler(initialValue, t(`rolePage.${currentValue.displayName}`)),
    '',
  );
};

const getArrayFromArray = <TypeItem, TypeArg>({ items, argFilter }: { items: TypeItem[]; argFilter: string }) => {
  return items?.reduce((initialValue: TypeArg[], currentValue: TypeItem | any) => {
    if (initialValue && items.keys()) {
      return [...initialValue, ...currentValue[argFilter]];
    } else {
      return [...currentValue[argFilter]];
    }
  }, []);
};

const concatItemsDisplayName = <TypeItem, TypeArg>({
  items,
  argFilter,
  argDisplay,
}: {
  items: TypeItem[];
  argFilter?: string;
  argDisplay: string;
}) => {
  const concatHandler = (initialItemsName: string, currentItemsDisplayName: string) => {
    if (initialItemsName) return initialItemsName.concat(', ', currentItemsDisplayName);
    else if (currentItemsDisplayName) return initialItemsName.concat(currentItemsDisplayName);
    return '';
  };

  if (argFilter) {
    const realItems = getArrayFromArray<TypeItem, TypeArg>({ items, argFilter });
    if (argDisplay === 'country')
      return realItems?.reduce((initialValue: string, currentValue: any) => concatHandler(initialValue, currentValue[argDisplay]?.name), '');
    return realItems?.reduce((initialValue: string, currentValue: any) => concatHandler(initialValue, currentValue[argDisplay]), '');
  } else {
    return items?.reduce((initialValue: string, currentValue: any) => concatHandler(initialValue, currentValue[argDisplay]), '');
  }
};

const concatAddressDisplay = <TypeItem, TypeArg>({ items, argFilter }: { items: TypeItem[]; argFilter?: string }) => {
  const concatHandler = ({
    initialValue,
    street,
    state,
    city,
    country,
  }: {
    initialValue: string;
    street: string;
    state: string;
    city: string;
    country: string;
  }) => {
    if (initialValue) return initialValue.concat(', ', `${street}-${state}-${city}-${country}`);
    return initialValue.concat(`${street}-${state}-${city}-${country}`);
  };

  if (argFilter) {
    const realItems = getArrayFromArray<TypeItem, TypeArg>({ items, argFilter });
    return realItems?.reduce(
      (initialValue: string, currentValue: any) =>
        concatHandler({
          initialValue,
          street: currentValue['street'],
          state: currentValue['state'],
          city: currentValue['city'],
          country: currentValue['country'],
        }),
      '',
    );
  } else {
    return items?.reduce(
      (initialValue: string, currentValue: any) =>
        concatHandler({
          initialValue,
          street: currentValue['street'],
          state: currentValue['state'],
          city: currentValue['city'],
          country: currentValue['country'],
        }),
      '',
    );
  }
};

const exportArrayToFile = (exportedData: any, fileName: string) => {
  const wb = utils.json_to_sheet(exportedData);
  const wbout = utils.book_new();
  utils.book_append_sheet(wbout, wb);
  writeFile(wbout, fileName, {
    bookType: 'csv'
  });
};

const exportToFile = (tbl: MutableRefObject<any>, fileName: string, raw: boolean = false) => {
  const elt = tbl.current;
  const wb = utils.table_to_book(elt, { raw });
  writeFile(wb, fileName, {
    bookType: 'csv'
  });
};

const getCountryAndPhoneCodeEnOptions = () => {
  const myCountryCodesObject = countryCodes.customList('countryCode', '{countryNameEn}:+{countryCallingCode}');
  const myCountryCodesArray = Object.entries(myCountryCodesObject);
  return myCountryCodesArray.map((o: any[]) => {
    const [value, label] = o;
    const countryNameEn = label.split(':')[0];
    const countryPhoneCode = label.split(':')[1];
    return { label: countryNameEn, value, phoneCode: countryPhoneCode };
  });
};

const getCountryEnOptions = () => {
  const myCountryCodesObject = countryCodes.customList('countryCode', '{countryNameEn}');
  const myCountryCodesArray = Object.entries(myCountryCodesObject);
  return myCountryCodesArray.map((o: any[]) => {
    const [value, label] = o;
    return { label, value };
  });
};

const getPhoneCodeOptions = () => {
  const myCountryCodesObject = countryCodes.customList('countryCode', '+{countryCallingCode}');

  const myCountryCodesArray = Object.entries(myCountryCodesObject);

  return myCountryCodesArray.map((o: any[]) => {
    const [label, value] = o;
    return { label: `${label} (${value})`, value };
  });
};

const convertNumberToCurrency = (value: number) => {
  if (value) {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  return null;
};

const convertNumberToCurrencyWithoutUnit = (value: number) => {
  if (value) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return null;
};

const selectDateHandler = async <propsType>({ date, props, fieldName }: { date: Date; props: FormikProps<propsType>; fieldName: string }) => {
  await props.setFieldValue(fieldName, date);
};

const selectItemHandler = async <propsType>({
  items,
  props,
  fieldName,
}: {
  items: MultiValue<OptionType>;
  props: FormikProps<propsType>;
  fieldName: string;
}) => {
  await props.setFieldValue(fieldName, items);
};

const setCurrencyValue = async <propsType>({
  currency,
  props,
  isZero,
}: {
  currency: {
    value?: string;
    name?: string;
  };
  props: FormikProps<propsType>;
  isZero?: boolean;
}) => {
  const currencyValue = isZero
    ? currency.value || currency.value?.toString() === '0'
      ? parseFloat(currency.value as string)
      : ''
    : currency.value
      ? parseFloat(currency.value as string)
      : '';
  await props.setFieldValue(currency.name as string, currencyValue);
};

const setTextValue = async <propsType>({
  text,
  props,
}: {
  text: {
    value?: string;
    name?: string;
  };
  props: FormikProps<propsType>;
}) => {
  const textValue = text && text.value ? text.value : '';
  await props.setFieldValue(text.name as string, textValue);
};

const setBooleanValue = async <propsType>({
  booleanValue,
  props,
}: {
  booleanValue: {
    value?: boolean;
    name?: string;
  };
  props: FormikProps<propsType>;
}) => {
  const textValue = booleanValue && booleanValue.value ? booleanValue.value : false;
  await props.setFieldValue(booleanValue.name as string, textValue);
};

const getEPaymentTypeOptions = () => Object.entries(E_PAYMENT_TYPE).map(item => ({ label: item[1], value: item[1] }));

const getOutstanding = (outstanding: number) => convertNumberToCurrency(getNumberValueFromApi(outstanding)) as string;

const getTotalBorrowed = (totalBorrowed: number) => {
  if (totalBorrowed) {
    return convertNumberToCurrency(getNumberValueFromApi(totalBorrowed));
  }
  return '';
};

const getTotalOutstanding = (totalOutstanding: number) => {
  if (totalOutstanding) {
    return convertNumberToCurrency(getNumberValueFromApi(totalOutstanding));
  }
  return '';
};

const getNumberValueFromApi = (value: number) => value / 100;
const getNumberValueSendToApi = (value: number) => Math.round(value * 100);

const convertPermissionToAbilities = (
  roles: (RoleType & {
    permissions: string[];
  })[],
) => {
  let tempAbilities: {
    action: string;
    subject: string;
  }[] = [];

  if (roles && roles.length) {
    const tempPermissions = roles.reduce(
      (
        combinePermissions: string[],
        role: RoleType & {
          permissions: string[];
        },
      ) => uniq(combinePermissions.concat(role?.permissions)),
      [],
    );
    tempAbilities = [...tempPermissions.map(item => ({ action: ABILITY_ACTION.manage, subject: item }))];
  }
  return tempAbilities;
};

const getMonthsPerPayment = ({ monthsPerPaymentType, term }: { monthsPerPaymentType: string; term: number }) => {
  let monthsPerPayment = 0;
  switch (monthsPerPaymentType) {
    case E_PAYMENT_TYPE.MONTHLY:
      monthsPerPayment = term > 1 ? 0 : term;
      break;
    case E_PAYMENT_TYPE.QUARTERLY:
      monthsPerPayment = term > 3 ? 3 : term;
      break;
    case E_PAYMENT_TYPE.SEMI_ANNUALLY:
      monthsPerPayment = term > 6 ? 6 : term;
      break;
    case E_PAYMENT_TYPE.ANNUALLY:
      monthsPerPayment = term > 12 ? 12 : term;
      break;
    case E_PAYMENT_TYPE.AT_MATURITY:
      monthsPerPayment = term;
      break;
    default:
      break;
  }
  return monthsPerPayment;
};

const getOptions = (items: Object) => Object.entries(items).map(item => ({ label: item[0], value: item[1] }));
const getOptions1 = (items: Object) => Object.entries(items).map(item => ({ label: item[1], value: item[1] }));

const getPieChartOptions = (items: Object) =>
  Object.entries(items).map(item => ({
    name: item[0],
    value: item[1] ? getNumberValueFromApi(item[1]) : 0,
  }));

const mapToLetterGrade = (value: number) => {
  switch (true) {
    case value >= 95 && value <= 100:
      return { value, grade: 'A' };
    case value >= 90 && value <= 94.9:
      return { value, grade: 'B+' };
    case value >= 85 && value <= 89.9:
      return { value, grade: 'B-' };
    case value >= 80 && value <= 84.9:
      return { value, grade: 'C+' };
    case value >= 75 && value <= 79.9:
      return { value, grade: 'C-' };
    case value >= 65 && value <= 74.9:
      return { value, grade: 'D' };
    case value <= 64.9:
      return { value, grade: 'F' };
    default:
      return { grade: 'F', value };
  }
};

const getColorStatus = (status: string) => {
  switch (status) {
    case PAYMENT_STATUS.PARTIAL:
      return 'bg-yellow-300';

    case PAYMENT_STATUS.PAID_IN_FULL:
      return 'bg-green-400';

    case PAYMENT_STATUS.PAID:
      return 'bg-green-200';

    case PAYMENT_STATUS.UN_PAID:
      return 'bg-orange-300';

    case PAYMENT_STATUS.PAID_IN_ADVANCE:
      return 'bg-purple-500';

    default:
      return '';
  }
};

const checkInterestOrPrincipalPayment = ({ interestReceived, principalReceived }: { interestReceived?: number; principalReceived?: number }) => {
  switch (true) {
    case !interestReceived:
      return 'principal';

    case !principalReceived:
      return 'interest';

    default:
      return 'total';
  }
};

const checkDate15OfMonth = (date: Date): string => {
  if (date.getDate() >= 15) return moment.utc(date, FORMAT_DATE_SHOW_TABLE).add(1, 'months').date(15).format(FORMAT_DATE_SHOW_TABLE);

  return moment.utc(date, FORMAT_DATE_SHOW_TABLE).date(15).format(FORMAT_DATE_SHOW_TABLE);
};

const exportRowDataToCSV = ({ stream, fileName }: { stream: any; fileName: string }): void => {
  const csvBlob = new Blob([stream]);
  const url = window.URL.createObjectURL(csvBlob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

const openStaticFile = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
};

const renderLetterGradeByExternalRating = (externalRating: number) => {
  if (externalRating >= 97 && externalRating <= 100) {
    return 'AAA';
  } else if (externalRating >= 94 && externalRating <= 96.9) {
    return 'AA';
  } else if (externalRating >= 90 && externalRating <= 93.9) {
    return 'A';
  } else if (externalRating >= 87 && externalRating <= 89.9) {
    return 'BBB';
  } else if (externalRating >= 84 && externalRating <= 86.9) {
    return 'BB';
  } else if (externalRating >= 80 && externalRating <= 83.9) {
    return 'B';
  } else if (externalRating >= 77 && externalRating <= 79.9) {
    return 'CCC';
  } else if (externalRating >= 74 && externalRating <= 76.9) {
    return 'CC';
  } else if (externalRating >= 70 && externalRating <= 73.9) {
    return 'C';
  } else if (externalRating >= 65 && externalRating <= 69.9) {
    return 'RD';
  } else if (externalRating < 65) {
    return 'D';
  }
};

export {
  formatDate,
  showToast,
  checkLowerUpper,
  checkNumberDymbol,
  checkLeast8Char,
  checkNoSpaces,
  validatiePassword,
  messageErrors,
  concatName,
  concatRoleDisplayName,
  concatItemsDisplayName,
  concatAddressDisplay,
  exportArrayToFile,
  exportToFile,
  getCountryEnOptions,
  getArrayFromArray,
  convertNumberToCurrency,
  selectDateHandler,
  selectItemHandler,
  setCurrencyValue,
  getEPaymentTypeOptions,
  getOutstanding,
  getTotalBorrowed,
  getTotalOutstanding,
  getNumberValueFromApi,
  getNumberValueSendToApi,
  convertPermissionToAbilities,
  getMonthsPerPayment,
  getOptions,
  mapToLetterGrade,
  getColorStatus,
  getPieChartOptions,
  getOptions1,
  setTextValue,
  checkInterestOrPrincipalPayment,
  checkDate15OfMonth,
  exportRowDataToCSV,
  renderLetterGradeByExternalRating,
  openStaticFile,
  convertNumberToCurrencyWithoutUnit,
  setBooleanValue,
};
