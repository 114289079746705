import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import classNames from 'classnames';
import moment from 'moment/moment';
import { NoteType } from 'utils/proptypes';

import NoNotes from '../../../../../components/common/noNote';
import TooltipComponent from '../../../../../components/common/Tooltip';
import { ABILITY_ACTION, FORMAT_DATE_SHOW_TABLE, PERMISSION } from '../../../../../utils/constants';

type LoanNoteType = { isFetching: boolean; notes: NoteType[]; addLoanNotes: Function; ability: any };

const LoanNote = (props: LoanNoteType) => {
  const { isFetching, notes, addLoanNotes, ability } = props;
  const [t] = useTranslation()
  return (
    <div className="note__container flex flex-col justify-start items-center mb-3">
      <div className="note__container--title w-full flex justify-between items-center bg-yellow-200 rounded-t p-4">
        <h3 className="note__container--title w-full leading-none text-gray-900 text-base font-semibold text-start bg-yellow-200 rounded-t">{t('note')}</h3>
        {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.LOAN.UPDATE) && (
          <button onClick={() => addLoanNotes()} className="bg-blue-700 cursor-pointer px-1 py-1 bg-white rounded-lg" style={{ height: 'auto' }}>
            <HiPlus className="h-4 w-4" />
          </button>
        )}
      </div>
      <div
        className={classNames('note__container--content w-full bg-yellow-50 rounded-b', {
          'grid grid-cols-3': notes?.filter(note => note.content !== '')?.length,
        })}
      >
        {notes?.filter(note => note.content !== '')?.length ? (
          notes
            ?.filter(note => note.content !== '')
            ?.map((note: NoteType, index) => (
              <div
                key={`content_${index}`}
                className={classNames('w-full content-item', {
                  'border-left-none': index % 3 !== 0,
                  'border-top-none': index + 1 > 3,
                })}
              >
                <div className="w-full space-y-4 px-4 py-4">
                  <h4 className="content-date leading-none text-sm font-normal text-yellow-500">
                    {moment.utc(note.created).format(FORMAT_DATE_SHOW_TABLE)}
                  </h4>
                  <TooltipComponent
                    anchorSelect={`partner_content_text${index}`}
                    content={!isFetching && note?.content ? note?.content : ''}
                    className="content-text leading-4 text-gray-900 text-sm font-normal"
                  />
                </div>
              </div>
            ))
        ) : (
          <div className="note-no-result h-full pt-2 pb-2">
            <NoNotes />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanNote;
