import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GroupButton from 'components/button/groupButton';
import { Modal } from 'flowbite-react';

import './confirmModal.scss';

function HiOutlineExclamationCircle() {
  return null;
}

type ConfirmModalProps = {
  typeEvent: string;
  openModal: boolean;
  setOpenModal: Function;
  title: string;
  confirmFunction: Function;
};

const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation();
  const { typeEvent, openModal = false, setOpenModal, title, confirmFunction } = props;
  const closeModalHandler = () => {
    setOpenModal(!openModal);
  };

  const confirmHandler = async () => {
    await confirmFunction();
    closeModalHandler();
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal id="confirm-modal" dismissible={true} show={openModal} size="md" popup={true} onClose={() => closeModalHandler()}>
      <Modal.Header>{''}</Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{t('confirmMessageModal', { typeEvent, title })}</h3>
          <GroupButton
            className="w-full gap-2 justify-center pb-2"
            buttons={[
              {
                type: 'button',
                text: t('modal.noCancel'),
                classType: 'white',
                action: () => closeModalHandler(),
              },
              {
                type: 'submit',
                text: t('modal.yesImSure'),
                classType: 'blue',
                action: () => confirmHandler(),
              },
            ]}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmModal;
