import { useTranslation } from 'react-i18next';
import DateInput from 'components/input/dateInput/DateInput';
import CustomSelectInput from 'components/input/selectInput/CustomSelectInput';
import moment from 'moment';
import { FORMAT_DATE_API, FORMAT_DATE_PICKER } from 'utils/constants';
import { FilterType } from 'utils/proptypes';

type HeaderTableReportsType = {
  className: string;
  hideFilter: boolean;
  filterArray: FilterType[];
  setCurrentPage: Function;
  setFilterValue: Function;
};

const HeaderTableReports = <FilterNameType,>(props: HeaderTableReportsType) => {
  const { filterArray = [], className, hideFilter = true, setFilterValue } = props;
  const [t] = useTranslation()
  return (
    <div className={`flex flex-row items-center justify-between ${className}__header ml-4 mr-4`}>
      {!hideFilter && (
        <div className={`text-gray-600 items-center ${className}__header--left sm:flex justify-start items-center sm:space-x-4 w-full`}>
          <p className="text-gray-900 font-semibold text-sm uppercase">{t('filter')}</p>
          {filterArray.length &&
            filterArray.map((item: FilterType, index: number) => {
              return (
                <div key={`filter-group-${index}`} className={`header-filter-group header-filter-group-${index} w-[300px] mt-4 sm:mt-0 mx-auto`}>
                  {item.type === 'date' && (
                    <DateInput
                      isSelectRange={item.isSelectRangeDate}
                      isFilter={true}
                      selectItemsHandler={(startDate: Date, setStartDate: Function, endDate: Date) => {
                        if (setFilterValue)
                          if (item.isSelectRangeDate) {
                            setFilterValue((state: FilterNameType) => ({
                              ...state,
                              f_startDate: startDate ? moment(startDate).format(FORMAT_DATE_API) : '',
                              f_endDate: startDate ? moment(endDate).format(FORMAT_DATE_API) : '',
                            }));
                          } else {
                            setStartDate(startDate);
                            setFilterValue((state: FilterNameType) => ({
                              ...state,
                              reportDate: startDate ? moment(startDate).format(FORMAT_DATE_API) : item.defaultValue,
                            }));
                          }
                      }}
                      defaultValue={item.defaultValue}
                      placeHolder={item.placeholder}
                      isClearable={true}
                      dateFormat={FORMAT_DATE_PICKER}
                    />
                  )}
                  {item.type === 'select' && (
                    <CustomSelectInput
                      options={item.options || []}
                      selectItemsHandler={(selectValue: string | number) => {
                        if (setFilterValue)
                          setFilterValue((state: FilterNameType) => ({
                            ...state,
                            [item.name as string]: selectValue ? selectValue : '',
                          }));
                      }}
                      placeHolder={item.placeholder}
                      isFilter={true}
                      disableSearch={true}
                    />
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
export default HeaderTableReports;
