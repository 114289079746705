import { ENTITY_TYPE } from 'utils/constants';

import base from '../baseApi';

export type ExportToCsvType = {
  entityType: keyof typeof ENTITY_TYPE;
};

const { urls, methods, execute } = base();

const exportToCsv = (payload: ExportToCsvType) => {
  const method = methods.POST;
  const url = urls.contact.export;
  return execute(method, url, { authorization: true }, payload);
};

const deleteContact = (contactId: string) => {
  const method = methods.DELETE;
  const url = `${urls.contact.contacts}/${contactId}`;
  return execute(method, url, { authorization: true });
};

export { exportToCsv, deleteContact };
