import { PieChartDataType } from 'utils/proptypes';

import PieChartCustom from './pieChartCustom';

type GroupPieChartsCustomType = {
  data: { reportDate: string; dataPieCharts: PieChartDataType[] };
};

const GroupPieChartsCustom = (props: GroupPieChartsCustomType) => {
  const { data } = props;

  return (
    <div className="group-pie-charts grid xl:justify-center gap-6 mt-4 mb-4">
      {data?.dataPieCharts?.map((item: PieChartDataType, index: number) => (
        <PieChartCustom key={`pie-chart-key-${index}`} title={item.title} data={item.data} reportDate={data?.reportDate} />
      ))}
    </div>
  );
};
export default GroupPieChartsCustom;
