// eslint-disable-next-line
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import FormComponent from '../components/form/form';
import GroupButton from '../components/button/groupButton';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { changePassword, getUserByVerifiedCode } from 'api/userApi';
import { Formik, Form } from 'formik';
import EmailVerified from './emailVerified';
import InvalidLink from './invalidLink';
import { validatiePassword, messageErrors } from '../utils/utils';
import _ from 'lodash';

const logo = require('../assets/image/svg/logo.svg').default;

const ChangePassword = () => {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isExist, setIsExist] = useState<any>(null);
  const verifiedCode = searchParams.get('verifiedCode');

  const mutaChangePassword = useMutation('changePassword', {
    mutationFn: changePassword,
    onSuccess: () => {
      setIsSuccess(true);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const mutaGetUser = useMutation('getUserByVerifiedCode', {
    mutationFn: getUserByVerifiedCode,
    onSuccess: () => setIsExist(true),
    onError: () => setIsExist(false),
  });

  useEffect(() => {
    mutaGetUser.mutate(verifiedCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (value: any) => {
    mutaChangePassword.mutate({ ...value, verifiedCode });
  };

  const onValidate = (values: any) => {
    const errors: any = {};
    if (_.isEmpty(values.newPasswordConfirm) || !validatiePassword(values.newPassword)) errors.newPassword = t('changePassword.newPassInvalid');
    if (!values.newPasswordConfirm || _.isEmpty(values.newPasswordConfirm)) errors.newPasswordConfirm = t('requiredField');
    if (values.newPassword !== values.newPasswordConfirm) errors.newPasswordConfirm = t('changePassword.confirmPassInvalid');
    return errors;
  };

  if (isExist === null) return <></>;
  if (!isExist) return <InvalidLink />;
  if (isSuccess) return <EmailVerified />;
  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="p-50 w-screen bg-white shadow dark:bg-gray-800">
        <img className="w-15 h-15 md:rounded-none  mx-auto" src={logo} alt="logo" />
        <h1 className="text-3xl text-center mt-6 mb-6 font-semibold tracking-tight text-gray-900 dark:text-white">{t('changePassword.title')}</h1>
        <h3 className="my-4">{t('changePassword.description')}</h3>
        <Formik onSubmit={handleSubmit} initialValues={{}} validate={onValidate} validateOnChange={true}>
          <Form className="flex flex-col gap-6">
            <FormComponent
              label={t('changePassword.newPassword')}
              id="newPassword"
              type="password"
              placeholder="********"
              name="newPassword"
              isValidatePassword={true}
            />
            <FormComponent
              label={t('changePassword.newPasswordConfirm')}
              id="newPasswordConfirm"
              type="password"
              placeholder="********"
              name="newPasswordConfirm"
            />
            <GroupButton
              className="items-center justify-center"
              buttons={[
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: mutaChangePassword.isLoading,
                },
              ]}
            />
          </Form>
        </Formik>
      </div>
    </div>
  );
};
export default ChangePassword;
