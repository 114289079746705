import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCalendar, HiOutlineDownload, HiPlus, HiReceiptRefund } from 'react-icons/hi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import {
  getPaymentsScheduleByLoanId,
  recalculateSchedulePaymentById,
  removeSchedulePayment,
  updateSchedulePaymentById,
  UpdateSchedulePaymentType,
} from 'api/loanFundManager/schedulePaymentApi';
import PayOffModal from 'components/modal/loan/payOffModal';
import AddPaymentModal from 'components/modal/payment/addPaymentModal';
import PaginateTable from 'components/table/paginate';
import { AbilityContext } from 'contexts/can';
import useLoading from 'hooks/useLoading';
import { omit } from 'lodash';
import moment from 'moment';
import { ABILITY_ACTION, EXPORT_FILE_NAME, FORMAT_DATE_API, FORMAT_DATE_SHOW_TABLE, PERMISSION } from 'utils/constants';
import { LoanType, PaymentType } from 'utils/proptypes';
import { convertNumberToCurrency, exportArrayToFile, getNumberValueFromApi, getNumberValueSendToApi, messageErrors } from 'utils/utils';
import { read, utils } from 'xlsx';

import { removePayment, updatePaymentById, UpdatePaymentRecordType } from '../../../../../api/loanFundManager/paymentApi';
import RemoveModal from '../../../../../components/modal/common/removeModal';
import ConfirmRecalculateModal from '../../../../../components/modal/loan/confirmRecalculateModal';
import AddNonPerformingModal from '../../../../../components/modal/payment/addNonPerformingModal';
import AddScheduleModal from '../../../../../components/modal/payment/addScheduleModal';
import WriteOffModal from '../../../../../components/modal/payment/writeOffModal';
import LoanDetailPdf from '../../../loans/components/loanDetailPdf';

import RenderPaymentsTableScheduleData from './components/RenderPaymentsTableScheduleData';

type PaymentScheduleType = {
  selectedLoan?: LoanType;
  isFetchingLoan: boolean;
};

type AddPaymentFormType = {
  selectedPayment: PaymentType;
  paymentReceivedAmount: number | string;
  status: string;
};
const PaymentSchedule = ({ selectedLoan, isFetchingLoan = false }: PaymentScheduleType) => {
  const downloadPDFRef = useRef(null);
  const [t] = useTranslation();
  const ability = useContext(AbilityContext);
  const queryClient = useQueryClient();
  const params = useParams();
  const loanId = params.id;
  const [totalEntities, setTotalEntities] = useState(0);
  const [paymentsSchedule, setPaymentsSchedule] = useState<PaymentType[]>([]);
  const [totalDisplay, setTotalDisplay] = useState({
    totalDaysSinceLastTransaction: 0,
    totalInterestAmount: 0,
    totalOfTotalToPay: 0,
    totalPrincipalAmount: 0,
  });
  const [__html, setHtml] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddScheduleModal, setOpenAddScheduleModal] = useState(false);
  const [openAddNonPerformingModal, setOpenAddNonPerformingModal] = useState(false);
  const [openWriteOffModal, setOpenWriteOffModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<PaymentType>();
  const [openPayOffModal, setOpenPayOffModal] = useState(false);
  const [isPayOff, setIsPayOff] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const tbl = useRef(null);
  const ref = useRef(null);

  const { refetch: fetchPaymentsScheduleByLoanId, isFetching: isFetchingPaymentsSchedule } = useQuery(
    ['paymentsSchedule'],
    () => getPaymentsScheduleByLoanId(loanId as string),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.totalEntities);
        setPaymentsSchedule(data.entities);
        setTotalDisplay({
          totalDaysSinceLastTransaction: data.totalDaysSinceLastTransaction,
          totalInterestAmount: data.totalInterestAmount,
          totalOfTotalToPay: data.totalOfTotalToPay,
          totalPrincipalAmount: data.totalPrincipalAmount,
        });
        const payOff = data?.entities?.find((entity: PaymentType) => entity.payOffDate);
        setIsPayOff(!!payOff);
        const f = new ArrayBuffer(data.entities);
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const dataPayment = utils.sheet_to_html(ws);
        setHtml(dataPayment);
      },
      onError: () => {
        setPaymentsSchedule([]);
        setTotalEntities(0);
      },
    },
  );

  useLoading({ isLoading: isFetchingPaymentsSchedule || isFetchingLoan, ref });

  const addPaymentHandler = () => {
    setOpenAddModal(!openAddModal);
  };

  const addScheduleHandler = () => {
    setOpenAddScheduleModal(!openAddModal);
  };

  const writeOffHandler = () => {
    setOpenWriteOffModal(!openWriteOffModal);
  };

  const payOffHandler = () => {
    setOpenPayOffModal(!openPayOffModal);
  };

  const addNonPerformingHandler = () => {
    setOpenAddNonPerformingModal(!openAddNonPerformingModal);
  };

  const updateSchedulePayment = async (schedulePaymentPayload: UpdateSchedulePaymentType & { id: string }) => {
    await updateSchedulePaymentById(schedulePaymentPayload?.id as string, omit(schedulePaymentPayload, 'id') as any);
  };

  const mutationUpdate = useMutation('update-schedule-payment', {
    mutationFn: updateSchedulePayment,
  });

  const successCallback = async (message: string, isBlur = false) => {
    !isBlur && toast.success(message);
    await fetchPaymentsScheduleByLoanId();
    await queryClient.invalidateQueries(['paymentsSchedule']);
  };

  const handleUpdateSchedulePayment = (data: UpdateSchedulePaymentType & { id: string }, isBlur = false) => {
    const tempData = {
      id: data.id,
      paymentDate: data.paymentDate ? moment(data.paymentDate).format(FORMAT_DATE_API) : data.paymentDate === null ? null : undefined,
      principalAmount: data.principalAmount ? getNumberValueSendToApi(data.principalAmount) : data.principalAmount !== undefined ? null : undefined,
      status: data.status ? data.status : undefined,
    };
    mutationUpdate.mutate(
      {
        ...(tempData as any),
      },
      {
        onSuccess: async () => {
          const message: string = t('paymentPage.editSuccessMessage');
          await successCallback(message, isBlur);
        },
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const updatePayment = async (paymentPayload: UpdatePaymentRecordType & { id: string }) => {
    await updatePaymentById(paymentPayload?.id as string, omit(paymentPayload, 'id') as any);
  };

  const mutationPaymentUpdate = useMutation('update-payment', {
    mutationFn: updatePayment,
  });

  const handleUpdatePayment = (data: AddPaymentFormType) => {
    const tempData = {
      id: data.selectedPayment.id,
      paymentReceivedAmount: data.paymentReceivedAmount
        ? getNumberValueSendToApi(data.paymentReceivedAmount as number)
        : data.paymentReceivedAmount === ''
        ? null
        : undefined,
      status: data.status ? data?.status : undefined,
    };
    mutationPaymentUpdate.mutate(
      {
        ...(tempData as any),
      },
      {
        onSuccess: async () => {
          const message: string = t('paymentPage.editSuccessMessage');
          await successCallback(message);
        },
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const recalculateSchedulePayments = async (payload: { loanId: string }) => {
    await recalculateSchedulePaymentById(payload.loanId);
  };

  const mutationRecalculate = useMutation('recalculate-schedule-payments', {
    mutationFn: recalculateSchedulePayments,
  });

  const handleDownloadPDF = useReactToPrint({
    content: () => downloadPDFRef.current,
    documentTitle: `loan-${loanId}-schedule.pdf`,
    copyStyles: true,
  });

  const handleRecalculateSchedulePayments = () => {
    setOpenConfirmModal(true);
  };

  const handleRecalculateSchedulePaymentsSubmit = () => {
    mutationRecalculate.mutate(
      {
        loanId: loanId as string,
      },
      {
        onSuccess: async () => {
          const message: string = t('paymentPage.recalculateSuccessMessage');
          await successCallback(message);
        },
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const exportPaymentHandler = () => {
    const exportedData: any = paymentsSchedule
      ?.filter((payment: PaymentType) => !payment?.paymentReceived)
      .map((payment: PaymentType) => {
        return {
          'Payment Date': moment.utc(payment.paymentDate).format(FORMAT_DATE_SHOW_TABLE),
          'Principal to Pay':
            payment.principalAmount && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.principalAmount)) : '',
          'Days Since Last Trans': `${payment.daysSinceLastTransaction} ${t('days')}`,
          'Interest to Pay': payment?.interestAmount && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.interestAmount)) : '',
          'Total to Pay':
            payment.totalToPay || payment.paymentReceivedAmount
              ? convertNumberToCurrency(
                  getNumberValueFromApi(payment.paymentReceivedAmount ? (payment.paymentReceivedAmount as number) : (payment.totalToPay as number)),
                )
              : '',
          Balance: payment.balance && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.balance)) : '',
        };
      });
    exportArrayToFile(exportedData, EXPORT_FILE_NAME.PAYMENT);
  };

  const handleDeletePaymentOrSchedule = async (paymentId: string) => {
    if (selectedPayment?.name) {
      await removePayment(paymentId);
    } else {
      await removeSchedulePayment(paymentId);
    }
  };

  const mutationDelete = useMutation('delete-loan-payment-or-schedule', {
    mutationFn: handleDeletePaymentOrSchedule,
  });

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      {
        <RenderPaymentsTableScheduleData
          totalDisplay={totalDisplay}
          paymentsSchedule={paymentsSchedule}
          isFetching={isFetchingPaymentsSchedule}
          tbl={tbl}
          editPaymentScheduleHandler={handleUpdateSchedulePayment}
          editPaymentHandler={handleUpdatePayment}
          setSelectedPayment={setSelectedPayment}
          setIsOpenRemoveModal={setIsOpenRemoveModal}
          isOpenRemoveModal={isOpenRemoveModal}
        />
      }
      {paymentsSchedule.length > 0 && (
        <PaginateTable
          className="payment__pagination"
          totalEntities={paymentsSchedule.length}
          isFetching={isFetchingPaymentsSchedule}
          exportHandler={exportPaymentHandler}
          hidePaginate={true}
          items={paymentsSchedule}
        />
      )}
      {ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.CREATE) && totalEntities !== paymentsSchedule?.length - 1 && (
        <>
          <div className="flex gap-3 button-group">
            <button className="add-schedule-button cursor-pointer px-2 py-1 mr-2 text-white bg-blue-900 rounded-lg" onClick={addScheduleHandler}>
              <HiPlus className="h-4 w-4 mr-2" />
              <span className="font-semibold text-xs">{t('addLine')}</span>
            </button>
            <button className="write-off-button cursor-pointer px-2 py-1 mr-2 text-white bg-blue-900 rounded-lg" onClick={writeOffHandler}>
              <HiOutlineCalendar className="h-4 w-4 mr-2" />
              <span className="font-semibold text-xs">{t('Write Off')}</span>
            </button>
            {!isPayOff && (
              <button className="pay-off-button cursor-pointer px-2 py-1 mr-2 text-white bg-blue-900 rounded-lg" onClick={payOffHandler}>
                <HiReceiptRefund className="h-4 w-4 mr-2" />
                <span className="font-semibold text-xs">{t('Pay Off')}</span>
              </button>
            )}
            <button
              className="add-non-performing-button cursor-pointer px-2 py-1 mr-2 text-white bg-blue-900 rounded-lg"
              onClick={addNonPerformingHandler}
            >
              <HiOutlineCalendar className="h-4 w-4 mr-2" />
              <span className="font-semibold text-xs">{t('Set Non-Performing')}</span>
            </button>

            <button className="download-pdf-button cursor-pointer px-2 py-1 mr-2 text-white bg-blue-900 rounded-lg" onClick={handleDownloadPDF}>
              <HiOutlineDownload className="h-4 w-4 mr-2" />
              <span className="font-semibold text-xs">{t('downloadPDF')}</span>
            </button>
            <button
              onClick={() => handleRecalculateSchedulePayments()}
              className="recalculate-button cursor-pointer px-2 py-1 mr-2 text-white bg-blue-900 rounded-lg"
            >
              <HiReceiptRefund className="h-4 w-4 mr-2" />
              <span className="font-semibold text-xs">{t('recalculateBalance')}</span>
            </button>
            <button
              onClick={() => addPaymentHandler()}
              className="add-icon cursor-pointer px-1 py-1 mr-2 text-white bg-blue-700 rounded-lg right-0"
              data-testid="test-add-payment-icon"
            >
              <HiPlus className="h-4 w-4" />
            </button>
          </div>
        </>
      )}
      <AddScheduleModal
        openModal={openAddScheduleModal}
        setOpenModal={setOpenAddScheduleModal}
        refetchApi={fetchPaymentsScheduleByLoanId}
        selectedLoan={selectedLoan}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        paymentsSchedule={paymentsSchedule}
      />
      <WriteOffModal openModal={openWriteOffModal} setOpenModal={setOpenWriteOffModal} selectedLoan={selectedLoan} />
      <PayOffModal openModal={openPayOffModal} setOpenModal={setOpenPayOffModal} schedulePayments={paymentsSchedule} selectedLoan={selectedLoan} />
      <AddNonPerformingModal openModal={openAddNonPerformingModal} setOpenModal={setOpenAddNonPerformingModal} selectedLoan={selectedLoan} />
      <AddPaymentModal
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        refetchApi={fetchPaymentsScheduleByLoanId}
        selectedLoan={selectedLoan}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        paymentsSchedule={paymentsSchedule}
      />
      <RemoveModal
        title={`${t('loan')}'s ${selectedPayment?.name ? t('payment') : t('schedule')}`}
        openModal={isOpenRemoveModal}
        setOpenModal={setIsOpenRemoveModal}
        refetchApi={fetchPaymentsScheduleByLoanId}
        successDeleteMessage={selectedPayment?.name ? t(`paymentPage.deleteSuccessMessage`) : t(`paymentPage.deleteScheduleSuccessMessage`)}
        mutation={mutationDelete}
        selectItemId={selectedPayment?.id as string}
      />
      <ConfirmRecalculateModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        handleSubmit={handleRecalculateSchedulePaymentsSubmit}
      />
      <div className="download-pdf-content absolute -z-10">
        <div ref={downloadPDFRef}>
          <LoanDetailPdf />
        </div>
      </div>
    </>
  );
};

export default PaymentSchedule;
