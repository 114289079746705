import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronLeft, HiChevronRight, HiOutlineDownload } from 'react-icons/hi';
import classNames from 'classnames';
import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { PaginateType } from 'utils/proptypes';

const PaginateTable = (props: PaginateType) => {
  const {
    setCurrentPage,
    currentPage = 0,
    totalEntities,
    limit = DEFAULT_VALUE_FILTER.LIMIT,
    className,
    exportHandler,
    hidePaginate = false,
    setIsOpenRangeDateModal,
    items,
    isFetchingDownload = false,
  } = props;
  const [t] = useTranslation()
  const residualRecords = useMemo(() => {
    return totalEntities % limit;
  }, [totalEntities, limit]);

  const tempCurrentPage = useMemo(() => {
    return currentPage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const totalPage = useMemo(() => {
    return Math.ceil(totalEntities / limit);
  }, [totalEntities, limit]);

  const handleNextPage = () => {
    const page = currentPage + 1;
    if (totalEntities / limit > page && setCurrentPage) {
      setCurrentPage(page);
    }
  };
  const handlePreviousPage = () => {
    const page = currentPage - 1;
    if (currentPage > 0 && setCurrentPage) {
      setCurrentPage(page);
    }
  };
  return (
    <div className={`w-full flex ${className} bg-white`}>
      <div
        className={classNames(
          `w-full flex items-center border-b border-gray-300 ${className}--container border-b border-gray-200 bg-white shadow-md`,
          { 'justify-end': hidePaginate, 'justify-between': !hidePaginate },
        )}
      >
        {!hidePaginate && (
          <div className="flex items-center">
            <div className="inline-flex mt-2 mb-2 xs:mt-0">
              <div
                data-testid="paginationPrevious"
                onClick={() => handlePreviousPage()}
                className="cursor-pointer inline-flex items-center py-2 px-2 text-sm font-medium text-gray-700 rounded-l hover:bg-gray-100"
              >
                <HiChevronLeft className="h-7 w-7" />
              </div>
              <div
                data-testid="paginationNext"
                onClick={() => handleNextPage()}
                className="cursor-pointer inline-flex items-center py-2 px-2 text-sm font-medium text-gray-700 rounded-r hover:bg-gray-100"
              >
                <HiChevronRight className="h-7 w-7" />
              </div>
            </div>
            <span className="text-sm text-gray-700 dark:text-gray-400 px-2">
              <span>{t('showing')} </span>
              <span data-testid="paginationRecords" className="font-semibold text-gray-900 dark:text-white">
                {totalEntities !== 0 ? tempCurrentPage * limit + 1 : '0'}
              </span>{' '}
              -{' '}
              <span data-testid="paginationTotalEntityPerPage" className="font-semibold text-gray-900 dark:text-white">
                {limit * tempCurrentPage + (tempCurrentPage + 1 === totalPage && residualRecords ? residualRecords : limit)}
              </span>{' '}
              {t('of')}{' '}
              <span data-testid="paginationTotalEntity" className="font-semibold text-gray-900 dark:text-white">
                {totalEntities}
              </span>
            </span>
          </div>
        )}
        <div
          className={classNames('download-data text-sm text-gray-900 flex items-center justify-center mr-6 cursor-pointer', {
            'animate-pulse': isFetchingDownload,
          })}
          onClick={() => {
            if (typeof setIsOpenRangeDateModal === 'function') {
              setIsOpenRangeDateModal(true);
            } else if (typeof exportHandler === 'function' && !isFetchingDownload) exportHandler();
          }}
          data-testid="paginationDownload"
        >
          <div className="md:mr-2">
            <HiOutlineDownload size={16} />
          </div>
          <span className="hidden-mobile">{t('downloadData')}</span>
        </div>
      </div>
    </div>
  );
};
export default PaginateTable;
