import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { addEditUserType, createUser, updateUserById } from 'api/userApi';
import GroupButton from 'components/button/groupButton';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import TextForm from 'components/form/textForm/textForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import { ROUTE_PATHS } from 'utils/constants';
import { OptionType, UserType } from 'utils/proptypes';
import { messageErrors, setTextValue } from 'utils/utils';
import * as Yup from 'yup';

import './addUserManagementModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddUsersManagementModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit: boolean;
  setIsEdit: Function;
  refetchApi: Function;
  rolesData: { [key: string]: any }[];
  selectedUser?: UserType;
};

type AddEditUserFormTypes = {
  firstName: string;
  lastName: string;
  emailAddress?: string;
  emailVerified: boolean;
  roles: { [key: string]: string }[];
};

const AddUsersManagementModal = (props: AddUsersManagementModalTypes) => {
  const { openModal = false, setOpenModal, isEdit = false, setIsEdit, refetchApi, rolesData, selectedUser } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const navigate = useNavigate();

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, t('userManagementPage.nameLengthMin'))
      .max(100, t('userManagementPage.nameLengthMax'))
      .nullable()
      .required(t('userManagementPage.requiredField')),
    lastName: Yup.string()
      .min(1, t('userManagementPage.nameLengthMin'))
      .max(100, t('userManagementPage.nameLengthMax'))
      .nullable()
      .required(t('userManagementPage.requiredField')),
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    roles: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('userManagementPage.roleTypeIsString')),
        }),
      )
      .min(1, t('userManagementPage.requiredField')),
  });

  const roleOptions = rolesData
    ?.filter((role: { [key: string]: string }) => role.displayName !== 'OrganizationAdmin' && role.displayName !== 'StandardUser')
    ?.reduce((options: OptionType[], role: { [key: string]: string }) => {
      return [...options, { label: t(`rolePage.${role.displayName}`), value: role.id }];
    }, []);

  const initialValues: AddEditUserFormTypes = useMemo(() => {
    return isEdit && selectedUser
      ? {
        firstName: selectedUser?.firstName,
        lastName: selectedUser?.lastName,
        emailAddress: selectedUser?.emailAddress,
        emailVerified: selectedUser?.emailVerified as boolean,
        roles: selectedUser?.roles?.reduce(
          (selectedRoles: OptionType[], role: any) => {
            return [...selectedRoles, { value: role.id, label: t(`rolePage.${role.displayName}`) }];
          },
          [t],
        ),
      }
      : {
        firstName: '',
        lastName: '',
        emailAddress: '',
        emailVerified: false,
        isResetForm,
        roles: [],
      };
  }, [isEdit, selectedUser, t, isResetForm]);

  const handleAddAndUpdateUser = async (userPayload: addEditUserType) => {
    if (isEdit) {
      await updateUserById(selectedUser?.id as string, userPayload);
    } else {
      await createUser(userPayload);
    }
  };

  const mutation = useMutation('create-update-user', {
    mutationFn: handleAddAndUpdateUser,
  });

  const handleSubmit = async (data: AddEditUserFormTypes, action: { [key: string]: any }) => {
    const successCallback = (message: string) => {
      navigate(ROUTE_PATHS.USERS_PAGE, { replace: true });
      setOpenModal(!openModal);
      setIsEdit(false);
      toast.success(message);
      action.resetForm();
      refetchApi();
    };
    const tempData = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: !isEdit ? data.emailAddress : undefined,
      emailVerified: !isEdit,
      roleIds: data.roles.filter(role => role.value).reduce((roleIds: any[], role) => [...roleIds, role.value], []),
    };
    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: () => {
            const message: string = isEdit ? t('userManagementPage.editSuccessMessage') : t('userManagementPage.createSuccessMessage');
            successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<AddEditUserFormTypes>) => {
    setOpenModal(!openModal);
    setIsEdit(false);
    props.resetForm();
    props.setErrors({});
    navigate(ROUTE_PATHS.USERS_PAGE, { replace: true });
  };

  const selectRoleHandler = async (roles: MultiValue<OptionType>, props: FormikProps<AddEditUserFormTypes>) => {
    const noEmptyRoles = roles.filter((role: OptionType) => role.label !== '');
    await props.setFieldValue('roles', noEmptyRoles);
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-user-modal"
      show={openModal}
      size="md"
      onClose={() => {
        setOpenModal(!openModal);
        setIsEdit(false);
        navigate(ROUTE_PATHS.USERS_PAGE, { replace: true });
        if (setIsResetForm) setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{isEdit ? t('editUser') : t('addUser')}</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<AddEditUserFormTypes>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-6 pb-2 sm:pb-6 lg:px-8 xl:pb-8">
                  <TextForm
                    id="firstName"
                    name="firstName"
                    label={t('firstName')}
                    placeholder={t('firstName')}
                    propsFormik={props}
                    isEdit={isEdit}
                    isRequired={true}
                    dataTestId="addUserModalFirstName"
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddEditUserFormTypes>,
                    ) => {
                      await setTextValue<AddEditUserFormTypes>({ text, props });
                    }}
                  />
                  <TextForm
                    id="lastName"
                    name="lastName"
                    label={t('lastName')}
                    placeholder={t('lastName')}
                    propsFormik={props}
                    isEdit={isEdit}
                    isRequired={true}
                    dataTestId="addUserModalLastName"
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddEditUserFormTypes>,
                    ) => {
                      await setTextValue<AddEditUserFormTypes>({ text, props });
                    }}
                  />
                  <TextForm
                    id="emailAddress"
                    name="emailAddress"
                    label={t('email')}
                    placeholder={t('email')}
                    propsFormik={props}
                    isDisabled={isEdit}
                    isEdit={isEdit}
                    isRequired={true}
                    dataTestId="addUserModalEmailAddress"
                    setCurrentValue={async (
                      text: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<AddEditUserFormTypes>,
                    ) => {
                      await setTextValue<AddEditUserFormTypes>({ text, props });
                    }}
                  />
                  <CustomSelectForm
                    label={t('roles')}
                    id="roles"
                    name="roles"
                    placeHolder={t('selectRole')}
                    options={roleOptions}
                    selectItemsHandler={selectRoleHandler}
                    propsFormik={props}
                    isMulti={true}
                    isEdit={isEdit}
                    isRequired={true}
                    dataTestId="addUserModalRoles"
                    disableSearch={true}
                  />
                </div>
                <GroupButton
                  className="w-full gap-2 justify-center pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddUsersManagementModal;
