import { useTranslation } from 'react-i18next';
import { Button } from 'flowbite-react';
import useUser from 'hooks/useUser';
const searchNormalIcon = require('../assets/image/svg/search-normal.svg').default;

const NotAuthorizedPage = () => {
  const [t] = useTranslation();
  const { handleLogout } = useUser();

  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="flex flex-col self-center items-center justify-center p-50 w-full dark:bg-gray-800">
        <img className="w-15 h-15 md:rounded-none mx-auto" src={searchNormalIcon} alt="successIcon" />
        <h1
          className="mt-30 text-4xl text-blue-600 font-semibold text-center mt-4 mb-3 tracking-tight dark:text-white"
          style={{
            color: 'var(--color-button-blue)',
          }}
        >
          {' '}
          {t('notAuthorized.title')}
        </h1>
        <p
          className="text-base mb-5 text-center"
          style={{
            color: 'var(--color-text-primary)',
          }}
        >
          {t('notAuthorized.contentText1')}
          <br /> {t('notAuthorized.contentText2')}
        </p>
        <Button
          color="info"
          type="button"
          onClick={() => {
            // TODO: Will be open when dashboard is designed
            // navigate(ROUTE_PATHS.DASHBOARD_PAGE);
            handleLogout();
          }}
        >
          {t('homePage')}
        </Button>
      </div>
    </div>
  );
};
export default NotAuthorizedPage;
