import { useTranslation } from 'react-i18next';
import { Tabs } from 'flowbite-react';

import PortfolioReportPage from './components/portfolioReports';
import SummaryReportPage from './components/summaryReport';

import '../../../styles/styles.scss';
import './reports.scss';

const ReportsPage = () => {
  const [t] = useTranslation()
  return (
    <div className="reports-page flex flex-col">
      <div className="reports-page__body mt-4 flex justify-between items-center">
        <Tabs.Group aria-label="Tabs with underline" className="reports-page__body--tabs">
          <Tabs.Item title={t('portfolioReport')}>
            <PortfolioReportPage />
          </Tabs.Item>
          <Tabs.Item title={t('monthlySumaryReport')}>
            <div className="summary-report">
              <SummaryReportPage />
            </div>
          </Tabs.Item>
        </Tabs.Group>
      </div>
    </div>
  );
};

export default ReportsPage;
