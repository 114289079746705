import { useTranslation } from "react-i18next";
import GroupButton from 'components/button/groupButton';
import { Modal } from "flowbite-react";

import './confirmEditLoanModal.scss'

type ConfirmEditLoanModalTypes = {
    openConfirmModal: boolean;
    setOpenConfirmModal: Function;
    handleSubmit: Function;
    data: any;
    action: any
};

const ConfirmEditLoanModal = (props: ConfirmEditLoanModalTypes) => {
    const { openConfirmModal, setOpenConfirmModal, handleSubmit, data, action } = props
    const [t] = useTranslation()

    return (
        <Modal
            id="confirm-edit-loan-modal"
            show={openConfirmModal}
            size="xl"
            onClose={() => {
                setOpenConfirmModal(false)
            }}
            dismissible={true}
        >
            <Modal.Header>{''}</Modal.Header>
            <Modal.Body className="pb-6 pt-2">
                <div className="text-center font-semibold mb-2">
                    {t('paymentOverwritten')}
                </div>
                <div className="text-center mb-6 ">
                    {t('confirmOverwriteMessage')}
                </div>
                <GroupButton
                    className="w-full gap-2 justify-center pb-2"
                    buttons={[
                        {
                            type: 'button',
                            text: t('modal.cancel'),
                            classType: 'white',
                            action: () => setOpenConfirmModal(false),
                        },
                        {
                            type: 'button',
                            dataTestId: "confirm-edit-button",
                            text: t('modal.save'),
                            classType: 'blue',
                            action: () => {
                                handleSubmit(data, action)
                                setOpenConfirmModal(false)
                            }
                        },
                    ]}
                />
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmEditLoanModal