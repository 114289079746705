
import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import classNames from 'classnames';
import NoNotes from 'components/common/noNote';
import moment from 'moment';
import { ABILITY_ACTION, FORMAT_DATE_SHOW_TABLE, PERMISSION } from 'utils/constants';
import { NoteType } from 'utils/proptypes';

import TooltipComponent from '../../../../../components/common/Tooltip';

type partnerNoteType = { notes: NoteType[]; addPartnerNotes: Function; ability: any };

const PartnerNote = (props: partnerNoteType) => {
  const { notes, addPartnerNotes, ability } = props;
  const [t] = useTranslation()
  return (
    <div className="note__container flex flex-col justify-start items-center">
      <div className="note__container--title w-full flex justify-between items-center bg-yellow-200 p-4 rounded-t">
        <h3 className="leading-none text-gray-900 text-base font-semibold text-start uppercase">{t('note')}</h3>
        {ability && ability.can(ABILITY_ACTION.manage, PERMISSION.PARTNER.UPDATE) && (
          <button onClick={() => addPartnerNotes()} className="bg-blue-700 cursor-pointer px-1 py-1 bg-white rounded-lg" style={{ height: 'auto' }}>
            <HiPlus className="h-4 w-4" />
          </button>
        )}
      </div>
      <div className="note__container--content h-full w-full bg-yellow-50 rounded-b">
        {notes?.filter(note => note.content !== '')?.length ? (
          notes
            ?.filter(note => note.content !== '')
            ?.map((note: NoteType, index) => (
              <div key={`content_${index}`} className="w-full">
                <div className="w-full space-y-4 px-4 py-4 relative">
                  <h4 className="content-date leading-none text-yellow-500 text-sm font-normal">
                    {moment.utc(note.created).format(FORMAT_DATE_SHOW_TABLE)}
                  </h4>
                  <TooltipComponent
                    anchorSelect={`partner_content_text${index}`}
                    content={note?.content ? note?.content : ''}
                    className="content-text leading-4 text-gray-900 text-sm font-normal"
                  />
                </div>
                <div className={classNames('w-full border-b border-gray-200', { 'br-last': index === notes?.length })} />
              </div>
            ))
        ) : (
          <div className="note-no-result h-full">
            <NoNotes />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerNote;
