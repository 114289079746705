import { NoteType, QueryFilterType } from 'utils/proptypes';

import base from '../baseApi';

export type UpdateNotesPaymentType = {
  notes: NoteType[];
};

const { urls, methods, execute } = base();

const getPaymentsByFilter = async (queryFilter: QueryFilterType) => {
  const { page, limit, searchQuery, f_startDate, f_endDate, startReceivedDate, endReceivedDate, f_status } = queryFilter;
  const method = methods.GET;
  const params: any = Object.fromEntries(Object.entries(queryFilter).filter(([key, value]) => !!value));
  const queryString = new URLSearchParams(params);
  const url = `${urls.payment.payments}?${queryString.toString()}`;
  return execute(method, url, { authorization: true });
};

const updateNotesPayment = (id: string, payload: UpdateNotesPaymentType) => {
  const method = methods.PUT;
  const url = `${urls.payment.notes}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

export { getPaymentsByFilter, updateNotesPayment };
