import { LoginPage, LogoutPage, UpdateProfile, VersionPage } from 'pages';
import ReportsPage from 'pages/accountant/reports/reports';
import AdditionalSettings from 'pages/additional-settings';
import UsersManagementPage from 'pages/admin/usersManagement/usersManagement';
import RenderLoanDetail from 'pages/loanFundManager/loans/components/RenderLoanDetail';
import { RenderPartnerDetail } from 'pages/loanFundManager/partners/components';
import PartnersPage from 'pages/loanFundManager/partners/partners';
import NotAuthorizedPage from 'pages/notAuthorizedPage';
import NotFoundPage from 'pages/notFound';
import { RenderSupporterDetail } from 'pages/supporterManager/supporters/components';
import SupporterManagerPage from 'pages/supporterManager/supporters/supporterManager';
import { ROUTE_PATHS } from 'utils/constants';
import { RouteType } from 'utils/proptypes';

import LoanPaymentPage from '../pages/accountant/paymentsRecord/loanPaymentsPage';
import LoanDetailPdf from '../pages/loanFundManager/loans/components/loanDetailPdf';
import RenderInvestmentDetail from '../pages/supporterManager/investments/components/RenderInvestmentDetail';

const routes: RouteType[] = [
  {
    path: ROUTE_PATHS.VERSION_PAGE,
    component: VersionPage,
    isGuarded: false,
    children: [],
  },
  {
    path: ROUTE_PATHS.LOGIN_PAGE,
    component: LoginPage,
    isGuarded: false,
    children: [],
    title: 'WCCN',
  },
  {
    path: ROUTE_PATHS.LOGOUT_PAGE,
    component: LogoutPage,
    isGuarded: false,
    children: [],
  },
  {
    path: ROUTE_PATHS.DASHBOARD_PAGE,
    layout: 'MainLayout',
    component: PartnersPage,
    isGuarded: true,
    children: [],
  },
  {
    path: ROUTE_PATHS.PROFILE_PAGE,
    layout: 'MainLayout',
    component: UpdateProfile,
    isGuarded: false,
    children: [],
  },
  {
    path: ROUTE_PATHS.NOT_FOUND_PAGE,
    component: NotFoundPage,
    isGuarded: false,
    children: [],
  },
  {
    path: ROUTE_PATHS.NOT_AUTHORIZED,
    component: NotAuthorizedPage,
    isGuarded: false,
    children: [],
  },
  {
    path: ROUTE_PATHS.USERS_PAGE,
    component: UsersManagementPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
    isAdmin: true,
  },
  {
    path: ROUTE_PATHS.SUPPORTER_MANAGER_PAGE,
    component: SupporterManagerPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: ROUTE_PATHS.PARTNERS_PAGE,
    component: PartnersPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: `${ROUTE_PATHS.PARTNER_DETAIL}`,
    component: RenderPartnerDetail,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: `${ROUTE_PATHS.LOAN_DETAIL}`,
    component: RenderLoanDetail,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: `${ROUTE_PATHS.LOAN_DETAIL_PDF}`,
    component: LoanDetailPdf,
    isGuarded: true,
    children: [],
  },
  {
    path: ROUTE_PATHS.REPORTS_PAGE,
    component: ReportsPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: ROUTE_PATHS.PAYMENT_PAGE,
    component: LoanPaymentPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: `${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE_DETAIL}`,
    component: RenderSupporterDetail,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: `${ROUTE_PATHS.INVESTMENT_DETAIL}`,
    component: RenderInvestmentDetail,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: `${ROUTE_PATHS.ADDITIONAL_SETTINGS}`,
    component: AdditionalSettings,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
    isAdmin: true,
  },
];

export default routes;
