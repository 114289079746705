import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { FilterPaymentsValueType, QueryFilterType } from 'utils/proptypes';

import base from '../baseApi';

export type AddPaymentType = {
  loanId: string;
  principalAmount: number;
  interestAmount: number;
  paymentReceivedAmount: number;
  paymentDate: string;
  paymentReceived: string;
  status: string;
};

export type RecordPaymentType = {
  loanId: string;
  paymentReceivedAmount: number;
  paymentReceived: string;
  paymentDate: string;
  status: string;
};

export type UpdatePaymentRecordType = {
  paymentReceivedAmount: number;
  paymentReceived: string;
};

type GetPaymentsByLoanIdType = QueryFilterType & {
  loanId?: string;
};

export type changePaymentStatusByIdType = {
  status: string;
};

const { urls, methods, execute } = base();

const createPayment = (payload: AddPaymentType) => {
  const method = methods.POST;
  const url = urls.payment.payments;
  return execute(method, url, { authorization: true }, payload as object);
};

const recordPayment = (payload: RecordPaymentType) => {
  const method = methods.POST;
  const url = urls.payment.recordPayment;
  return execute(method, url, { authorization: true }, payload as object);
};

const getPaymentsByFilter = async (queryFilter: QueryFilterType) => {
  const { page = DEFAULT_VALUE_FILTER.PAGE, limit = DEFAULT_VALUE_FILTER.LIMIT, searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY } = queryFilter;
  const method = methods.GET;
  const url = `${urls.payment.payments}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  return execute(method, url, { authorization: true });
};

const getPaymentById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.payment.payments}/${id}`;
  return execute(method, url, { authorization: true });
};

const getPaymentsByLoanId = (queryFilterById: GetPaymentsByLoanIdType) => {
  const {
    page = DEFAULT_VALUE_FILTER.PAGE,
    limit = DEFAULT_VALUE_FILTER.LIMIT,
    searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY,
    loanId,
  } = queryFilterById;

  const method = methods.GET;
  const url = `${urls.payment.paymentsByLoanId}/${loanId}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  return execute(method, url, { authorization: true });
};

const getPaymentsScheduleByLoanId = (queryFilterById: GetPaymentsByLoanIdType) => {
  const { page, limit, searchQuery, loanId } = queryFilterById;
  const method = methods.GET;
  const url = `${urls.payment.paymentsScheduleByLoanId}/${loanId}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  return execute(method, url, { authorization: true });
};

const updatePaymentById = (id: string, payload: AddPaymentType) => {
  const method = methods.PATCH;
  const url = `${urls.payment.payments}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const changePaymentStatusById = (id: string, payload: changePaymentStatusByIdType) => {
  const method = methods.PUT;
  const url = `${urls.payment.status}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const removePayment = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.payment.payments}/${id}`;

  return execute(method, url, { authorization: true });
};

const exportPaymentsToCSV = async (payload: FilterPaymentsValueType & { searchQuery: string }) => {
  const method = methods.POST;
  const url = urls.payment.exportPaymentsToCSV;
  return execute(method, url, { authorization: true }, payload);
};

export {
  createPayment,
  recordPayment,
  getPaymentsByFilter,
  getPaymentById,
  updatePaymentById,
  removePayment,
  getPaymentsByLoanId,
  changePaymentStatusById,
  getPaymentsScheduleByLoanId,
  exportPaymentsToCSV,
};
