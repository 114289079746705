import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCash } from 'react-icons/hi';
import { useMutation, useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { getPortfolioReports, updateReference, UpdateReferencePayloadType } from 'api/accountant/reportApi';
import { CardIndexType } from 'components/common/CardIndex';
import CardIndexGroup from 'components/common/CardIndexGroup';
import PaginateTable from 'components/table/paginate';
import { Button } from 'flowbite-react';
import useLoading from 'hooks/useLoading';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME, FORMAT_DATE_API, FORMAT_DATE_SHOW_TABLE, TYPE_INPUT } from 'utils/constants';
import { FilterReportsValueType, FilterType, OptionType, PieChartDataType, PortfolioReportsResponse } from 'utils/proptypes';
import {
  convertNumberToCurrency,
  exportToFile,
  getNumberValueFromApi,
  getNumberValueSendToApi,
  getPieChartOptions,
  messageErrors,
} from 'utils/utils';
import { read, utils } from 'xlsx';

import HeaderTableReports from './components/headerTableReports';
import GroupPieChartsCustom from './components/portfolioReports/groupPieChart';
import RenderPortfolioReportsTableData from './components/portfolioReports/renderPortfolioReportsTable';
import PortfolioReportPdfPage from './portfolioReportsPdf';

import '../../../../styles/styles.scss';

const PortfolioReportPage = () => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntities, setTotalEntities] = useState(0);
  const [totalEntitiesNonPerforming, setTotalEntitiesNonPerforming] = useState(0);
  const limit = 100;
  const [portfolioReportsPerformingData, setPortfolioReportsPerformingData] = useState<PortfolioReportsResponse>();
  const [portfolioReportsNonPerformingData, setPortfolioReportsNonPerformingData] = useState<PortfolioReportsResponse>();
  const [pieChartDataGroup, setPieChartDataGroup] = useState<{
    reportDate: string;
    dataPieCharts: PieChartDataType[];
  }>();
  const [filterValue, setFilterValue] = useState<Partial<FilterReportsValueType>>({
    reportDate: moment().format(FORMAT_DATE_API),
  });

  const [__html, setHtml] = useState('');
  const tbl = useRef(null);
  const ref = useRef(null);
  const refReportPDF = useRef(null);

  const handleDownloadPDF = useReactToPrint({
    content: () => refReportPDF.current,
    documentTitle: `PORTFOLIO REPORT REPORT ${filterValue.reportDate}`,
    pageStyle: `
    @page {
      size: A4 landscape;
      margin: 0;
    }
  `,
    copyStyles: true,
  });
  const { t } = useTranslation();

  const filterArray: FilterType[] = [
    {
      placeholder: t('reportDates'),
      type: 'date',
      isSelectRangeDate: false,
      defaultValue: moment().format(FORMAT_DATE_API),
    },
  ];

  const { isFetching, refetch: refetchPortfolioReports } = useQuery(
    ['portfolioReports', currentPage, filterValue],
    () => getPortfolioReports({ page: currentPage, limit, ...filterValue }),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.entities?.length as number);
        setPortfolioReportsPerformingData({
          ...data,
        });
        const countryChartData = data.charts?.country?.length
          ? data.charts?.country?.map((item: OptionType) => ({
              name: item.label,
              value: getNumberValueFromApi(item.value as number),
            }))
          : [];
        const sectorChartData = data.charts?.sector?.length
          ? data.charts?.sector?.map((item: OptionType) => ({
              name: item.label,
              value: getNumberValueFromApi(item.value as number),
            }))
          : [];
        const partnerAssetSize = !isEmpty(data.charts?.partnerAssetSize) ? getPieChartOptions(data.charts?.partnerAssetSize) : [];
        const scorecards = !isEmpty(data.charts?.scorecard) ? getPieChartOptions(data.charts?.scorecard) : [];
        const dataPieChartGroup = {
          reportDate: moment.utc(filterValue.reportDate).format(FORMAT_DATE_SHOW_TABLE),
          dataPieCharts: [
            {
              title: t('WCCNPorfolioAllocationByCountry'),
              data: [...countryChartData],
            },
            {
              title: t('WCCNPorfolioAllocationBySector'),
              data: [...sectorChartData],
            },
            {
              title: t('WCCNPorfolioAllocationByPartnerAssetSize'),
              data: [...partnerAssetSize],
            },
            {
              title: t('WCCNPorfolioAllocationByScorecard'),
              data: [...scorecards],
            },
          ],
        };
        setPieChartDataGroup({ ...dataPieChartGroup });
        const f = new ArrayBuffer(data.entities);
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const dataPortfolioReport = utils.sheet_to_html(ws);
        setHtml(dataPortfolioReport);
      },
      onError: () => {
        setPortfolioReportsPerformingData(undefined);
        setTotalEntities(0);
      },
    },
  );

  const { isFetching: fetchingNonPerformingPortfolio, refetch: refetchNonPerformingPortfolioReports } = useQuery(
    ['nonPerformingPortfolioReports', currentPage, filterValue],
    () => getPortfolioReports({ page: currentPage, limit, ...filterValue, isNonPerforming: 1 }),
    {
      onSuccess: ({ data }) => {
        setTotalEntitiesNonPerforming(data.entities?.length as number);
        setPortfolioReportsNonPerformingData({
          ...data,
        });
      },
    },
  );

  const handleUpdateReference = async (referencePayload: Partial<UpdateReferencePayloadType>) => {
    await updateReference(referencePayload);
  };

  const mutation = useMutation('update-report-reference', {
    mutationFn: handleUpdateReference,
  });

  const updateReferenceClick = ({ fieldNameInput, value }: { fieldNameInput: string; value: number }) => {
    mutation.mutate(
      { [fieldNameInput]: getNumberValueSendToApi(value) },
      {
        onSuccess: async () => {
          const message: string = t('reportPage.editReferenceSuccessMessage');
          toast.success(message);
          await refetchPortfolioReports();
          await refetchNonPerformingPortfolioReports();
        },
        onError: (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const cardIndexData: CardIndexType[] = [
    {
      inputType: TYPE_INPUT.TEXT,
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'capitalForCommunitiesFund',
      bgColor: 'bg-yellow-50',
      bgIconColor: 'bg-yellow-400',
      textIconColor: 'text-white',
      label: t('capitalForCommunitiesFund'),
      value: portfolioReportsPerformingData?.capitalForCommunitiesFund
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.capitalForCommunitiesFund))
        : '',
      icon: HiOutlineCash,
    },
    {
      inputType: TYPE_INPUT.TEXT,
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'capitalForCommunitiesCommunityNeedsLLC',
      bgColor: 'bg-purple-50',
      bgIconColor: 'bg-purple-500',
      textIconColor: 'text-white',
      label: t('communityNeedsLLC'),
      value: portfolioReportsPerformingData?.capitalForCommunitiesCommunityNeedsLLC
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.capitalForCommunitiesCommunityNeedsLLC))
        : '',
      icon: HiOutlineCash,
    },
    {
      inputType: TYPE_INPUT.TEXT,
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'totalLoanPortfolio',
      bgColor: 'bg-green-100',
      bgIconColor: 'bg-green-400',
      textIconColor: 'text-white',
      label: t('totalLoanPortfolio'),
      value: portfolioReportsPerformingData?.totalLoanPortfolio
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.totalLoanPortfolio))
        : '',
      icon: HiOutlineCash,
    },
    {
      inputType: 'currency',
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'totalAssets',
      bgColor: 'bg-red-50',
      bgIconColor: 'bg-red-500',
      textIconColor: 'text-white',
      label: t('totalWCCNAssets'),
      value: portfolioReportsPerformingData?.totalAssets
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.totalAssets))
        : '',
      icon: HiOutlineCash,
    },
  ];

  useLoading({ isLoading: isFetching, ref });

  const exportPortfolioReportHandler = useCallback(() => {
    exportToFile(tbl, EXPORT_FILE_NAME.PORTFOLIO_REPORT, true);
  }, [tbl]);

  return (
    <>
      <div className="portfolio-report">
        <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
        <HeaderTableReports<FilterReportsValueType>
          className="portfolio-report"
          hideFilter={false}
          setCurrentPage={setCurrentPage}
          setFilterValue={setFilterValue}
          filterArray={filterArray}
        />
        <GroupPieChartsCustom data={pieChartDataGroup as { reportDate: string; dataPieCharts: PieChartDataType[] }} />
        <CardIndexGroup cardIndexData={cardIndexData} className="mt-4 grid md:grid-cols-2 xl:grid-cols-4 gap-4" />
        <div className="portfolio-report__table mt-4">
          <RenderPortfolioReportsTableData
            dangerouslySetInnerHTML={{ __html }}
            tbl={tbl}
            portfolioReportsResponse={portfolioReportsPerformingData}
            isFetching={isFetching}
            filterValue={filterValue}
          />
        </div>
        {totalEntities > 0 && (
          <PaginateTable
            className="portfolio-report__pagination"
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isFetching={isFetching}
            exportHandler={exportPortfolioReportHandler}
            items={portfolioReportsPerformingData}
          />
        )}

        <div className="portfolio-report__table mt-4">
          <RenderPortfolioReportsTableData
            dangerouslySetInnerHTML={{ __html }}
            tbl={tbl}
            portfolioReportsResponse={portfolioReportsNonPerformingData}
            isFetching={fetchingNonPerformingPortfolio}
            filterValue={filterValue}
            isNonPerforming={true}
          />
        </div>
        {totalEntitiesNonPerforming > 0 && (
          <PaginateTable
            className="portfolio-report__pagination"
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntitiesNonPerforming}
            isFetching={fetchingNonPerformingPortfolio}
            exportHandler={exportPortfolioReportHandler}
            items={portfolioReportsNonPerformingData}
          />
        )}
        {(totalEntities > 0 || totalEntitiesNonPerforming > 0) && (
          <div className="portfolio-report__report-buttons" style={{ marginTop: '1rem' }}>
            <Button className="donwnload-button text-white font-normal" onClick={() => exportPortfolioReportHandler()}>
              {t('downloadCSVReports')}
            </Button>
            <Button className="donwnload-button text-white font-normal" onClick={() => handleDownloadPDF()}>
              {t('downloadPDF')}
            </Button>
          </div>
        )}
      </div>
      <div className="download-pdf-content" ref={refReportPDF}>
        <PortfolioReportPdfPage />
      </div>
    </>
  );
};
export default PortfolioReportPage;
