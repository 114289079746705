import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { Dropdown, Navbar } from 'flowbite-react';
import useUser from 'hooks/useUser';
import { ROUTE_PATHS } from 'utils/constants';

import { userSelector } from '../../redux/selectors';
import { USER_ROLES } from '../../utils/constants';

import './navbar.scss';

const logoWccnMobileTablet = require('../../assets/image/svg/logo-wccn-moblie-tablet.svg').default;
const logoWccn = require('../../assets/image/svg/logo-wccn.svg').default;

type NavBarComponentProps = {
  isDashboard?: boolean;
};

const NavBarComponent = (props: NavBarComponentProps) => {
  const { isDashboard = false } = props;
  const { user } = useAuth0();
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { userRole, firstName, lastName } = userInfo;
  const { handleLogout } = useUser();
  const navigate = useNavigate();

  const redirectUri = (url: string) => {
    navigate(url);
  };

  return (
    <Navbar fluid={false} rounded={true} className={classNames('navbar flex items-center shadow-md sticky top-0 z-10')}>
      <div className="navbar__logo">
        <a
          aria-labelledby="flowbite-navbar-logo-:r8:"
          className={classNames('flex items-center justify-center navbar__logo--link', {
            'link-dashboard': isDashboard,
          })}
          href="/"
        >
          <img alt="MileMarker logo" className={classNames('h-18 sm:h-18 img-desktop')} src={logoWccn} />
          <img alt="MileMarker logo" className={classNames('h-9 sm:h-9 img-tablet-mobile')} src={logoWccnMobileTablet} />
        </a>
      </div>
      <div className="flex flex-1 justify-end py-1 container__dropdown">
        <Dropdown
          arrowIcon={false}
          inline={true}
          label={
            <div className="flex items-center">
              <span className="mr-2">
                <img className="w-10 h-10 overflow-hidden rounded-full object-cover" src={userInfo?.image?.url || user?.picture} alt="avatar" />
              </span>
              <IoIosArrowDown size={24} />
            </div>
          }
        >
          <Dropdown.Item>
            <div>
              <span className="block text-sm text-left text-gray-500 capitalize">
                {firstName && lastName ? `${firstName} ${lastName}` : user?.nickname}
              </span>
              <span className="block truncate text-sm text-gray-300">{user?.email}</span>
            </div>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => redirectUri(ROUTE_PATHS.PROFILE_PAGE)}>{t('navbar.userProfile')}</Dropdown.Item>
          {userRole?.includes(USER_ROLES.SUPER_ADMIN) && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => redirectUri(ROUTE_PATHS.USERS_PAGE)}>
                <span data-testid="navbarAdmin" className="block truncate text-sm text-gray-700">
                  {t('navbar.admin')}
                </span>
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleLogout()}>
            <span className="text-red-500">{t('logout')}</span>
          </Dropdown.Item>
        </Dropdown>
      </div>
    </Navbar>
  );
};
export default NavBarComponent;
