import { ROUTE_PATHS, USER_ROLES } from './constants';

const PERMISSION_PROFILES = [
  {
    type: USER_ROLES.STANDARD_USER,
    defaultRedirectUrl: ROUTE_PATHS.PARTNERS_PAGE,
    permittedRoutes: [
      ROUTE_PATHS.FILE_REVIEW_PAGE,
      ROUTE_PATHS.DASHBOARD_PAGE,
      ROUTE_PATHS.PROFILE_PAGE,
      ROUTE_PATHS.UPDATE_PROFILE,
      ROUTE_PATHS.VERSION_PAGE,
    ],
    customActions: null,
  },
  {
    type: USER_ROLES.SUPER_ADMIN,
    defaultRedirectUrl: ROUTE_PATHS.PARTNERS_PAGE,
    permittedRoutes: [
      ROUTE_PATHS.FILE_REVIEW_PAGE,
      ROUTE_PATHS.DASHBOARD_PAGE,
      ROUTE_PATHS.PROFILE_PAGE,
      ROUTE_PATHS.UPDATE_PROFILE,
      ROUTE_PATHS.VERSION_PAGE,
      ROUTE_PATHS.USERS_PAGE,
      ROUTE_PATHS.SUPPORTER_MANAGER_PAGE,
      ROUTE_PATHS.SUPPORTER_MANAGER_PAGE_DETAIL,
      ROUTE_PATHS.REPORTS_PAGE,
      ROUTE_PATHS.PARTNERS_PAGE,
      ROUTE_PATHS.PARTNER_DETAIL,
      ROUTE_PATHS.LOAN_DETAIL,
    ],
    customActions: null,
  },
  {
    type: USER_ROLES.LOAN_FUND_MANAGER,
    defaultRedirectUrl: ROUTE_PATHS.PARTNERS_PAGE,
    permittedRoutes: [
      ROUTE_PATHS.FILE_REVIEW_PAGE,
      ROUTE_PATHS.DASHBOARD_PAGE,
      ROUTE_PATHS.PROFILE_PAGE,
      ROUTE_PATHS.UPDATE_PROFILE,
      ROUTE_PATHS.VERSION_PAGE,
      ROUTE_PATHS.PARTNERS_PAGE,
      ROUTE_PATHS.PARTNER_DETAIL,
      ROUTE_PATHS.LOAN_DETAIL,
    ],
    customActions: null,
  },
  {
    type: USER_ROLES.SUPPORTER_MANAGER,
    defaultRedirectUrl: ROUTE_PATHS.SUPPORTER_MANAGER_PAGE,
    permittedRoutes: [
      ROUTE_PATHS.FILE_REVIEW_PAGE,
      ROUTE_PATHS.DASHBOARD_PAGE,
      ROUTE_PATHS.PROFILE_PAGE,
      ROUTE_PATHS.UPDATE_PROFILE,
      ROUTE_PATHS.VERSION_PAGE,
      ROUTE_PATHS.SUPPORTER_MANAGER_PAGE,
      ROUTE_PATHS.SUPPORTER_MANAGER_PAGE_DETAIL,
    ],
    customActions: null,
  },
  {
    type: USER_ROLES.ACCOUNTANT,
    defaultRedirectUrl: ROUTE_PATHS.REPORTS_PAGE,
    permittedRoutes: [
      ROUTE_PATHS.FILE_REVIEW_PAGE,
      ROUTE_PATHS.DASHBOARD_PAGE,
      ROUTE_PATHS.PROFILE_PAGE,
      ROUTE_PATHS.UPDATE_PROFILE,
      ROUTE_PATHS.VERSION_PAGE,
      ROUTE_PATHS.REPORTS_PAGE,
    ],
    customActions: null,
  },
];

export { PERMISSION_PROFILES };
