

import base from './baseApi';

const { urls, methods, execute } = base();

const getAdditionalSettings = () => {
    const method = methods.GET;
    const url = `${urls.additionalSettings.additionalSettings}`;
    return execute(method, url, { authorization: true });
};

const updateAdditionalSettings = (payload: any) => {
    const method = methods.POST;
    const url = `${urls.additionalSettings.additionalSettings}`;
    return execute(method, url, { authorization: true }, payload);
};
export {
    getAdditionalSettings,
    updateAdditionalSettings
}