import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loansOfPartner: { entities: [], totalEntities: 0, rowPerPages: 0, currentPage: null },
};

const setLoansOfPartner = (state: any, action: any) => {
  state.loansOfPartner.entities = action.payload?.entities;
  state.loansOfPartner.totalEntities = action.payload?.totalEntities;
  state.loansOfPartner.rowPerPages = action.payload?.rowPerPages;
  state.loansOfPartner.currentPage = action.payload?.currentPage;
};

export const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    setLoansOfPartner,
  },
});

export default loanSlice;
