import { useTranslation } from 'react-i18next';
import { LoanType } from 'utils/proptypes';

type loanCollateralType = { selectedLoan?: LoanType; isFetching: boolean };

const LoanCollateral = (props: loanCollateralType) => {
  const { selectedLoan, isFetching } = props;
  const [t] = useTranslation()
  return (
    <div className="collateral__container flex flex-col justify-start items-center mb-3">
      <h3 className="collateral__container--title uppercase w-full leading-none text-gray-900 text-base font-semibold text-start bg-yellow-100 p-4 rounded-t">
        {t('collateral')}
      </h3>
      <div className="collateral__container--content w-full bg-yellow-50 rounded-b">
        <div className="w-full space-y-4 px-4 py-4 ">
          <p className="content-text leading-4 text-gray-900 text-sm font-normal">{!isFetching && selectedLoan?.collateral}</p>
        </div>
      </div>
    </div>
  );
};

export default LoanCollateral;
