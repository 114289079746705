import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getLoansByPartnerId } from 'api/loanFundManager/loanApi';
import { getPartnersByFilter } from 'api/loanFundManager/partnerApi';
import { recordPayment, RecordPaymentType } from 'api/loanFundManager/paymentApi';
import GroupButton from 'components/button/groupButton';
import CustomAsyncSelectForm from 'components/form/asyncSelectForm/customAsyncSelectForm';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { DEFAULT_VALUE_FILTER, FORMAT_DATE_API, PAYMENT_STATUS_OPTIONS, SEARCH_PARTNER_VALUE_KEY } from 'utils/constants';
import { LoanType, OptionType } from 'utils/proptypes';
import { getNumberValueSendToApi, messageErrors, selectDateHandler, selectItemHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import './recordPaymentModal.scss';
import '../../../styles/common/multiSelect.scss';

type RecordPaymentModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  refetchApi: Function;
  setSearchPartnerQuery: Function;
  setCurrentPartnerPage: Function;
  setPartnerOptions: Function;
  partnerOptions: OptionType[];
  searchPartnerQuery: string;
  currentPartnerPage: number;
  isFetchingPartner: boolean;
};

export type RecordPaymentFormType = {
  loan: OptionType[];
  paymentReceivedAmount: number;
  paymentReceived: string;
  paymentDate: string;
  status: OptionType[];
};

const RecordPaymentModal = (props: RecordPaymentModalTypes) => {
  const {
    openModal = false,
    setOpenModal,
    refetchApi,
    setSearchPartnerQuery,
    setCurrentPartnerPage,
    searchPartnerQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY,
    currentPartnerPage = DEFAULT_VALUE_FILTER.PAGE,
    isFetchingPartner = false,
    setPartnerOptions,
    partnerOptions = [],
  } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [partnerId, setPartnerId] = useState('');

  const ValidateSchema = Yup.object().shape({
    partner: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('paymentPage.requiredField')),
        }),
      )
      .min(1, t('paymentPage.requiredField')),
    loan: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('paymentPage.requiredField')),
        }),
      )
      .min(1, t('paymentPage.requiredField')),
    paymentReceivedAmount: Yup.number()
      .min(0, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .required(t('paymentPage.requiredField'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    paymentReceived: Yup.date().nullable().required(t('paymentPage.requiredField')),
    status: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('paymentPage.requiredField')),
        }),
      )
      .min(1, t('paymentPage.requiredField')),
  });

  const initialValues: any = useMemo(() => {
    return {
      partner: [],
      loan: [],
      paymentReceivedAmount: undefined,
      paymentReceived: '',
      status: [],
      isResetForm,
    };
  }, [isResetForm]);

  const { data: loansStore } = useQuery(['loans', partnerId], () => getLoansByPartnerId({ limit: 0, partnerId }), {
    enabled: !!partnerId,
  });

  const loanOptions: OptionType[] = useMemo(() => {
    return loansStore?.data.entities?.map((loan: LoanType) => ({ label: loan.name, value: loan.id }));
  }, [loansStore?.data.entities]);

  const handleRecordPayment = async (paymentPayload: RecordPaymentType) => {
    await recordPayment(paymentPayload);
  };

  const mutation = useMutation('record-payment', {
    mutationFn: handleRecordPayment,
  });

  const handleSubmit = (data: RecordPaymentFormType, action: { [key: string]: any }) => {
    const successCallback = (message: string) => {
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      refetchApi();
    };
    const tempData = {
      loanId: data.loan[0].value as string,
      paymentReceivedAmount: getNumberValueSendToApi(data.paymentReceivedAmount),
      paymentReceived: moment(data.paymentReceived).format(FORMAT_DATE_API),
      paymentDate: moment(data.paymentDate).format(FORMAT_DATE_API),
      status: data.status[0].value as string,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...tempData,
        },
        {
          onSuccess: () => {
            const message: string = t('paymentPage.recordSuccessMessage');
            successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<RecordPaymentFormType>) => {
    setOpenModal(!openModal);
    localStorage.setItem(SEARCH_PARTNER_VALUE_KEY, '');
    props.resetForm();
    props.setErrors({});
  };

  const onKeyDown = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  useEffect(() => {
    if (openModal) {
      setIsResetForm(!isResetForm);
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const handleChangeLoanAfterChoosePartner = (props: any) => {
    props.setFieldValue('loan', []);
  };

  return (
    <Modal
      id="record-payment-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        localStorage.setItem(SEARCH_PARTNER_VALUE_KEY, '');
        setOpenModal(!openModal);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('recordPayment')}</Modal.Header>
      <Modal.Body>
        <Formik enableReinitialize onSubmit={handleSubmit} initialValues={initialValues} validationSchema={ValidateSchema} validateOnChange={true}>
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4" onKeyDown={onKeyDown}>
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <CustomAsyncSelectForm
                    label={t('partner')}
                    id="partner"
                    name="partner"
                    placeHolder={t('partner')}
                    selectItemsHandler={async (items: any, props: FormikProps<RecordPaymentFormType>) => {
                      await selectItemHandler<RecordPaymentFormType>({ items, props, fieldName: 'partner' });
                      setPartnerId(items[0].value);
                      handleChangeLoanAfterChoosePartner(props);
                    }}
                    propsFormik={props}
                    isRequired={true}
                    isMulti={false}
                    fetchApi={getPartnersByFilter}
                    isResetAll={openModal}
                    isFetching={isFetchingPartner}
                    currentPage={currentPartnerPage}
                    setCurrentPage={setCurrentPartnerPage}
                    searchQuery={searchPartnerQuery}
                    setSearchQuery={setSearchPartnerQuery}
                    partnerOptions={partnerOptions}
                    setPartnerOptions={setPartnerOptions}
                  />
                  <CustomSelectForm
                    label={t('loan')}
                    id="loan"
                    name="loan"
                    placeHolder={t('loan')}
                    options={loanOptions}
                    selectItemsHandler={(items: any, props: FormikProps<RecordPaymentFormType>) =>
                      selectItemHandler<RecordPaymentFormType>({ items, props, fieldName: 'loan' })
                    }
                    propsFormik={props}
                    isRequired={true}
                    isMulti={false}
                  />
                  <CurrencyForm
                    id="paymentReceivedAmount"
                    name="paymentReceivedAmount"
                    label={t('amountReceived')}
                    placeholder={t('amountReceived')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<RecordPaymentFormType>,
                    ) => {
                      await setCurrencyValue<RecordPaymentFormType>({ currency, props });
                    }}
                    isRequired={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CustomDatePickerForm
                    id="paymentReceived"
                    name="paymentReceived"
                    label={t('receivedDate')}
                    placeHolder={t('receivedDate')}
                    propsFormik={props}
                    selectItemsHandler={(date: Date) =>
                      selectDateHandler<RecordPaymentFormType>({
                        date,
                        props,
                        fieldName: 'paymentReceived',
                      })
                    }
                    isRequired={true}
                    isDeleteSelectedDate={openModal}
                  />
                  <CustomDatePickerForm
                    id="paymentDate"
                    name="paymentDate"
                    label={t('dueDate')}
                    placeHolder={t('dueDate')}
                    propsFormik={props}
                    selectItemsHandler={(date: Date) =>
                      selectDateHandler<RecordPaymentFormType>({
                        date,
                        props,
                        fieldName: 'paymentDate',
                      })
                    }
                    isRequired={true}
                    isDeleteSelectedDate={openModal}
                  />
                  <CustomSelectForm
                    label={t('status')}
                    id="status"
                    name="status"
                    placeHolder={t('status')}
                    options={PAYMENT_STATUS_OPTIONS}
                    selectItemsHandler={(items: any, props: FormikProps<RecordPaymentFormType>) =>
                      selectItemHandler<RecordPaymentFormType>({ items, props, fieldName: 'status' })
                    }
                    propsFormik={props}
                    isRequired={true}
                    isMulti={false}
                    disableSearch={true}
                  />
                </div>
                <GroupButton
                  className="w-full gap-4 justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => closeModalHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default RecordPaymentModal;
