import classNames from 'classnames';
import { Label } from 'flowbite-react';
import { ErrorMessage, FormikProps } from 'formik';

type TextFromType = {
  label: string;
  id: string;
  placeholder: string;
  name: string;
  propsFormik: FormikProps<any>;
  isDisabled?: boolean;
  isEdit?: boolean;
  isRequired?: boolean;
  dataTestId?: string;
  setCurrentValue: Function;
};
const TextForm = ({ id, placeholder, name, propsFormik, isDisabled = false, isRequired = false, dataTestId, setCurrentValue }: TextFromType) => {
  const borderError = 'border-red-500 focus:border-red-500 dark:border-red-600 dark:focus:border-red-500 focus:ring-red-500 dark:focus:ring-red-500';
  const borderSuccess = 'border-gray-200 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500';
  return (
    <div className={`${id} relative text-form w-full`}>
      <input
        id={id}
        type="text"
        name={name}
        value={propsFormik.values[name]}
        onChange={e => {
          setCurrentValue({ value: e.target.value, name }, propsFormik);
        }}
        className={classNames(
          `${
            propsFormik.errors[name] && propsFormik.touched[name] ? borderError : borderSuccess
          } border block w-full disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 py-4 text-sm rounded`,
          { haveValue: propsFormik?.values[name] },
        )}
        disabled={isDisabled}
      />
      <div
        className={classNames('text-form__label absolute block bg-white', {
          disabled: isDisabled,
          haveValue: propsFormik?.values[name],
        })}
      >
        <Label value={placeholder} />
        {isRequired && !isDisabled && <span className="text-red-500 text-xs"> *</span>}
      </div>
      {propsFormik.errors[name] && propsFormik.touched[name] && (
        <ErrorMessage data-testid={dataTestId} className="text-red-500 text-xs mt-1" name={name} component="p" />
      )}
    </div>
  );
};

export default TextForm;
