import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine } from 'react-icons/ri';
import { MultiSelect } from 'react-multi-select-component';
import { MultiValue } from 'react-select';
import classNames from 'classnames';
import { Label } from 'flowbite-react';
import { ErrorMessage, FormikProps } from 'formik';
import { OptionType } from 'utils/proptypes';

import { CustomValueRenderer } from './custom/customValueRenderer';

type MultiSelectFormType = {
  label: string;
  id: string;
  name: string;
  placeHolder: string;
  options: OptionType[];
  selectItemsHandler: Function;
  propsFormik: FormikProps<any>;
  isMulti: boolean;
  isDisabled?: boolean;
  isEdit?: boolean;
  isRequired?: boolean;
  dataTestId?: string;
  disableSearch?: boolean;
};
const CustomSelectForm = ({
  name,
  placeHolder,
  options,
  selectItemsHandler,
  propsFormik,
  isMulti = false,
  isDisabled = false,
  isEdit = false,
  isRequired = false,
  dataTestId,
  disableSearch = false,
}: MultiSelectFormType) => {
  const customArrowRenderer = () => <RiArrowDownSLine className="open-menu-icon" />;
  const [t] = useTranslation()
  return (
    <div
      className={classNames('custom-multi-select relative w-full', {
        disabled: isDisabled,
        haveValue: propsFormik?.values[name]?.length,
      })}
    >
      <MultiSelect
        overrideStrings={{
          selectSomeItems: t('select'),
          selectAll: isMulti ? t('selectAll') : '',
          search: t('search'),
          selectAllFiltered: isMulti ? t('selectAllFiltered') : '',
          noOptions: t('noOptions'),
        }}
        ArrowRenderer={customArrowRenderer}
        options={options || []}
        value={propsFormik?.values[name] as any[]}
        onChange={(items: MultiValue<OptionType>) => {
          if (!isMulti && Array.isArray(items) && items.length > 1) {
            items.splice(0, items.length - 1);
            selectItemsHandler(items, propsFormik);
          } else {
            selectItemsHandler(items, propsFormik);
          }
        }}
        labelledBy=""
        className={classNames('multi-select', {
          error: propsFormik.errors[name] && propsFormik.touched[name],
          disabled: isDisabled,
          'single-select': !isMulti,
        })}
        hasSelectAll={isMulti}
        valueRenderer={selected =>
          CustomValueRenderer({
            selected,
            placeHolder: !isEdit ? placeHolder : '',
            isMulti,
            isRequired,
            isEdit,
          })
        }
        disabled={isDisabled}
        disableSearch={disableSearch}
        closeOnChangedValue={!isMulti}
      />
      <div
        className={classNames('custom-multi-select__label absolute block bg-white', {
          disabled: isDisabled,
          haveValue: propsFormik?.values[name]?.length,
        })}
      >
        <Label value={placeHolder} />
        {isRequired && !isDisabled && <span className="text-red-500 text-xs"> *</span>}
      </div>
      {propsFormik.errors[name] && propsFormik.touched[name] && (
        <ErrorMessage data-testid={dataTestId} className="text-red-500 text-xs mt-1" name={name} component="p" />
      )}
    </div>
  );
};

export default CustomSelectForm;
