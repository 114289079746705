import { PropsMainLayoutType } from 'utils/proptypes';

import NavbarComponent from '../../components/layouts/navbar';

import SidebarComponent from './sidebar';

import './mainLayout.scss';

const MainLayout = (props: PropsMainLayoutType) => {
  const { children } = props;

  return (
    <div className="main-layout h-fit">
      <div className="w-full flex flex-col h-full">
        <NavbarComponent />
        <div className="w-full flex bg-gray-50" style={{ minHeight: 'calc(100vh - 64px' }}>
          <SidebarComponent isAdmin={props.isAdmin} />
          <div className="mt-content-mb flex flex-col border border-gray-200 border-t-[rgba(229, 231, 235,0.1)] wrapper-content w-full bg-gray-50">
            {children}
          </div>
        </div>
        <div className="h-full block" />
      </div>
    </div>
  );
};
export default MainLayout;
