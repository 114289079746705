import { useTranslation } from 'react-i18next';
import { LoanType } from 'utils/proptypes';

import PaymentSchedulePdf from './components/paymentSchedule/PaymentSchedulePdf';

type PaymentsPagePdfType = {
  fetchLoanById: Function;
  selectedLoan?: LoanType;
  isFetchingLoan: boolean;
};

const PaymentsPagePdf = ({ selectedLoan, isFetchingLoan = false }: PaymentsPagePdfType) => {
  const [t] = useTranslation()
  return (
    <div className="payment flex flex-col h-full bg-white relative">
      <h2 className="p-3">{t('paymentSchedule')}</h2>
      <div className="payment__header flex justify-between items-center">
        <PaymentSchedulePdf selectedLoan={selectedLoan} isFetchingLoan={isFetchingLoan} />
      </div>
    </div>
  );
};

export default PaymentsPagePdf;
