import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';
import get from 'lodash/get';

import { checkLeast8Char, checkLowerUpper, checkNoSpaces, checkNumberDymbol } from '../../utils/utils';

import ErrorItem, { SUCCESS } from './errorItem';

import './styles.scss';

const FormComponent = (props: any) => {
  const [t] = useTranslation();
  const { label, id, type, placeholder, required, name, disabled, isValidatePassword, options, isFieldRequire = false } = props;
  const [focus, setFocus] = useState(false);
  const formikContext = useFormikContext();
  const value = get(formikContext, `values[${name}]`, '');
  const error = get(formikContext, `errors[${name}]`, '');
  const touched = get(formikContext, `touched[${name}]`, '');

  let border =
    error && touched
      ? 'border-red-300 focus:border-red-500 dark:border-red-600 dark:focus:border-red-500 focus:ring-red-500 dark:focus:ring-red-500'
      : 'border-gray-300 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500';

  useEffect(() => {
    setFocus(!!touched);
  }, [touched]);

  const handleFocus = () => {
    if (isValidatePassword) setFocus(true);
  };

  return (
    <div className="">
      <div className="mb-1.5">
        <Label value={label} />
      </div>
      <div className="relative w-full">
        {type === 'select' ? (
          <Field
            className={`block w-full border ${border} disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 rounded-md p-2.5 text-sm`}
            id={id}
            as={type}
            required={required}
            name={name}
            onFocus={handleFocus}
            disabled={disabled}
            component="select"
          >
            <option disabled={isFieldRequire} value="">
              {placeholder}
            </option>

            {options?.map((o: any) => {
              return (
                <option value={o.value} key={o.value}>
                  {o.label}
                </option>
              );
            })}
          </Field>
        ) : (
          <Field
            className={`block w-full border ${border} disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 rounded-md p-2.5 text-sm`}
            id={id}
            type={type}
            placeholder={placeholder}
            required={required}
            name={name}
            onFocus={handleFocus}
            disabled={disabled}
          />
        )}
      </div>
      {error ? (
        !isValidatePassword ? (
          <div className={`text-red-600 text-xs font-normal mt-1 veri-modal ${error && focus ? 'height-16' : ''}`}>{error}</div>
        ) : (
          <div className={`text-xs font-normal mt-1 veri-modal ${error && focus ? 'height-80' : ''}`}>
            <span className="text-red-600">{t('changePassword.validationTitle')}</span>
            <ErrorItem text={t('includeBothLowerAndUpperCase')} status={checkLowerUpper(value) && SUCCESS} />
            <ErrorItem text={t('includeAtLeastOneNumberAndSymbol')} status={checkNumberDymbol(value) && SUCCESS} />
            <ErrorItem text={t('useAtLeast8characters')} status={checkLeast8Char(value) && SUCCESS} />
            <ErrorItem text={t('noSpaces')} status={checkNoSpaces(value) && SUCCESS} />
          </div>
        )
      ) : null}
    </div>
  );
};
export default FormComponent;
