import { HiOutlineUpload, HiX } from 'react-icons/hi';
import { ACCEPT_IMAGE_TYPE_FILE } from 'utils/constants';
import { UploadImageTypes } from 'utils/proptypes';

import './styles.scss';
const logoUpload = require('../../assets/image/svg/logo-upload.svg').default;

const FileUpload = (props: UploadImageTypes) => {
  const { handleUploadImage, imgUrl, handleRemoveImage } = props;

  return (
    <div className="flex justify-center items-center w-fit h-fit mb-4">
      {imgUrl !== '' && imgUrl !== null ? (
        <div
          className="relative rounded-full flex flex-col justify-center items-center  bg-gray-50  shadow-md shadow-cyan-100/50 border border-gray-100"
          style={{ height: '180px', width: '180px' }}
        >
          <div
            onClick={() => handleRemoveImage()}
            className="absolute flex justify-center items-center top-0 right-0 rounded-full h-10 w-10 bg-orange-500 cursor-pointer"
          >
            <HiX className="text-white" />
          </div>
          <img className="w-full h-full preview object-cover rounded-full" src={imgUrl} alt="avatar" />
        </div>
      ) : (
        <label
          htmlFor="dropzone-file"
          className="relative rounded-full flex flex-col justify-center items-center bg-gray-50 shadow-md shadow-cyan-100/50 border border-gray-100"
          style={{ height: '180px', width: '180px' }}
        >
          <div className="rounded-full flex flex-col justify-center items-center">
            <img src={logoUpload} className="h-16 w-16 text-gray-300" alt="default avatar" />
            <div className="absolute flex justify-center items-center bottom-0 right-0 rounded-full h-10 w-10 bg-orange-500 cursor-pointer">
              <HiOutlineUpload className="h-6 w-6 text-white" />
            </div>
          </div>
          <input
            id="dropzone-file"
            type="file"
            accept={ACCEPT_IMAGE_TYPE_FILE}
            onChange={e => handleUploadImage(e)}
            className="hidden rounded-full"
          />
        </label>
      )}
    </div>
  );
};
export default FileUpload;
