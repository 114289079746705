import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { FORMAT_DATE_API, FORMAT_DATE_SHOW_TABLE } from '../utils/constants';
import { InvestmentPaymentsType, InvestmentType } from '../utils/proptypes';
import { getNumberValueFromApi } from '../utils/utils';

type UsePayOffParams = {
  newPaymentReceivedDate: Date;
  investmentPayments: InvestmentPaymentsType[];
  interestOption: string;
  selectedInvestment: InvestmentType;
};

type UsePayOffResponse = {
  receivedPayments: InvestmentPaymentsType[];
  receivedPaymentQuantity: number;
  initPaymentsSchedule: InvestmentPaymentsType[];
  amountReceived?: number;
  payPrincipal?: number;
  payInterest?: number;
  dueDate?: Date;
};
export const usePayOff = ({ newPaymentReceivedDate, investmentPayments, interestOption, selectedInvestment }: UsePayOffParams): UsePayOffResponse => {
  const [t] = useTranslation();
  const receivedPayments: InvestmentPaymentsType[] = useMemo(() => {
    return investmentPayments?.filter((payment: InvestmentPaymentsType) => payment.name && payment.amountReceived) as InvestmentPaymentsType[];
  }, [investmentPayments]);

  const receivedPaymentQuantity: number = useMemo(() => {
    return receivedPayments.length;
  }, [receivedPayments]);
  const initPaymentsSchedule = useMemo(() => {
    return investmentPayments?.filter((payment: InvestmentPaymentsType) => !payment.name);
  }, [investmentPayments]);
  const payPrincipal: number = useMemo(() => {
    return getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.principalReceived as number);
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const interestPayPerDate: number = useMemo(() => {
    return (
      (getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity]?.balance) * getNumberValueFromApi(selectedInvestment?.interestRate)) /
      100 /
      365
    );
  }, [selectedInvestment, initPaymentsSchedule, receivedPaymentQuantity]);

  const newDaysSinceLastTransaction: number = newPaymentReceivedDate
    ? moment(newPaymentReceivedDate).diff(
        moment(moment.utc(initPaymentsSchedule?.[receivedPaymentQuantity]?.dueDate).format(FORMAT_DATE_API)),
        t('days'),
      )
    : (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.transactionDays as number);
  // eslint-disable-next-line
  const payInterest: number = useMemo(() => {
    if (interestOption === 'Roll-Over') {
      let payInterestTotal = 0;
      for (let i = 0; i < initPaymentsSchedule?.length; i++) {
        const rollOverDaysSinceLastTransaction = newPaymentReceivedDate
          ? moment(newPaymentReceivedDate).diff(moment(moment.utc(initPaymentsSchedule?.[i - 1]?.dueDate).format(FORMAT_DATE_API)), t('days'))
          : (initPaymentsSchedule?.[i]?.transactionDays as number);

        if (rollOverDaysSinceLastTransaction > 0) {
          if ((rollOverDaysSinceLastTransaction as number) < initPaymentsSchedule?.[i]?.transactionDays) {
            // Last day less
            payInterestTotal +=
              ((getNumberValueFromApi(initPaymentsSchedule?.[i - 1]?.balance) * getNumberValueFromApi(selectedInvestment?.interestRate)) /
                100 /
                365) *
              rollOverDaysSinceLastTransaction;
          } else {
            payInterestTotal += getNumberValueFromApi(initPaymentsSchedule?.[i]?.interestReceived as number);
          }
        }
      }
      return payInterestTotal;
    }
    if (newPaymentReceivedDate) {
      return interestPayPerDate * newDaysSinceLastTransaction;
    }
    return getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.interestReceived as number);
    // eslint-disable-next-line
  }, [initPaymentsSchedule, receivedPaymentQuantity, interestPayPerDate, newDaysSinceLastTransaction, newPaymentReceivedDate]);

  const amountReceived = useMemo(() => {
    return interestOption !== 'Roll-Over'
      ? payInterest + payPrincipal
      : receivedPaymentQuantity === 0
      ? getNumberValueFromApi(initPaymentsSchedule?.[initPaymentsSchedule?.length - 1]?.amountReceived)
      : undefined;
    // eslint-disable-next-line
  }, [payInterest, payPrincipal, initPaymentsSchedule, interestOption, receivedPaymentQuantity]);
  const initPaymentsScheduleWithoutElement0 = initPaymentsSchedule?.filter((payment: InvestmentPaymentsType) => payment.amountReceived);
  const dueDate =
    interestOption !== 'Roll-Over'
      ? initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.dueDate
        ? moment(moment.utc(initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.dueDate).format(FORMAT_DATE_SHOW_TABLE)).toDate()
        : undefined
      : receivedPaymentQuantity === 0
      ? moment(
          moment.utc(initPaymentsScheduleWithoutElement0?.[initPaymentsScheduleWithoutElement0?.length - 1]?.dueDate).format(FORMAT_DATE_SHOW_TABLE),
        ).toDate()
      : undefined;
  return {
    receivedPayments,
    initPaymentsSchedule,
    receivedPaymentQuantity,
    amountReceived,
    payInterest,
    payPrincipal,
    dueDate,
  };
};
