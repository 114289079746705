import { MutableRefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import moment from 'moment';
import { FORMAT_DATE_SHOW_TABLE } from 'utils/constants';
import { FilterReportsValueType, PortfolioReportsResponse, PortfolioReportsType, PortfolioReportType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi, mapToLetterGrade } from 'utils/utils';

import CustomSpinner from '../../../../../../components/common/customSpinner';

type RenderPortfolioReportsTableDataType = {
  tbl: MutableRefObject<null>;
  portfolioReportsResponse?: Partial<PortfolioReportsResponse>;
  dangerouslySetInnerHTML: any;
  isFetching?: boolean;
  filterValue: Partial<FilterReportsValueType>;
  isNonPerforming?: boolean;
};

const RenderPortfolioReportsTableData = (props: RenderPortfolioReportsTableDataType) => {
  const [t] = useTranslation();

  const { tbl, portfolioReportsResponse, isFetching, filterValue, isNonPerforming = false } = props;
  const portfolioReports = useMemo(() => portfolioReportsResponse?.entities, [portfolioReportsResponse]);
  return (
    <table
      className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', {
        'h-full': !portfolioReports?.length,
      })}
      ref={tbl}
    >
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent isShowTooltip={false} anchorSelect="portfolio-report-name" content={t('partner')} className="max-w-full dark:text-white" />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-since"
              content={t('partnerSince')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent isShowTooltip={false} anchorSelect="portfolio-report-sector" content={t('sector')} className="max-w-full dark:text-white" />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-outstanding"
              content={t('totalBalanceOutstanding')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-assets"
              content={`% ${t('totalWCCNAssets')}`}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-rating"
              content={t('internalRating')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-rating-date"
              content={t('internalRatingDate')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent isShowTooltip={false} anchorSelect="portfolio-report-llr" content="LLR" className="max-w-full dark:text-white" />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-credit-limit"
              content={t('boardApprovedCreditLimit')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-expiration-date"
              content={t('creditLimitExpirationDate')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-interest-rate"
              content={t('latestInterestRate')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent isShowTooltip={false} anchorSelect="portfolio-commission" content={t('commission')} className="max-w-full dark:text-white" />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-fund-allocation"
              content={t('FUNDAllocation')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-gross-loan-portfolio"
              content={t('grossLoanPortfolio')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent
              isShowTooltip={false}
              anchorSelect="portfolio-report-net-loan-portfolio"
              content={t('netLoanPortfolio')}
              className="max-w-full dark:text-white"
            />
          </th>
          <th scope="col" className="py-3 px-6">
            <TooltipComponent isShowTooltip={false} anchorSelect="portfolio-report-par30" content={t('PAR30')} className="max-w-full dark:text-white" />
          </th>
        </tr>
      </thead>
      {portfolioReports?.length ? (
        portfolioReports?.map((portfolioReport: PortfolioReportsType, index: number) => {
          return (
            <tbody key={`${index}-list-portfolio-reports`}>
              {portfolioReport?.items?.length ? (
                portfolioReport?.items?.map((partner: PortfolioReportType, key: number) => {
                  return (
                    <tr
                      key={`${key}-list-portfolio-reports-per-country`}
                      className={`portfolio-report-${key === portfolioReports?.length - 1 ? 'last' : key
                        } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
                    >
                      <th scope="row" className="w-100 py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-name-${key}`}
                          content={partner?.partnerNickName ? partner?.partnerNickName : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-since-${key}`}
                          content={partner?.partnerSince ? partner?.partnerSince : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-sector-${key}`}
                          content={partner?.businessSector ? partner?.businessSector : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-full py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-outstanding-${key}`}
                          content={
                            partner?.totalBalanceOutstanding
                              ? (convertNumberToCurrency(getNumberValueFromApi(partner?.totalBalanceOutstanding)) as string)
                              : ''
                          }
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-assets-${key}`}
                          content={partner?.percentTotalAssets ? `${getNumberValueFromApi(partner?.percentTotalAssets).toFixed(2)}% ` : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-rating-${key}`}
                          content={partner?.internalRating ? mapToLetterGrade(getNumberValueFromApi(partner?.internalRating)).grade : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-rating-date-${key}`}
                          content={partner?.internalRatingDate ? moment.utc(partner.internalRatingDate).format(FORMAT_DATE_SHOW_TABLE) : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-llr-${key}`}
                          content={partner?.llr ? `${getNumberValueFromApi(partner?.llr).toFixed(2)}%` : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-credit-limit-${key}`}
                          content={
                            partner?.boardApprovedCreditLimit ? convertNumberToCurrency(getNumberValueFromApi(partner.boardApprovedCreditLimit)) : ''
                          }
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-expiration-date-${key}`}
                          content={
                            partner?.creditLimitExpirationDate
                              ? moment.utc(partner?.creditLimitExpirationDate).toDate() < moment.utc(filterValue?.reportDate).toDate()
                                ? 'Expired'
                                : moment.utc(partner.creditLimitExpirationDate).format(FORMAT_DATE_SHOW_TABLE)
                              : ''
                          }
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-interest-rate-${key}`}
                          content={partner?.latestInterestRate ? `${getNumberValueFromApi(partner?.latestInterestRate).toFixed(2)}%` : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-commission-${key}`}
                          content={partner?.commission ? `${getNumberValueFromApi(partner?.commission).toFixed(2)}%` : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-fund-allocation-${key}`}
                          content={partner?.fundAllocation ? partner.fundAllocation : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-gross-loan-portfolio-${key}`}
                          content={
                            partner?.grossLoanPortfolio ? (convertNumberToCurrency(getNumberValueFromApi(partner.grossLoanPortfolio)) as string) : ''
                          }
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-net-loan-portfolio-${key}`}
                          content={
                            partner?.netLoanPortfolio ? (convertNumberToCurrency(getNumberValueFromApi(partner.netLoanPortfolio)) as string) : ''
                          }
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                      <th scope="row" className="w-fit py-4 px-6 font-normal text-gray-900 portfolio-reports-page--truncate dark:text-white">
                        <TooltipComponent
                          isShowTooltip={false}
                          anchorSelect={`portfolio-report-par30-${key}`}
                          content={partner?.par30 ? `${getNumberValueFromApi(partner?.par30).toFixed(2)}%` : ''}
                          className="font-normal max-w-full dark:text-white"
                        />
                      </th>
                    </tr>
                  );
                })
              ) : (
                <tr className="h-full portfolio-report-no-result text-center">
                  <th colSpan={16} className="p-8">
                    {isFetching ? <CustomSpinner /> : <NoResult />}
                  </th>
                </tr>
              )}
              <tr className="bg-blue-50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="col" colSpan={3} className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {`${portfolioReport.country} ${t('portfolioTotal')}`}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {portfolioReport?.total?.totalBalanceOutstanding
                    ? (convertNumberToCurrency(getNumberValueFromApi(portfolioReport?.total?.totalBalanceOutstanding)) as string)
                    : ''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {portfolioReport?.total?.percentTotalAssets
                    ? `${getNumberValueFromApi(portfolioReport?.total?.percentTotalAssets).toFixed(2)}%`
                    : ''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {portfolioReport?.total?.llr ? `${getNumberValueFromApi(portfolioReport?.total?.llr).toFixed(2)}%` : ''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {portfolioReport?.total?.boardApprovedCreditLimit
                    ? (convertNumberToCurrency(getNumberValueFromApi(portfolioReport?.total?.boardApprovedCreditLimit)) as string)
                    : ''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
                <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
                  {''}
                </th>
              </tr>
            </tbody>
          );
        })
      ) : (
        <tbody>
          <tr className="h-full portfolio-report-no-result text-center">
            <th className="p-8" colSpan={16}>
              {isFetching ? <CustomSpinner /> : <NoResult />}
            </th>
          </tr>
        </tbody>
      )}
      {portfolioReports?.length ? (
        <tfoot>
          <tr className="bg-blue-200 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="col" colSpan={3} className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {isNonPerforming ? t('totalNonPerformingLoanPortfolio') : t('totalPerformingLoanPortfolio')}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {portfolioReportsResponse?.totalBalanceOutstanding
                ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsResponse.totalBalanceOutstanding))
                : ''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {portfolioReportsResponse?.totalPercentTotalAssets
                ? `${getNumberValueFromApi(portfolioReportsResponse?.totalPercentTotalAssets).toFixed(2)}%`
                : ''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {portfolioReportsResponse?.totalLlr ? `${getNumberValueFromApi(portfolioReportsResponse?.totalLlr).toFixed(2)}%` : ''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {portfolioReportsResponse?.totalBoardApprovedCreditLimit
                ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsResponse.totalBoardApprovedCreditLimit))
                : ''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
            <th scope="row" className="w-fit py-4 px-6 text-gray-900 portfolio-reports-page--truncate dark:text-white">
              {''}
            </th>
          </tr>
        </tfoot>
      ) : (
        ''
      )}
    </table>
  );
};

export default RenderPortfolioReportsTableData;
