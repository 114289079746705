import classNames from 'classnames';
import { ErrorMessage, FormikProps } from 'formik';

type CheckboxFromType = {
  name: string;
  label: string;
  propsFormik: FormikProps<any>;
  isDisabled?: boolean;
  isEdit?: boolean;
  isRequired?: boolean;
  dataTestId?: string;
  setCurrentValue: Function;
};
const CheckboxForm = ({ name, label, propsFormik, isDisabled = false, dataTestId, setCurrentValue, isRequired }: CheckboxFromType) => {
  const borderError = 'border-red-500 focus:border-red-500 dark:border-red-600 dark:focus:border-red-500 focus:ring-red-500 dark:focus:ring-red-500';
  const borderSuccess = 'border-gray-200 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500';
  return (
    <div className="relative checkbox-form">
      <div className="flex items-center space-x-2">
        <input
          id={label}
          type="checkbox"
          name={name}
          checked={propsFormik.values[name]?.includes(label)}
          onChange={e => {
            setCurrentValue({ value: e.target.checked ? label : '', name }, propsFormik);
          }}
          className={classNames(
            `${
              propsFormik.errors[name] && propsFormik.touched[name] ? borderError : borderSuccess
            } border disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 py-1 text-sm rounded cursor-pointer`,
            { haveValue: propsFormik?.values[name] },
          )}
          disabled={isDisabled}
        />
        <label htmlFor={label} className="text-sm text-gray-900 font-normal cursor-pointer">
          {label}
        </label>
      </div>
      {isRequired && propsFormik.errors[name] && propsFormik.touched[name] && (
        <ErrorMessage data-testid={dataTestId} className="text-red-500 text-xs mt-1" name={name} component="p" />
      )}
    </div>
  );
};

export default CheckboxForm;
