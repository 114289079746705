import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPencilAlt, HiX } from 'react-icons/hi';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import { AbilityContext } from 'contexts/can';
import moment from 'moment';
import { ABILITY_ACTION, FORMAT_DATE_SHOW_TABLE, PERMISSION } from 'utils/constants';
import { DonationType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import CustomSpinner from '../../../../components/common/customSpinner';

type RenderLoansTableDataProps = {
  donations: Partial<DonationType>[];
  openAddModalHandler: Function;
  setIsOpenRemoveModal: Function;
  isOpenRemoveModal: boolean;
  setSelectedDonation: Function;
  isFetching: Boolean;
  isAdmin: boolean;
};

const RenderDonationsTableData = (props: RenderLoansTableDataProps) => {
  const [t] = useTranslation();
  const ability = useContext(AbilityContext);
  const { donations = [], openAddModalHandler, isOpenRemoveModal = false, setIsOpenRemoveModal, setSelectedDonation, isFetching, isAdmin } = props;

  return donations?.length > 0 ? (
    <table className={classNames('w-full h-auto text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': !donations.length })}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 rounded">
        <tr className="capitalize">
          <th scope="col" className="py-3 px-3 max-width-100">
            {t('id')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-100">
            {t('amount')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-100 hidden-mobile">
            {t('date')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-100 hidden-mobile-tablet">
            {t('category')}
          </th>
          <th scope="col" className="py-3 px-3">
            <p className="sr-only">  {t('edit')}</p>
            <p className="sr-only">{t('delete')}</p>
          </th>
        </tr>
      </thead>

      <tbody className="text-xs">
        {donations?.map((donation: Partial<DonationType>, key: number) => {
          return (
            <tr
              key={`${key}-list-donations`}
              data-testid={`donations-table-row-${key}`}
              className={`donation-${key === donations.length - 1 ? 'last' : key
                } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
            >
              <th scope="row" className="py-3 px-3 font-normal text-gray-900 donations--truncate max-width-100 dark:text-white">
                <TooltipComponent
                  anchorSelect={`donation-content-${key}`}
                  content={donation?.recordDonationId}
                  className="font-normal supporter-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th scope="row" className="py-3 px-3 font-normal text-gray-900 donations--truncate max-width-100 dark:text-white">
                <TooltipComponent
                  anchorSelect={`donation-amount-${key}`}
                  content={convertNumberToCurrency(getNumberValueFromApi(donation?.amount as number)) as string}
                  className="font-normal supporter-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th scope="row" className="py-3 px-3 font-normal text-gray-900 donations--truncate max-width-100 dark:text-white hidden-mobile">
                <TooltipComponent
                  anchorSelect={`donation-disbursement-date-${key}`}
                  content={donation?.date ? moment.utc(donation?.date).format(FORMAT_DATE_SHOW_TABLE) : ''}
                  className="font-normal supporter-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th scope="row" className="py-3 px-3 font-normal text-gray-900 donations--truncate max-width-100 dark:text-white hidden-mobile-tablet">
                <TooltipComponent
                  anchorSelect={`donation-total-outstanding-${key}`}
                  content={donation?.category ? donation?.category : ''}
                  className="font-normal supporter-page--truncate max-w-full dark:text-white"
                />
              </th>
              <th scope="row" className="h-full flex justify-end items-center py-3 px-3 space-x-2">
                {ability.can(ABILITY_ACTION.manage, PERMISSION.DONATION.UPDATE) && (
                  <button
                    onClick={() => openAddModalHandler({ isEditClick: true, currentDonation: donation })}
                    className="cursor-pointer px-1 py-1 bg-blue-700 rounded-lg"
                  >
                    <HiPencilAlt className="h-4 w-4" />
                  </button>
                )}
                {ability.can(ABILITY_ACTION.manage, PERMISSION.DONATION.DELETE) && isAdmin && (
                  <button
                    onClick={() => {
                      setIsOpenRemoveModal(!isOpenRemoveModal);
                      setSelectedDonation(donation);
                    }}
                    className="cursor-pointer px-1 py-1 bg-red-700 rounded-lg"
                  >
                    <HiX className="h-4 w-4" />
                  </button>
                )}
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div className="h-full donation-no-result text-center bg-white py-4">{isFetching ? <CustomSpinner /> : <NoResult />}</div>
  );
};

export default RenderDonationsTableData;
