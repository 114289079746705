import { useTranslation } from 'react-i18next';
import GroupButton from 'components/button/groupButton';
import { Modal } from 'flowbite-react';

import './confirmRecalculateModal.scss';

type ConfirmRecalculateModalTypes = {
  openConfirmModal: boolean;
  setOpenConfirmModal: Function;
  handleSubmit: Function;
};

const ConfirmRecalculateModal = (props: ConfirmRecalculateModalTypes) => {
  const { openConfirmModal, setOpenConfirmModal, handleSubmit } = props;
  const [t] = useTranslation();

  return (
    <Modal
      id="confirm-recalculate-modal"
      show={openConfirmModal}
      size="xl"
      onClose={() => {
        setOpenConfirmModal(false);
      }}
      dismissible={true}
    >
      <Modal.Header>{''}</Modal.Header>
      <Modal.Body className="pb-6 pt-2">
        <div className="text-center font-semibold mb-2">{t('confirmRecalculateMessage')}</div>
        <div className="text-center mb-6 ">{t('confirmRecalculateSubMessage')}</div>
        <GroupButton
          className="w-full gap-2 justify-center pb-2"
          buttons={[
            {
              type: 'button',
              text: t('modal.cancel'),
              classType: 'white',
              action: () => setOpenConfirmModal(false),
            },
            {
              type: 'button',
              dataTestId: 'confirm-recalculate-button',
              text: t('modal.save'),
              classType: 'blue',
              action: () => {
                handleSubmit();
                setOpenConfirmModal(false);
              },
            },
          ]}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmRecalculateModal;
