import { DEFAULT_VALUE_FILTER } from 'utils/constants';
import { ContactType, LoanType, NoteType, QueryFilterType } from 'utils/proptypes';

import base from '../baseApi';

export type AddEditPartnerType = {
  name: string;
  contacts?: ContactType[];
  loans?: LoanType[];
  note?: NoteType[];
};

export type UpdateRatingPartnerType = {
  internalRating?: number;
  externalRating?: number;
  outlook?: string;
  internalRatingDate?: string;
  externalRatingDate?: string;
};

export type AddPartnerContactsType = {
  contacts: ContactType[];
};

const { urls, methods, execute } = base();

const createPartner = (payload: AddEditPartnerType) => {
  const method = methods.POST;
  const url = urls.partner.partners;
  return execute(method, url, { authorization: true }, payload);
};

const getPartnersByFilter = async (queryFilter: QueryFilterType) => {
  const { page = DEFAULT_VALUE_FILTER.PAGE, limit = DEFAULT_VALUE_FILTER.LIMIT, searchQuery = DEFAULT_VALUE_FILTER.SEARCH_QUERY } = queryFilter;
  const method = methods.GET;
  const url = `${urls.partner.filter}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  return execute(method, url, { authorization: true });
};

const getPartnerById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.partner.partners}/${id}`;
  return execute(method, url, { authorization: true });
};

const updatePartnerById = <T = AddEditPartnerType>(id: string, payload: T) => {
  const method = methods.PATCH;
  const url = `${urls.partner.partners}/${id}`;
  return execute(method, url, { authorization: true }, payload as object);
};

const addPartnerContacts = (id: string, payload: AddPartnerContactsType) => {
  const method = methods.PUT;
  const url = `${urls.partner.partners}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const removePartner = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.partner.partners}/${id}`;

  return execute(method, url, { authorization: true });
};

const exportPartnersToCSV = async (payload: { searchQuery: string }) => {
  const method = methods.POST;
  const url = urls.partner.exportPartnersToCSV;
  return execute(method, url, { authorization: true }, payload);
};

const updatePartnerContact = (partnerId: string, contactId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.partner.partners}/${partnerId}/contacts/${contactId}`;
  return execute(method, url, { authorization: true }, payload);
};

export {
  createPartner,
  getPartnersByFilter,
  getPartnerById,
  updatePartnerById,
  removePartner,
  addPartnerContacts,
  exportPartnersToCSV,
  updatePartnerContact,
};
