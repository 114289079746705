import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCash } from 'react-icons/hi';
import { useMutation, useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import { getPortfolioReports, updateReference, UpdateReferencePayloadType } from 'api/accountant/reportApi';
import { CardIndexType } from 'components/common/CardIndex';
import CardIndexGroup from 'components/common/CardIndexGroup';
import PaginateTable from 'components/table/paginate';
import { Button } from 'flowbite-react';
import useLoading from 'hooks/useLoading';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { DEFAULT_VALUE_FILTER, EXPORT_FILE_NAME, FORMAT_DATE_API, FORMAT_DATE_SHOW_TABLE, TYPE_INPUT } from 'utils/constants';
import { FilterReportsValueType, FilterType, OptionType, PieChartDataType, PortfolioReportsResponse } from 'utils/proptypes';
import {
  convertNumberToCurrency,
  exportToFile,
  getNumberValueFromApi,
  getNumberValueSendToApi,
  getPieChartOptions,
  messageErrors,
} from 'utils/utils';
import { read, utils } from 'xlsx';

import HeaderTableReports from './components/headerTableReports';
import GroupPieChartsCustom from './components/portfolioReports/groupPieChart';
import PieChartCustom from './components/portfolioReports/pieChartCustom';
import RenderPortfolioReportsTableData from './components/portfolioReports/renderPortfolioReportsTable';

import '../../../../styles/styles.scss';
import '../reports.scss';
import './portfolioReportPdf.scss';

const PortfolioReportPdfPage = () => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE_FILTER.PAGE);
  const [totalEntities, setTotalEntities] = useState(0);
  const [totalEntitiesNonPerforming, setTotalEntitiesNonPerforming] = useState(0);
  const limit = 100;
  const [portfolioReportsPerformingData, setPortfolioReportsPerformingData] = useState<PortfolioReportsResponse>();
  const [portfolioReportsNonPerformingData, setPortfolioReportsNonPerformingData] = useState<PortfolioReportsResponse>();
  const [pieChartDataGroup, setPieChartDataGroup] = useState<{
    reportDate: string;
    dataPieCharts: PieChartDataType[];
  }>();
  const [filterValue, setFilterValue] = useState<Partial<FilterReportsValueType>>({
    reportDate: moment().format(FORMAT_DATE_API),
  });

  const [__html, setHtml] = useState('');
  const tbl = useRef(null);
  const refReportPDF = useRef(null);
  const { t } = useTranslation();

  const handleDownloadPDF = useReactToPrint({
    content: () => refReportPDF.current,
    documentTitle: `PORTFOLIO REPORT REPORT ${filterValue.reportDate}`,
    pageStyle: `
    @page {
      size: A3 landscape;
    }
  `,
  });

  const { refetch: refetchPortfolioReports } = useQuery(
    ['portfolioReports', currentPage, filterValue],
    () => getPortfolioReports({ page: currentPage, limit, ...filterValue }),
    {
      onSuccess: ({ data }) => {
        setTotalEntities(data.entities?.length as number);
        setPortfolioReportsPerformingData({
          ...data,
        });
        const countryChartData = data.charts?.country?.length
          ? data.charts?.country?.map((item: OptionType) => ({
            name: item.label,
            value: getNumberValueFromApi(item.value as number),
          }))
          : [];
        const sectorChartData = data.charts?.sector?.length
          ? data.charts?.sector?.map((item: OptionType) => ({
            name: item.label,
            value: getNumberValueFromApi(item.value as number),
          }))
          : [];
        const partnerAssetSize = !isEmpty(data.charts?.partnerAssetSize) ? getPieChartOptions(data.charts?.partnerAssetSize) : [];
        const scorecards = !isEmpty(data.charts?.scorecard) ? getPieChartOptions(data.charts?.scorecard) : [];
        const dataPieChartGroup = {
          reportDate: moment.utc(filterValue.reportDate).format(FORMAT_DATE_SHOW_TABLE),
          dataPieCharts: [
            {
              title: t('WCCNPorfolioAllocationByCountry'),
              data: [...countryChartData],
            },
            {
              title: t('WCCNPorfolioAllocationBySector'),
              data: [...sectorChartData],
            },
            {
              title: t('WCCNPorfolioAllocationByPartnerAssetSize'),
              data: [...partnerAssetSize],
            },
            {
              title: t('WCCNPorfolioAllocationByScorecard'),
              data: [...scorecards],
            },
          ],
        };
        setPieChartDataGroup({ ...dataPieChartGroup });
        const f = new ArrayBuffer(data.entities);
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const dataPortfolioReport = utils.sheet_to_html(ws);
        setHtml(dataPortfolioReport);
      },
      onError: () => {
        setPortfolioReportsPerformingData(undefined);
        setTotalEntities(0);
      },
    },
  );

  const { refetch: refetchNonPerformingPortfolioReports } = useQuery(
    ['nonPerformingPortfolioReports', currentPage, filterValue],
    () => getPortfolioReports({ page: currentPage, limit, ...filterValue, isNonPerforming: 1 }),
    {
      onSuccess: ({ data }) => {
        setTotalEntitiesNonPerforming(data.entities?.length as number);
        setPortfolioReportsNonPerformingData({
          ...data,
        });
      },
    },
  );

  const handleUpdateReference = async (referencePayload: Partial<UpdateReferencePayloadType>) => {
    await updateReference(referencePayload);
  };

  const mutation = useMutation('update-report-reference', {
    mutationFn: handleUpdateReference,
  });

  const updateReferenceClick = ({ fieldNameInput, value }: { fieldNameInput: string; value: number }) => {
    mutation.mutate(
      { [fieldNameInput]: getNumberValueSendToApi(value) },
      {
        onSuccess: async () => {
          const message: string = t('reportPage.editReferenceSuccessMessage');
          toast.success(message);
          await refetchPortfolioReports();
          await refetchNonPerformingPortfolioReports();
        },
        onError: (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  const cardIndexData: CardIndexType[] = [
    {
      inputType: TYPE_INPUT.CURRENCY,
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'capitalForCommunitiesFund',
      bgColor: 'bg-yellow-50',
      bgIconColor: 'bg-yellow-400',
      textIconColor: 'text-white',
      label: t('capitalForCommunitiesFund'),
      value: portfolioReportsPerformingData?.capitalForCommunitiesFund
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.capitalForCommunitiesFund))
        : '',
      icon: HiOutlineCash,
    },
    {
      inputType: TYPE_INPUT.CURRENCY,
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'capitalForCommunitiesCommunityNeedsLLC',
      bgColor: 'bg-purple-50',
      bgIconColor: 'bg-purple-500',
      textIconColor: 'text-white',
      label: t('communityNeedsLLC'),
      value: portfolioReportsPerformingData?.capitalForCommunitiesCommunityNeedsLLC
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.capitalForCommunitiesCommunityNeedsLLC))
        : '',
      icon: HiOutlineCash,
    },
    {
      inputType: 'currency',
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'totalLoanPortfolio',
      bgColor: 'bg-green-100',
      bgIconColor: 'bg-green-400',
      textIconColor: 'text-white',
      label: t('totalLoanPortfolio'),
      value: portfolioReportsPerformingData?.totalLoanPortfolio
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.totalLoanPortfolio))
        : '',
      icon: HiOutlineCash,
    },
    {
      inputType: 'currency',
      changeValueHandler: updateReferenceClick,
      fieldNameInput: 'totalAssets',
      bgColor: 'bg-red-50',
      bgIconColor: 'bg-red-500',
      textIconColor: 'text-white',
      label: t('totalWCCNAssets'),
      value: portfolioReportsPerformingData?.totalAssets
        ? convertNumberToCurrency(getNumberValueFromApi(portfolioReportsPerformingData.totalAssets))
        : '',
      icon: HiOutlineCash,
    },
  ];

  return (
    <div className='portfolio-report-pdf'>
      <div className="flex flex-wrap justify-center">
        {pieChartDataGroup?.dataPieCharts?.map((item: PieChartDataType, index: number) => (
          <PieChartCustom key={`pie-chart-key-${index}`} title={item.title} data={item.data} reportDate={pieChartDataGroup?.reportDate} />
        ))}
      </div>
      <CardIndexGroup cardIndexData={cardIndexData} className="mt-4 grid md:grid-cols-4 xl:grid-cols-4 gap-4" />
      <div className="portfolio-report__table w-full max-w-full mt-4">
        <RenderPortfolioReportsTableData
          dangerouslySetInnerHTML={{ __html }}
          tbl={tbl}
          portfolioReportsResponse={portfolioReportsPerformingData}
          filterValue={filterValue}
        />
      </div>
      <div className="portfolio-report__table w-full max-w-full mt-4">
        <RenderPortfolioReportsTableData
          dangerouslySetInnerHTML={{ __html }}
          tbl={tbl}
          portfolioReportsResponse={portfolioReportsNonPerformingData}
          filterValue={filterValue}
          isNonPerforming={true}
        />
      </div>
    </div>
  );
};
export default PortfolioReportPdfPage;
