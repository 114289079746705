const vector = require('../../../../../assets/image/svg/partners/vector.svg').default;
const VectorIcon = () => {
  return (
    <svg xmlns={vector} width="24" height="24" fill="none" viewBox="-3 -3 22 22">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.82 1.82A1.667 1.667 0 001.332 3v1.666a1.667 1.667 0 001.667 1.667h1.666a1.667 1.667 0 001.667-1.667V3a1.667 1.667 0 00-1.667-1.667H3c-.442 0-.866.176-1.179.488zM10.153 1.82A1.667 1.667 0 009.665 3v1.666a1.667 1.667 0 001.667 1.667h1.667a1.667 1.667 0 001.666-1.667V3A1.667 1.667 0 0013 1.332h-1.667c-.442 0-.866.176-1.179.488zM1.82 10.153a1.667 1.667 0 00-.488 1.179v1.667a1.667 1.667 0 001.667 1.666h1.666A1.667 1.667 0 006.332 13v-1.667a1.667 1.667 0 00-1.667-1.667H3c-.442 0-.866.176-1.179.489zM10.153 10.153a1.667 1.667 0 00-.488 1.179v1.667a1.667 1.667 0 001.667 1.666h1.667A1.667 1.667 0 0014.665 13v-1.667A1.667 1.667 0 0013 9.665h-1.667c-.442 0-.866.176-1.179.489z"
      />
    </svg>
  );
};

export default VectorIcon;
