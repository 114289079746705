import cn from 'classnames';

import './styles.scss';

const GroupButton = (props: any) => {
  const { className, buttons } = props;
  return (
    <div className={cn('flex mt-2', className)}>
      {buttons?.map((button: any, index: number) => {
        let classType = '';
        if (button?.classType === 'white') {
          classType = 'text-blue-600 hover:bg-blue-100 bg-blue-700';
        } else if (button?.classType === 'blue') {
          classType = 'text-white hover:bg-blue-700 bg-fill-blue-700';
        } else if (button?.classType === 'red') {
          classType = 'text-red-600 hover:bg-blue-700 bg-red-700';
        }
        return (
          <div className={`relative ${index !== 0 ? 'ml-4' : ''}`} key={`key_${index}`}>
            <button
              data-testid={button.dataTestId || ''}
              type={button?.type}
              onClick={button?.action || (() => { })}
              disabled={button?.isLoading}
              className={`button ${button?.isLoading ? 'is-loading' : ''
                } ${classType} border focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center`}
            >
              {button?.text}
            </button>
          </div>
        );
      })}
    </div>
  );
};
export default GroupButton;
