import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getPaymentsScheduleByLoanId,
} from 'api/loanFundManager/schedulePaymentApi';
import useLoading from 'hooks/useLoading';
import { LoanType, PaymentType } from 'utils/proptypes';

import RenderPaymentsTableScheduleDataPdf from './components/RenderPaymentsTableScheduleDataPdf';

type PaymentSchedulePdfType = {
  selectedLoan?: LoanType;
  isFetchingLoan: boolean;
};

const PaymentSchedulePdf = ({ selectedLoan, isFetchingLoan = false }: PaymentSchedulePdfType) => {
  const [t] = useTranslation();
  const params = useParams();
  const loanId = params.id;
  const [paymentsSchedule, setPaymentsSchedule] = useState<PaymentType[]>([]);
  const [totalDisplay, setTotalDisplay] = useState({
    totalDaysSinceLastTransaction: 0,
    totalInterestAmount: 0,
    totalOfTotalToPay: 0,
    totalPrincipalAmount: 0,
  });

  const ref = useRef(null);

  const { refetch: fetchPaymentsScheduleByLoanId, isFetching: isFetchingPaymentsSchedule } = useQuery(
    ['paymentsSchedule'],
    () => getPaymentsScheduleByLoanId(loanId as string),
    {
      onSuccess: ({ data }) => {
        setPaymentsSchedule(data.entities);
        setTotalDisplay({
          totalDaysSinceLastTransaction: data.totalDaysSinceLastTransaction,
          totalInterestAmount: data.totalInterestAmount,
          totalOfTotalToPay: data.totalOfTotalToPay,
          totalPrincipalAmount: data.totalPrincipalAmount,
        });
      },
      onError: () => {
        setPaymentsSchedule([]);
      },
    },
  );

  useLoading({ isLoading: isFetchingPaymentsSchedule || isFetchingLoan, ref });

  return (
    <>
      <RenderPaymentsTableScheduleDataPdf
        totalDisplay={totalDisplay}
        paymentsSchedule={paymentsSchedule}
        isFetching={isFetchingPaymentsSchedule}
      />
    </>
  );
};

export default PaymentSchedulePdf;
