import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import { AbilityContext } from 'contexts/can';
import { ABILITY_ACTION, PERMISSION } from 'utils/constants';
import { AddressType, ContactType, PartnerType } from 'utils/proptypes';
import { concatItemsDisplayName, getTotalBorrowed, getTotalOutstanding } from 'utils/utils';

import CustomSpinner from '../../../../components/common/customSpinner';

type renderPartnersTableDataType = {
  partners: PartnerType[];
  openPartnerDetail: Function;
  editPartnerHandler: Function;
  removePartner: Function;
  isFetching: boolean;
};

const RenderPartnersTableData = (props: renderPartnersTableDataType) => {
  const ability = useContext(AbilityContext);
  const [t] = useTranslation()
  const { partners, openPartnerDetail, isFetching } = props;
  return (
    <table
      className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': !partners.length })}
      data-testid="test-partner-table"
    >
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="py-3 px-6 max-width-140 hidden-mobile-tablet">
            {t('nickName')}
          </th>
          <th scope="col" className="py-3 px-6 max-width-140">
            {t('name')}
          </th>
          <th scope="col" className="py-3 px-6 max-width-140 hidden-mobile-tablet">
            {t('city')}
          </th>
          <th scope="col" className="py-3 px-6 max-width-140 hidden-mobile">
            {t('country')}
          </th>
          <th scope="col" className="py-3 px-6 max-width-140 hidden-mobile">
            {t('totalBorrowed')}
          </th>
          <th scope="col" className="py-3 px-6 max-width-140 hidden-mobile">
            {t('totalOutStanding')}
          </th>
        </tr>
      </thead>
      <tbody>
        {partners?.length ? (
          partners?.map((item: PartnerType, key: number) => {
            return (
              <tr
                key={`${key}-list-partner`}
                className={`partner-${key === partners?.length - 1 ? 'last' : key
                  } bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
                data-testid="test-partner-row"
                onClick={() => {
                  if (ability.can(ABILITY_ACTION.manage, PERMISSION.PARTNER.VIEW_DETAIL)) openPartnerDetail(item);
                }}
              >
                <th
                  scope="row"
                  className="py-4 px-6 font-normal text-gray-900 partners-page--truncate max-width-140 dark:text-white hidden-mobile-tablet"
                >
                  <TooltipComponent
                    anchorSelect={`partner-nickname-${key}`}
                    content={item?.nickName}
                    className="font-semibold partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 partners-page--truncate max-width-140 dark:text-white">
                  <TooltipComponent
                    anchorSelect={`partner-name-${key}`}
                    content={item?.name}
                    className="font-semibold partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th
                  scope="row"
                  className="py-4 px-6 font-normal text-gray-900 partners-page--truncate max-width-140 dark:text-white hidden-mobile-tablet"
                >
                  <TooltipComponent
                    anchorSelect={`partner-city-${key}`}
                    content={concatItemsDisplayName<ContactType, AddressType>({
                      items: item?.contacts,
                      argFilter: 'address',
                      argDisplay: 'city',
                    })}
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 partners-page--truncate max-width-140 dark:text-white hidden-mobile">
                  <TooltipComponent
                    anchorSelect={`partner-country-${key}`}
                    content={
                      concatItemsDisplayName<ContactType, AddressType>({
                        items: item?.contacts,
                        argFilter: 'address',
                        argDisplay: 'country',
                      }) || ''
                    }
                    className="font-normal partners-page--truncate max-w-full dark:text-white"
                  />
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 partners-page--truncate max-width-140 dark:text-white hidden-mobile">
                  {item?.totalBorrowed ? getTotalBorrowed(item?.totalBorrowed) : ''}
                </th>
                <th scope="row" className="py-4 px-6 font-normal text-gray-900 partners-page--truncate dark:text-white max-width-140 hidden-mobile">
                  <div className="flex items-center">{item?.totalOutstanding ? getTotalOutstanding(item?.totalOutstanding) : ''}</div>
                </th>
              </tr>
            );
          })
        ) : (
          <tr className="h-full partner-no-result text-center">
            <th colSpan={6}>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RenderPartnersTableData;
