import moment from 'moment/moment';
import { DEFAULT_VALUE_FILTER, FORMAT_DATE_API } from 'utils/constants';
import { GetDonationBySupporterFilter, NoteType, OptionType } from 'utils/proptypes';

import base from '../baseApi';

export type AddEditDonationType = {
  supporterId: string;
  amount: number;
  date: Date;
  category: OptionType[];
  note?: NoteType[];
};

const { urls, methods, execute } = base();

const createDonation = (payload: AddEditDonationType) => {
  const method = methods.POST;
  const url = urls.donation.donations;
  return execute(method, url, { authorization: true }, payload);
};

const updateDonationById = (id: string, payload: Partial<AddEditDonationType>) => {
  const method = methods.PATCH;
  const url = `${urls.donation.donations}/${id}`;
  return execute(method, url, { authorization: true }, payload);
};

const removeDonation = (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.donation.donations}/${id}`;

  return execute(method, url, { authorization: true });
};

export const getDonationBySupporter = (supporterId: string, queryFilter: GetDonationBySupporterFilter) => {
  const { page = DEFAULT_VALUE_FILTER.PAGE, limit = DEFAULT_VALUE_FILTER.LIMIT, f_startDate, f_endDate } = queryFilter;
  const method = methods.GET;

  const url = `${urls.donation.donationSupporter}/${supporterId}/?page=${page}&limit=${limit}${f_startDate ? `&f_startDate=${f_startDate}` : ''}${
    f_endDate ? `&f_endDate=${f_endDate}` : ''
  }`;
  return execute(method, url, { authorization: true });
};

const downloadDonationInfoBySupporterId = (supporterId: string, filterDonationBySupproter: GetDonationBySupporterFilter) => {
  const { f_startDate, f_endDate } = filterDonationBySupproter;
  const method = methods.POST;
  const url = `${urls.donation.donationSupporter}/${supporterId}/${urls.donation.downloadDonationInfo}?${
    f_startDate ? `f_startDate=${f_startDate}&` : ''
  }${f_endDate ? `f_endDate=${moment(f_endDate).format(FORMAT_DATE_API)}` : ''}`;
  return execute(method, url, { authorization: true });
};

const downloadAllDonationInfo = (filterDonationBySupporter: GetDonationBySupporterFilter) => {
  const { f_startDate, f_endDate } = filterDonationBySupporter;
  const method = methods.POST;
  const url = `${urls.donation.donationDownloadAllDonationInfo}?${f_startDate ? `f_startDate=${f_startDate}&` : ''}${
    f_endDate ? `f_endDate=${moment(f_endDate).format(FORMAT_DATE_API)}` : ''
  }`;
  return execute(method, url, { authorization: true });
};

export { createDonation, updateDonationById, removeDonation, downloadDonationInfoBySupporterId, downloadAllDonationInfo };
